import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const NotasManualesTableMultipleGrades = ({
    enrolledUsers,
    identifier,
    title,
    selectedEvaluation,
    alerts,
    setAlerts,
    setAlertSuccess,
    setAlertError
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const handleDownloadEncuestaQuestionResponsesCSV = () => {
        let header = [
            "Usuario",
            "Nota",
            "Observaciones"
        ];
        let lines = [];
        let output = [];
        for (let user of enrolledUsers) {
            var thisLine = [];
            thisLine.push(user.username);
            thisLine.push(selectedEvaluation.grades.find(grade => grade.user_id === user.user_id)?.grade.toFixed(1) || "—");
            thisLine.push(selectedEvaluation.grades.find(grade => grade.user_id === user.user_id)?.observations || "—");
            lines.push(thisLine);
        }
        
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        
        const fileName = "notas_" + selectedEvaluation.evaluation_name.replace(/ /g, "_") + "_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'grade',
            disableColumnMenu: true,
            headerName: 'Nota',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'observations',
            disableColumnMenu: true,
            headerName: 'Observaciones',
            width: 650,
            headerAlign: 'center',
            sortable: false,
            align: 'left'
        }
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(enrolledUsers)) {
            rows.push({
                id: key,
                username: value.username || "—",
                grade: selectedEvaluation.grades.find(grade => grade.user_id === value.user_id)?.grade.toFixed(1) || "—",
                observations: selectedEvaluation.grades.find(grade => grade.user_id === value.user_id)?.observations || ""
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Notas</Typography>
                <Box>
                    <IconButton onClick={handleDownloadEncuestaQuestionResponsesCSV}><DownloadIcon></DownloadIcon></IconButton> 
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        (selectedEvaluation === undefined) ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p-small' textAlign={"center"}>No hay datos disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1010px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    getRowHeight={() => 'auto'}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'username',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default NotasManualesTableMultipleGrades;