import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Grid, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from "@mui/icons-material/Close";
import CatalogCourseSectionCreateModal from "./crud/CatalogCourseSectionCreateModal";
import CatalogCourseSection from "../../../catalog/CatalogCourseSection";


const CatalogCourseSectionsModal = ({
    keycloak,
    open,
    handleClose,
    catalog,
    setCatalog,
    courseIndex,
    implementationIndex,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [implementation, setImplementation] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const handleOpenCreateModal = () => {
        setShowCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    }

    useEffect(() => {
        setImplementation(catalog.courses[courseIndex].implementations[implementationIndex]);
    }, [catalog]);
    
    return (
        implementation && 
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                {catalog.courses[courseIndex].shortname} — Implementación {implementation.year}-{implementation.term} ({implementation.type})
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={"1.1em"} variant="p" fontWeight={"bold"}>Secciones</Typography>
                    </Grid>
                    {implementation.sections.length === 0 ?
                        <Grid item xs={12} mb={"20px"}>
                            <Typography variant={"p"} fontSize={"0.8em"} textAlign={"center"}>No hay secciones.</Typography>
                        </Grid>
                        :
                        implementation.sections.map((section, sectionIndex) => {
                            return (
                                <Grid item xs={12} md={6} key={sectionIndex}>
                                    <CatalogCourseSection
                                        keycloak={keycloak}
                                        catalog={catalog}
                                        setCatalog={setCatalog}
                                        courseIndex={courseIndex}
                                        implementationIndex={implementationIndex}
                                        sectionIndex={sectionIndex}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    ></CatalogCourseSection>
                                </Grid>
                            );
                        })
                    }
                    <Grid item xs={12} textAlign={"center"}>
                        <Button variant='blue' onClick={handleOpenCreateModal} startIcon={<AddCircleOutlineIcon />}>
                            Nueva sección
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <CatalogCourseSectionCreateModal
                keycloak={keycloak}
                open={showCreateModal}
                handleClose={handleCloseCreateModal}
                courseIndex={courseIndex}
                implementation={implementation}
                catalog={catalog}
                setCatalog={setCatalog}
                otherSections={implementation.sections}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </Dialog>
    );

}

export default CatalogCourseSectionsModal;