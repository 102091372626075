import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider, Link, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { postUpdateUserSituacion } from '../../../requests/postUpdateUserSituacion';


const ResumenUserActionsModal = ({
    keycloak,
    open,
    handleClose,
    courseKey,
    courseType,
    selectedUser,
    situacion,
    setSituaciones,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {
    
    const [situacionActual, setSituacionActual] = useState("none");
    const [situacionFinalCMM, setSituacionFinalCMM] = useState("none");
    const [situacionFinalCPEIP, setSituacionFinalCPEIP] = useState("none");
    const [observaciones, setObservaciones] = useState("");
    const [loading, setLoading] = useState(false);
    const [observacionesError, setObservacionesError] = useState("");

    const validateObservaciones = (observaciones) => {
        if (observaciones !== "") {
            if (observaciones.length > 300) {
                return "El máximo de caracteres permitidos es de 300.";
            }
        }
        return "";
    }

    const handleUpdateSituacion = async () => {
        let observacionesError = validateObservaciones(observaciones);
        if (observacionesError !== "") {
            setObservacionesError(observacionesError);
            return;
        }
        setLoading(true);
        let data = {
            "user_id": selectedUser.id,
            "course_key": courseKey,
            "course_type": courseType,
            "situacion_actual": situacionActual !== "none" ? situacionActual : null,
            "situacion_final_cmm": situacionFinalCMM !== "none" ? situacionFinalCMM : null,
            "situacion_final_cpeip": situacionFinalCPEIP !== "none" ? situacionFinalCPEIP : null,
            "observaciones": observaciones !== "" ? observaciones : null
        }
        const response = await postUpdateUserSituacion(keycloak.token, data);
        if (response.error) {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al actualizar situación del docente", text: response.error },
            });
        } else {
            setAlertSuccess(true);
            setAlerts({
                ...alerts,
                success: { title: "Situación del docente actualizada correctamente", text: "La situación del docente ha sido actualizada correctamente." },
            });
            handleClose();
            setSituaciones(response.situaciones);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (situacion) {
            setSituacionActual(situacion.current_situation ? situacion.current_situation : "none");
            setSituacionFinalCMM(situacion.final_situation ? situacion.final_situation : "none");
            setSituacionFinalCPEIP(situacion.final_situation_cpeip ? situacion.final_situation_cpeip : "none");
            setObservaciones(situacion.observaciones ? situacion.observaciones : "");
        }
    }, [situacion, selectedUser]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={() => {setObservacionesError(""); handleClose()}}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Gestionar situación del docente
                <Typography variant="p-small" fontSize={"1rem"} fontWeight={"bold"}>{selectedUser.username}</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2} mt={"10px"}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Situación actual"
                            fullWidth
                            value={situacionActual}
                            onChange={(e) => setSituacionActual(e.target.value)}
                            select
                        >
                            <MenuItem value="none">Sin situación actual</MenuItem>
                            <MenuItem value="Activo">Activo</MenuItem>
                            <MenuItem value="Atrasado">Atrasado</MenuItem>
                            <MenuItem value="Retirado">Retirado</MenuItem>
                            <MenuItem value="Incomunicado">Incomunicado</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Situación final CMMEdu"
                            fullWidth
                            value={situacionFinalCMM}
                            onChange={(e) => setSituacionFinalCMM(e.target.value)}
                            select
                        >
                            <MenuItem value="none">Sin situación final</MenuItem>
                            <MenuItem value="Aprobado">Aprobado</MenuItem>
                            <MenuItem value="Reprobado">Reprobado</MenuItem>
                            <MenuItem value="Eliminado">Eliminado</MenuItem>
                            <MenuItem value="Abandono">Abandono</MenuItem>
                            <MenuItem value="Retirado">Retirado</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Situación final CPEIP"
                            fullWidth
                            value={situacionFinalCPEIP}
                            onChange={(e) => setSituacionFinalCPEIP(e.target.value)}
                            select
                        >
                            <MenuItem value="none">Sin situación final</MenuItem>
                            <MenuItem value="Aprobado">Aprobado</MenuItem>
                            <MenuItem value="Reprobado">Reprobado</MenuItem>
                            <MenuItem value="Retirado">Retirado</MenuItem>
                            <MenuItem value="Desertor">Desertor</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Observaciones"
                            fullWidth
                            multiline
                            rows={4}
                            value={observaciones}
                            onChange={(e) => {
                                setObservaciones(e.target.value)
                                setObservacionesError("")
                            }}
                            error={observacionesError !== ""}
                            helperText={observacionesError}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ "display": "flex", "justifyContent": "center" }}>
                        <Button variant="red" onClick={handleUpdateSituacion} disabled={loading}>Actualizar</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ResumenUserActionsModal;