import React from "react";
import { Typography, Grid, Box, CircularProgress, Divider } from "@mui/material";

const CourseSectionsAll = ({
    keycloak,
    course,
    selectedImplementation,
    allImplementationData,
    loadingAllImplementationData,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    return (
        (!loadingAllImplementationData && allImplementationData) ?
            <>
                <Grid container spacing={2} mt={"5px"}>
                    <Grid item xs={12}>
                        <Typography fontSize={{xs: "0.8em", sm: "0.9em", md: "1.0em", lg: "1.2em"}} variant="title">
                            Implementación {course.implementations[selectedImplementation]?.year}-{course.implementations[selectedImplementation]?.term} ({course.implementations[selectedImplementation]?.type})
                        </Typography>
                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                        <Typography textAlign={"center"} mt={"50px"} variant="p">
                            Los datos agregados por implementación estarán disponibles pronto.
                        </Typography>
                    </Grid>
                </Grid>
            </> 
        : loadingAllImplementationData ?
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}> 
                <CircularProgress />
            </Box> 
        : 
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}> 
                <Typography variant="p-small">No se pudo obtener la información de la implementación</Typography>
            </Box>
    );
}

export default CourseSectionsAll;