import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import { formatTimestamp, formatXBlockResponse, getXBlockResponseAttempts, getXBlockResponseIsCorrect } from '../../../utils/formatters';
import { MathJax } from 'better-react-mathjax';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const XblockResponsesTable = ({
    enrolledUsers,
    xblock,
    responses,
    identifier,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const handleDownloadXBlockResponsesCSV = () => {
        let header = [
            "Usuario",
            "Fecha de respuesta",
            "Respuesta",
            "Intentos",
            "Correcto"
        ];
        let lines = [];
        let output = [];
        
        for (let response of responses) {
            var thisLine = [];
            // Get username or default to "—"
            let username = enrolledUsers.find(user => user.user_id === response.user_id)?.username || "—"
            if (username === "—") {
                continue;
            }
            thisLine.push(username);
            
            // Format timestamp or default to "—"
            thisLine.push(response.created ? new Date(response.created).toLocaleString() : "—");
            
            // Get response text and decode HTML entities
            const responseText = response.response ? formatXBlockResponse(xblock, response.response, username) : "—";
            const decodedText = document.createElement('textarea');
            decodedText.innerHTML = responseText;
            thisLine.push(decodedText.value);
            
            // Get attempts
            thisLine.push(response.response ? getXBlockResponseAttempts(xblock.true_block_type, response.response) : "—");
            
            // Format correct/incorrect status
            const isCorrect = response.response ? getXBlockResponseIsCorrect(xblock, response.response, username) : null;
            thisLine.push(isCorrect !== null ? (isCorrect ? "Sí" : "No") : "—");
            
            lines.push(thisLine);
        }
        
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        
        const fileName = "respuestas_xblock_" + xblock.block_key + "_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'created',
            disableColumnMenu: true,
            headerName: 'Fecha de respuesta',
            width: 240,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'response',
            disableColumnMenu: true,
            headerName: 'Respuesta',
            width: 450,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    <MathJax>
                        <div
                            style={{ maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{
                                __html: params.value.replace(
                                    /<img/g,
                                    '<img style="max-width:100%;height:auto"'
                                )
                            }}
                        />
                    </MathJax>
                </Box>
            ),
        },
        {
            field: 'attemps',
            disableColumnMenu: true,
            headerName: 'Intentos',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'is_correct',
            disableColumnMenu: true,
            headerName: 'Correcto',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            valueGetter: (params, row) => row == null ? "—" : row.is_correct == null ? "—" : row.is_correct,
            renderCell: (params) => (
                params.row.is_correct !== "—" ?
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.row.is_correct ?
                            <CheckCircleIcon sx={{ color: "green" }}>
                            </CheckCircleIcon> :
                            <CancelIcon sx={{ color: "red" }}>
                            </CancelIcon>
                        }
                    </Box> : "—"
            ),
        }
    ];

    function makeRows() {
        let rows = [];
        // Create a map to track unique user responses
        const uniqueResponses = new Map();

        for (let [key, value] of Object.entries(responses)) {
            let username = enrolledUsers.find(user => user.user_id === value.user_id)?.username || "—"
            if (username === "—") {
                continue;
            }

            // Create a unique key for each user
            const userKey = value.user_id;
            
            // Only keep the latest response for each user
            if (!uniqueResponses.has(userKey) || 
                new Date(value.created) > new Date(uniqueResponses.get(userKey).created)) {
                uniqueResponses.set(userKey, value);
            }
        }

        // Convert unique responses to rows
        for (let value of uniqueResponses.values()) {
            let username = enrolledUsers.find(user => user.user_id === value.user_id)?.username;
            let is_correct = value.response ? getXBlockResponseIsCorrect(xblock, value.response, username) : "—"
            rows.push({
                id: value._id,
                username: username,
                created: value.created ? formatTimestamp(value.created) : "—",
                response: value.response ? formatXBlockResponse(xblock, value.response, username) : "—",
                attemps: value.response ? getXBlockResponseAttempts(xblock.true_block_type, value.response) : "—",
                is_correct: is_correct !== null ? is_correct : "—"
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Lista de respuestas del XBlock</Typography>
                <Box>
                    <IconButton onClick={handleDownloadXBlockResponsesCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        responses.length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p-small' textAlign={"center"}>No hay datos de respuestas disponibles, o este XBlock no tiene respuestas.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1120px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    getRowHeight={() => 'auto'}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default XblockResponsesTable;