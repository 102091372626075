import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, List, ListItem, ListItemButton, Card, CardContent, Divider } from '@mui/material';
import EventsModal from '../../modals/EventsModal';
import AsistenciaTable from '../../tables/AsistenciaTable';
import { asistenciaChart } from '../../../../utils/charts';


const CourseSectionAsistencia = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    asistencia,
    setAsistencia,
    selectedModule,
    selectedImplementation,
    selectedSection,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {
    // Track current selected tab/dashboard
    const [selectedTab, setSelectedTab] = useState("Resumen");
    const [eventsModal, setEventsModal] = useState(false);
    const [eventsList, setEventsList] = useState([]);

    // Update events list when asistencia changes
    useEffect(() => {
        // Only update if events were added/removed
        const newEventsList = asistencia.map(event => event.name);
        if (JSON.stringify(newEventsList) !== JSON.stringify(eventsList)) {
            setEventsList(newEventsList);
        }
    }, [asistencia]);

    useEffect(() => {
        if (selectedTab !== "Resumen") {
            asistenciaChart(asistencia.find(event => event.event_name === selectedTab).asistencia);
        }
    }, [selectedTab, asistencia]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    return (
        sectionData ?
            <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                {asistencia.length === 0 ?
                    <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                        <Typography variant={"p-small"} textAlign={"center"} mb={"20px"}>No hay eventos registrados.</Typography>
                        <Button variant="red" onClick={() => setEventsModal(true)}>Gestionar eventos</Button>
                    </Box>
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <List>
                                <ListItem key={"Resumen"} disablePadding sx={{ "backgroundColor": selectedTab === "Resumen" ? "#60696C" : "#efefef" }}>
                                    <ListItemButton onClick={() => handleTabClick("Resumen")}>
                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={selectedTab === "Resumen" ? "#efefef" : "#646464"}>Resumen</Typography>
                                    </ListItemButton>
                                </ListItem>
                                {asistencia.length > 0 && (
                                    <>
                                        <Typography variant="p" sx={{
                                            color: "#646464",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "0.9em",
                                            marginTop: "15px",
                                            fontFamily: "Avenir Heavy"
                                        }}>Eventos</Typography>
                                        <List>
                                            {asistencia.map(event => (
                                                <ListItem key={event.event_name} disablePadding sx={{ "backgroundColor": selectedTab === event.event_name ? "#60696C" : "#efefef" }}>
                                                    <ListItemButton onClick={() => handleTabClick(event.event_name)}>
                                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={selectedTab === event.event_name ? "#efefef" : "#646464"}>{event.event_name}</Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box>
                                {selectedTab === "Resumen" ? (
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection="column">
                                        <Typography variant='p-small' textAlign={"center"} mb={2}>Resumen de asistencia</Typography>
                                        <Button variant="red" onClick={() => setEventsModal(true)}>Gestionar eventos</Button>
                                    </Box>
                                ) : (
                                    <Grid container spacing={2} sx={{ maxWidth: '1010px' }}>
                                        <Grid item xs={12} md={4}>
                                            <Card sx={{ "height": "100%" }}>
                                                <CardContent>
                                                    <Typography variant='title' fontSize={"1.2em"} mb={2}>{selectedTab}</Typography>
                                                    <Divider sx={{ "my": "10px" }} />
                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography variant='p'>Fecha</Typography>
                                                        <Typography variant='p'>{asistencia.find(event => event.event_name === selectedTab).event_date}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography variant='p'>Asistencias</Typography>
                                                        <Typography variant='p'>{asistencia.find(event => event.event_name === selectedTab).asistencia.filter(asistencia => asistencia.assistance === "2").length}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography variant='p'>Inasistencias justificadas</Typography>
                                                        <Typography variant='p'>{asistencia.find(event => event.event_name === selectedTab).asistencia.filter(asistencia => asistencia.assistance === "1").length}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography variant='p'>Inasistencias</Typography>
                                                        <Typography variant='p'>{asistencia.find(event => event.event_name === selectedTab).asistencia.filter(asistencia => asistencia.assistance === "0").length}</Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Card sx={{ "height": "100%" }}>
                                                <CardContent>
                                                    <Typography variant='title' fontSize={"1.2em"} mb={2}>Distribución de asistencia</Typography>
                                                    {
                                                        asistencia.find(event => event.event_name === selectedTab).asistencia.length > 0 ?
                                                            <div id="asistencia-chart-container"></div>
                                                            :
                                                            <Typography variant='p-small' textAlign={"center"} mt={"40px"}>No hay datos de asistencia disponibles.</Typography>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AsistenciaTable
                                                keycloak={keycloak}
                                                events={asistencia}
                                                eventId={asistencia.find(event => event.event_name === selectedTab).id}
                                                setAsistencia={setAsistencia}
                                                courseType={isDiplomado ? "diplomado" : "course"}
                                                courseKey={isDiplomado ?
                                                    course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                                                    :
                                                    course.implementations[selectedImplementation].sections[selectedSection].course_key
                                                }
                                                enrolledUsers={sectionData.enrolled_users}
                                                identifier={
                                                    isDiplomado ?
                                                        course.shortname + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                                        :
                                                        course.shortname + "_" +
                                                        course.implementations[selectedImplementation].year + "_" +
                                                        course.implementations[selectedImplementation].term + "_" +
                                                        course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                                        course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                        course.implementations[selectedImplementation].sections[selectedSection].section
                                                }
                                                alerts={alerts}
                                                setAlerts={setAlerts}
                                                setAlertSuccess={setAlertSuccess}
                                                setAlertError={setAlertError}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                <EventsModal
                    keycloak={keycloak}
                    open={eventsModal}
                    handleClose={() => setEventsModal(false)}
                    courseKey={isDiplomado ?
                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                        :
                        course.implementations[selectedImplementation].sections[selectedSection].course_key
                    }
                    courseType={isDiplomado ? "diplomado" : "course"}
                    asistencia={asistencia}
                    setAsistencia={setAsistencia}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </Box>
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>
    );
}

export default CourseSectionAsistencia;