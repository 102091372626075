import axios from 'axios';

export const getAnalytics = async (token, siteId, startDay, endDay) => {

    try {

        const response = await axios.get(process.env.REACT_APP_API_URL + "/get_matomo_analytics/" + siteId, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            params: {
                start_day: startDay,
                end_day: endDay
            }
        });

        switch (response.status) {
            case 200:
                return { analytics: response.data, error: "" };
            default:
                return { analytics: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { analytics: null, error: "Error desconocido: " + error.message };
    }
}