import axios from 'axios';

export const getCourseAllData = async (token, courseShortname, courseType) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/course_data", {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            params: {
                course_shortname: courseShortname,
                course_type: courseType
            }
        });
        switch (response.data.status) {
            case 100:
                return { data: response.data.data, error: "" };
            case 101:
                return { data: null, error: response.data.message };
            default:
                return { data: null, error: "Error desconocido: " + response.data.message };
        }
    } catch (error) {
        return { data: null, error: "Error desconocido: " + error.message };
    }
}