import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Grid, List, ListItem, ListItemButton, Divider, Card, CardContent, Button } from '@mui/material';
import CalificacionesTable from '../../tables/CalificacionesTable';
import PreTestPostTestTable from '../../tables/PreTestPostTestTable';
import ManualGradesUnique from './evaluations/ManualGradesUnique';
import NotasResumenTable from '../../tables/NotasResumenTable';
import { calculateEvaluationStats, calculateEvaluationGrade, calculateFinalGrade } from '../../../../utils/formatters';
import { gradesDistributionChart, destroyGradesChart, finalGradesDistributionChart } from '../../../../utils/charts';

const CourseSectionEvaluaciones = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    evaluations,
    manualGrades,
    setManualGrades,
    selectedModule,
    selectedImplementation,
    selectedSection,
    allResponses,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [calificacionesTab, setCalificacionesTab] = useState({ name: "Resumen", index: null });

    const handleCalificacionesTabClick = (tab, tabReport, index = null) => {
        setCalificacionesTab({ name: tab, index });
    }

    const getSummaryData = () => {
        const allUsers = new Set();
        [...evaluations.controles, ...evaluations.tests].forEach(evaluation => {
            evaluation.contents?.forEach(content => {
                content.responses?.forEach(response => {
                    allUsers.add(response.user_id);
                });
            });
        });

        const summaryData = Array.from(allUsers).map(userId => {
            const userSummary = {
                username: userId,
                grades: {
                    controles: {},
                    tests: {},
                    preguntasCalificadas: {},
                    other: {}
                }
            };

            // Calculate grades for tests
            evaluations.tests.forEach((test, index) => {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);
                const userResponses = test.contents?.map(content => {
                    return content.responses?.find(r => r.user_id === userId) || {
                        score: 0,
                        max_score: content.max_score || 12
                    };
                });
                userSummary.grades.tests[test.name] = calculateEvaluationGrade(userResponses, scale);
            });


            // Calculate grades for controls
            evaluations.controles.forEach((control, index) => {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);
                const userResponses = control.contents?.map(content => {
                    return content.responses?.find(r => r.user_id === userId) || {
                        score: 0,
                        max_score: content.max_score || 12
                    };
                });

                userSummary.grades.controles[`Control ${index + 1}`] = calculateEvaluationGrade(userResponses, scale);
            });

            // Calculate grades for preguntas calificadas
            if (evaluations.preguntasCalificadas.length > 0) {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);

                // Calculate grade for each pregunta calificada individually
                evaluations.preguntasCalificadas.forEach((taller, index) => {
                    const userResponses = taller.contents?.map(content => {
                        return content.responses?.find(r => r.user_id === userId) || {
                            score: 0,
                            max_score: content.max_score || 12
                        };
                    }) || [];
    
                    userSummary.grades.preguntasCalificadas[`Taller ${index + 1}`] = calculateEvaluationGrade(userResponses, scale);
    
                });
            }

            // Calculate grades for other rates
            Object.keys(manualGrades).forEach(grade => {
                let evaluation = manualGrades[grade]
                if (Object.keys(evaluation)[0] === "0" && evaluation[0].find(ev => ev.user_id === userId)) {
                    userSummary.grades.other[grade] = evaluation[0].find(user => user.user_id === userId).grade;
                }
            });

            return userSummary;
        });
        return summaryData;
    }

    useEffect(() => {
        if (calificacionesTab.name === "Resumen" && manualGrades) {
            const grading = isDiplomado
                ? course.modules[selectedModule].implementations[selectedImplementation].grading
                : course.implementations[selectedImplementation].grading;

            const summaryData = getSummaryData();
            // Filter out students with missing grades
            const validSummaryData = summaryData.filter(data => {
                // Check if student has all required controles
                if (grading.controles_rate > 0) {
                    const controlesGrades = evaluations.controles.map((_, index) =>
                        data.grades?.controles[Object.keys(data.grades.controles)[index]]
                    );
                    if (controlesGrades.some(grade => !grade)) return false;
                }
                return true;
            });

            finalGradesDistributionChart(
                sectionData.enrolled_users,
                validSummaryData,
                isDiplomado ?
                    course.modules[selectedModule].implementations[selectedImplementation].grading :
                    course.implementations[selectedImplementation].grading,
                sectionData,
                allResponses
            );
        } else {
            const contents = evaluations.controles.find((control, idx) =>
                control.name === calificacionesTab.name
            )?.contents ||
                evaluations.tests.find((test, idx) =>
                    test.name === calificacionesTab.name
                )?.contents ||
                evaluations.preguntasCalificadas.find((taller, idx) =>
                    taller.name === calificacionesTab.name
                )?.contents;

            if (contents) {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);

                gradesDistributionChart(
                    contents, 
                    sectionData.enrolled_users, 
                    scale,
                    getSummaryData(),
                    calificacionesTab.name
                );
            }
        }

        return () => {
            destroyGradesChart();
        };
    }, [calificacionesTab, evaluations, manualGrades]);

    return (
        sectionData ?
            <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <List>
                            <ListItem
                                key={"Resumen"}
                                disablePadding
                                sx={{
                                    "backgroundColor": calificacionesTab.name === "Resumen" ? "#60696C" : "#efefef"
                                }}
                            >
                                <ListItemButton onClick={() => handleCalificacionesTabClick("Resumen", null)}>
                                    <Typography
                                        variant="p"
                                        sx={{ "textAlign": "left", "width": "100%" }}
                                        fontSize={"0.85em"}
                                        m={"5px 0"}
                                        color={calificacionesTab.name === "Resumen" ? "#efefef" : "#646464"}
                                    >
                                        Resumen
                                    </Typography>
                                </ListItemButton>
                            </ListItem>


                            {evaluations.tests.filter(test => test.name !== "Pre-Test - Post-Test").length > 0 && (
                                <>
                                    <Divider sx={{ mt: 2, mb: 1 }} />
                                    <Typography variant="p" sx={{
                                        color: "#646464",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: "0.9em",
                                        fontFamily: "Avenir Heavy"
                                    }}>Tests</Typography>
                                    {evaluations.tests
                                        .filter(test => test.name !== "Pre-Test - Post-Test")
                                        .map((test, index) => (
                                            <ListItem
                                                key={`${test.name}-${index}`}
                                                disablePadding
                                                sx={{
                                                    "backgroundColor": calificacionesTab.name === test.name && calificacionesTab.index === index ? "#60696C" : "#efefef"
                                                }}
                                            >
                                                <ListItemButton onClick={() => handleCalificacionesTabClick(test.name, "reporte_tests", index)}>
                                                    <Typography
                                                        variant="p"
                                                        sx={{ "textAlign": "left", "width": "100%" }}
                                                        fontSize={"0.85em"}
                                                        m={"5px 0"}
                                                        color={calificacionesTab.name === test.name && calificacionesTab.index === index ? "#efefef" : "#646464"}
                                                    >
                                                        {test.name}
                                                    </Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                </>
                            )}

                            {(() => {
                                const grading = isDiplomado
                                    ? course.modules[selectedModule].implementations[selectedImplementation].grading
                                    : course.implementations[selectedImplementation].grading;

                                return evaluations.preguntasCalificadas.length > 0 && grading?.preguntas_calificadas_rate > 0 && (
                                    <>
                                        <Divider sx={{ mt: 2, mb: 1 }} />
                                        <Typography variant="p" sx={{
                                            color: "#646464",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "0.9em",
                                            fontFamily: "Avenir Heavy"
                                        }}>Preguntas Calificadas</Typography>
                                        {evaluations.preguntasCalificadas.map((taller, index) => (
                                            <ListItem
                                                key={`${taller.name}-${index}`}
                                                disablePadding
                                                sx={{
                                                    "backgroundColor": calificacionesTab.name === taller.name && calificacionesTab.index === index ? "#60696C" : "#efefef"
                                                }}
                                            >
                                                <ListItemButton onClick={() => handleCalificacionesTabClick(taller.name, "preguntas_calificadas", index)}>
                                                    <Typography
                                                        variant="p"
                                                        sx={{ "textAlign": "left", "width": "100%" }}
                                                        fontSize={"0.85em"}
                                                        m={"5px 0"}
                                                        color={calificacionesTab.name === taller.name && calificacionesTab.index === index ? "#efefef" : "#646464"}
                                                    >
                                                        {taller.name}
                                                    </Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </>
                                );
                            })()}

                            {evaluations.controles.length > 0 && (
                                <>
                                    <Divider sx={{ mt: 2, mb: 1 }} />
                                    <Typography variant="p" sx={{
                                        color: "#646464",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: "0.9em",
                                        fontFamily: "Avenir Heavy"
                                    }}>Controles</Typography>
                                    {evaluations.controles.map((control, index) => (
                                        <ListItem
                                            key={`${control.name}-${index}`}
                                            disablePadding
                                            sx={{
                                                "backgroundColor": calificacionesTab.name === control.name && calificacionesTab.index === index ? "#60696C" : "#efefef"
                                            }}
                                        >
                                            <ListItemButton onClick={() => handleCalificacionesTabClick(control.name, "reporte_controles", index)}>
                                                <Typography
                                                    variant="p"
                                                    sx={{ "textAlign": "left", "width": "100%" }}
                                                    fontSize={"0.85em"}
                                                    m={"5px 0"}
                                                    color={calificacionesTab.name === control.name && calificacionesTab.index === index ? "#efefef" : "#646464"}
                                                >
                                                    {control.name}
                                                </Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </>
                            )}

                            {(() => {
                                const grading = isDiplomado
                                    ? course.modules[selectedModule].implementations[selectedImplementation].grading
                                    : course.implementations[selectedImplementation].grading;

                                if (grading?.other_rates?.length > 0) {
                                    return (
                                        <>
                                            <Divider sx={{ mt: 2, mb: 1 }} />
                                            <Typography variant="p" sx={{
                                                color: "#646464",
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                fontSize: "0.9em",
                                                fontFamily: "Avenir Heavy"
                                            }}>Notas Manuales</Typography>
                                            {grading.other_rates.map((rate, index) => (
                                                <ListItem
                                                    key={`manual-${index}`}
                                                    disablePadding
                                                    sx={{
                                                        "backgroundColor": calificacionesTab.name === rate.name ? "#60696C" : "#efefef"
                                                    }}
                                                >
                                                    <ListItemButton onClick={() => {
                                                        handleCalificacionesTabClick(rate.name, "manual_grade", index);
                                                    }}>
                                                        <Typography
                                                            variant="p"
                                                            sx={{ "textAlign": "left", "width": "100%" }}
                                                            fontSize={"0.85em"}
                                                            m={"5px 0"}
                                                            color={calificacionesTab.name === rate.name ? "#efefef" : "#646464"}
                                                        >
                                                            {rate.name}
                                                        </Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </>
                                    );
                                }
                                return null;
                            })()}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {calificacionesTab.name === null ?
                            <Box width={"100%"} textAlign={"center"}>
                                <Typography variant='p' textAlign={"center"}>Por favor seleccione una pestaña.</Typography>
                            </Box>
                            : (() => {
                                const grading = isDiplomado
                                    ? course.modules[selectedModule].implementations[selectedImplementation].grading
                                    : course.implementations[selectedImplementation].grading;

                                return (
                                    calificacionesTab.name === "Resumen" ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <Card sx={{ height: '400px' }}>
                                                    <CardContent sx={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        overflow: 'auto'
                                                    }}>
                                                        <Typography variant='title' fontSize={"1.2em"}>Notas finales</Typography>
                                                        <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                        {(() => {
                                                            const summaryData = getSummaryData();
                                                            const grades = summaryData.map(user => {
                                                                let finalGrade = 0;
                                                                let totalWeight = 0;

                                                                if (grading.controles_rate > 0) {
                                                                    const controlesGrades = Object.values(user.grades.controles);
                                                                    const avgControles = controlesGrades.reduce((a, b) => a + b, 0) / controlesGrades.length;
                                                                    finalGrade += avgControles * (grading.controles_rate / 100);
                                                                    totalWeight += grading.controles_rate;
                                                                }

                                                                if (grading.preguntas_calificadas_rate > 0) {
                                                                    const preguntasCalificadasGrades = Object.values(user.grades.preguntasCalificadas);
                                                                    const avgPreguntasCalificadas = preguntasCalificadasGrades.reduce((a, b) => a + b, 0) / preguntasCalificadasGrades.length;
                                                                    finalGrade += avgPreguntasCalificadas * (grading.preguntas_calificadas_rate / 100);
                                                                    totalWeight += grading.preguntas_calificadas_rate;
                                                                }

                                                                if (grading.avance_rate > 0) {
                                                                    let totalAnswered = 0;
                                                                    let totalQuestions = 0;

                                                                    sectionData.sections
                                                                        .filter(section => section.name.toLowerCase().includes('taller'))
                                                                        .forEach(section => {
                                                                            const xblocks = section.subsections.flatMap(subsection =>
                                                                                subsection.units.flatMap(unit =>
                                                                                    unit.xblocks.filter(xblock =>
                                                                                        ["table_advanced_problem", "multiplechoice", "radiomultiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3"].includes(xblock.true_block_type)
                                                                                    )
                                                                                )
                                                                            );

                                                                            totalQuestions += xblocks.length;

                                                                            xblocks.forEach(xblock => {
                                                                                const responseKey = `${xblock.id}_${user.username}`;
                                                                                if (allResponses[responseKey]) {
                                                                                    totalAnswered++;
                                                                                }
                                                                            });
                                                                        });

                                                                    const percentage = totalQuestions === 0 ? 0 : (totalAnswered / totalQuestions) * 100;

                                                                    const decimalScale = grading.scale / 100;
                                                                    let avanceGrade;

                                                                    // Calculate grade (1.0 - 7.0 scale with 4.0 at scale%)
                                                                    if (percentage < decimalScale*100) {
                                                                        avanceGrade = (4.0 - 1.0) * (percentage / (decimalScale*100)) + 1.0;
                                                                    } else {
                                                                        // For percentage < scale: linear from 1.0 to 4.0
                                                                        avanceGrade = (7.0 - 4.0) * ((percentage - decimalScale*100) / (100*(1 - decimalScale))) + 4.0;
                                                                    }

                                                                    finalGrade += avanceGrade * (grading.avance_rate / 100);
                                                                    totalWeight += grading.avance_rate;
                                                                }

                                                                if (grading.other_rates?.length > 0) {
                                                                    grading.other_rates.forEach(rate => {
                                                                        finalGrade += (user.grades.other[rate.name] || 1) * (rate.value / 100);
                                                                        totalWeight += rate.value;
                                                                    });
                                                                }

                                                                return totalWeight > 0 ? (finalGrade * 100 / totalWeight) : 1.0;
                                                            });

                                                            const stats = {
                                                                averageGrade: (grades.reduce((a, b) => a + b, 0) / grades.length || 0).toFixed(1),
                                                                medianGrade: grades.sort((a, b) => a - b)[Math.floor(grades.length / 2)].toFixed(1),
                                                                stdDeviation: Math.sqrt(grades.reduce((sq, n) => sq + Math.pow(n - (grades.reduce((a, b) => a + b, 0) / grades.length), 2), 0) / (grades.length - 1)).toFixed(1),
                                                                maxGrade: Math.max(...grades).toFixed(1),
                                                                submittedCount: grades.filter(g => g > 0).length,
                                                                passedCount: grades.filter(g => g >= 4.0).length,
                                                                failedCount: grades.filter(g => g > 0 && g < 4.0).length
                                                            };

                                                            return (
                                                                <Box sx={{ flex: 1 }}>
                                                                    <Typography variant='p-small' fontWeight="bold" mb={1}>Criterios de evaluación</Typography>
                                                                    {grading.controles_rate > 0 && (
                                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                            <Typography variant='p-small'>Controles</Typography>
                                                                            <Typography variant='p-small'>{grading.controles_rate}%</Typography>
                                                                        </Box>
                                                                    )}
                                                                    {grading.preguntas_calificadas_rate > 0 && (
                                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                            <Typography variant='p-small'>Preguntas calificadas</Typography>
                                                                            <Typography variant='p-small'>{grading.preguntas_calificadas_rate}%</Typography>
                                                                        </Box>
                                                                    )}
                                                                    {grading.avance_rate > 0 && (
                                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                            <Typography variant='p-small'>Avance en plataforma</Typography>
                                                                            <Typography variant='p-small'>{grading.avance_rate}%</Typography>
                                                                        </Box>
                                                                    )}
                                                                    {grading.other_rates?.map((rate, index) => (
                                                                        <Box key={index} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                            <Typography variant='p-small'>{rate.name}</Typography>
                                                                            <Typography variant='p-small'>{rate.value}%</Typography>
                                                                        </Box>
                                                                    ))}

                                                                    <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                                    <Typography variant='p-small' fontWeight="bold" mb={1}>Estadísticas</Typography>

                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography variant='p-small'>Nota promedio</Typography>
                                                                        <Typography variant='p-small'>{stats.averageGrade}</Typography>
                                                                    </Box>
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography variant='p-small'>Nota mediana</Typography>
                                                                        <Typography variant='p-small'>{stats.medianGrade}</Typography>
                                                                    </Box>
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography variant='p-small'>Desviación estándar</Typography>
                                                                        <Typography variant='p-small'>{stats.stdDeviation}</Typography>
                                                                    </Box>
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography variant='p-small'>Nota máxima</Typography>
                                                                        <Typography variant='p-small'>{stats.maxGrade}</Typography>
                                                                    </Box>
                                                                    <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography variant='p-small'>Aprobados</Typography>
                                                                        <Typography variant='p-small'>{stats.passedCount}</Typography>
                                                                    </Box>
                                                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                        <Typography variant='p-small'>Reprobados</Typography>
                                                                        <Typography variant='p-small'>{stats.failedCount}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })()}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Card sx={{ height: '400px', width: '100%' }}>
                                                    <CardContent sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}>
                                                        <Typography variant='title' fontSize={"1.2em"}>Distribución de notas</Typography>
                                                        <Box sx={{ flex: 1, width: '100%' }}>
                                                            <div id="final-grades-distribution-chart" style={{ height: '100%', width: '100%' }}></div>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NotasResumenTable
                                                    enrolledUsers={sectionData.enrolled_users}
                                                    summaryData={getSummaryData()}
                                                    evaluations={evaluations}
                                                    grading={grading}
                                                    allResponses={allResponses}
                                                    sectionData={sectionData}
                                                    identifier={
                                                        isDiplomado ?
                                                            course.shortname + "_" +
                                                            course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                                            course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                                            course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                                            :
                                                            course.shortname + "_" +
                                                            course.implementations[selectedImplementation].year + "_" +
                                                            course.implementations[selectedImplementation].term + "_" +
                                                            course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                                            course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                            course.implementations[selectedImplementation].sections[selectedSection].section
                                                    }
                                                    alerts={alerts}
                                                    setAlerts={setAlerts}
                                                    setAlertError={setAlertError}
                                                    setAlertSuccess={setAlertSuccess}
                                                />
                                            </Grid>
                                            {
                                                evaluations.tests.find(test => test.name === "Pre-Test - Post-Test") && (
                                                    <Grid item xs={12}>
                                                        <PreTestPostTestTable
                                                            questions={evaluations.tests.find(test => test.name === "Pre-Test - Post-Test")?.contents}
                                                            enrolledUsers={sectionData.enrolled_users}
                                                            title={"Pre-Test - Post-Test"}
                                                            identifier={
                                                                isDiplomado ?
                                                                    course.shortname + "_" +
                                                                    course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                                                    course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                                                    course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                                    course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                                                    :
                                                                    course.shortname + "_" +
                                                                    course.implementations[selectedImplementation].year + "_" +
                                                                    course.implementations[selectedImplementation].term + "_" +
                                                                    course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                                                    course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                                    course.implementations[selectedImplementation].sections[selectedSection].section
                                                            }
                                                            alerts={alerts}
                                                            setAlerts={setAlerts}
                                                            setAlertError={setAlertError}
                                                            setAlertSuccess={setAlertSuccess}
                                                        />
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                        : grading.other_rates.some(rate => rate.name === calificacionesTab.name && rate.type === "otros_unico") ?
                                            <ManualGradesUnique
                                                keycloak={keycloak}
                                                course={course}
                                                selectedModule={selectedModule}
                                                selectedImplementation={selectedImplementation}
                                                selectedSection={selectedSection}
                                                sectionData={sectionData}
                                                title={calificacionesTab.name}
                                                gradingObject={grading.other_rates.find(rate => rate.name === calificacionesTab.name)}
                                                manualGrades={manualGrades}
                                                setManualGrades={setManualGrades}
                                                alerts={alerts}
                                                setAlerts={setAlerts}
                                                setAlertError={setAlertError}
                                                setAlertSuccess={setAlertSuccess}
                                            />
                                            : grading.other_rates.some(rate => rate.name === calificacionesTab.name && rate.type === "otros_multiple") ?
                                                <></>
                                                :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={4}>
                                                        <Card>
                                                            <CardContent>
                                                                {(() => {
                                                                    const contents = evaluations.controles.find((control, idx) =>
                                                                        control.name === calificacionesTab.name
                                                                    )?.contents ||
                                                                        evaluations.tests.find((test, idx) =>
                                                                            test.name === calificacionesTab.name
                                                                        )?.contents ||
                                                                        evaluations.preguntasCalificadas.find((taller, idx) =>
                                                                            taller.name === calificacionesTab.name
                                                                        )?.contents;

                                                                    const scale = isDiplomado ?
                                                                        (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                                                                        (course.implementations[selectedImplementation].grading?.scale || 60);

                                                                    const stats = calculateEvaluationStats(contents, sectionData.enrolled_users, scale);

                                                                    return (
                                                                        <>
                                                                            <Typography variant='title' fontSize={"1.2em"}>{calificacionesTab.name}</Typography>
                                                                            <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />

                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Preguntas</Typography>
                                                                                <Typography variant='p-small'>{contents?.length || 0}</Typography>
                                                                            </Box>
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Escala</Typography>
                                                                                <Typography variant='p-small'>
                                                                                    {isDiplomado ?
                                                                                        (course.modules[selectedModule].implementations[selectedImplementation].grading ?
                                                                                            course.modules[selectedModule].implementations[selectedImplementation].grading.scale :
                                                                                            60)
                                                                                        :
                                                                                        (course.implementations[selectedImplementation].grading ?
                                                                                            course.implementations[selectedImplementation].grading.scale :
                                                                                            60)
                                                                                    }%
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Nota promedio</Typography>
                                                                                <Typography variant='p-small'>{stats.averageGrade}</Typography>
                                                                            </Box>
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Nota mediana</Typography>
                                                                                <Typography variant='p-small'>{stats.medianGrade}</Typography>
                                                                            </Box>
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Desviación estándar</Typography>
                                                                                <Typography variant='p-small'>{stats.stdDeviation}</Typography>
                                                                            </Box>
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Nota máxima</Typography>
                                                                                <Typography variant='p-small'>{stats.maxGrade}</Typography>
                                                                            </Box>
                                                                            <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Aprobados</Typography>
                                                                                <Typography variant='p-small'>{stats.passedCount}</Typography>
                                                                            </Box>
                                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                                <Typography variant='p-small'>Reprobados</Typography>
                                                                                <Typography variant='p-small'>{stats.failedCount}</Typography>
                                                                            </Box>
                                                                        </>
                                                                    );
                                                                })()}
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Card>
                                                            <CardContent>
                                                                <Typography variant='title' fontSize={"1.2em"}>Distribución de notas</Typography>
                                                                <div id="grades-distribution-chart"></div>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                                            <CalificacionesTable
                                                                questions={
                                                                    evaluations.controles.find((control, idx) =>
                                                                        control.name === calificacionesTab.name
                                                                    )?.contents ||
                                                                    evaluations.tests.find((test, idx) =>
                                                                        test.name === calificacionesTab.name
                                                                    )?.contents ||
                                                                    evaluations.preguntasCalificadas.find((taller, idx) =>
                                                                        taller.name === calificacionesTab.name
                                                                    )?.contents
                                                                }
                                                                isPreguntasCalificadas={calificacionesTab.name.startsWith('Taller')}
                                                                enrolledUsers={sectionData.enrolled_users}
                                                                title={calificacionesTab.name}
                                                                identifier={
                                                                    isDiplomado ?
                                                                        course.shortname + "_" +
                                                                        course.modules[selectedModule].module + "_" +
                                                                        course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                                                        course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                                                        :
                                                                        course.shortname + "_" +
                                                                        course.implementations[selectedImplementation].year + "_" +
                                                                        course.implementations[selectedImplementation].term + "_" +
                                                                        course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                                                        course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                                        course.implementations[selectedImplementation].sections[selectedSection].section
                                                                }
                                                                alerts={alerts}
                                                                setAlerts={setAlerts}
                                                                setAlertError={setAlertError}
                                                                setAlertSuccess={setAlertSuccess}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                )
                            })()}
                    </Grid>
                </Grid>
            </Box >
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>
    );

}

export default CourseSectionEvaluaciones;