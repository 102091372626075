import ApexCharts from 'apexcharts';
import { mapDeviceTypes } from './mappers';
import ReactDOMServer from 'react-dom/server';
import { calculateEvaluationGrade } from './formatters';

const OTHER_COLORS = [
    "#388659",
    "#4E4187",
    "#595959"
]

const CORRECT_COLORS = [
    "green",
    "#D23831",
    "#646464"
]

export const usersPerDayChart = (data, palette) => {
    let values = [];
    let labels = [];
    Object.keys(data.analytics.users_per_day).map(key => {
        labels.push(`${key.slice(0, 4)}-${key.slice(4, 6)}-${key.slice(6, 8)}`);
        values.push(data.analytics.users_per_day[key]);
    });
    var options = {
        series: [{
            name: 'Usuarios por día',
            data: values
        }],
        colors: palette,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        markers: {
            size: 3,
            hover: {
                size: 5
            }
        },
        title: {
            text: 'Usuarios por día',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 1
        },
        xaxis: {
            type: 'date',
            categories: labels
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy'
            },
        },
    };

    var chart = new ApexCharts(document.getElementById("users-per-day"), options);
    chart.render();
}


export const usersPerCountryChart = (data, palette) => {
    let values = [];
    let labels = [];
    Object.keys(data.analytics.users_per_country).map(key => {
        labels.push(key);
        values.push(data.analytics.users_per_country[key]);
    });
    let zipped = labels.map(function (e, i) {
        return [e, values[i]];
    });
    zipped.sort(function (a, b) {
        return b[1] - a[1];
    });
    labels = zipped.map(function (e) {
        return e[0];
    });
    values = zipped.map(function (e) {
        return e[1];
    });
    var options = {
        series: [{
            name: 'Usuarios por país',
            data: values.slice(0, 10)
        }],
        colors: palette,
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por país',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                borderRadiusApplication: 'end',
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels.slice(0, 10),
        }
    };

    var chart = new ApexCharts(document.getElementById("users-per-country"), options);
    chart.render();
}


export const usersPerCityChart = (data, palette) => {
    let values = [];
    let labels = [];
    Object.keys(data.analytics.users_per_city).map(key => {
        labels.push(key);
        values.push(data.analytics.users_per_city[key]);
    });
    let zipped = labels.map(function (e, i) {
        return [e, values[i]];
    });
    zipped.sort(function (a, b) {
        return b[1] - a[1];
    });
    labels = zipped.map(function (e) {
        return e[0];
    });
    values = zipped.map(function (e) {
        return e[1];
    });
    var options = {
        series: [{
            name: 'Usuarios por ciudad',
            data: values.slice(0, 10)
        }],
        colors: palette,
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por ciudad',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                borderRadiusApplication: 'end',
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels.slice(0, 10),
        }
    };

    var chart = new ApexCharts(document.getElementById("users-per-city"), options);
    chart.render();
}


export const usersPerBrowserChart = (data, palette) => {
    let values = [];
    let labels = [];
    Object.keys(data.analytics.users_per_browser).map(key => {
        labels.push(key);
        values.push(data.analytics.users_per_browser[key]);
    });
    let zipped = labels.map(function (e, i) {
        return [e, values[i]];
    });
    zipped.sort(function (a, b) {
        return b[1] - a[1];
    });
    labels = zipped.map(function (e) {
        return e[0];
    });
    values = zipped.map(function (e) {
        return e[1];
    });

    // After sorting, take top 5 and aggregate the rest
    const top5Labels = labels.slice(0, 5);
    const top5Values = values.slice(0, 5);

    // Sum up the remaining values
    const otrosValue = values.slice(5).reduce((sum, val) => sum + val, 0);

    // Only add "Otros" if there are remaining values
    const finalLabels = otrosValue > 0 ? [...top5Labels, "Otros"] : top5Labels;
    const finalValues = otrosValue > 0 ? [...top5Values, otrosValue] : top5Values;

    var options = {
        series: finalValues,
        chart: {
            width: 380,
            type: 'pie',
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por navegador web',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        legend: {
            position: 'bottom'
        },
        colors: palette,
        labels: finalLabels,
        responsive: [{
            breakpoint: 360,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    var chart = new ApexCharts(document.getElementById("users-per-browser"), options);
    chart.render();
}


export const usersPerOSChart = (data, palette) => {
    let values = [];
    let labels = [];

    // Extract first word from each label and aggregate the values
    Object.keys(data.analytics.users_per_operating_system).map(key => {
        const firstWord = key.split(' ')[0]; // Take the first word from the label
        labels.push(firstWord === "Chrome" ? "Chrome OS" : firstWord);
        values.push(data.analytics.users_per_operating_system[key]);
    });

    // Aggregate the values for identical labels (first words)
    let aggregatedData = labels.reduce((acc, label, index) => {
        if (acc[label]) {
            acc[label] += values[index];
        } else {
            acc[label] = values[index];
        }
        return acc;
    }, {});

    // Prepare the final labels and values after aggregation
    labels = Object.keys(aggregatedData);
    values = Object.values(aggregatedData);

    // Sort the data based on values
    let zipped = labels.map(function (e, i) {
        return [e, values[i]];
    });
    zipped.sort(function (a, b) {
        return b[1] - a[1];
    });
    labels = zipped.map(function (e) {
        return e[0];
    });
    values = zipped.map(function (e) {
        return e[1];
    });

    // After sorting, take top 5 and aggregate the rest
    const top5Labels = labels.slice(0, 5);
    const top5Values = values.slice(0, 5);

    // Sum up the remaining values
    const otrosValue = values.slice(5).reduce((sum, val) => sum + val, 0);

    // Only add "Otros" if there are remaining values
    const finalLabels = otrosValue > 0 ? [...top5Labels, "Otros"] : top5Labels;
    const finalValues = otrosValue > 0 ? [...top5Values, otrosValue] : top5Values;

    var options = {
        series: finalValues,
        chart: {
            width: 380,
            type: 'pie',
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por sistema operativo',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        legend: {
            position: 'bottom'
        },
        colors: palette,
        labels: finalLabels,
        responsive: [{
            breakpoint: 360,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    var chart = new ApexCharts(document.getElementById("users-per-os"), options);
    chart.render();
}


export const usersPerOriginChart = (data, palette) => {
    let values = [];
    let labels = [];
    Object.keys(data.analytics.users_per_referrer).map(key => {
        labels.push(key);
        values.push(data.analytics.users_per_referrer[key]);
    });
    let zipped = labels.map(function (e, i) {
        return [e, values[i]];
    });
    zipped.sort(function (a, b) {
        return b[1] - a[1];
    });
    labels = zipped.map(function (e) {
        return e[0];
    });
    values = zipped.map(function (e) {
        return e[1];
    });
    var options = {
        series: [{
            name: 'Origen de los usuarios',
            data: values.slice(0, 10)
        }],
        colors: palette,
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Origen de los usuarios',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                borderRadiusApplication: 'end',
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels.slice(0, 10),
        }
    };

    var chart = new ApexCharts(document.getElementById("users-per-origin"), options);
    chart.render();
}


export const usersPerHourChart = (data, palette) => {
    let values = new Array(24).fill(0);  // Initialize array with 24 zeros
    let labels = Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`);

    // Fill in actual values where they exist
    Object.entries(data.analytics.users_per_hour).forEach(([hour, count]) => {
        values[parseInt(hour)] = count;
    });

    var options = {
        series: [{
            name: 'Usuarios por hora',
            data: values
        }],
        colors: palette,
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por hora',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                borderRadiusApplication: 'end',
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels
        }
    };

    var chart = new ApexCharts(document.getElementById("users-per-hour"), options);
    chart.render();
}


export const usersPerDeviceTypeChart = (data, palette) => {
    let rawValues = [];
    let rawLabels = [];

    // Map and collect raw labels and values
    Object.keys(data.analytics.users_per_device_type).map(key => {
        rawLabels.push(mapDeviceTypes(key));
        rawValues.push(data.analytics.users_per_device_type[key]);
    });

    // Aggregate the values for identical labels
    const aggregatedData = rawLabels.reduce((acc, label, index) => {
        if (acc[label]) {
            acc[label] += rawValues[index];
        } else {
            acc[label] = rawValues[index];
        }
        return acc;
    }, {});

    // Extract and sort aggregated labels and values
    const sortedData = Object.entries(aggregatedData)
        .sort(([, valueA], [, valueB]) => valueB - valueA); // Sort by value (descending)

    const labels = sortedData.map(([label]) => label);
    const values = sortedData.map(([, value]) => value);

    // Chart configuration
    var options = {
        series: values,
        chart: {
            width: 380,
            type: 'pie',
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por tipo de dispositivo',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        legend: {
            position: 'bottom'
        },
        colors: palette,
        labels: labels,
        responsive: [{
            breakpoint: 360,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    var chart = new ApexCharts(document.getElementById("users-per-device-type"), options);
    chart.render();
}

export const redfidUsersByPlatformChart = (data, palette) => {
    // Initialize variables
    let rawLabels = [];
    let rawValues = [];
    let colorMap = {};

    // Helper function to classify and aggregate platforms
    const classifyAndAggregate = (key, value) => {
        if (key.includes("https://www.redfid.cl")) {
            rawLabels.push("Home");
            rawValues.push(value);
            colorMap["Home"] = palette[0];
        } else if (key.includes("https://cursos.redfid.cl")) {
            rawLabels.push("Aprendizaje Profesional");
            rawValues.push(value);
            colorMap["Aprendizaje Profesional"] = palette[1];
        } else if (key.includes("https://comunidades.redfid.cl")) {
            rawLabels.push("Comunidades");
            rawValues.push(value);
            colorMap["Comunidades"] = palette[2];
        } else if (key.includes("https://recursos.redfid.cl")) {
            rawLabels.push("Recursos");
            rawValues.push(value);
            colorMap["Recursos"] = palette[3];
        } else { }
    };

    // Process data
    Object.keys(data.analytics.users_per_hostname).forEach(key => {
        classifyAndAggregate(key, data.analytics.users_per_hostname[key]);
    });

    // Aggregate identical labels
    const aggregatedData = rawLabels.reduce((acc, label, index) => {
        if (acc[label]) {
            acc[label] += rawValues[index];
        } else {
            acc[label] = rawValues[index];
        }
        return acc;
    }, {});

    // Extract and sort aggregated data
    const sortedData = Object.entries(aggregatedData)
        .sort(([, valueA], [, valueB]) => valueB - valueA);

    const labels = sortedData.map(([label]) => label);
    const values = sortedData.map(([, value]) => value);
    const colors = labels.map(label => colorMap[label]);

    // Chart configuration
    var options = {
        series: values,
        chart: {
            width: 380,
            type: 'pie',
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: 'Usuarios por plataforma',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        legend: {
            position: 'bottom'
        },
        colors: colors,
        labels: labels,
        responsive: [{
            breakpoint: 360,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    // Render the chart
    var chart = new ApexCharts(document.getElementById("visits-per-platform-redfid"), options);
    chart.render();
}


export const redfidVisitsPerSiteChart = (data, site, palette) => {
    let rawValues = [];
    let rawLabels = [];
    // Map and collect raw labels and values
    if (site === "home") {
        Object.keys(data.analytics.users_per_hostname).map(key => {
            if (key.includes("https://www.redfid.cl")) {
                rawLabels.push(
                    (key === "https://www.redfid.cl" || key === "https://www.redfid.cl/") ? "Home" :
                        key.includes("https://www.redfid.cl/formulario-de-registro") ? "Formulario de registro" :
                            key.includes("https://www.redfid.cl/terminos-y-condiciones") ? "Términos y condiciones" :
                                key.includes("/events/") ? "Noticia: " + key.split("https://www.redfid.cl/events/")[1] :
                                    key.split("https://www.redfid.cl")[1]);
                rawValues.push(data.analytics.users_per_hostname[key]);
            }
        });
    } else if (site === "cursos") {
        Object.keys(data.analytics.users_per_hostname).map(key => {
            if (key.includes("https://cursos.redfid.cl/")) {
                rawLabels.push(
                    (key === "https://cursos.redfid.cl/" || key === "https://cursos.redfid.cl" || key === "https://cursos.redfid.cl/dashboard" || key === "https://cursos.redfid.cl/dashboard/") ? "Home" :
                        (key === "https://cursos.redfid.cl/courses" || key === "https://cursos.redfid.cl/courses/") ? "Catálogo de cursos" :
                            (key.includes("https://cursos.redfid.cl/courses/") && key.includes("about")) ? "Acerca de un curso" :
                                (key.includes("https://cursos.redfid.cl/courses/") && (key.includes("/courseware/") || key.includes("/course/"))) ? "Contenido de un curso" :
                                    key.includes("https://cursos.redfid.cl/dashboard?display=1") ? "Talleres" :
                                        key.includes("https://cursos.redfid.cl/dashboard?display=2") ? "Webinars" :
                                            key.includes("https://cursos.redfid.cl/login") ? "Iniciar sesión" :
                                                key.split("https://cursos.redfid.cl")[1]);
                rawValues.push(data.analytics.users_per_hostname[key]);
            }
        });
    } else if (site === "comunidades") {
        Object.keys(data.analytics.users_per_hostname).map(key => {
            if (key.includes("https://comunidades.redfid.cl/")) {
                rawLabels.push(
                    (key === "https://comunidades.redfid.cl/" || key === "https://comunidades.redfid.cl") ? "Home" :
                        (key === "https://comunidades.redfid.cl/categories" || key === "https://comunidades.redfid.cl/categories/") ? "Categorías" :
                            (key === "https://comunidades.redfid.cl/hot" || key === "https://comunidades.redfid.cl/hot/") ? "Discusiones más activas" :
                                key.includes("https://comunidades.redfid.cl/c/") ? "Categoría: " + key.split("/")[4] :
                                    key.includes("https://comunidades.redfid.cl/g") ? "Grupos" :
                                        key.includes("https://comunidades.redfid.cl/u/") ? "Usuario: " + key.split("/")[4] :
                                            key.includes("https://comunidades.redfid.cl/t") ? "Discusión: " + key.split("/")[4] :
                                                key.split("https://comunidades.redfid.cl")[1]);
                rawValues.push(data.analytics.users_per_hostname[key]);
            }
        });
    } else if (site === "recursos") {
        Object.keys(data.analytics.users_per_hostname).map(key => {
            if (key.includes("https://recursos.redfid.cl/")) {
                rawLabels.push(
                    (key === "https://recursos.redfid.cl/" || key === "https://recursos.redfid.cl") ? "Home" :
                        (key === "https://recursos.redfid.cl/docente" || key === "https://recursos.redfid.cl/docente/") ? "Material docente" :
                            (key === "https://recursos.redfid.cl/academico" || key === "https://recursos.redfid.cl/academico/") ? "Material académico" :
                                (key === "https://recursos.redfid.cl/difusion" || key === "https://recursos.redfid.cl/difusion/") ? "Línea gráfica y difusión" :
                                    key.includes("https://recursos.redfid.cl/recurso/") ? "Recurso académico: ID " + key.split("/")[4] :
                                        key.includes("https://recursos.redfid.cl/activity/") ? "Recurso docente: ID " + key.split("/")[4] :
                                            key.split("https://recursos.redfid.cl")[1]);
                rawValues.push(data.analytics.users_per_hostname[key]);
            }
        });
    } else { }

    // Aggregate the values for identical labels
    const aggregatedData = rawLabels.reduce((acc, label, index) => {
        if (acc[label]) {
            acc[label] += rawValues[index];
        } else {
            acc[label] = rawValues[index];
        }
        return acc;
    }, {});

    // Extract and sort aggregated labels and values
    const sortedData = Object.entries(aggregatedData)
        .sort(([, valueA], [, valueB]) => valueB - valueA).slice(0, 10);

    const labels = sortedData.map(([label]) => label);
    const values = sortedData.map(([, value]) => value);

    // Chart configuration
    var options = {
        series: [{
            data: values,
            name: `Visitas por sitio (${site.toUpperCase().slice(0, 1) + site.slice(1)})`
        }],
        chart: {
            height: 380,
            type: 'bar',
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        title: {
            text: `Visitas por sitio (${site.toUpperCase().slice(0, 1) + site.slice(1)})`,
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                borderRadiusApplication: 'end',
                horizontal: true,
            }
        },
        colors: palette,
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        }
    };

    var chart = new ApexCharts(document.getElementById(`redfid-${site}-sites-chart`), options);
    chart.render();
}


export const cmmeduVisitsPerSiteChart = (data, palette) => {
    let rawValues = [];
    let rawLabels = [];
    // Map and collect raw labels and values
    Object.keys(data.analytics.users_per_hostname).map(key => {
        if (key.includes("https://cmmedu.uchile.cl")) {
            rawLabels.push(
                (key === "https://cmmedu.uchile.cl" || key === "https://cmmedu.uchile.cl/") ? "Home" :
                    key.includes("https://cmmedu.uchile.cl/suma-y-sigue-aula") ? "Suma y Sigue Aula" :
                        key.includes("https://cmmedu.uchile.cl/alicia-en-el-pais-de-las-probabilidades") ? "Alicia" :
                            key.includes("https://cmmedu.uchile.cl/sumo-primero/") ? "Sumo Primero" :
                                key.includes("https://cmmedu.uchile.cl/sumo-primero-formacion") ? "Sumo Primero Formación" :
                                    key.includes("https://cmmedu.uchile.cl/quienes-somos") ? "Quiénes somos" :
                                        key.includes("https://cmmedu.uchile.cl/diplomado-para-formadores") ? "Diplomado para formadores" :
                                            key.includes("https://cmmedu.uchile.cl/catedra-unesco") ? "Cátedra Unesco" :
                                                key.includes("https://cmmedu.uchile.cl/modelo-para-la-fid") ? "Cátedra Unesco" :
                                                    key.includes("https://cmmedu.uchile.cl/rutas-digitales") ? "Rutas digitales" :
                                                        key.includes("https://cmmedu.uchile.cl/investigacion") ? "Investigación" :
                                                            key.includes("https://cmmedu.uchile.cl/desarrollo-profesional") ? "Desarrollo profesional" :
                                                                key.includes("https://cmmedu.uchile.cl/seminarios") ? "Seminarios" :
                                                                    key.includes("https://cmmedu.uchile.cl/webinars") ? "Webinars" :
                                                                        key.includes("https://cmmedu.uchile.cl/events/") ? "Evento: " + key.split("https://cmmedu.uchile.cl/events/")[1] :
                                                                            key.split("https://cmmedu.uchile.cl")[1]);
            rawValues.push(data.analytics.users_per_hostname[key]);
        }
    });

    // Aggregate the values for identical labels
    const aggregatedData = rawLabels.reduce((acc, label, index) => {
        if (acc[label]) {
            acc[label] += rawValues[index];
        } else {
            acc[label] = rawValues[index];
        }
        return acc;
    }, {});

    // Extract and sort aggregated labels and values
    const sortedData = Object.entries(aggregatedData)
        .sort(([, valueA], [, valueB]) => valueB - valueA).slice(0, 10);

    const labels = sortedData.map(([label]) => label);
    const values = sortedData.map(([, value]) => value);
    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        colors: palette,
        series: [{
            name: 'Visitas',
            data: values
        }],
        title: {
            text: 'Visitas por sitio',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        }
    };

    var chart = new ApexCharts(document.getElementById("cmmedu-sites-chart"), options);
    chart.render();
};


export const cmmeduVisitsPerSeminarioChart = (data, palette) => {
    let rawValues = [];
    let rawLabels = [];
    // Map and collect raw labels and values
    Object.keys(data.analytics.users_per_hostname).map(key => {
        if (key.includes("https://cmmedu.uchile.cl/events/")) {
            rawLabels.push(
                key.split("https://cmmedu.uchile.cl/events/")[1]
            );
            rawValues.push(data.analytics.users_per_hostname[key]);
        }
    });

    // Aggregate the values for identical labels
    const aggregatedData = rawLabels.reduce((acc, label, index) => {
        if (acc[label]) {
            acc[label] += rawValues[index];
        } else {
            acc[label] = rawValues[index];
        }
        return acc;
    }, {});

    // Extract and sort aggregated labels and values
    const sortedData = Object.entries(aggregatedData)
        .sort(([, valueA], [, valueB]) => valueB - valueA).slice(0, 10);

    const labels = sortedData.map(([label]) => label);
    const values = sortedData.map(([, value]) => value);
    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        colors: palette,
        series: [{
            name: 'Visitas',
            data: values
        }],
        title: {
            text: 'Visitas por seminario',
            align: 'left',
            style: {
                fontFamily: "Avenir Regular",
                color: "#646464"
            }
        },
        xaxis: {
            categories: labels
        },
        dataLabels: {
            enabled: false
        }
    };

    var chart = new ApexCharts(document.getElementById("cmmedu-seminarios-chart"), options);
    chart.render();
};


export const plotAvance = (data) => {
    // Wait for container to exist
    const container = document.getElementById("avance-plot-container");
    if (!container) {
        console.warn("Avance plot container not found");
        return;
    }

    // Remove existing chart if it exists
    const existingChart = document.getElementById("avance-plot");
    if (existingChart) {
        existingChart.remove();
    }

    var options = {
        series: data,
        chart: {
            type: 'rangeBar',
            height: Math.max(350, data[0]?.data.length * 28 || 350), // Height based on number of users
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false,
                allowMouseWheelZoom: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '80%',
                rangeBarGroupRows: true
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM yyyy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontFamily: "Avenir Regular"
                }
            }
        },
        tooltip: {
            custom: function(opts) {
                const fromDate = new Date(opts.y1).toLocaleString();
                const toDate = new Date(opts.y2).toLocaleString();
                const tallerName = opts.w.config.series[opts.seriesIndex].name;
                const userName = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x;
                
                return (
                    '<div class="apexcharts-tooltip-rangebar">' +
                    '<div> <span class="series-name">' +
                    tallerName +
                    '</span></div>' +
                    '<div> <span class="category">' +
                    userName +
                    '</span></div>' +
                    '<div> <span>Inicio: ' + fromDate + '</span></div>' +
                    '<div> <span>Fin: ' + toDate + '</span></div>' +
                    '</div>'
                );
            }
        },
        legend: {
            position: 'right',
            fontFamily: "Avenir Regular"
        }
    };

    // Create new element for chart
    const chartElement = document.createElement("div");
    chartElement.id = "avance-plot";
    container.appendChild(chartElement);

    // Render chart
    const chart = new ApexCharts(chartElement, options);
    chart.render();
}


export const answersHistogramChartMultipleChoice = (data, renderedXblock, isPreTestPostTest = false) => {
    // Wait for container to exist
    const container = document.getElementById("answers-histogram-container");
    if (!container) {
        console.warn("Histogram container not found");
        return;
    }

    // Remove existing chart if it exists
    const existingChart = document.getElementById("answers-histogram");
    if (existingChart) {
        existingChart.remove();
    }

    // Helper function to get first letter of text content
    const getFirstLetter = (text) => {
        const textContent = text || "";
        return textContent.trim().slice(0, 1).toUpperCase();
    };

    // Extract alternatives and find correct answer from rendered xblock
    const alternatives = [];
    let correctAnswer = '';
    const renderedHtml = ReactDOMServer.renderToString(renderedXblock);

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(renderedHtml, "text/html");
    xmlDoc.querySelectorAll('input[type="radio"]').forEach(input => {
        const nextSpan = input.nextElementSibling;
        if (nextSpan && nextSpan.tagName === 'SPAN') {
            const textContent = nextSpan.textContent || nextSpan.innerText;
            const label = getFirstLetter(textContent);
            if (label) {
                alternatives.push(label);
                if (input.hasAttribute('checked')) {
                    correctAnswer = label;
                }
            }
        }
    });

    if (isPreTestPostTest) {
        // Handle pre-test and post-test data
        const preTestData = data.preTest;
        const postTestData = data.postTest;

        // Initialize counts with 0 for all alternatives
        const preTestCounts = alternatives.reduce((acc, alt) => {
            acc[alt] = 0;
            return acc;
        }, { "No responde": 0 });

        const postTestCounts = alternatives.reduce((acc, alt) => {
            acc[alt] = 0;
            return acc;
        }, { "No responde": 0 });

        // Count frequency of each answer for pre-test
        preTestData.forEach(answer => {
            const formattedAnswer = answer === "" ? "No responde" : answer.toUpperCase();
            if (preTestCounts.hasOwnProperty(formattedAnswer)) {
                preTestCounts[formattedAnswer]++;
            }
        });

        // Count frequency of each answer for post-test
        postTestData.forEach(answer => {
            const formattedAnswer = answer === "" ? "No responde" : answer.toUpperCase();
            if (postTestCounts.hasOwnProperty(formattedAnswer)) {
                postTestCounts[formattedAnswer]++;
            }
        });

        // Convert to arrays for chart and sort alphabetically
        const sortedLabels = Object.keys(preTestCounts).sort();
        const preTestValues = sortedLabels.map(label => preTestCounts[label]);
        const postTestValues = sortedLabels.map(label => postTestCounts[label]);

        const options = {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            colors: ['#388659', '#4E4187'],  // Different colors for pre and post test
            series: [
                {
                    name: 'Pre-test',
                    data: preTestValues
                },
                {
                    name: 'Post-test',
                    data: postTestValues
                }
            ],
            xaxis: {
                categories: sortedLabels,
                labels: {
                    style: {
                        fontFamily: "Avenir Regular"
                    }
                }
            },
            legend: {
                position: 'bottom',
                fontFamily: "Avenir Regular"
            }
        };

        // Create new element for chart
        const chartElement = document.createElement("div");
        chartElement.id = "answers-histogram";
        container.appendChild(chartElement);

        // Render chart
        const chart = new ApexCharts(chartElement, options);
        chart.render();

    } else {
        // Single-test logic
        // Initialize counts with 0 for all alternatives
        const answerCounts = alternatives.reduce((acc, alt) => {
            acc[alt] = 0;
            return acc;
        }, { "No responde": 0 });

        // Count frequency of each answer
        data.forEach(answer => {
            const formattedAnswer = answer === "" ? "No responde" : answer.toUpperCase();
            if (answerCounts.hasOwnProperty(formattedAnswer)) {
                answerCounts[formattedAnswer]++;
            }
        });

        // Convert to arrays for chart and sort alphabetically
        const sortedLabels = Object.keys(answerCounts).sort();
        const values = sortedLabels.map(label => answerCounts[label]);

        // Prepare colors array - green for correct, red for incorrect, gray for no response
        const barColors = sortedLabels.map(label => {
            if (label === "No responde") {
                return "#646464";
            }
            if (label === correctAnswer) {
                return "green";
            }
            return "#D23831";
        });

        const options = {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    distributed: true
                },
            },
            colors: barColors,
            series: [{
                name: 'Respuestas',
                data: values
            }],
            legend: {
                show: false
            },
            xaxis: {
                categories: sortedLabels,
                labels: {
                    style: {
                        fontFamily: "Avenir Regular"
                    }
                }
            }
        };

        // Create new element for chart
        const chartElement = document.createElement("div");
        chartElement.id = "answers-histogram";
        container.appendChild(chartElement);

        // Render chart
        const chart = new ApexCharts(chartElement, options);
        chart.render();
    }
}


export const answersHistogramChartVOF = (data, renderedXblock, isPreTestPostTest = false) => {
    if (isPreTestPostTest) {
        return; // Don't render chart for pre/post test VOF questions
    }

    // Wait for container to exist
    const container = document.getElementById("answers-histogram-container");
    if (!container) {
        console.warn("Histogram container not found");
        return;
    }

    // Remove existing chart if it exists
    const existingChart = document.getElementById("answers-histogram");
    if (existingChart) {
        existingChart.remove();
    }

    // Parse rendered content to get statements
    const renderedHtml = ReactDOMServer.renderToString(renderedXblock);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(renderedHtml, "text/html");

    // Get statements from the last div's spans
    const statements = Array.from(xmlDoc.querySelectorAll('.vof-question')).map((span, index) => {
        return `Afirmación ${index + 1}`;
    });


    // Prepare data for the chart
    const vCountPerIndex = Array(statements.length).fill(0);
    const fCountPerIndex = Array(statements.length).fill(0);
    data.forEach((response, index) => {
        let values = response.split(", ")
        for (let i = 0; i < values.length; i++) {
            if (values[i] === "V") {
                vCountPerIndex[i]++;
            } else if (values[i] === "F") {
                fCountPerIndex[i]++;
            }
        }
    });

    const series = [
        {
            name: "Verdadero",
            group: "V",
            data: vCountPerIndex
        },
        {
            name: "Falso",
            group: "F",
            data: fCountPerIndex
        }
    ]


    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        colors: OTHER_COLORS,
        series: series,
        xaxis: {
            categories: statements,
            labels: {
                style: {
                    fontFamily: "Avenir Regular"
                }
            }
        },
        legend: {
            position: 'bottom',
            fontFamily: "Avenir Regular"
        }
    };

    // Create new element for chart
    const chartElement = document.createElement("div");
    chartElement.id = "answers-histogram";
    container.appendChild(chartElement);

    // Render chart
    const chart = new ApexCharts(chartElement, options);
    chart.render();
}

export const answersPieChart = (data, isPreTestPostTest = false) => {
    // Get container element
    const container = document.getElementById("answers-pie-container");
    if (!container) return;

    // Clear any existing charts
    container.innerHTML = '';

    if (isPreTestPostTest) {
        // Count transitions between pre and post test
        const counts = {
            'Correcta → Correcta': 0,
            'Correcta → Incorrecta': 0,
            'Incorrecta → Correcta': 0,
            'Incorrecta → Incorrecta': 0,
            'Otro/No responde': 0
        };

        // Get pre and post test data
        const preTest = data.preTest;
        const postTest = data.postTest;

        // Count each type of transition
        for (let i = 0; i < preTest.length; i++) {
            if (preTest[i] === null || postTest[i] === null) {
                counts['Otro/No responde']++;
            } else {
                const transition = `${preTest[i] ? 'Correcta' : 'Incorrecta'} → ${postTest[i] ? 'Correcta' : 'Incorrecta'}`;
                counts[transition]++;
            }
        }

        const options = {
            chart: {
                type: 'pie',
                height: 350
            },
            colors: [
                '#388659',  // Dark green for Correcta → Correcta
                '#D23831',  // Red for Correcta → Incorrecta
                '#52BE80',  // Light green for Incorrecta → Correcta
                '#C17C7C',  // Light red for Incorrecta → Incorrecta
                '#646464'   // Gray for Otro/No responde
            ],
            series: Object.values(counts),
            labels: Object.keys(counts),
            legend: {
                position: 'bottom',
                fontFamily: "Avenir Regular"
            }
        };

        // Create new element for chart
        const chartElement = document.createElement("div");
        chartElement.id = "answers-pie";
        container.appendChild(chartElement);

        // Render chart
        const chart = new ApexCharts(chartElement, options);
        chart.render();

    } else {
        // Original single-test logic
        const counts = {
            'Correctas': data.filter(x => x === true).length,
            'Incorrectas': data.filter(x => x === false).length,
            'Sin responder': data.filter(x => x === null).length
        };

        const options = {
            chart: {
                type: 'pie',
                height: 350
            },
            colors: CORRECT_COLORS,
            series: Object.values(counts),
            labels: Object.keys(counts),
            legend: {
                position: 'bottom',
                fontFamily: "Avenir Regular"
            }
        };

        // Create new element for chart
        const chartElement = document.createElement("div");
        chartElement.id = "answers-pie";
        container.appendChild(chartElement);

        // Render chart
        const chart = new ApexCharts(chartElement, options);
        chart.render();
    }
}

export const answersEncuestaSlider = (responses, code, isResumen) => {
    const container = document.getElementById(`encuestas-chart-container-${code}`);
    if (!container) return;
    container.innerHTML = '';

    // Initialize counts for each value (1-10)
    const counts = Array(10).fill(0);

    // Count responses
    responses.forEach(response => {
        const value = parseInt(response);
        if (value >= 1 && value <= 10) {
            counts[value - 1]++;
        }
    });

    const options = {
        series: [{
            name: 'Respuestas',
            data: counts
        }],
        chart: {
            type: 'bar',
            height: isResumen ? 250 : 350,
            width: "100%"
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            }
        },
        colors: ["#d23831"],
        xaxis: {
            categories: Array.from({ length: 10 }, (_, i) => i + 1),
            title: {
                text: isResumen ? 'Satisfacción general del curso' : 'Valor',
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            }
        },
        yaxis: {
            title: {
                text: 'Cantidad de respuestas',
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            }
        }
    };

    const chartElement = document.createElement("div");
    chartElement.id = `encuestas-chart-${code}`;
    container.appendChild(chartElement);

    // Render chart
    const chart = new ApexCharts(chartElement, options);
    chart.render();
}

export const answersEncuestaMultipleChoice = (responses, code, alternatives) => {
    const container = document.getElementById(`encuestas-chart-container-${code}`);
    if (!container) return;

    // Clear any existing chart
    const existingChart = container.querySelector('.apexcharts-canvas');
    if (existingChart) {
        const chartId = existingChart.id.split('-')[0];
        ApexCharts.getChartByID(chartId)?.destroy();
    }
    container.innerHTML = '';

    // Calculate dynamic height based on number of alternatives
    const baseHeight = 400;
    const heightPerFiveBars = 150;
    const numBars = alternatives.length;
    const additionalSets = Math.floor((numBars - 1) / 5); // -1 to start counting after first 5
    const chartHeight = baseHeight + (additionalSets * heightPerFiveBars);

    // Initialize counts for each alternative
    const counts = Array(alternatives.length).fill(0);

    // Count responses
    responses.forEach(response => {
        const values = response.split('_');
        values.forEach((value, index) => {
            if (value === 'true') {
                counts[index]++;
            }
        });
    });

    // Function to split text into lines of max 20 chars, breaking at word boundaries
    const splitTextIntoLines = (text, maxLength = 22) => {
        const words = text.split(' ');
        const lines = [];
        let currentLine = '';

        words.forEach(word => {
            if (currentLine.length + word.length + 1 <= maxLength) {
                currentLine += (currentLine.length ? ' ' : '') + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });
        if (currentLine) {
            lines.push(currentLine);
        }
        return lines;
    };

    const options = {
        series: [{
            name: 'Respuestas',
            data: counts
        }],
        chart: {
            type: 'bar',
            height: chartHeight // Use dynamic height
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '55%',
                endingShape: 'rounded'
            }
        },
        colors: ["#d23831"],
        xaxis: {
            categories: alternatives.map(alt => splitTextIntoLines(alt.text)),
            title: {
                text: 'Cantidad de respuestas',
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            },
            labels: {
                formatter: function (val) {
                    if (Array.isArray(val)) {
                        return val.join('\n');
                    }
                    return val;
                },
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            }
        },
        yaxis: {
            title: {
                text: 'Alternativas',
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            }
        }
    };

    const chart = new ApexCharts(container, options);
    chart.render();
}

export const answersEncuestaCheckboxes = (responses, code, alternatives) => {
    // Use the same logic as multiple choice since the data structure is the same
    answersEncuestaMultipleChoice(responses, code, alternatives);
}

export const answersEncuestaNumber = (responses, code) => {
    const container = document.getElementById(`encuestas-chart-container-${code}`);
    if (!container) return;
    container.innerHTML = '';

    // Convert responses to numbers and filter out invalid values
    const numericResponses = responses
        .map(response => parseInt(response))
        .filter(num => !isNaN(num));

    if (numericResponses.length === 0) {
        container.innerHTML = '<p>No hay respuestas numéricas válidas.</p>';
        return;
    }

    // Find min and max values
    const minValue = Math.min(...numericResponses);
    const maxValue = Math.max(...numericResponses);

    // Calculate bin size - we want approximately 10 bins
    const range = maxValue - minValue;
    const binSize = Math.max(1, Math.ceil(range / 10)); // Ensure minimum bin size of 1

    // Create bins
    const bins = {};
    for (let i = minValue; i <= maxValue; i += binSize) {
        const binLabel = `${i}-${Math.min(i + binSize - 1, maxValue)}`;
        bins[binLabel] = 0;
    }

    // Count responses in each bin
    numericResponses.forEach(value => {
        const binIndex = Math.floor((value - minValue) / binSize);
        const binStart = minValue + (binIndex * binSize);
        const binLabel = `${binStart}-${Math.min(binStart + binSize - 1, maxValue)}`;
        bins[binLabel]++;
    });

    const options = {
        series: [{
            name: 'Respuestas',
            data: Object.values(bins)
        }],
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            }
        },
        colors: ["#d23831"],
        xaxis: {
            categories: Object.keys(bins),
            title: {
                text: 'Intervalos',
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            }
        },
        yaxis: {
            title: {
                text: 'Cantidad de respuestas',
                style: {
                    "fontFamily": "Avenir Regular",
                    "color": "#646464"
                }
            }
        }
    };

    const chartElement = document.createElement("div");
    chartElement.id = `encuestas-chart-${code}`;
    container.appendChild(chartElement);

    // Render chart
    const chart = new ApexCharts(chartElement, options);
    chart.render();
}

// Add a variable to store the chart instance
let gradesChart = null;

export const gradesDistributionChart = (contents, enrolledUsers, scale = 60, summaryData = null, evaluationName = null) => {
    // Get the container
    const container = document.getElementById("grades-distribution-chart");
    if (!container) {
        console.warn("Grades distribution chart container not found");
        return;
    }

    // Destroy previous chart if it exists
    if (gradesChart) {
        gradesChart.destroy();
        gradesChart = null;
    }

    // Get grades either from summaryData or calculate them
    let grades;
    if (summaryData && evaluationName) {
        // Extract grades from summaryData based on evaluation type and name
        grades = enrolledUsers.map(user => {
            const userData = summaryData.find(data => data.username === user.user_id);
            if (!userData) return null;

            let grade = null;
            if (evaluationName.startsWith('Control')) {
                grade = userData.grades.controles[evaluationName];
            } else if (evaluationName.startsWith('Taller')) {
                grade = userData.grades.preguntasCalificadas[evaluationName];
            } else {
                grade = userData.grades.tests[evaluationName];
            }
            
            return grade;
        }).filter(grade => {
            const isValid = grade !== null && !isNaN(grade) && grade !== '—';
            return isValid;
        });
        
    }

    // Initialize counts for each grade interval
    const gradeCounts = {
        "1.0 - 1.9": 0,
        "2.0 - 2.9": 0,
        "3.0 - 3.9": 0,
        "4.0 - 4.9": 0,
        "5.0 - 5.9": 0,
        "6.0 - 6.9": 0,
        "7.0": 0
    };

    // Count frequency of each grade
    grades.forEach(grade => {
        if (grade >= 7.0) {
            gradeCounts["7.0"]++;
        } else {
            const interval = Math.floor(grade) + ".0 - " + Math.floor(grade) + ".9";
            gradeCounts[interval]++;
        }
    });

    const options = {
        chart: {
            type: 'bar',
            height: 200,
            width: '100%' // Make chart width responsive
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                distributed: true
            }
        },
        colors: [
            '#D23831', // 1.0-1.9 - Red
            '#D23831', // 2.0-2.9 - Red
            '#D23831', // 3.0-3.9 - Red
            '#388659', // 4.0-4.9 - Green
            '#388659', // 5.0-5.9 - Green
            '#388659', // 6.0-6.9 - Green
            '#388659'  // 7.0 - Green
        ],
        series: [{
            name: 'Docentes',
            data: Object.values(gradeCounts)
        }],
        xaxis: {
            categories: Object.keys(gradeCounts),
            labels: {
                style: {
                    fontFamily: "Avenir Regular"
                }
            },
            title: {
                show: false
            }
        },
        yaxis: {
            title: {
                text: 'Cantidad de docentes',
                style: {
                    fontFamily: "Avenir Regular",
                    color: "#646464"
                }
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontFamily: "Avenir Regular"
            }
        },
        title: {
            show: false
        },
        legend: {
            show: false // Hide the legend
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                }
            }
        }]
    };

    // Store the chart instance
    gradesChart = new ApexCharts(container, options);
    gradesChart.render();
};

// Add a cleanup function
export const destroyGradesChart = () => {
    if (gradesChart) {
        gradesChart.destroy();
        gradesChart = null;
    }
};

export const finalGradesDistributionChart = (enrolledUsers, summaryData, grading, sectionData, allResponses) => {
    // Get the container
    const container = document.getElementById("final-grades-distribution-chart");
    if (!container) {
        console.warn("Final grades distribution chart container not found");
        return;
    }

    // Helper function to calculate avance grade (copied from NotasResumenTable.js)
    const calculateAvanceGrade = (userId) => {
        let totalAnswered = 0;
        let totalQuestions = 0;

        // Only count Taller sections
        sectionData.sections
            .filter(section => section.name.toLowerCase().includes('taller'))
            .forEach(section => {
                const xblocks = section.subsections.flatMap(subsection =>
                    subsection.units.flatMap(unit =>
                        unit.xblocks.filter(xblock => 
                            ["multiplechoice", "table_advanced_problem", "radiomultiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3"].includes(xblock.true_block_type)
                        )
                    )
                );

                totalQuestions += xblocks.length;

                xblocks.forEach(xblock => {
                    const responseKey = `${xblock.id}_${userId}`;
                    const response = allResponses[responseKey];
                    
                    if (response) {
                        totalAnswered++;
                    }
                });
            });

        const percentage = totalQuestions === 0 ? 0 : (totalAnswered / totalQuestions) * 100;
        // Convert percentage to grade using scale
        return percentage >= grading.scale ? 7 : Math.max(1, Math.min(6.9, (percentage * 7) / grading.scale));
    };

    // Calculate final grades for all users
    const grades = enrolledUsers.map(user => {
        let finalGrade = 0;
        let totalWeight = 0;

        // Add controles grades if they exist
        if (grading.controles_rate > 0) {
            const controlesGrades = Object.values(
                summaryData.find(data => data.username === user.user_id)?.grades?.controles || {}
            );
            if (controlesGrades.length > 0) {
                const avgControles = controlesGrades.reduce((a, b) => a + b, 0) / controlesGrades.length;
                finalGrade += avgControles * (grading.controles_rate / 100);
                totalWeight += grading.controles_rate;
            }
        }

        // Add preguntas calificadas grade if it exists
        if (grading.preguntas_calificadas_rate > 0) {
            const pcGrade = summaryData.find(data => data.username === user.user_id)?.grades?.tests["Preguntas Calificadas"] || 1;
            finalGrade += pcGrade * (grading.preguntas_calificadas_rate / 100);
            totalWeight += grading.preguntas_calificadas_rate;
        }

        // Add avance grade if it exists
        if (grading.avance_rate > 0) {
            const avanceGrade = calculateAvanceGrade(user.user_id);
            finalGrade += avanceGrade * (grading.avance_rate / 100);
            totalWeight += grading.avance_rate;
        }

        // Add other rates if they exist
        if (grading.other_rates?.length > 0) {
            grading.other_rates.forEach(rate => {
                const otherGrade = summaryData.find(data => data.username === user.user_id)?.grades?.other[rate.name] || 1;
                finalGrade += otherGrade * (rate.value / 100);
                totalWeight += rate.value;
            });
        }

        return totalWeight > 0 ? Math.round((finalGrade * 100 / totalWeight) * 10) / 10 : null;
    }).filter(grade => grade !== null);

    // Initialize counts for each grade interval
    const gradeCounts = {
        "1.0 - 1.9": 0,
        "2.0 - 2.9": 0,
        "3.0 - 3.9": 0,
        "4.0 - 4.9": 0,
        "5.0 - 5.9": 0,
        "6.0 - 6.9": 0,
        "7.0": 0
    };

    // Count frequency of each grade
    grades.forEach(grade => {
        if (grade >= 7.0) {
            gradeCounts["7.0"]++;
        } else {
            const interval = Math.floor(grade) + ".0 - " + Math.floor(grade) + ".9";
            gradeCounts[interval]++;
        }
    });

    const options = {
        chart: {
            type: 'bar',
            height: 300
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                distributed: true
            }
        },
        colors: [
            '#D23831', // 1.0-1.9 - Red
            '#D23831', // 2.0-2.9 - Red
            '#D23831', // 3.0-3.9 - Red
            '#388659', // 4.0-4.9 - Green
            '#388659', // 5.0-5.9 - Green
            '#388659', // 6.0-6.9 - Green
            '#388659'  // 7.0 - Green
        ],
        series: [{
            name: 'Docentes',
            data: Object.values(gradeCounts)
        }],
        xaxis: {
            categories: Object.keys(gradeCounts),
            labels: {
                style: {
                    fontFamily: "Avenir Regular"
                }
            },
            title: {
                show: false
            }
        },
        yaxis: {
            title: {
                text: 'Cantidad de docentes',
                style: {
                    fontFamily: "Avenir Regular",
                    color: "#646464"
                }
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontFamily: "Avenir Regular"
            }
        },
        title: {
            show: false
        },
        legend: {
            show: false // Hide the legend
        }
    };

    const chart = new ApexCharts(container, options);
    chart.render();
};

export const asistenciaChart = (data) => {
    // Get container element
    const container = document.getElementById("asistencia-chart-container");
    if (!container) return;

    // Clear any existing charts
    container.innerHTML = '';

    // Count attendance values
    const counts = {
        'Asistencia': data.filter(a => a.assistance === "2").length,
        'Inasistencia justificada': data.filter(a => a.assistance === "1").length,
        'Inasistencia': data.filter(a => a.assistance === "0").length
    };

    const options = {
        chart: {
            type: 'pie',
            height: 150
        },
        colors: [
            '#388659',  // Green for attendance
            '#F0B04F',  // Yellow for justified absence
            '#D23831'   // Red for absence
        ],
        series: Object.values(counts),
        labels: Object.keys(counts),
        legend: {
            position: 'right',
            fontFamily: "Avenir Regular"
        }
    };

    // Create new element for chart
    const chartElement = document.createElement("div");
    chartElement.id = "asistencia-pie";
    container.appendChild(chartElement);

    // Render chart
    const chart = new ApexCharts(chartElement, options);
    chart.render();
}
