import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, CardContent, TextField, MenuItem, Divider, Box } from "@mui/material";


const Resumen = ({ keycloak, catalog }) => {

    const [selectedSemester, setSelectedSemester] = useState("all");

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} display={"flex"} flexDirection={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-between" }}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mb={"20px"}>
                        <Typography variant={"title"} fontSize={"1.6em"} mb={0}>CMMEdu Analytics</Typography>
                        <Typography width={"100%"} variant={"p-small"} fontWeight={"bold"}>Plataforma de datos y seguimiento de cursos</Typography>
                    </Box>
                    <TextField
                        select
                        label="Semestre"
                        variant="outlined"
                        fullWidth
                        sx={{ maxWidth: "250px" }}
                        value={selectedSemester}
                        onChange={(e) => setSelectedSemester(e.target.value)}
                    >
                        <MenuItem disabled value="none">Seleccione una opción...</MenuItem>
                        <MenuItem value="all">Histórico</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardContent>
                            <Typography variant={"title"} fontSize={"1.2em"}>Cursos activos</Typography>
                            <Divider sx={{ my: 1 }} />
                            <Typography variant={"p-small"}>En desarrollo</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant={"title"} fontSize={"1.2em"}>Satisfacción</Typography>
                            <Divider sx={{ my: 1 }} />
                            <Typography variant={"p-small"}>En desarrollo</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant={"title"} fontSize={"1.2em"}>Situación actual</Typography>
                            <Divider sx={{ my: 1 }} />
                            <Typography variant={"p-small"}>En desarrollo</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Resumen;