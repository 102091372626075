import axios from 'axios';

export const getStatic = async (token, server, path) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/static", {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            params: {
                server_id: server,
                path: path
            }
        });

        return { data: response.data.data, error: "" };

    } catch (error) {
        return { data: null, error: "Error desconocido: " + error.message };
    }
}