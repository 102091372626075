import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const NotasResumenTable = ({
    enrolledUsers,
    summaryData,
    evaluations,
    grading,
    allResponses,
    sectionData,
    identifier,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess,
    manualMultiGrades
}) => {


    console.log(enrolledUsers)

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const calculateAvanceGrade = (userId) => {
        let totalAnswered = 0;
        let totalQuestions = 0;

        // Only count Taller sections
        sectionData.sections
            .filter(section => section.name.toLowerCase().includes('taller'))
            .forEach(section => {
                const xblocks = section.subsections.flatMap(subsection =>
                    subsection.units.flatMap(unit =>
                        unit.xblocks.filter(xblock =>
                            ["multiplechoice", "table_advanced_problem", "radiomultiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3"].includes(xblock.true_block_type)
                        )
                    )
                );

                totalQuestions += xblocks.length;

                xblocks.forEach(xblock => {
                    const responseKey = `${xblock.id}_${userId}`;
                    const response = allResponses[responseKey];

                    if (response) {
                        totalAnswered++;
                    }
                });
            });

        const percentage = totalQuestions === 0 ? 0 : (totalAnswered / totalQuestions) * 100;

        // Calculate percentage achieved
        const decimalScale = 60 / 100;

        // Calculate grade (1.0 - 7.0 scale with 4.0 at scale%)
        if (percentage < decimalScale * 100) {
            return (4.0 - 1.0) * (percentage / (decimalScale * 100)) + 1.0;
        } else {
            // For percentage < scale: linear from 1.0 to 4.0
            return (7.0 - 4.0) * ((percentage - decimalScale * 100) / (100 * (1 - decimalScale))) + 4.0;
        }
    };

    const handleDownloadCalificacionesCSV = () => {
        let header = ['Docente'];

        // Add control columns
        if (grading.controles_rate > 0) {
            evaluations.controles.forEach((_, index) => {
                header.push(`Control ${index + 1}`);
            });
            header.push('Promedio Controles');
        }

        // Add preguntas calificadas if exists
        if (grading.preguntas_calificadas_rate > 0) {
            evaluations.preguntasCalificadas.forEach((_, index) => {
                header.push(`Pregunta Calificada ${index + 1}`);
            });
            header.push('Promedio Preguntas Calificadas');
        }

        // Add avance if exists
        if (grading.avance_rate > 0) {
            header.push('Avance');
        }

        // Add other rates if exist
        if (grading.other_rates?.length > 0) {
            grading.other_rates.forEach(rate => {
                header.push(rate.name);
            });
        }

        header.push('Nota Final');

        // Use makeRows() to get the data and sort by username
        const rows = makeRows()
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(row => {
                const values = [];
                values.push(row.id); // Docente

                // Add values in the same order as headers
                if (grading.controles_rate > 0) {
                    evaluations.controles.forEach((_, index) => {
                        values.push(row[`control_${index + 1}`]);
                    });
                    values.push(row.nota_controles);
                }

                if (grading.preguntas_calificadas_rate > 0) {
                    evaluations.preguntasCalificadas.forEach((_, index) => {
                        values.push(row[`pc_${index + 1}`]);
                    });
                    values.push(row.nota_pc);
                }

                if (grading.avance_rate > 0) {
                    values.push(row.avance);
                }

                if (grading.other_rates?.length > 0) {
                    grading.other_rates.forEach(rate => {
                        values.push(row[`other_${rate.name}`]);
                    });
                }

                values.push(row.nota_final);
                return values;
            });

        const output = [header, ...rows];
        const fileName = "resumen_notas_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    };

    const generateColumns = () => {
        let columns = [
            {
                field: 'id',
                headerName: 'Docente',
                width: 200,
                headerAlign: 'center',
                disableColumnMenu: true,
                align: 'center',
                description: 'Nombre del docente',
            }
        ];

        // Add test columns first
        evaluations.tests.forEach((test, index) => {
            if (test.name !== "Pre-Test - Post-Test") {
                columns.push({
                    field: test.name,
                    headerName: test.name.split(' ').map(word => word !== "de" ? word.charAt(0).toUpperCase() : "").join(''),
                    width: 50,
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    sortable: false,
                    align: 'center',
                    description: test.name,
                });
            }
        });
        // Add manual multi grades columns right after controles
        if (grading.other_rates?.filter(rate => rate.type === "otros_multiple").length > 0) {
            grading.other_rates.filter(rate => rate.type === "otros_multiple").forEach(rate => {
                const groupAbbrev = rate.name.split(' ')
                    .map(word => word.charAt(0).toUpperCase())
                    .join('');

                // Add individual grades for this group if evaluations exist
                if (manualMultiGrades?.[rate.name]?.length > 0) {
                    manualMultiGrades[rate.name].forEach((evaluation, index) => {
                        columns.push({
                            field: `multi_${rate.name}_${index}`,
                            headerName: `${groupAbbrev}${index + 1}`,
                            width: 50,
                            sortable: false,
                            headerAlign: 'center',
                            disableColumnMenu: true,
                            align: 'center',
                            description: evaluation.evaluation_name,
                        });
                    });
                }

                // Always add average column for this group
                columns.push({
                    field: `multi_${rate.name}_avg`,
                    headerName: groupAbbrev,
                    width: 50,
                    sortable: false,
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    align: 'center',
                    description: `Promedio ${rate.name}`,
                    renderCell: (params) => {
                        return <Typography fontWeight={"bold"} variant='p'>{params.value}</Typography>;
                    }
                });
            });
        }

        // Add preguntas calificadas columns if exists
        if (grading.preguntas_calificadas_rate > 0) {
            evaluations.preguntasCalificadas.forEach((taller, index) => {
                columns.push({
                    field: `pc_${index + 1}`,
                    headerName: `PC${index + 1}`,
                    width: 50,
                    sortable: false,
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    align: 'center',
                    description: `Pregunta Calificada ${index + 1}`,
                });
            });
        }

        // Add promedio preguntas calificadas column
        if (grading.preguntas_calificadas_rate > 0) {
            columns.push({
                field: 'nota_pc',
                headerName: 'PC',
                width: 50,
                sortable: false,
                headerAlign: 'center',
                disableColumnMenu: true,
                align: 'center',
                description: 'Nota de Preguntas Calificadas',
                renderCell: (params) => {
                    return <Typography fontWeight={"bold"} variant='p'>{params.value}</Typography>;
                }
            });
        }

        // Add control columns
        if (grading.controles_rate > 0) {
            evaluations.controles.forEach((_, index) => {
                columns.push({
                    field: `control_${index + 1}`,
                    headerName: `C${index + 1}`,
                    width: 50,
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    sortable: false,
                    align: 'center',
                    description: `Control ${index + 1}`,
                });
            });

            // Add nota controles column right after individual controls
            columns.push({
                field: 'nota_controles',
                headerName: 'NC',
                width: 50,
                sortable: false,
                headerAlign: 'center',
                disableColumnMenu: true,
                align: 'center',
                description: 'Nota de Controles',
                renderCell: (params) => {
                    return <Typography fontWeight={"bold"} variant='p'>{params.value}</Typography>;
                }
            });
        }

        // Add other rates if exist
        if (grading.other_rates.filter(rate => rate.type === "otros_unico").length > 0) {
            grading.other_rates.filter(rate => rate.type === "otros_unico").forEach(rate => {
                columns.push({
                    field: `other_${rate.name}`,
                    headerName: rate.name.split(' ').map(word => word.charAt(0).toUpperCase()).join(''),
                    width: 50,
                    sortable: false,
                    headerAlign: 'center',
                    disableColumnMenu: true,
                    align: 'center',
                    description: rate.name,
                    renderCell: (params) => {
                        return <Typography fontWeight={"bold"} variant='p'>{params.value}</Typography>;
                    }
                });
            });
        }

        // Add avance if exists
        if (grading.avance_rate > 0) {
            columns.push({
                field: 'avance',
                headerName: 'AP',
                width: 50,
                sortable: false,
                headerAlign: 'center',
                disableColumnMenu: true,
                align: 'center',
                description: 'Avance en Plataforma',
                renderCell: (params) => {
                    return <Typography fontWeight={"bold"} variant='p'>{params.value}</Typography>;
                }
            });
        }

        // Add final grade column
        columns.push({
            field: 'nota_final',
            headerName: 'NF',
            width: 50,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            align: 'center',
            description: 'Nota Final',
            renderCell: (params) => {
                return <Typography fontWeight={"bold"} variant='p'>{params.value}</Typography>;
            }
        });

        return columns;
    };

    const makeRows = () => {
        let rows = enrolledUsers.map(user => {
            let row = {
                id: user.username
            };

            // Add test grades
            evaluations.tests.forEach((test, index) => {
                const grade = summaryData.find(data => data.username === user.user_id)?.grades?.tests[test.name];
                row[test.name] = grade ? grade.toFixed(1) : '—';
            });

            // Add control grades
            if (grading.controles_rate > 0) {
                evaluations.controles.forEach((_, index) => {
                    const grade = summaryData.find(data => data.username === user.user_id)?.grades?.controles[`Control ${index + 1}`];
                    row[`control_${index + 1}`] = grade ? grade.toFixed(1) : '—';
                });
            }

            // Add preguntas calificadas grades
            if (grading.preguntas_calificadas_rate > 0) {
                evaluations.preguntasCalificadas.forEach((_, index) => {
                    const grade = summaryData.find(data => data.username === user.user_id)?.grades?.preguntasCalificadas[`Taller ${index + 1}`];
                    row[`pc_${index + 1}`] = grade ? grade.toFixed(1) : '—';
                });
            }

            // Calculate promedio preguntas calificadas
            if (grading.preguntas_calificadas_rate > 0) {
                const pcGrades = evaluations.preguntasCalificadas.map((_, index) =>
                    row[`pc_${index + 1}`]
                );

                // If any pregunta calificada is missing (has a dash), nota pc should be a dash
                if (pcGrades.some(grade => grade === '—')) {
                    row.nota_pc = '—';
                    row.nota_final = '—';
                } else {
                    const avgPC = pcGrades.reduce((a, b) => a + parseFloat(b), 0) / pcGrades.length;
                    row.nota_pc = avgPC.toFixed(1);
                }
            }

            // Add avance grade
            if (grading.avance_rate > 0) {
                row.avance = calculateAvanceGrade(user.user_id).toFixed(1);
            }

            // Add other rates grades
            if (grading.other_rates.filter(rate => rate.type === "otros_unico").length > 0) {
                grading.other_rates.filter(rate => rate.type === "otros_unico").forEach(rate => {
                    row[`other_${rate.name}`] = summaryData.find(data => data.username === user.user_id)?.grades?.other[rate.name] || '—';
                });
            }

            // Calculate nota controles
            if (grading.controles_rate > 0) {
                const controlesGrades = evaluations.controles.map((_, index) =>
                    row[`control_${index + 1}`]
                );

                // If any control is missing (has a dash), nota controles should be a dash
                if (controlesGrades.some(grade => grade === '—')) {
                    row.nota_controles = '—';
                    row.nota_final = '—';  // Final grade should also be a dash
                } else {
                    const avgControles = controlesGrades.reduce((a, b) => a + parseFloat(b), 0) / controlesGrades.length;
                    row.nota_controles = avgControles.toFixed(1);
                }
            }

            // Handle manual multi grades
            if (grading.other_rates?.filter(rate => rate.type === "otros_multiple").length > 0) {
                grading.other_rates.filter(rate => rate.type === "otros_multiple").forEach(rate => {
                    // Add individual grades if evaluations exist
                    if (manualMultiGrades?.[rate.name]?.length > 0) {
                        manualMultiGrades[rate.name].forEach((evaluation, index) => {
                            const grade = evaluation.grades.find(g => g.user_id === user.user_id)?.grade;
                            row[`multi_${rate.name}_${index}`] = grade || grade === 0 ? grade.toFixed(1) : '—';
                        });
                    }

                    // Always add average column with dash if no evaluations
                    if (!manualMultiGrades?.[rate.name]?.length) {
                        row[`multi_${rate.name}_avg`] = '—';
                    } else {
                        const grades = manualMultiGrades[rate.name].map((evaluation, index) => 
                            row[`multi_${rate.name}_${index}`]
                        );
                        row[`multi_${rate.name}_avg`] = grades.includes('—') ? 
                            '—' : 
                            (grades.reduce((sum, grade) => sum + parseFloat(grade), 0) / grades.length).toFixed(1);
                    }
                });
            }

            // Calculate final grade (only if nota_controles is not a dash)
            if (row.nota_controles !== '—' && (row.nota_pc !== null && row.nota_pc !== '—')) {
                let finalGrade = 0;
                let totalWeight = 0;
                let shouldCalculateFinal = true;

                // Add controles grade
                if (grading.controles_rate > 0) {
                    const controlesGrade = Math.round(parseFloat(row.nota_controles) * 10) / 10;
                    finalGrade += controlesGrade * (grading.controles_rate / 100);
                    totalWeight += grading.controles_rate;
                }

                // Add preguntas calificadas grade
                if (grading.preguntas_calificadas_rate > 0) {
                    if (row.nota_pc === '—') {
                        shouldCalculateFinal = false;
                    } else {
                        const pcGrade = Math.round(parseFloat(row.nota_pc) * 10) / 10;
                        finalGrade += pcGrade * (grading.preguntas_calificadas_rate / 100);
                        totalWeight += grading.preguntas_calificadas_rate;
                    }
                }

                // Add avance grade
                if (grading.avance_rate > 0) {
                    const avanceGrade = Math.round(calculateAvanceGrade(user.user_id) * 10) / 10;
                    finalGrade += avanceGrade * (grading.avance_rate / 100);
                    totalWeight += grading.avance_rate;
                }

                // Check manual multi grades first
                if (grading.other_rates?.filter(rate => rate.type === "otros_multiple").length > 0) {
                    grading.other_rates.filter(rate => rate.type === "otros_multiple").forEach(rate => {
                        const rateValue = grading.other_rates?.find(r => r.name === rate.name && r.type === "otros_multiple")?.value || 0;
                        
                        if (rateValue > 0) {
                            const avgGrade = row[`multi_${rate.name}_avg`];
                            if (avgGrade === '—') {
                                shouldCalculateFinal = false;
                            } else {
                                finalGrade += parseFloat(avgGrade) * (rateValue / 100);
                                totalWeight += rateValue;
                            }
                        }
                    });
                }

                // Add other single grades
                if (grading.other_rates?.filter(rate => rate.type === "otros_unico").length > 0) {
                    grading.other_rates.filter(rate => rate.type === "otros_unico").forEach(rate => {
                        const otherGrade = summaryData.find(data => data.username === user.user_id)?.grades?.other[rate.name];
                        // If the grade doesn't exist or is empty, don't calculate final
                        if (!otherGrade && otherGrade !== 0) {
                            shouldCalculateFinal = false;
                        } else {
                            const roundedGrade = Math.round(otherGrade * 10) / 10;
                            finalGrade += roundedGrade * (rate.value / 100);
                            totalWeight += rate.value;
                        }
                    });
                }

                // Only continue if we should calculate final grade
                if (shouldCalculateFinal) {
                    // Fix precision issues by multiplying by 100, rounding, then dividing by 100
                    // before doing the final rounding to 1 decimal
                    finalGrade = Math.round(Math.round(finalGrade * 100) / 100 * 10) / 10;
                    row.nota_final = totalWeight > 0 ?
                        (Math.round((finalGrade * 100 / totalWeight) * 10) / 10).toFixed(1) : '—';
                } else {
                    row.nota_final = '—';
                }
            }

            return row;
        });

        return rows;
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Notas por docente</Typography>
                <Box>
                    <IconButton onClick={handleDownloadCalificacionesCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(enrolledUsers).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p-error' textAlign={"center"}>No hay datos de evaluaciones disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1060px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={generateColumns()}
                    pageSizeOptions={[10]}
                    onPaginationModelChange={setPaginationModel}
                    getRowHeight={() => 'auto'}
                    columnGroupingModel={[
                        {
                            groupId: 'Controles',
                            children: [
                                ...evaluations.controles.map((_, index) => ({
                                    field: `control_${index + 1}`
                                })),
                                { field: 'nota_controles' }
                            ]
                        },
                        // Add groups for manual multi grades
                        ...Object.entries(manualMultiGrades || {}).map(([groupName, evaluations]) => ({
                            groupId: groupName,
                            children: [
                                ...evaluations.map((_, index) => ({
                                    field: `multi_${groupName}_${index}`
                                })),
                                { field: `multi_${groupName}_avg` }
                            ]
                        })),
                        {
                            groupId: 'Preguntas Calificadas',
                            children: evaluations.preguntasCalificadas.map((_, index) => ({
                                field: `pc_${index + 1}`
                            }))
                        }
                    ]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'asc' }],
                        },
                        pagination: { paginationModel }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default NotasResumenTable;