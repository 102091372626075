import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, CircularProgress, Tabs, Tab } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CourseSeguimientoModal from "../modals/CourseSeguimientoModal";
import CourseSectionResumen from "./course_section/CourseSectionResumen";
import CourseSectionAvance from "./course_section/CourseSectionAvance";
import CourseSectionAsistencia from "./course_section/CourseSectionAsistencia";
import CourseSectionEncuestas from "./course_section/CourseSectionEncuestas";
import CourseSectionEvaluaciones from "./course_section/CourseSectionEvaluaciones";
import CourseSectionExplorer from "./course_section/CourseSectionExplorer";
import { formatTimestamp, parseSurveyContents, formatXBlockResponse, getXBlockResponseIsCorrect, parseEvaluationContents } from "../../../utils/formatters";
import { getAllResponses } from "../../../requests/getAllResponses";
import { postGetCalificaciones } from "../../../requests/postGetCalificaciones";
import { postGetManualGrades } from "../../../requests/postGetManualGrades";
import { postGetAsistencia } from "../../../requests/postGetAsistencia";

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const CourseSection = ({
    keycloak,
    course,
    selectedImplementation,
    selectedSection,
    sectionData,
    loadingSectionData,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [tab, setTab] = useState("resumen");
    const [seguimientoModal, setSeguimientoModal] = useState(false);

    const [allResponses, setAllResponses] = useState(null);

    const [evaluations, setEvaluations] = useState({
        tests: [],
        controles: [],
        prePostComparison: []
    });
    const [manualGrades, setManualGrades] = useState(null);
    const [asistencia, setAsistencia] = useState(null);
    const [surveys, setSurveys] = useState({});

    const [loadingEvaluations, setLoadingEvaluations] = useState(true);
    const [loadingResponses, setLoadingResponses] = useState(true);
    const [loadingSurveys, setLoadingSurveys] = useState(true);
    const [loadingAsistencia, setLoadingAsistencia] = useState(true);
    const handleTabs = (event, newValue) => {
        setTab(newValue);
    }

    const getEvaluations = async () => {
        setLoadingEvaluations(true);
        let newEvaluations = {
            "tests": [],
            "controles": [],
            "prePostComparison": [],
            "preguntasCalificadas": []
        }
        let allIds = [];

        let preguntasCalificadasByTaller = {};

        let preTestQuestions = [];
        let postTestQuestions = [];

        for (let section of sectionData.sections) {
            for (let subsection of section.subsections) {
                for (let unit of subsection.units) {
                    if (unit.name.toLowerCase().startsWith("prueba de diagnóstico")) {
                        let newContents = parseEvaluationContents(unit.xblocks, false);
                        preTestQuestions.push(...newContents);
                        allIds.push(...newContents.map(content => content.content.id));
                        newEvaluations.tests.push({
                            "name": subsection.name,
                            "contents": newContents
                        });
                    } else if (unit.name.toLowerCase().startsWith("post-test")) {
                        let newContents = parseEvaluationContents(unit.xblocks, false);
                        allIds.push(...newContents.map(content => content.content.id));
                        newEvaluations.tests.push({
                            "name": subsection.name,
                            "contents": newContents
                        });
                    } else if (unit.name.toLowerCase().startsWith("control")) {
                        let newContents = parseEvaluationContents(unit.xblocks, false);
                        postTestQuestions.push(...newContents);
                        allIds.push(...newContents.map(content => content.content.id));
                        newEvaluations.controles.push({
                            "name": subsection.name,
                            "contents": newContents
                        });
                    } else if (unit.name.toLowerCase().startsWith("pregunta calificada")) {
                        let newContents = parseEvaluationContents(unit.xblocks, true, subsection.name);
                        allIds.push(...newContents.map(content => content.content.id));
                        
                        const tallerMatch = subsection.name.match(/T(\d+)A\d+/i);
                        if (tallerMatch) {
                            const tallerNum = tallerMatch[1];
                            const tallerKey = `Taller ${tallerNum}`;
                            
                            if (!preguntasCalificadasByTaller[tallerKey]) {
                                preguntasCalificadasByTaller[tallerKey] = {
                                    name: tallerKey,
                                    contents: []
                                };
                            }
                            preguntasCalificadasByTaller[tallerKey].contents.push(...newContents);
                        }
                    }
                }
            }
        }

        const matchingQuestions = findMatchingQuestions(preTestQuestions, postTestQuestions);
        if (matchingQuestions.length > 0) {
            newEvaluations.tests.push({
                "name": "Pre-Test - Post-Test",
                "contents": matchingQuestions
            });
        }

        newEvaluations.preguntasCalificadas = Object.values(preguntasCalificadasByTaller).sort((a, b) => {
            const numA = parseInt(a.name.match(/\d+/)[0]);
            const numB = parseInt(b.name.match(/\d+/)[0]);
            return numA - numB;
        });

        var courseKey;
        courseKey = course.implementations[selectedImplementation].sections[selectedSection].course_key;

        let response = await postGetCalificaciones(keycloak.token, allIds);
        let response2 = await postGetManualGrades(keycloak.token, courseKey, "course");
        if (response.data && response2.data) {
            for (let r of response.data) {
                for (let test of newEvaluations.tests) {
                    for (let content of test.contents) {
                        if (content.id === r.edx_xblock_id) {
                            let match;
                            let number;
                            if (test.name === "Preguntas Calificadas") {
                                match = content.question[0]?.subsection_name?.toLowerCase().match(/pregunta calificada (.*)/);
                                number = match ? match[1] : "";
                            } else {
                                number = content.question?.find(block => block.block_type === "eolquestion")?.index ? content.question.find(block => block.block_type === "eolquestion").index : "";
                            }
                            let score = 0;
                            let max_score = 0;
                            let is_correct = false;
                            if (content.content.true_block_type === "vof") {
                                score = r.response ? JSON.parse(r.response)["score"] : 0.0;
                                max_score = 1.0;
                                is_correct = undefined;
                            } else {
                                is_correct = getXBlockResponseIsCorrect(content.content, r.response, sectionData.enrolled_users.find(user => user.id === r.user_id)?.username);
                                score = r.response.score;
                                max_score = r.response.max_score;
                            }
                            let thisResponse = {
                                "id": r._id,
                                "created": r.created,
                                "edx_xblock_id": r.edx_xblock_id,
                                "user_id": r.user_id,
                                "response": formatXBlockResponse(content.content, r.response, sectionData.enrolled_users.find(user => user.id === r.user_id)?.username),
                                "is_correct": is_correct,
                                "score": score,
                                "max_score": max_score,
                                "number": number,
                                "is_vof": content.content.true_block_type === "vof"
                            }
                            content["responses"].push(thisResponse);
                        }
                    }
                }
                for (let control of newEvaluations.controles) {
                    for (let content of control.contents) {
                        if (content.id === r.edx_xblock_id) {
                            let thisResponse = {
                                "id": r._id,
                                "created": r.created,
                                "edx_xblock_id": r.edx_xblock_id,
                                "user_id": r.user_id,
                                "response": formatXBlockResponse(content.content, r.response, sectionData.enrolled_users.find(user => user.id === r.user_id)?.username),
                                "is_correct": getXBlockResponseIsCorrect(content.content, r.response, sectionData.enrolled_users.find(user => user.id === r.user_id)?.username),
                                "score": r.response?.score || 0,
                                "max_score": r.response?.max_score || 0,
                                "is_vof": content.content.true_block_type === "vof"
                            }
                            content["responses"].push(thisResponse);
                        }
                    }
                }
                for (let taller of newEvaluations.preguntasCalificadas) {
                    for (let content of taller.contents) {
                        if (content.id === r.edx_xblock_id) {
                            let match = content.question[0]?.subsection_name?.toLowerCase().match(/pregunta calificada (.*)/);
                            let number = match ? match[1] : "";
                            
                            let score = 0;
                            let max_score = 0;
                            let is_correct = false;
                            if (content.content.true_block_type === "vof") {
                                score = r.response ? JSON.parse(r.response)["score"] : 0.0;
                                max_score = 1.0;
                                is_correct = undefined;
                            } else {
                                is_correct = getXBlockResponseIsCorrect(content.content, r.response, sectionData.enrolled_users.find(user => user.id === r.user_id)?.username);
                                score = r.response.score;
                                max_score = r.response.max_score;
                            }
                            
                            let thisResponse = {
                                "id": r._id,
                                "created": r.created,
                                "edx_xblock_id": r.edx_xblock_id,
                                "user_id": r.user_id,
                                "response": formatXBlockResponse(content.content, r.response, sectionData.enrolled_users.find(user => user.id === r.user_id)?.username),
                                "is_correct": is_correct,
                                "score": score,
                                "max_score": max_score,
                                "number": number,
                                "is_vof": content.content.true_block_type === "vof"
                            }
                            content["responses"] = content["responses"] || [];
                            content["responses"].push(thisResponse);
                        }
                    }
                }
            }
            setEvaluations(newEvaluations);
            setManualGrades(response2.data);
        } else {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener calificaciones", text: response.error },
            });
        }
        setLoadingEvaluations(false);
    }

    const getAsistencia = async () => {
        setLoadingAsistencia(true);
        let courseKey = course.implementations[selectedImplementation].sections[selectedSection].course_key;
        let response = await postGetAsistencia(keycloak.token, courseKey, "course");
        if (response.data) {
            setAsistencia(response.data);
        } else {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener asistencia", text: response.error },
            });
        }
        setLoadingAsistencia(false);
    }


    const findMatchingQuestions = (preQuestions, postQuestions) => {
        const matchingPairs = [];

        preQuestions.forEach(preQ => {
            const matchingPost = postQuestions.find(postQ => {
                const cleanText = (text) => {
                    return stripHtml(text)
                        .toLowerCase()
                        .replace(/\s+/g, ' ')
                        .trim();
                };
                const preText = preQ.question?.find(block => block.block_type === "eolquestion")?.text ? preQ.question.find(block => block.block_type === "eolquestion").text : ""
                const postText = postQ.question?.find(block => block.block_type === "eolquestion")?.text ? postQ.question.find(block => block.block_type === "eolquestion").text : ""
                return cleanText(preText) === cleanText(postText);
            });

            if (matchingPost) {
                matchingPairs.push({
                    id: `${preQ.id}-${matchingPost.id}`,
                    question: preQ.question,
                    content: preQ.content,
                    responses: [],
                    matchingContent: {
                        preTest: preQ,
                        postTest: matchingPost
                    }
                });
            }
        });
        return matchingPairs;
    }

    const stripHtml = (html) => {
        const tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    const fetchAllResponses = async () => {
        setLoadingResponses(true)
        var courseKey;
        courseKey = course.implementations[selectedImplementation].sections[selectedSection].course_key
        const response = await getAllResponses(keycloak.token, courseKey);
        if (!response) {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener respuestas", text: response.error },
            });
        } else {
            setAllResponses(response.allResponses)
        }
        setLoadingResponses(false)
    }

    const getSurveys = async () => {
        setLoadingSurveys(true);
        let newSurveys = {
            "instrumentos": {},
            "encuestas": {}
        }
        let allIds = [];

        for (let section of sectionData.sections) {
            for (let subsection of section.subsections) {
                for (let unit of subsection.units) {
                    if (unit.name.toLowerCase().startsWith("encuesta")) {
                        let newContents = await parseSurveyContents(keycloak, unit.xblocks);
                        if (newContents.length > 0) {
                            allIds.push(...newContents.map(content => content.id));

                            if (newSurveys.encuestas[subsection.name]) {
                                newSurveys.encuestas[subsection.name].contents.push(...newContents);
                            } else {
                                newSurveys.encuestas[subsection.name] = {
                                    "name": subsection.name,
                                    "contents": newContents
                                };
                            }
                        }
                    } else if (unit.name.toLowerCase().includes("consentimiento") || unit.name.toLowerCase().includes("reglamento")) {
                        let newContents = await parseSurveyContents(keycloak, unit.xblocks);
                        if (newContents.length > 0) {
                            allIds.push(...newContents.map(content => content.id));

                            if (newSurveys.instrumentos[subsection.name]) {
                                newSurveys.instrumentos[subsection.name].contents.push(...newContents);
                            } else {
                                newSurveys.instrumentos[subsection.name] = {
                                    "name": subsection.name,
                                    "contents": newContents
                                };
                            }
                        }
                    }
                }
            }
        }

        newSurveys.instrumentos = Object.values(newSurveys.instrumentos);
        newSurveys.encuestas = Object.values(newSurveys.encuestas);

        let response = await postGetCalificaciones(keycloak.token, allIds);

        if (response.data) {
            for (let r of response.data) {
                for (let instrumento of newSurveys.instrumentos) {
                    for (let content of instrumento.contents) {
                        if (content.id === r.edx_xblock_id) {
                            content["responses"].push(r);
                        }
                    }
                }
                for (let encuesta of newSurveys.encuestas) {
                    for (let content of encuesta.contents) {
                        if (content.id === r.edx_xblock_id) {
                            content["responses"].push(r);
                        }
                    }
                }
            }
            setSurveys(newSurveys);
        } else {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener respuestas", text: response.error },
            });
        }
        setLoadingSurveys(false);
    }

    useEffect(() => {
        if (sectionData) {
            fetchAllResponses();
            getSurveys();
            getEvaluations();
            getAsistencia();
        }
    }, []);

    return (
        !loadingSectionData &&
        <>
            <Grid container spacing={2} mt={"5px"}>
                <Grid item xs={12} md={9}>
                    <Typography mb={{ xs: 0, md: "20px" }} fontSize={{ xs: "0.8em", sm: "0.9em", md: "1.0em", lg: "1.2em" }} variant="title">Sección {course.implementations[selectedImplementation]?.sections[selectedSection]?.location}{course.implementations[selectedImplementation]?.sections[selectedSection]?.section} de la implementación {course.implementations[selectedImplementation]?.year}-{course.implementations[selectedImplementation]?.term} ({course.implementations[selectedImplementation]?.type}) de {course.shortname}</Typography>
                </Grid>
                <Grid item xs={12} md={3} display={"flex"} justifyContent={{ xs: "center", md: "end" }} mb={"10px"}>
                    <Button
                        variant="red"
                        startIcon={<VisibilityIcon />}
                        color="primary"
                        sx={{ fontSize: "0.6em", marginRight: { xs: 0, md: "2px" } }}
                        disabled={loadingSectionData}
                        onClick={() => {
                            setSeguimientoModal(true);
                        }}
                    >
                        Gestionar datos
                    </Button>
                </Grid>
            </Grid>
            {sectionData ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {(!loadingResponses && !loadingSurveys && !loadingEvaluations && !loadingAsistencia && sectionData.last_update) ?
                            <>
                                <Typography fontSize={"0.8em"} variant="p">Última actualización: {formatTimestamp(sectionData.last_update)}</Typography>
                                <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} sx={{
                                    "&& .Mui-selected": {
                                        color: 'white',
                                        backgroundColor: '#d23831',
                                    },
                                    '& .MuiTabs-flexContainer': {
                                        flexWrap: 'wrap',
                                    },
                                    marginTop: "10px",
                                    marginBottom: "20px"
                                }}>
                                    <Tab
                                        label={
                                            <Box sx={{ "fontWeight": "bold", "textTransform": "none", "fontSize": { xs: "0.8em", md: "1.1em" }, "fontFamily": "Avenir Regular" }}>
                                                Resumen
                                            </Box>
                                        }
                                        sx={{ minWidth: "", padding: "10px", minHeight: "0" }}
                                        value={"resumen"} />
                                    <Tab
                                        label={
                                            <Box sx={{ "fontWeight": "bold", "textTransform": "none", "fontSize": { xs: "0.8em", md: "1.1em" }, "fontFamily": "Avenir Regular" }}>
                                                Avance
                                            </Box>
                                        }
                                        sx={{ minWidth: "", padding: "10px", minHeight: "0" }}
                                        value={"avance"} />
                                    <Tab
                                        label={
                                            <Box sx={{ "fontWeight": "bold", "textTransform": "none", "fontSize": { xs: "0.8em", md: "1.1em" }, "fontFamily": "Avenir Regular" }}>
                                                Asistencia
                                            </Box>
                                        }
                                        sx={{ minWidth: "", padding: "10px", minHeight: "0" }}
                                        value={"asistencia"} />
                                    <Tab
                                        label={
                                            <Box sx={{ "fontWeight": "bold", "textTransform": "none", "fontSize": { xs: "0.8em", md: "1.1em" }, "fontFamily": "Avenir Regular" }}>
                                                Encuestas e instrumentos
                                            </Box>
                                        }
                                        sx={{ minWidth: "", padding: "10px", minHeight: "0" }}
                                        value={"encuestas"}
                                    />
                                    {!course.implementations[selectedImplementation].grading?.autoinstruccional &&
                                        <Tab
                                            label={
                                                <Box sx={{ "fontWeight": "bold", "textTransform": "none", "fontSize": { xs: "0.8em", md: "1.1em" }, "fontFamily": "Avenir Regular" }}>
                                                    Evaluaciones
                                                </Box>
                                            }
                                            sx={{ minWidth: "", padding: "10px", minHeight: "0" }}
                                            value={"evaluaciones"}
                                        />
                                    }
                                    <Tab
                                        label={
                                            <Box sx={{ "fontWeight": "bold", "textTransform": "none", "fontSize": { xs: "0.8em", md: "1.1em" }, "fontFamily": "Avenir Regular" }}>
                                                Explorador
                                            </Box>
                                        }
                                        sx={{ minWidth: "", padding: "10px", minHeight: "0" }}
                                        value={"explorador"}
                                    />
                                </Tabs>
                                <CustomTabPanel value={tab} index={"resumen"}>
                                    <CourseSectionResumen
                                        keycloak={keycloak}
                                        course={course}
                                        isDiplomado={false}
                                        sectionData={sectionData}
                                        allResponses={allResponses}
                                        surveys={surveys}
                                        asistencia={asistencia}
                                        evaluations={evaluations}
                                        manualGrades={manualGrades}
                                        selectedModule={null}
                                        selectedImplementation={selectedImplementation}
                                        selectedSection={selectedSection}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    />
                                </CustomTabPanel>
                                <CustomTabPanel value={tab} index={"avance"}>
                                    <CourseSectionAvance
                                        keycloak={keycloak}
                                        course={course}
                                        isDiplomado={false}
                                        sectionData={sectionData}
                                        allResponses={allResponses}
                                        evaluations={evaluations}
                                        selectedModule={null}
                                        selectedImplementation={selectedImplementation}
                                        selectedSection={selectedSection}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    />
                                </CustomTabPanel>
                                <CustomTabPanel value={tab} index={"asistencia"}>
                                    <CourseSectionAsistencia
                                        keycloak={keycloak}
                                        course={course}
                                        isDiplomado={false}
                                        sectionData={sectionData}
                                        selectedModule={null}
                                        selectedImplementation={selectedImplementation}
                                        selectedSection={selectedSection}
                                        asistencia={asistencia}
                                        setAsistencia={setAsistencia}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    />
                                </CustomTabPanel>
                                <CustomTabPanel value={tab} index={"encuestas"}>
                                    <CourseSectionEncuestas
                                        keycloak={keycloak}
                                        course={course}
                                        isDiplomado={false}
                                        sectionData={sectionData}
                                        surveys={surveys}
                                        selectedModule={null}
                                        selectedImplementation={selectedImplementation}
                                        selectedSection={selectedSection}
                                    />
                                </CustomTabPanel>
                                {!course.implementations[selectedImplementation].grading?.autoinstruccional && <CustomTabPanel value={tab} index={"evaluaciones"}>
                                    <CourseSectionEvaluaciones
                                        keycloak={keycloak}
                                        course={course}
                                        isDiplomado={false}
                                        sectionData={sectionData}
                                        evaluations={evaluations}
                                        manualGrades={manualGrades}
                                        setManualGrades={setManualGrades}
                                        selectedModule={null}
                                        selectedImplementation={selectedImplementation}
                                        selectedSection={selectedSection}
                                        allResponses={allResponses}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    />
                                </CustomTabPanel>
                                }
                                <CustomTabPanel value={tab} index={"explorador"}>
                                    <CourseSectionExplorer
                                        keycloak={keycloak}
                                        course={course}
                                        isDiplomado={false}
                                        sectionData={sectionData}
                                        selectedModule={null}
                                        selectedImplementation={selectedImplementation}
                                        selectedSection={selectedSection}
                                        setAlertSuccess={setAlertSuccess}
                                        setAlertError={setAlertError}
                                        alerts={alerts}
                                        setAlerts={setAlerts}
                                    />
                                </CustomTabPanel>
                            </>
                            : (loadingResponses || loadingSurveys) ?
                                <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                                    <CircularProgress />
                                </Box>
                                : !sectionData.last_update ?
                                    <Box width={"100%"} mt={"50px"}>
                                        <Typography textAlign={"center"} variant="p-error">No se encontraron datos para esta sección.</Typography>
                                    </Box>
                                    : <></>
                        }
                    </Grid>
                </Grid>
                :
                <Box width={"100%"} mt={"50px"}>
                    <Typography textAlign={"center"} variant="p-error">Error desconocido.</Typography>
                </Box>
            }
            <CourseSeguimientoModal
                keycloak={keycloak}
                open={seguimientoModal}
                handleClose={() => setSeguimientoModal(false)}
                course={course}
                selectedImplementation={selectedImplementation}
                selectedSection={selectedSection}
                setAlertSuccess={setAlertSuccess}
                setAlertError={setAlertError}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </>
    );
}

export default CourseSection;