import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, CircularProgress, TextField, IconButton } from "@mui/material";
import ProfesoresTable from "../components/tables/ProfesoresTable";
import { getProfesores } from "../../requests/getProfesores";
import regions from "../../assets/json/regions.json";
import establecimientos from "../../assets/json/establecimientos.json";
import SearchIcon from "@mui/icons-material/Search";


const Profesores = ({
    keycloak,
    catalog,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {

    const [usersData, setUsersData] = useState(null);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [activeSearch, setActiveSearch] = useState("");


    const fetchUsers = async (token) => {
        setLoadingUsers(true);
        const response = await getProfesores(token);
        if (!response.profesores) {
            setLoadingUsers(false);
            setAlerts({ ...alerts, "error": { "title": "Error al obtener profesores", "text": response.error } });
            setAlertError(true);
            return;
        }
        setUsersData(response.profesores);
        setLoadingUsers(false);
    }

    useEffect(() => {
        fetchUsers(keycloak.token);
    }, []);

    const handleSearch = () => {
        setActiveSearch(document.getElementById("search-input").value.trim());
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <>
            <Typography fontSize={{ xs: "1.2em", md: "1.6em" }} mb={"10px"} variant="title">Docentes participantes</Typography>
            <Typography fontSize={{ xs: "0.8em", md: "1.0em" }} variant="title">Listados de docentes y establecimientos que han participado de los cursos del CMMEdu</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography fontSize={"0.8em"} variant="p">
                        El listado de profesores y establecimientos que han participado de los cursos del CMMEdu se actualiza de manera
                        automática a partir de los datos obtenidos desde los cursos. Sin embargo, los datos disponibles en las plataformas
                        son limitados, por lo que es necesario proporcionar la información detallada de manera manual.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {(usersData && !loadingUsers) ?
                        <>
                            <Box display="flex" alignItems="center" gap={1} mb={1}>
                                <TextField
                                    id="search-input"
                                    label="Buscar"
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: '300px' }}
                                    onKeyDown={handleKeyDown}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => handleSearch()}>
                                                <SearchIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Box>
                            <ProfesoresTable
                                keycloak={keycloak}
                                catalog={catalog}
                                users={usersData}
                                setUsers={setUsersData}
                                regions={regions}
                                establecimientos={establecimientos}
                                alerts={alerts}
                                setAlerts={setAlerts}
                                setAlertError={setAlertError}
                                setAlertSuccess={setAlertSuccess}
                                searchTerm={activeSearch}
                            />
                        </>
                        :
                        <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                            <CircularProgress sx={{ "color": "#d23831" }} />
                        </Box>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Profesores;