import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, CardContent, TextField, MenuItem, Divider, Box, CircularProgress } from "@mui/material";
import { CMMEDU_COLORS } from "../../utils/formatters";
import { getAllSituations } from "../../requests/getAllSituations";

const Resumen = ({
    keycloak,
    catalog,
    alerts,
    setAlerts,
    setAlertSuccess,
    setAlertError
}) => {

    const [availableImplementations, setAvailableImplementations] = useState(null);
    const [selectedSemester, setSelectedSemester] = useState("all");

    const [situaciones, setSituaciones] = useState(null);
    const [loadingSituaciones, setLoadingSituaciones] = useState(false);

    const getImplementations = () => {
        let implementations = new Set();
        for (const course of catalog.courses) {
            for (const implementation of course.implementations) {
                let year = implementation.year;
                let semester = implementation.term;
                implementations.add(`${year} (${semester === "0" ? "Anual" : semester === "1" ? "Otoño" : semester === "2" ? "Primavera" : "Verano"})`);
            }
        }
        implementations = Array.from(implementations);
        implementations.sort();
        return implementations;
    }

    const fetchSituaciones = async (token) => {
        setLoadingSituaciones(true);
        const response = await getAllSituations(token);
        if (!response.allSituations) {
            setLoadingSituaciones(false);
            setAlerts({ ...alerts, "error": { "title": "Error al obtener situaciones", "text": response.error } });
            setAlertError(true);
            return;
        }
        setSituaciones(response.allSituations);
        setLoadingSituaciones(false);
    }

    useEffect(() => {
        fetchSituaciones(keycloak.token);
    }, []);

    useEffect(() => {
        setAvailableImplementations(getImplementations());
    }, [catalog]);

    return (
        <>
            {availableImplementations && (
                <Grid container spacing={2}>
                    <Grid item xs={12} display={"flex"} flexDirection={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-between" }}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mb={"20px"}>
                            <Typography variant={"title"} fontSize={"1.6em"} mb={0}>Proyecto Datos</Typography>
                            <Typography width={"100%"} variant={"p-small"} fontWeight={"bold"}>Plataforma de datos y seguimiento de cursos</Typography>
                        </Box>
                        <TextField
                            select
                            label="Semestre"
                            variant="outlined"
                            fullWidth
                            sx={{ maxWidth: "250px" }}
                            value={selectedSemester}
                            onChange={(e) => setSelectedSemester(e.target.value)}
                        >
                            <MenuItem disabled value="none">Seleccione una opción...</MenuItem>
                            <MenuItem value="all">Histórico</MenuItem>
                            {availableImplementations.map((implementation) => (
                                <MenuItem key={implementation} value={implementation}>{implementation}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {(selectedSemester !== "none" && situaciones && !loadingSituaciones) ? (
                        <>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant={"title"} fontSize={"1.2em"}>Cursos activos</Typography>
                                        <Divider sx={{ my: 1 }} />
                                        {(() => {
                                            const filteredCourses = catalog.courses.filter((course) => {
                                                if (selectedSemester === "all") return true;
                                                const [year, termStr] = selectedSemester.split(" ");
                                                const term = termStr === "(Anual)" ? "0" :
                                                    termStr === "(Otoño)" ? "1" :
                                                        termStr === "(Primavera)" ? "2" : "3";
                                                return course.implementations.some(impl =>
                                                    impl.year.toString() === year && impl.term === term
                                                );
                                            });

                                            const getFilteredImplementations = (implementations) => {
                                                if (selectedSemester === "all") return implementations;
                                                const [year, termStr] = selectedSemester.split(" ");
                                                const term = termStr === "(Anual)" ? "0" :
                                                    termStr === "(Otoño)" ? "1" :
                                                        termStr === "(Primavera)" ? "2" : "3";
                                                return implementations.filter(impl => 
                                                    impl.year.toString() === year && impl.term === term
                                                );
                                            };

                                            return (
                                                <>
                                                    <Typography variant={"p-small"} fontWeight={"bold"}>
                                                        Total inscripciones: {filteredCourses.map(course => 
                                                            getFilteredImplementations(course.implementations)
                                                                .map(impl => impl.total_enrollments)
                                                                .reduce((a, b) => a + b, 0)
                                                        ).reduce((a, b) => a + b, 0)}
                                                    </Typography>
                                                    <Typography variant={"p-small"} fontWeight={"bold"}>
                                                        Total secciones: {filteredCourses.map(course => 
                                                            getFilteredImplementations(course.implementations)
                                                                .map(impl => impl.sections.length)
                                                                .reduce((a, b) => a + b, 0)
                                                        ).reduce((a, b) => a + b, 0)}
                                                    </Typography>
                                                    <Typography variant={"p-small"} fontWeight={"bold"}>
                                                        Docentes activos: {(() => {
                                                            // Get unique user IDs across all filtered courses from situaciones
                                                            const uniqueUserIds = new Set();
                                                            if (situaciones) {
                                                                filteredCourses.forEach(course => {
                                                                    getFilteredImplementations(course.implementations).forEach(impl => {
                                                                        impl.sections.forEach(section => {
                                                                            const sectionSituaciones = situaciones.courses.find(c => 
                                                                                c.shortname === course.shortname)?.implementations.find(i => 
                                                                                    i.year.toString() === impl.year.toString() && i.term === impl.term)?.sections.find(s => 
                                                                                        s.section === section.section)?.enrollments || [];
                                                                            
                                                                            sectionSituaciones.forEach(enrollment => {
                                                                                if ((enrollment.current_situation === "Activo" || enrollment.current_situation === "Atrasado") && (!enrollment.final_situation || enrollment.final_situation === "none")) {
                                                                                    uniqueUserIds.add(enrollment.user_id);
                                                                                }
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            }
                                                            return uniqueUserIds.size;
                                                        })()}
                                                    </Typography>
                                                    <Typography variant={"p-small"} fontWeight={"bold"}>
                                                        Docentes aprobados: {(() => {
                                                            const uniqueUserIds = new Set();
                                                            if (situaciones) {
                                                                filteredCourses.forEach(course => {
                                                                    getFilteredImplementations(course.implementations).forEach(impl => {
                                                                        impl.sections.forEach(section => {
                                                                            const sectionSituaciones = situaciones.courses.find(c => 
                                                                                c.shortname === course.shortname)?.implementations.find(i => 
                                                                                    i.year.toString() === impl.year.toString() && i.term === impl.term)?.sections.find(s => 
                                                                                        s.section === section.section)?.enrollments || [];
                                                                            
                                                                            sectionSituaciones.forEach(enrollment => {
                                                                                if (enrollment.final_situation === "Aprobado") {
                                                                                    uniqueUserIds.add(enrollment.user_id);
                                                                                }
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            }
                                                            return uniqueUserIds.size;
                                                        })()}
                                                    </Typography>
                                                    <Divider sx={{ my: 1 }} />
                                                    <Grid container spacing={2}>
                                                        {filteredCourses.map((course, index) => (
                                                            <Grid key={course.shortname} item xs={12} sm={6} md={4} xl={3}>
                                                                <Card sx={{ backgroundColor: CMMEDU_COLORS[index % CMMEDU_COLORS.length] }}>
                                                                    <CardContent>
                                                                        <Typography mb={0} color={"white"} fontWeight={"bold"} variant={"title"}>{course.shortname}</Typography>
                                                                        <Divider sx={{ my: 1 }} />
                                                                        {(() => {
                                                                            let implementations = [];
                                                                            if (selectedSemester !== "all") {
                                                                                const year = selectedSemester.split(" ")[0];
                                                                                const semester = selectedSemester.split(" ")[1] === "(Anual)" ? "0" :
                                                                                    selectedSemester.split(" ")[1] === "(Otoño)" ? "1" :
                                                                                        selectedSemester.split(" ")[1] === "(Primavera)" ? "2" : "3";
                                                                                implementations = course.implementations.filter(impl => 
                                                                                    impl.year.toString() === year && impl.term === semester
                                                                                );
                                                                            } else {
                                                                                implementations = course.implementations;
                                                                            }

                                                                            const elearning = implementations.find(impl => impl.type === "e-learning");
                                                                            const blearning = implementations.find(impl => impl.type === "b-learning");

                                                                            return (
                                                                                <>
                                                                                    <Box textAlign="center" mb={2}>
                                                                                        <Typography mb={0} color={"white"} variant={"p-small"}>
                                                                                            Secciones: {implementations.map(impl => impl.sections.length).reduce((a, b) => a + b, 0)}
                                                                                        </Typography>
                                                                                        <Typography mb={0} color={"white"} variant={"p-small"}>
                                                                                            Inscritos e-learning: {elearning ? elearning.total_enrollments : 0}
                                                                                        </Typography>
                                                                                        <Typography mb={0} color={"white"} variant={"p-small"}>
                                                                                            Inscritos b-learning: {blearning ? blearning.total_enrollments : 0}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Divider sx={{ my: 1 }} />
                                                                                    <Typography mb={0} color={"white"} fontWeight={"bold"} textAlign="center" variant={"p-small"}>
                                                                                        Gráfico de situaciones
                                                                                    </Typography>
                                                                                </>
                                                                            )
                                                                        })()}
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </>
                                            )
                                        })()}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography variant={"title"} fontSize={"1.2em"}>Satisfacción</Typography>
                                        <Divider sx={{ my: 1 }} />
                                        <Typography variant={"p-small"}>En desarrollo</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    ) : loadingSituaciones ? (
                        <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                            <CircularProgress sx={{ "color": "#d23831" }} />
                        </Box>
                    ) : (
                        <Box width={"100%"} mt={"50px"}>
                            <Typography textAlign={"center"} variant={"p-error"}>Error al obtener situación de docentes.</Typography>
                        </Box>
                    )}
                </Grid>
            )}
        </>
    )
}

export default Resumen;