import React, { useState, useEffect } from 'react';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { putDiplomadoModule } from '../../../../../../requests/catalog/putDiplomadoModule';


const CatalogDiplomadoModuleEditModal = ({
    keycloak,
    open,
    handleClose,
    catalog,
    diplomadoIndex,
    moduleIndex,
    otherModules,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [diplomadoModuleModule, setDiplomadoModuleModule] = useState("");
    const [diplomadoModuleName, setDiplomadoModuleName] = useState("");
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let errors = {};
        if (diplomadoModuleModule === "") {
            errors.diplomadoModuleModule = "Por favor ingrese una sigla.";
        } else if (diplomadoModuleModule.length !== 3) {
            errors.diplomadoModuleModule = "La sigla debe tener 3 caracteres.";
        } else if (!/^M\d{2}$/.test(diplomadoModuleModule)) {
            errors.diplomadoModuleModule = "La sigla debe seguir el formato MXX, donde XX es un número.";
        } else if (otherModules.some(m => m.module === diplomadoModuleModule)) {
            errors.diplomadoModuleModule = "Ya existe un módulo con esta sigla.";
        }
        if (diplomadoModuleName === "") {
            errors.diplomadoModuleName = "Por favor ingrese un nombre.";
        } else if (diplomadoModuleName.length < 5 || diplomadoModuleName.length > 100) {
            errors.diplomadoModuleName = "El nombre debe tener entre 5 y 100 caracteres.";
        } else if (otherModules.some(m => m.name === diplomadoModuleName)) {
            errors.diplomadoModuleName = "Ya existe un módulo con este nombre.";
        }
        return errors;
    }

    const handleEditDiplomadoModule = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let editData = {
            "id": catalog.diplomados[diplomadoIndex].modules[moduleIndex]._id,
            "diplomado_id": catalog.diplomados[diplomadoIndex]._id,
            "module": diplomadoModuleModule,
            "name": diplomadoModuleName
        }
        let response = await putDiplomadoModule(keycloak.token, editData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al editar módulo", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setDiplomadoModuleModule("");
            setDiplomadoModuleName("");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Módulo editado!`, "text": `El módulo seleccionado del diplomado ${catalog.diplomados[diplomadoIndex].shortname} ha sido editado.` } });
        }
        setFormLoading(false);
    }

    useEffect(() => {
        if (catalog.diplomados[diplomadoIndex].modules[moduleIndex] === undefined) {
            handleClose();
            return;
        }
        setDiplomadoModuleModule(catalog.diplomados[diplomadoIndex].modules[moduleIndex].module);
        setDiplomadoModuleName(catalog.diplomados[diplomadoIndex].modules[moduleIndex].name);
    }, [catalog]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Editar módulo de diplomado
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label="Módulo"
                            variant="outlined"
                            value={diplomadoModuleModule}
                            onChange={(e) => {
                                setDiplomadoModuleModule(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, diplomadoModuleModule: "" }))
                            }}
                            error={!!errors.diplomadoModuleModule}
                            helperText={errors.diplomadoModuleModule}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            variant="outlined"
                            value={diplomadoModuleName}
                            onChange={(e) => {
                                setDiplomadoModuleName(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, diplomadoModuleName: "" }))
                            }}
                            error={!!errors.diplomadoModuleName}
                            helperText={errors.diplomadoModuleName}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<AddCircleOutlineIcon />} variant='blue' onClick={handleEditDiplomadoModule} disabled={formLoading}>
                            Editar módulo
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogDiplomadoModuleEditModal;