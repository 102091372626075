import axios from 'axios';

export const postSetManualMultiGradeUsers = async (token, evaluation_name, evaluation_index, grades, course_key, course_type) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/set_manual_multi_grades_users",
            {
                evaluation_name,
                evaluation_index,
                grades,
                course_key,
                course_type
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json"
                }
            }
        );
        return { data: response.data, error: "" };
    } catch (error) {
        return { 
            data: null, 
            error: error.response?.data?.detail || "Error desconocido: " + error.message 
        };
    }
}