import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { formatTimestamp } from '../../../utils/formatters';
import { MathJax } from 'better-react-mathjax';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const EncuestaAnswersTable = ({
    enrolledUsers,
    responses,
    currentSubsection,
    identifier,
    alerts,
    setAlerts,
    setAlertSuccess,
    setAlertError
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const translateResponse = (rawValue) => {
        if (!currentSubsection || !rawValue) return "—";
        
        if (rawValue === "unanswered") {
            return "—";
        }

        switch (currentSubsection.type) {
            
            case 'likert':
            case 'multiplechoice':
            case 'checkboxes':
                try {
                    const values = rawValue.split('_');
                    const selectedAlternatives = currentSubsection.alternatives
                        .filter((_, index) => values[index] === 'true')
                        .map(alt => alt.text);
                    return selectedAlternatives.join(', ');
                } catch (error) {
                    return rawValue;
                }
            
            default:
                return rawValue;
        }
    };

    const handleDownloadEncuestaQuestionResponsesCSV = () => {
        let header = [
            "Usuario",
            "Fecha de respuesta",
            "Respuesta",
        ];
        let lines = [];
        let output = [];
        
        for (let response of responses) {
            var thisLine = [];
            thisLine.push(enrolledUsers.find(user => user.user_id === response.user_id)?.username || "—");
            thisLine.push(response.created ? new Date(response.created).toLocaleString() : "—");
            
            // Use translated response for CSV
            const translatedResponse = translateResponse(response.value);
            const decodedText = document.createElement('textarea');
            decodedText.innerHTML = translatedResponse;
            thisLine.push(decodedText.value);

            lines.push(thisLine);
        }
        
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        
        const fileName = "respuestas_" + currentSubsection.code.replace(/ /g, "_").replace(".", "_") + "_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'created',
            disableColumnMenu: true,
            headerName: 'Fecha de respuesta',
            width: 240,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'response',
            disableColumnMenu: true,
            headerName: 'Respuesta',
            width: 350,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    <MathJax>
                        <div
                            style={{ maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{
                                __html: params.value.toString().replace(
                                    /<img/g,
                                    '<img style="max-width:100%;height:auto"'
                                )
                            }}
                        />
                    </MathJax>
                </Box>
            ),
        }
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(responses)) {
            rows.push({
                id: key,
                username: enrolledUsers.find(user => user.user_id === value.userId)?.username || "—",
                created: value.timestamp ? formatTimestamp(value.timestamp) : "—",
                response: value.value ? translateResponse(value.value) : "—",
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Lista de respuestas del XBlock</Typography>
                <Box>
                    <IconButton onClick={handleDownloadEncuestaQuestionResponsesCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        responses.length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p-small' textAlign={"center"}>No hay datos de respuestas disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1120px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    getRowHeight={() => 'auto'}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default EncuestaAnswersTable;