import React, { useState } from "react";
import { Grid, Card, CardContent, Typography, Divider, Box, Button } from "@mui/material";
import NotasManualesUniqueModal from "../../../modals/NotasManualesUniqueModal";
import NotasManualesTableUnique from "../../../tables/NotasManualesTableUnique";
import { calculateManualGradeStats } from "../../../../../utils/formatters";

const ManualGradesUnique = ({
    keycloak,
    course,
    selectedModule,
    selectedImplementation,
    selectedSection,
    sectionData,
    isDiplomado,
    title,
    gradingObject,
    manualGrades,
    setManualGrades,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {

    const [uniqueModalOpen, setUniqueModalOpen] = useState(false);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant='title' fontSize={"1.2em"}>{title}</Typography>
                            <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='p-small'>Tipo</Typography>
                                <Typography variant='p-small'>{gradingObject.type === "otros_unico" ? "Nota única" : "Nota múltiple"}</Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='p-small'>Ponderación</Typography>
                                <Typography variant='p-small'>{gradingObject.value}%</Typography>
                            </Box>
                            <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                            {(() => {
                                const stats = calculateManualGradeStats(manualGrades[title], gradingObject.type);
                                return (
                                    <>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Nota promedio</Typography>
                                            <Typography variant='p-small'>{stats.averageGrade}</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Nota mediana</Typography>
                                            <Typography variant='p-small'>{stats.medianGrade}</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Desviación estándar</Typography>
                                            <Typography variant='p-small'>{stats.stdDeviation}</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Nota máxima</Typography>
                                            <Typography variant='p-small'>{stats.maxGrade}</Typography>
                                        </Box>
                                        <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Docentes evaluados</Typography>
                                            <Typography variant='p-small'>{stats.submittedCount}</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Aprobados</Typography>
                                            <Typography variant='p-small'>{stats.passedCount}</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='p-small'>Reprobados</Typography>
                                            <Typography variant='p-small'>{stats.failedCount}</Typography>
                                        </Box>
                                    </>
                                );
                            })()}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ "height": "100%" }}>
                        <CardContent>
                            <Typography variant='title' fontSize={"1.2em"}>Distribución de notas</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {(manualGrades[title] === undefined || manualGrades[title]?.["0"]?.length === 0) ?
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Button onClick={() => setUniqueModalOpen(true)} variant="red">Agregar notas</Button>
                        </Box>
                        :
                        <NotasManualesTableUnique
                            enrolledUsers={sectionData.enrolled_users.filter(user => user.show)}
                            grades={manualGrades[title]}
                            handleEdit={() => setUniqueModalOpen(true)}
                            identifier={
                                isDiplomado ?
                                    course.shortname + "_" +
                                    course.modules[selectedModule].module + "_" +
                                    course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                    course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                    course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                    :
                                    course.shortname + "_" +
                                    course.implementations[selectedImplementation].year + "_" +
                                    course.implementations[selectedImplementation].term + "_" +
                                    course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                    course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                    course.implementations[selectedImplementation].sections[selectedSection].section
                            }
                            title={title}
                            index={0}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setAlertError={setAlertError}
                            setAlertSuccess={setAlertSuccess}
                        />
                    }
                </Grid>
            </Grid>
            <NotasManualesUniqueModal
                keycloak={keycloak}
                courseKey={
                    isDiplomado ?
                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                        :
                        course.implementations[selectedImplementation].sections[selectedSection].course_key
                }
                courseType={isDiplomado ? "diplomado" : "course"}
                open={uniqueModalOpen}
                handleClose={() => setUniqueModalOpen(false)}
                enrolledUsers={sectionData.enrolled_users.filter(user => user.show)}
                title={title}
                selectedNotasManuales={manualGrades[title] === undefined ? [] : manualGrades[title]["0"] ? manualGrades[title]["0"] : []}
                setNotasManuales={setManualGrades}
                setAlertError={setAlertError}
                setAlertSuccess={setAlertSuccess}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </>
    );
}

export default ManualGradesUnique;