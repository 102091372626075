import axios from 'axios';

export const postGetCalificaciones = async (token, ids) => {

    try {

        const response = await axios.post(process.env.REACT_APP_API_URL + "/get_calificaciones", {ids: ids}, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        return { data: response.data, error: "" };

    } catch (error) {
        return { data: null, error: "Error desconocido: " + error.message };
    }
}