import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Grid, Card, CardContent, Divider } from '@mui/material';
import ResumenTable from '../../tables/ResumenTable';
import { answersEncuestaSlider } from '../../../../utils/charts';
import { getSituaciones } from '../../../../requests/getSituaciones';

const CourseSectionResumen = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    surveys,
    evaluations,
    manualGrades,
    asistencia,
    selectedModule,
    selectedImplementation,
    selectedSection,
    allResponses,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess,
}) => {

    const [situaciones, setSituaciones] = useState(null);
    const [loadingSituaciones, setLoadingSituaciones] = useState(true);

    const findSatisfactionResponses = (code) => {
        if (!surveys?.encuestas) return [];

        for (let encuesta of surveys.encuestas) {
            for (let content of encuesta.contents) {
                for (let section of content.sections) {
                    for (let subsection of section.subsections) {
                        if (subsection.code === code) {
                            return content.responses.map(response => {
                                let jsonResponse = JSON.parse(response.response);
                                if (jsonResponse && jsonResponse.student_answers) {
                                    let responseText = Object.values(jsonResponse.student_answers)[0];
                                    if (responseText) {
                                        let answerText = JSON.parse(responseText);
                                        if (answerText) {
                                            let answer = answerText["answer"].replace("\"", "").replace("'", "").replace("EC_AÑOS.EXP", "EC_ANIOS_EXP");
                                            let answers = answer.split("_*_");
                                            let matchingAnswer = answers.find(a => a.startsWith(code));
                                            if (matchingAnswer) {
                                                return {
                                                    value: matchingAnswer.split(";_")[1],
                                                    userId: response.user_id,
                                                    timestamp: response.created
                                                };
                                            }
                                        }
                                    }
                                }
                                return null;
                            }).filter(Boolean);
                        }
                    }
                }
            }
        }
        return [];
    };

    const fetchSituaciones = async () => {
        setLoadingSituaciones(true);
        var courseKey;
        if (isDiplomado) {
            courseKey = course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key;
        } else {
            courseKey = course.implementations[selectedImplementation].sections[selectedSection].course_key;
        }
        const response = await getSituaciones(keycloak.token, isDiplomado ? "diplomado" : "course", courseKey);
        if (!response.situaciones) {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener situación de los docentes", text: response.error },
            });
        } else {
            setSituaciones(response.situaciones);
        }
        setLoadingSituaciones(false);
    }

    useEffect(() => {
        if (sectionData) {
            fetchSituaciones();
        }
    }, [sectionData]);

    useEffect(() => {
        if (surveys && situaciones) {
            const container = document.getElementById('encuestas-chart-container-ES_SAT.CURSO');
            if (container) {
                container.innerHTML = '';
                const responses = findSatisfactionResponses('ES_SAT.CURSO');

                if (responses.length > 0) {
                    const values = responses.map(r => r.value);
                    answersEncuestaSlider(values, 'ES_SAT.CURSO', true);
                } else {
                    container.innerHTML = '<p style="color: #646464; font-family: Avenir Regular; text-align: center;">No hay datos de satisfacción disponibles.</p>';
                }
            }
        }
    }, [surveys, situaciones]);

    const getGradingConfig = () => {
        return isDiplomado 
            ? course.modules[selectedModule].implementations[selectedImplementation].grading
            : course.implementations[selectedImplementation].grading;
    }

    const renderGradingCriteria = () => {
        const grading = getGradingConfig();
        
        if (grading == null) {
            return <Typography variant={"p"}>No hay criterios de evaluación</Typography>;
        }
        
        if (grading.autoinstruccional) {
            return <Typography variant={"p"}>Este curso es autoinstruccional y no contempla evaluaciones.</Typography>;
        }

        const criteria = [];
        if (grading.controles_rate > 0) {
            criteria.push({ name: "Controles", value: grading.controles_rate });
        }
        if (grading.avance_rate > 0) {
            criteria.push({ name: "Avance en plataforma", value: grading.avance_rate });
        }
        if (grading.preguntas_calificadas_rate > 0) {
            criteria.push({ name: "Preguntas calificadas", value: grading.preguntas_calificadas_rate });
        }
        if (grading.other_rates?.length > 0) {
            grading.other_rates.forEach(rate => {
                criteria.push({ name: rate.name, value: rate.value });
            });
        }

        return criteria.map((criterion, index) => (
            <Box key={index}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography fontWeight={"bold"} variant="p">{criterion.name}:</Typography>
                    {criterion.value && (
                        <Typography variant="p">
                            {criterion.value}%
                        </Typography>
                    )}
                </Box>
                {criterion.name === "Controles" &&
                    <Typography ml={"10px"} variant="p-small">
                        Exigencia: {grading.scale}%
                    </Typography>
                }
            </Box>
        ));
    }

    return (
        sectionData ?
            (!loadingSituaciones && situaciones) ?
                <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography fontSize={"1.2em"} variant={"title"}>Información del curso</Typography>
                                    <Divider sx={{ mb: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Inscritos</Typography>
                                        <Typography variant={"p"}>
                                            {!isDiplomado ?
                                                course.implementations[selectedImplementation].sections[selectedSection].enrollments
                                                :
                                                course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].enrollments
                                            }
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Situación actual</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Activos</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Activo").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Atrasados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Atrasado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Retirados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Retirado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Incomunicados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Incomunicado").length}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Situación final CMMEdu</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Aprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Aprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Reprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Reprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Eliminados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Eliminado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Abandono</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Abandono").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Retirados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Retirado").length}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Situación final CPEIP</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Aprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Aprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Reprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Reprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Retirados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Retirado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Desertores</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Desertores").length}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Card>
                                    <CardContent>
                                        <Typography fontSize={"1.2em"} variant={"title"}>Notas parciales</Typography>
                                        <Divider sx={{ my: 2 }} />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>

                        {(() => {
                            const grading = getGradingConfig();
                            if (grading && !grading.autoinstruccional) {
                                return (
                                    <Grid item xs={12} lg={6}>
                                        <Card sx={{ height: '100%' }}>
                                            <CardContent>
                                                <Typography fontSize={"1.2em"} variant={"title"}>Pre-test y post-test</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            }
                            return null;
                        })()}

                        <Grid item xs={12}>
                            <ResumenTable
                                keycloak={keycloak}
                                isDiplomado={isDiplomado}
                                courseKey={
                                    isDiplomado ?
                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                                        :
                                        course.implementations[selectedImplementation].sections[selectedSection].course_key
                                }
                                sectionData={sectionData}
                                allResponses={allResponses}
                                situaciones={situaciones}
                                setSituaciones={setSituaciones}
                                evaluations={evaluations}
                                manualGrades={manualGrades}
                                asistencia={asistencia}
                                alerts={alerts}
                                setAlerts={setAlerts}
                                setAlertError={setAlertError}
                                setAlertSuccess={setAlertSuccess}
                                grading={getGradingConfig()}
                                identifier={
                                    isDiplomado ?
                                        course.shortname + "_" +
                                        course.modules[selectedModule].module + "_" +
                                        course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                        course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                        :
                                        course.shortname + "_" +
                                        course.implementations[selectedImplementation].year + "_" +
                                        course.implementations[selectedImplementation].term + "_" +
                                        course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                        course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                        course.implementations[selectedImplementation].sections[selectedSection].section
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
                : loadingSituaciones ?
                    <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                        <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
                    </Box>
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>

    );

}

export default CourseSectionResumen;