import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import Resumen from './Resumen';
import TraficoWeb from './TraficoWeb';
import Catalogo from './Catalogo';
import Profesores from './Profesores';
import Static from './Static';
import Historial from './Historial';
import Course from './Course';
import Diplomado from './Diplomado';
import { useMediaQuery, useTheme, Box, Snackbar, AppBar, CssBaseline, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { getCatalog } from '../../requests/catalog/getCatalog';
import Sidebar from '../components/Sidebar';
import { mapTopics } from '../../utils/mappers';



const Home = ({ keycloak }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [loadingCatalog, setLoadingCatalog] = useState(true);
  const [catalog, setCatalog] = useState(null);
  const [availableDashboards, setAvailableDashboards] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState({ key: null, type: null });

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alerts, setAlerts] = useState({ "success": { "title": "", "text": "" }, "warning": { "title": "", "text": "" }, "error": { "title": "", "text": "" } });

  const FIXED_PERMISSIONS = {
    "dashboards": ["*"],
    "courses": ["*"],
    "actions": ["*"]
  };

  const permissions = FIXED_PERMISSIONS;

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSectionClick = (section) => {
    setSelectedDashboard(section);
    if (isMobile) setMenuOpen(false);
  };

  const fetchData = async () => {
    try {
      setLoadingCatalog(true);
      const response_catalog = await getCatalog(keycloak.token);
      setCatalog(response_catalog.courses);
      setLoadingCatalog(false);
    } catch (error) {
      setLoadingCatalog(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseAlert = (event, reason, type) => {
    if (reason === 'clickaway') {
      return;
    }
    if (type === "success") {
      setAlertSuccess(false);
      setAlerts({ ...alerts, "success": { "title": "", "text": "" } });
    } else if (type === "error") {
      setAlertError(false);
      setAlerts({ ...alerts, "error": { "title": "", "text": "" } });
    }
  };

  const createHandleCloseAlert = (type) => {
    return (event, reason) => {
      handleCloseAlert(event, reason, type);
    };
  };

  const filterDashboards = (init) => {
    let dashboards = [];
    dashboards.push({ key: "inicio", type: "fixed", header: null });
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("catalogo")) {
      dashboards.push({ key: "catalogo", type: "fixed", header: null });
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("profesores")) {
      dashboards.push({ key: "profesores", type: "fixed", header: null });
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("static")) {
      dashboards.push({ key: "static", type: "fixed", header: null });
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("trafico")) {
      dashboards.push({ key: "trafico", type: "fixed", header: null });
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("historial")) {
      dashboards.push({ key: "history", type: "fixed", header: null });
    }
    const addCourseDashboards = (topic) => {
      const topicCourses = Object.entries(catalog.courses).filter(
        ([key, course]) => course.topic === topic &&
          (permissions.dashboards.includes("*") || permissions.dashboards.includes(key))
      );

      topicCourses.forEach(([key, course]) => {
        dashboards.push({ header: topic !== "otros" ? "SyS - " + mapTopics(topic) : mapTopics(topic), key: course.name, type: "course" });
      });
    };
    const addDiplomadoDashboards = () => {
      const topicCourses = Object.entries(catalog.diplomados).filter(
        ([key, course]) =>
          (permissions.dashboards.includes("*") || permissions.dashboards.includes(key))
      );

      topicCourses.forEach(([key, course]) => {
        dashboards.push({ header: "SyS - Diplomados", key: course.name, type: "diplomado" });
      });
    };
    if (catalog) {
      addCourseDashboards("numeros");
      addCourseDashboards("algebra");
      addCourseDashboards("geometria");
      addCourseDashboards("datos");
      addDiplomadoDashboards();
      addCourseDashboards("otros");
    }
    setAvailableDashboards(dashboards);
    if (init) {
      setSelectedDashboard({ key: "inicio", type: "fixed" });
    }
  };

  useEffect(() => {
    if (!loadingCatalog && catalog) {
      if (!availableDashboards) {
        filterDashboards(true);
      } else {
        filterDashboards(false);
      }
    }
  }, [catalog, loadingCatalog]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setMenuOpen(false);
    }
  }, [isMobile]);

  return (
    availableDashboards &&
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Header
          isMobile={isMobile}
          onMenuToggle={handleMenuToggle}
          isMenuOpen={menuOpen}
          availableDashboards={availableDashboards}
          handleSectionClick={handleSectionClick}
        />
      </AppBar>
      {(catalog && !loadingCatalog) ? (
        <>
          {!isMobile &&
            <Sidebar
              catalog={catalog}
              loadingCatalog={loadingCatalog}
              permissions={FIXED_PERMISSIONS}
              availableDashboards={availableDashboards}
              setAvailableDashboards={setAvailableDashboards}
              selectedDashboard={selectedDashboard}
              setSelectedDashboard={setSelectedDashboard}
            >
            </Sidebar>
          }
          <Box component="main" sx={{ width: "100%", flexGrow: 1, py: 3, px: isMobile ? 2 : 3 }} mt={isMobile ? "80px" : "100px"}>
            {selectedDashboard.key === "inicio" ?
              <Resumen courses={catalog} catalog={catalog}></Resumen>
              : selectedDashboard.key === "trafico" ?
                <TraficoWeb keycloak={keycloak}></TraficoWeb>
                : selectedDashboard.key === "catalogo" ?
                  <Catalogo
                    keycloak={keycloak}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    loadingCatalog={loadingCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                  ></Catalogo>
                  : selectedDashboard.key === "profesores" ?
                    <Profesores
                      keycloak={keycloak}
                      catalog={catalog}
                      setAlertSuccess={setAlertSuccess}
                      setAlertError={setAlertError}
                      alerts={alerts}
                      setAlerts={setAlerts}
                    ></Profesores>
                    : selectedDashboard.key === "static" ?
                      <Static
                        keycloak={keycloak}
                        setAlertSuccess={setAlertSuccess}
                        setAlertError={setAlertError}
                        alerts={alerts}
                        setAlerts={setAlerts}
                      ></Static>
                      : selectedDashboard.key === "history" ?
                        <Historial
                          keycloak={keycloak}
                          setAlertSuccess={setAlertSuccess}
                          setAlertError={setAlertError}
                          alerts={alerts}
                          setAlerts={setAlerts}
                        ></Historial>
                        : selectedDashboard.type === "course" ?
                          <Course
                            key={selectedDashboard.key}
                            keycloak={keycloak}
                            course={catalog.courses.find(course => course.name === selectedDashboard.key)}
                            setAlertSuccess={setAlertSuccess}
                            setAlertError={setAlertError}
                            alerts={alerts}
                            setAlerts={setAlerts}
                          ></Course>
                          : selectedDashboard.type === "diplomado" ?
                            <Diplomado
                              keycloak={keycloak}
                              diplomado={catalog.diplomados.find(diplomado => diplomado.name === selectedDashboard.key)}
                              setAlertSuccess={setAlertSuccess}
                              setAlertError={setAlertError}
                              alerts={alerts}
                              setAlerts={setAlerts}
                            ></Diplomado>
                            : <></>
            }
          </Box>
        </>
      ) : (
        <Box width={"100%"} textAlign={"center"} mt={"50px"}>
          <CircularProgress sx={{ "color": "#d23831" }} />
        </Box>
      )}
      <Snackbar open={alertSuccess} autoHideDuration={6000} onClose={createHandleCloseAlert("success")}>
        <Alert
          onClose={createHandleCloseAlert("success")}
          severity="success"
          variant="filled"
          sx={{ width: '100%', fontFamily: 'Avenir Regular' }}
        >
          <AlertTitle sx={{ width: '100%', fontFamily: 'Avenir Regular' }}>{alerts.success.title}</AlertTitle>
          {alertSuccess ? alerts.success.text : ""}
        </Alert>
      </Snackbar>
      <Snackbar open={alertError} autoHideDuration={6000} onClose={createHandleCloseAlert("error")}>
        <Alert
          onClose={createHandleCloseAlert("error")}
          severity="error"
          variant="filled"
          sx={{ width: '100%', fontFamily: 'Avenir Regular' }}
        >
          <AlertTitle sx={{ width: '100%', fontFamily: 'Avenir Regular' }}>{alerts.error.title}</AlertTitle>
          {alertError ? alerts.error.text : ""}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Home;
