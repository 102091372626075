import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, Divider, Box, Button } from "@mui/material";
import NotasManualesMultipleModal from "../../../modals/NotasManualesMultipleModal";
import NotasManualesTableMultiple from "../../../tables/NotasManualesTableMultiple";
import { gradesDistributionChart, destroyGradesChart, manualMultiGradesChart } from "../../../../../utils/charts";

const ManualGradesMultiple = ({
    keycloak,
    course,
    selectedModule,
    selectedImplementation,
    selectedSection,
    sectionData,
    isDiplomado,
    title,
    identifier,
    gradingObject,
    manualMultiGrades,
    setManualMultiGrades,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {

    const [multipleModalOpen, setMultipleModalOpen] = useState(false);

    // Calculate stats for the group's average grades
    const calculateStats = () => {
        if (!manualMultiGrades || !manualMultiGrades[title]) return null;

        // Calculate average grades for users who have all grades
        const averageGrades = sectionData.enrolled_users.filter(user => user.show).map(user => {
            const grades = manualMultiGrades[title].map(evaluation => {
                const grade = evaluation.grades.find(g => g.user_id === user.user_id)?.grade;
                return grade || grade === 0 ? grade : null;
            });

            // Only calculate average if user has all grades
            if (grades.some(g => g === null)) return null;
            return grades.reduce((sum, grade) => sum + grade, 0) / grades.length;
        }).filter(grade => grade !== null);

        if (averageGrades.length === 0) return null;

        return {
            averageGrade: (averageGrades.reduce((a, b) => a + b, 0) / averageGrades.length).toFixed(1),
            medianGrade: averageGrades.sort((a, b) => a - b)[Math.floor(averageGrades.length / 2)].toFixed(1),
            stdDeviation: Math.sqrt(averageGrades.reduce((sq, n) => 
                sq + Math.pow(n - (averageGrades.reduce((a, b) => a + b, 0) / averageGrades.length), 2), 0
            ) / (averageGrades.length - 1)).toFixed(1),
            maxGrade: Math.max(...averageGrades).toFixed(1),
            submittedCount: averageGrades.length,
            passedCount: averageGrades.filter(g => g >= 4.0).length,
            failedCount: averageGrades.filter(g => g < 4.0).length
        };
    };

    // Update chart when grades change
    useEffect(() => {
        if (manualMultiGrades && manualMultiGrades[title]) {
            // Calculate average grades for the chart
            const averageGrades = sectionData.enrolled_users.filter(user => user.show).map(user => {
                // First check if user has all grades
                const hasAllGrades = manualMultiGrades[title].every(evaluation => 
                    evaluation.grades.some(g => g.user_id === user.user_id && 
                        (g.grade || g.grade === 0) && g.grade !== "" // Check for empty string
                    )
                );

                if (!hasAllGrades) return null;

                // Calculate average only for users with all grades
                const grades = manualMultiGrades[title].map(evaluation => 
                    evaluation.grades.find(g => g.user_id === user.user_id).grade
                );

                return grades.reduce((sum, grade) => sum + grade, 0) / grades.length;
            }).filter(grade => grade !== null);

            manualMultiGradesChart(averageGrades);
        }

        return () => {
            destroyGradesChart();
        };
    }, [manualMultiGrades, title]);

    return (
        <>
            <Grid container spacing={2}>
                {manualMultiGrades && manualMultiGrades[title] && Object.keys(manualMultiGrades[title]).length > 0 ?
                    <>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant='title' fontSize={"1.2em"}>{title}</Typography>
                                    <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography variant='p-small'>Tipo</Typography>
                                        <Typography variant='p-small'>Notas múltiples</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography variant='p-small'>Ponderación</Typography>
                                        <Typography variant='p-small'>{gradingObject.value}%</Typography>
                                    </Box>
                                    <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                    {(() => {
                                        const stats = calculateStats();
                                        return stats && (
                                            <>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Nota promedio</Typography>
                                                    <Typography variant='p-small'>{stats.averageGrade}</Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Nota mediana</Typography>
                                                    <Typography variant='p-small'>{stats.medianGrade}</Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Desviación estándar</Typography>
                                                    <Typography variant='p-small'>{stats.stdDeviation}</Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Nota máxima</Typography>
                                                    <Typography variant='p-small'>{stats.maxGrade}</Typography>
                                                </Box>
                                                <Divider sx={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Docentes evaluados</Typography>
                                                    <Typography variant='p-small'>{stats.submittedCount}</Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Aprobados</Typography>
                                                    <Typography variant='p-small'>{stats.passedCount}</Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography variant='p-small'>Reprobados</Typography>
                                                    <Typography variant='p-small'>{stats.failedCount}</Typography>
                                                </Box>
                                            </>
                                        );
                                    })()}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Card sx={{ "height": "100%" }}>
                                <CardContent>
                                    <Typography variant='title' fontSize={"1.2em"}>Distribución de notas</Typography>
                                    <div id="grades-distribution-chart"></div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <NotasManualesTableMultiple
                                keycloak={keycloak}
                                enrolledUsers={sectionData.enrolled_users.filter(user => user.show)}
                                courseKey={
                                    isDiplomado ?
                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                                        :
                                        course.implementations[selectedImplementation].sections[selectedSection].course_key
                                }
                                courseType={isDiplomado ? "diplomado" : "course"}
                                evaluations={manualMultiGrades[title]}
                                handleEdit={() => setMultipleModalOpen(true)}
                                identifier={identifier}
                                title={title}
                                setManualMultiGrades={setManualMultiGrades}
                                alerts={alerts}
                                setAlerts={setAlerts}
                                setAlertSuccess={setAlertSuccess}
                                setAlertError={setAlertError}
                            />
                        </Grid>
                    </> :
                    <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <Typography my={"20px"} variant='p-small'>No hay evaluaciones disponibles.</Typography>
                            <Button onClick={() => setMultipleModalOpen(true)} variant="red">Agregar evaluaciones</Button>
                        </Box>
                    </Grid>
                }
            </Grid>
            <NotasManualesMultipleModal
                keycloak={keycloak}
                courseKey={
                    isDiplomado ?
                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                        :
                        course.implementations[selectedImplementation].sections[selectedSection].course_key
                }
                enrolledUsers={sectionData.enrolled_users.filter(user => user.show)}
                courseType={isDiplomado ? "diplomado" : "course"}
                open={multipleModalOpen}
                handleClose={() => setMultipleModalOpen(false)}
                title={title}
                manualMultiGrades={manualMultiGrades}
                setManualMultiGrades={setManualMultiGrades}
                gradingObject={gradingObject}
                setAlertError={setAlertError}
                setAlertSuccess={setAlertSuccess}
                alerts={alerts}
                setAlerts={setAlerts}
            />
        </>
    );
}

export default ManualGradesMultiple;