import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DownloadIcon from '@mui/icons-material/Download';
import { mapAsistencia } from '../../../utils/mappers';
import AsistenciaModal from '../modals/AsistenciaModal';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const AsistenciaTable = ({
    keycloak,
    enrolledUsers,
    events,
    eventId,
    courseType,
    courseKey,
    setAsistencia,
    identifier,
    alerts,
    setAlerts,
    setAlertSuccess,
    setAlertError
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [editNoteModal, setEditNoteModal] = useState(false);

    const handleAsistenciaCSV = () => {
        let header = [
            "Usuario",
            "Asistencia",
            "Observaciones"
        ];
        let lines = [];
        let output = [];
        
        for (let user of enrolledUsers) {
            var thisLine = [];
            thisLine.push(user.username || "—");
            thisLine.push(events.find(e => e.id === eventId).asistencia.find(asistencia => asistencia.user_id === user.user_id)?.assistance ? mapAsistencia(events.find(e => e.id === eventId).asistencia.find(asistencia => asistencia.user_id === user.user_id).assistance) : "—");
            thisLine.push(events.find(e => e.id === eventId).asistencia.find(asistencia => asistencia.user_id === user.user_id)?.observaciones || "—");

            lines.push(thisLine);
        }
        
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        
        const fileName = "asistencia_" + events.find(e => e.id === eventId).event_name.replace(/ /g, "_").replace(".", "_") + "_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'assistance',
            disableColumnMenu: true,
            headerName: 'Asistencia',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const getAssistanceColor = (value) => {
                    switch(value) {
                        case "Inasistencia": return "#d23831";
                        case "Inasistencia justificada": return "#8b8000";
                        case "Asistencia": return "green";
                        default: return "#646464";
                    }
                };

                return (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%" 
                        sx={{ color: getAssistanceColor(params.value) }}>
                        {params.value}
                    </Box>
                );
            }
        },
        {
            field: 'observaciones',
            disableColumnMenu: true,
            sortable: false,
            headerName: 'Observaciones',
            width: 600,
            headerAlign: 'center',
            align: 'center',
        }
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(enrolledUsers)) {
            rows.push({
                id: key,
                username: value.username || "—",
                assistance: events.find(e => e.id === eventId).asistencia.find(asistencia => asistencia.user_id === value.user_id)?.assistance ? mapAsistencia(events.find(e => e.id === eventId).asistencia.find(asistencia => asistencia.user_id === value.user_id).assistance) : "—",
                observaciones: events.find(e => e.id === eventId).asistencia.find(asistencia => asistencia.user_id === value.user_id)?.observaciones || "—"
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Listado de asistencia</Typography>
                <Box>
                    <IconButton onClick={() => setEditNoteModal(true)}><EditNoteIcon></EditNoteIcon></IconButton>
                    <IconButton onClick={handleAsistenciaCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        enrolledUsers.length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p-small' textAlign={"center"}>No hay datos de asistencia disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1010px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    getRowHeight={() => 'auto'}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                <AsistenciaModal
                    keycloak={keycloak}
                    open={editNoteModal}
                    handleClose={() => setEditNoteModal(false)}
                    enrolledUsers={enrolledUsers}
                    courseType={courseType}
                    courseKey={courseKey}
                    events={events}
                    eventId={eventId}
                    setAsistencia={setAsistencia}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </div>
    );
};

export default AsistenciaTable;