import axios from 'axios';

export const getSituaciones = async (token, courseType, courseKey) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/situaciones/" + courseType + "/" + courseKey, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        return { situaciones: response.data, error: "" };

    } catch (error) {
        return { situaciones: null, error: "Error desconocido: " + error.message };
    }
}