import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Grid, Card, CardContent, Divider } from '@mui/material';
import ResumenTable from '../../tables/ResumenTable';
import { answersEncuestaSlider } from '../../../../utils/charts';
import { getSituaciones } from '../../../../requests/getSituaciones';
import PreTestPostTestTable from '../../tables/PreTestPostTestTable';
import { calculateEvaluationStats, calculateEvaluationGrade, calculateFinalGrade } from '../../../../utils/formatters';
import { gradesDistributionChart, destroyGradesChart, finalGradesDistributionChart } from '../../../../utils/charts';

const CourseSectionResumen = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    surveys,
    evaluations,
    manualGrades,
    asistencia,
    selectedModule,
    selectedImplementation,
    selectedSection,
    allResponses,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess,
    manualMultiGrades,
}) => {

    const [situaciones, setSituaciones] = useState(null);
    const [loadingSituaciones, setLoadingSituaciones] = useState(true);

    const findSatisfactionResponses = (code) => {
        if (!surveys?.encuestas) return [];

        for (let encuesta of surveys.encuestas) {
            for (let content of encuesta.contents) {
                for (let section of content.sections) {
                    for (let subsection of section.subsections) {
                        if (subsection.code === code) {
                            return content.responses.map(response => {
                                let jsonResponse = JSON.parse(response.response);
                                if (jsonResponse && jsonResponse.student_answers) {
                                    let responseText = Object.values(jsonResponse.student_answers)[0];
                                    if (responseText) {
                                        let answerText = JSON.parse(responseText);
                                        if (answerText) {
                                            let answer = answerText["answer"].replace("\"", "").replace("'", "").replace("EC_AÑOS.EXP", "EC_ANIOS_EXP");
                                            let answers = answer.split("_*_");
                                            let matchingAnswer = answers.find(a => a.startsWith(code));
                                            if (matchingAnswer) {
                                                return {
                                                    value: matchingAnswer.split(";_")[1],
                                                    userId: response.user_id,
                                                    timestamp: response.created
                                                };
                                            }
                                        }
                                    }
                                }
                                return null;
                            }).filter(Boolean);
                        }
                    }
                }
            }
        }
        return [];
    };

    const fetchSituaciones = async () => {
        setLoadingSituaciones(true);
        var courseKey;
        if (isDiplomado) {
            courseKey = course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key;
        } else {
            courseKey = course.implementations[selectedImplementation].sections[selectedSection].course_key;
        }
        const response = await getSituaciones(keycloak.token, isDiplomado ? "diplomado" : "course", courseKey);
        if (!response.situaciones) {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener situación de los docentes", text: response.error },
            });
        } else {
            setSituaciones(response.situaciones);
        }
        setLoadingSituaciones(false);
    }

    useEffect(() => {
        if (sectionData) {
            fetchSituaciones();
        }
    }, [sectionData]);

    useEffect(() => {
        if (surveys && situaciones) {
            const container = document.getElementById('encuestas-chart-container-ES_SAT.CURSO');
            if (container) {
                container.innerHTML = '';
                const responses = findSatisfactionResponses('ES_SAT.CURSO');

                if (responses.length > 0) {
                    const values = responses.map(r => r.value);
                    answersEncuestaSlider(values, 'ES_SAT.CURSO', true);
                } else {
                    container.innerHTML = '<p style="color: #646464; font-family: Avenir Regular; text-align: center;">No hay datos de satisfacción disponibles.</p>';
                }
            }
        }
    }, [surveys, situaciones]);

    const getGradingConfig = () => {
        return isDiplomado 
            ? course.modules[selectedModule].implementations[selectedImplementation].grading
            : course.implementations[selectedImplementation].grading;
    }

    const getSummaryData = () => {
        const allUsers = new Set();
        [...evaluations.controles, ...evaluations.tests].forEach(evaluation => {
            evaluation.contents?.forEach(content => {
                content.responses?.forEach(response => {
                    allUsers.add(response.user_id);
                });
            });
        });

        const summaryData = Array.from(allUsers).map(userId => {
            const userSummary = {
                username: userId,
                grades: {
                    controles: {},
                    tests: {},
                    preguntasCalificadas: {},
                    other: {}
                }
            };

            // Calculate grades for tests
            evaluations.tests.forEach((test, index) => {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);
                const userResponses = test.contents?.map(content => {
                    return content.responses?.find(r => r.user_id === userId) || {
                        score: 0,
                        max_score: content.max_score || 12
                    };
                });
                userSummary.grades.tests[test.name] = calculateEvaluationGrade(userResponses, scale);
            });

            // Calculate grades for controls
            evaluations.controles.forEach((control, index) => {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);
                const userResponses = control.contents?.map(content => {
                    return content.responses?.find(r => r.user_id === userId) || {
                        score: 0,
                        max_score: content.max_score || 12
                    };
                });
                userSummary.grades.controles[`Control ${index + 1}`] = calculateEvaluationGrade(userResponses, scale);
            });

            // Calculate grades for preguntas calificadas
            if (evaluations.preguntasCalificadas.length > 0) {
                const scale = isDiplomado ?
                    (course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60) :
                    (course.implementations[selectedImplementation].grading?.scale || 60);

                evaluations.preguntasCalificadas.forEach((taller, index) => {
                    const userResponses = taller.contents?.map(content => {
                        return content.responses?.find(r => r.user_id === userId) || {
                            score: 0,
                            max_score: content.max_score || 12
                        };
                    }) || [];

                    userSummary.grades.preguntasCalificadas[`Taller ${index + 1}`] = calculateEvaluationGrade(userResponses, scale);
                });
            }

            // Calculate grades for other rates
            Object.keys(manualGrades).forEach(grade => {
                let evaluation = manualGrades[grade]
                if (Object.keys(evaluation)[0] === "0" && evaluation[0].find(ev => ev.user_id === userId)) {
                    userSummary.grades.other[grade] = evaluation[0].find(user => user.user_id === userId).grade;
                }
            });

            return userSummary;
        });
        return summaryData;
    }

    useEffect(() => {
        if (sectionData && !loadingSituaciones) {
            // Add timeout to ensure container exists
            setTimeout(() => {
                const container = document.getElementById('final-grades-distribution-chart-summary');
                if (container) {
                    const grading = isDiplomado
                        ? course.modules[selectedModule].implementations[selectedImplementation].grading
                        : course.implementations[selectedImplementation].grading;

                    const summaryData = getSummaryData();
                    // Filter out students with missing grades
                    const validSummaryData = summaryData.filter(data => {
                        // Check if student has all required controles
                        if (grading.controles_rate > 0) {
                            const controlesGrades = evaluations.controles.map((_, index) =>
                                data.grades?.controles[Object.keys(data.grades.controles)[index]]
                            );
                            if (controlesGrades.some(grade => !grade)) return false;
                        }
                        return true;
                    });

                    finalGradesDistributionChart(
                        sectionData.enrolled_users.filter(user => user.show),
                        validSummaryData,
                        grading,
                        sectionData,
                        allResponses,
                        manualMultiGrades,
                        true
                    );
                }
            }, 500);
        }

        return () => {
            destroyGradesChart();
        };
    }, [sectionData, loadingSituaciones]);

    const renderGradingCriteria = () => {
        const grading = getGradingConfig();
        
        if (grading == null) {
            return <Typography variant={"p"}>No hay criterios de evaluación</Typography>;
        }
        
        if (grading.autoinstruccional) {
            return <Typography variant={"p"}>Este curso es autoinstruccional y no contempla evaluaciones.</Typography>;
        }

        const criteria = [];
        if (grading.controles_rate > 0) {
            criteria.push({ name: "Controles", value: grading.controles_rate });
        }
        if (grading.avance_rate > 0) {
            criteria.push({ name: "Avance en plataforma", value: grading.avance_rate });
        }
        if (grading.preguntas_calificadas_rate > 0) {
            criteria.push({ name: "Preguntas calificadas", value: grading.preguntas_calificadas_rate });
        }
        if (grading.other_rates?.length > 0) {
            grading.other_rates.forEach(rate => {
                criteria.push({ name: rate.name, value: rate.value });
            });
        }

        return criteria.map((criterion, index) => (
            <Box key={index}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography fontWeight={"bold"} variant="p">{criterion.name}:</Typography>
                    {criterion.value && (
                        <Typography variant="p">
                            {criterion.value}%
                        </Typography>
                    )}
                </Box>
                {criterion.name === "Controles" &&
                    <Typography ml={"10px"} variant="p-small">
                        Exigencia: {grading.scale}%
                    </Typography>
                }
            </Box>
        ));
    }

    return (
        sectionData ?
            (!loadingSituaciones && situaciones) ?
                <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography fontSize={"1.2em"} variant={"title"}>Información del curso</Typography>
                                    <Divider sx={{ mb: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Inscritos</Typography>
                                        <Typography variant={"p"}>
                                            {!isDiplomado ?
                                                course.implementations[selectedImplementation].sections[selectedSection].enrollments
                                                :
                                                course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].enrollments
                                            }
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Situación actual</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Activos</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Activo").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Atrasados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Atrasado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Retirados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Retirado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Incomunicados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.current_situation === "Incomunicado").length}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Situación final CMMEdu</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Aprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Aprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Reprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Reprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Eliminados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Eliminado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Abandono</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Abandono").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Retirados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation === "Retirado").length}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Typography fontWeight={"bold"} variant={"p"}>Situación final CPEIP</Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Aprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Aprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Reprobados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Reprobado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Retirados</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Retirado").length}
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant={"p-small"}>Desertores</Typography>
                                        <Typography variant={"p-small"}>
                                            {situaciones.filter(situacion => situacion.final_situation_cpeip === "Desertores").length}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Card sx={{ height: '100%' }}>
                                    <CardContent>
                                        <Typography fontSize={"1.2em"} variant={"title"}>Notas finales</Typography>
                                        <Divider sx={{ my: 2 }} />
                                        {(() => {
                                            const grading = getGradingConfig();
                                            if (!grading || grading.autoinstruccional) {
                                                return <Typography variant="p">Este curso es autoinstruccional y no contempla evaluaciones.</Typography>;
                                            }

                                            const summaryData = getSummaryData();
                                            const grades = sectionData.enrolled_users.filter(user => user.show).map(user => {
                                                let finalGrade = 0;
                                                let totalWeight = 0;
                                                let shouldCalculateFinal = true;
                                                const userData = summaryData.find(data => data.username === user.user_id);
                                                if (!userData) return null;

                                                // Add controles grades if they exist
                                                if (grading.controles_rate > 0) {
                                                    const controlesGrades = Object.values(userData.grades.controles)
                                                        .map(grade => Math.round(grade * 10) / 10); // Round each control grade

                                                    if (controlesGrades.length > 0) {
                                                        if (controlesGrades.some(grade => grade === 0 || grade === '—')) {
                                                            shouldCalculateFinal = false;
                                                        } else {
                                                            const avgControles = Math.round((controlesGrades.reduce((a, b) => a + b, 0) / controlesGrades.length) * 10) / 10;
                                                            finalGrade += avgControles * (grading.controles_rate / 100);
                                                            totalWeight += grading.controles_rate;
                                                        }
                                                    } else {
                                                        shouldCalculateFinal = false;
                                                    }
                                                }

                                                // Add preguntas calificadas grade if it exists
                                                if (grading.preguntas_calificadas_rate > 0) {
                                                    const pcGrades = Object.values(userData.grades.preguntasCalificadas)
                                                        .map(grade => Math.round(grade * 10) / 10); // Round each PC grade

                                                    if (pcGrades.length > 0) {
                                                        if (pcGrades.some(grade => grade === 0 || grade === '—')) {
                                                            shouldCalculateFinal = false;
                                                        } else {
                                                            const avgPC = Math.round((pcGrades.reduce((a, b) => a + b, 0) / pcGrades.length) * 10) / 10;
                                                            finalGrade += avgPC * (grading.preguntas_calificadas_rate / 100);
                                                            totalWeight += grading.preguntas_calificadas_rate;
                                                        }
                                                    } else {
                                                        shouldCalculateFinal = false;
                                                    }
                                                }

                                                // Add avance grade if it exists
                                                if (grading.avance_rate > 0) {
                                                    let totalAnswered = 0;
                                                    let totalQuestions = 0;

                                                    // Only count Taller sections
                                                    sectionData.sections
                                                        .filter(section => section.name.toLowerCase().includes('taller'))
                                                        .forEach(section => {
                                                            const xblocks = section.subsections.flatMap(subsection =>
                                                                subsection.units.flatMap(unit =>
                                                                    unit.xblocks.filter(xblock =>
                                                                        ["multiplechoice", "table_advanced_problem", "radiomultiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3"].includes(xblock.true_block_type)
                                                                    )
                                                                )
                                                            );

                                                            totalQuestions += xblocks.length;

                                                            xblocks.forEach(xblock => {
                                                                const responseKey = `${xblock.id}_${user.user_id}`;
                                                                if (allResponses[responseKey]) {
                                                                    totalAnswered++;
                                                                }
                                                            });
                                                        });

                                                    const percentage = totalQuestions === 0 ? 0 : (totalAnswered / totalQuestions) * 100;
                                                    const decimalScale = 60 / 100;
                                                    let avanceGrade;

                                                    if (percentage < decimalScale * 100) {
                                                        avanceGrade = (4.0 - 1.0) * (percentage / (decimalScale * 100)) + 1.0;
                                                    } else {
                                                        avanceGrade = (7.0 - 4.0) * ((percentage - decimalScale * 100) / (100 * (1 - decimalScale))) + 4.0;
                                                    }

                                                    avanceGrade = Math.round(avanceGrade * 10) / 10; // Round avance grade
                                                    finalGrade += avanceGrade * (grading.avance_rate / 100);
                                                    totalWeight += grading.avance_rate;
                                                }

                                                // Add manual multi grades if they exist
                                                if (grading.other_rates?.length > 0) {
                                                    grading.other_rates.forEach(rate => {
                                                        if (rate.type === "otros_multiple") {
                                                            // Skip if no evaluations exist for this group
                                                            if (!manualMultiGrades?.[rate.name]?.length) {
                                                                shouldCalculateFinal = false;
                                                                return;
                                                            }

                                                            // Calculate average grade for this group
                                                            if (manualMultiGrades && manualMultiGrades[rate.name]) {
                                                                const hasAllGrades = manualMultiGrades[rate.name].every(evaluation => 
                                                                    evaluation.grades.some(g => g.user_id === user.user_id && 
                                                                        (g.grade || g.grade === 0) && g.grade !== ""
                                                                    )
                                                                );

                                                                if (!hasAllGrades) {
                                                                    shouldCalculateFinal = false;
                                                                } else {
                                                                    const grades = manualMultiGrades[rate.name].map(evaluation => {
                                                                        const grade = evaluation.grades.find(g => g.user_id === user.user_id).grade;
                                                                        return Math.round(grade * 10) / 10;
                                                                    });
                                                                    const avgGrade = Math.round((grades.reduce((sum, grade) => sum + grade, 0) / grades.length) * 10) / 10;
                                                                    finalGrade += avgGrade * (rate.value / 100);
                                                                    totalWeight += rate.value;
                                                                }
                                                            }
                                                        }
                                                    });
                                                }

                                                // Add other single grades
                                                if (grading.other_rates?.filter(rate => rate.type === "otros_unico").length > 0) {
                                                    grading.other_rates.filter(rate => rate.type === "otros_unico").forEach(rate => {
                                                        const otherGrade = userData.grades.other[rate.name];
                                                        // If the grade doesn't exist or is empty, don't calculate final
                                                        if (!otherGrade && otherGrade !== 0) {
                                                            shouldCalculateFinal = false;
                                                        } else {
                                                            const roundedGrade = Math.round(otherGrade * 10) / 10;
                                                            finalGrade += roundedGrade * (rate.value / 100);
                                                            totalWeight += rate.value;
                                                        }
                                                    });
                                                }

                                                if (shouldCalculateFinal) {
                                                    // Fix precision issues by multiplying by 100, rounding, then dividing by 100
                                                    // before doing the final rounding to 1 decimal
                                                    finalGrade = Math.round(Math.round(finalGrade * 100) / 100 * 10) / 10;
                                                }

                                                // Only return grade if we should calculate final
                                                return shouldCalculateFinal && totalWeight > 0 ? 
                                                    Math.round((finalGrade * 100 / totalWeight) * 10) / 10 : 
                                                    null;
                                            }).filter(grade => grade !== null);

                                            const stats = {
                                                averageGrade: grades.length > 0 ? 
                                                    (grades.reduce((a, b) => a + b, 0) / grades.length).toFixed(1) : 
                                                    '—',
                                                medianGrade: grades.length > 0 ? 
                                                    grades.sort((a, b) => a - b)[Math.floor(grades.length / 2)].toFixed(1) : 
                                                    '—',
                                                stdDeviation: grades.length > 1 ? 
                                                    Math.sqrt(grades.reduce((sq, n) => sq + Math.pow(n - (grades.reduce((a, b) => a + b, 0) / grades.length), 2), 0) / (grades.length - 1)).toFixed(1) : 
                                                    '—',
                                                maxGrade: grades.length > 0 ? 
                                                    Math.max(...grades).toFixed(1) : 
                                                    '—',
                                                submittedCount: grades.length,
                                                passedCount: grades.filter(g => g >= 4.0).length,
                                                failedCount: grades.filter(g => g < 4.0).length
                                            };

                                            return (
                                                <Box>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant='p-small' fontWeight="bold" mb={1}>Estadísticas</Typography>
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography variant='p-small'>Nota promedio</Typography>
                                                            <Typography variant='p-small'>{stats.averageGrade}</Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography variant='p-small'>Nota mediana</Typography>
                                                            <Typography variant='p-small'>{stats.medianGrade}</Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography variant='p-small'>Desviación estándar</Typography>
                                                            <Typography variant='p-small'>{stats.stdDeviation}</Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography variant='p-small'>Nota máxima</Typography>
                                                            <Typography variant='p-small'>{stats.maxGrade}</Typography>
                                                        </Box>
                                                        <Divider sx={{ my: 1 }} />
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography variant='p-small'>{"Aprobados (≥ 4.0)"}</Typography>
                                                            <Typography variant='p-small'>{stats.passedCount}</Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography variant='p-small'>{"Reprobados (< 4.0)"}</Typography>
                                                            <Typography variant='p-small'>{stats.failedCount}</Typography>
                                                        </Box>
                                                        <Divider sx={{ my: 1 }} />
                                                    </Box>
                                                    <Box sx={{ height: '200px', width: '100%' }}>
                                                        <div id="final-grades-distribution-chart-summary" style={{ height: '100%', width: '100%' }}></div>
                                                    </Box>
                                                </Box>
                                            );
                                        })()}
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>

                        {(() => {
                            const grading = getGradingConfig();
                            if (grading && !grading.autoinstruccional) {
                                return (
                                    <Grid item xs={12} lg={6}>
                                        {evaluations.tests.find(test => test.name === "Pre-Test - Post-Test") && (
                                            <PreTestPostTestTable
                                                questions={evaluations.tests.find(test => test.name === "Pre-Test - Post-Test")?.contents}
                                                enrolledUsers={sectionData.enrolled_users.filter(user => user.show)}
                                                title={"Pre-Test - Post-Test"}
                                                isSummary={true}
                                                identifier={
                                                    isDiplomado ?
                                                        course.shortname + "_" +
                                                        course.modules[selectedModule].module + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                                        :
                                                        course.shortname + "_" +
                                                        course.implementations[selectedImplementation].year + "_" +
                                                        course.implementations[selectedImplementation].term + "_" +
                                                        course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                                        course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                        course.implementations[selectedImplementation].sections[selectedSection].section
                                                }
                                                alerts={alerts}
                                                setAlerts={setAlerts}
                                                setAlertError={setAlertError}
                                                setAlertSuccess={setAlertSuccess}
                                            />
                                        )}
                                    </Grid>
                                );
                            }
                            return null;
                        })()}

                        <Grid item xs={12}>
                            <ResumenTable
                                keycloak={keycloak}
                                isDiplomado={isDiplomado}
                                courseKey={
                                    isDiplomado ?
                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                                        :
                                        course.implementations[selectedImplementation].sections[selectedSection].course_key
                                }
                                sectionData={sectionData}
                                allResponses={allResponses}
                                situaciones={situaciones}
                                setSituaciones={setSituaciones}
                                evaluations={evaluations}
                                manualGrades={manualGrades}
                                manualMultiGrades={manualMultiGrades}
                                asistencia={asistencia}
                                alerts={alerts}
                                setAlerts={setAlerts}
                                setAlertError={setAlertError}
                                setAlertSuccess={setAlertSuccess}
                                grading={getGradingConfig()}
                                identifier={
                                    isDiplomado ?
                                        course.shortname + "_" +
                                        course.modules[selectedModule].module + "_" +
                                        course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                        course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                        :
                                        course.shortname + "_" +
                                        course.implementations[selectedImplementation].year + "_" +
                                        course.implementations[selectedImplementation].term + "_" +
                                        course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                        course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                        course.implementations[selectedImplementation].sections[selectedSection].section
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
                : loadingSituaciones ?
                    <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                        <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
                    </Box>
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>

    );

}

export default CourseSectionResumen;