import DOMPurify from 'dompurify';
import { Box, Typography, Link, IconButton, Divider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { mapXblockTypes } from './mappers';
import { postGetSurveyContents } from '../requests/postGetSurveyContents';

export const formatFileSize = (size) => {
    if (size === 0) {
        return "0";
    }
    if (size < 1024) return size + ' Bytes';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num = (size / Math.pow(1024, i));
    let round = Math.round(num);
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
    return `${num} ${['Bytes', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatTimestamp = (timestamp) => {
    const date = isNaN(Number(timestamp)) ? new Date(timestamp) : new Date(Number(timestamp));
    const formatter = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false
    });
    return formatter.format(date);
}

export function capitalizeFirstLetter(string) {
    return string
        .split(' ') // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter, make the rest lowercase
        .join(' '); // Join the words back into a single string
}

export const formatXBlockResponse = (xblock, response, username = null) => {
    switch (xblock.true_block_type) {
        case "dialogsquestionsxblock":
            try {
                return Object.values(JSON.parse(response)["student_answers"]).join(", ");
            } catch (error) {
                return response;
            }
        case "vof":
            try {
                return Object.values(JSON.parse(response)["respuestas"]).join(", ").replaceAll("verdadero", "V").replaceAll("falso", "F");
            } catch (error) {
                return response;
            }
        case "multiplechoice":
            try {
                const parser = new DOMParser();
                const doc = parser.parseFromString(xblock.data, "text/html");
                const checkboxGroup = doc.querySelector("choicegroup");
                const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];
                const choiceData = choices.map((choice) => ({
                    text: choice.innerHTML.trim(),
                    isCorrect: choice.getAttribute("correct") === "true",
                }));
                if ("student_answers" in JSON.parse(response)) {
                    let index = parseInt(Object.values(JSON.parse(response)["student_answers"])[0].slice(-1))
                    return choiceData[index].text
                } else {
                    return ""
                }
            } catch (error) {
                return response;
            }
        case "radiomultiplechoice":
            try {
                const parser = new DOMParser();
                const doc = parser.parseFromString(xblock.data, "text/html");
                const checkboxGroup = doc.querySelector("radiogroup");
                const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];
                const choiceData = choices.map((choice) => ({
                    text: choice.innerHTML.trim(),
                    isCorrect: choice.getAttribute("correct") === "true",
                }));
                if ("student_answers" in JSON.parse(response)) {
                    let indexes = Object.values(JSON.parse(response)["student_answers"])[0].map(answer => answer.slice(-1))
                    return indexes.map(index => choiceData[index].text).join(", ")
                } else {
                    return ""
                }
            } catch (error) {
                return response;
            }
        case "checkboxes":
            try {
                const parser = new DOMParser();
                const doc = parser.parseFromString(xblock.data, "text/html");
                const checkboxGroup = doc.querySelector("checkboxgroup");
                const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];
                const choiceData = choices.map((choice) => ({
                    text: choice.innerHTML.trim(),
                    isCorrect: choice.getAttribute("correct") === "true",
                }));
                if ("student_answers" in JSON.parse(response)) {
                    let indexes = Object.values(JSON.parse(response)["student_answers"])[0].map(answer => answer.slice(-1))
                    return indexes.map(index => choiceData[index].text).join(", ")
                } else {
                    return ""
                }
            } catch (error) {
                return response;
            }
        case "dropdown":
            try {
                if ("student_answers" in JSON.parse(response)) {
                    return Object.values(JSON.parse(response)["student_answers"]).join(", ");
                } else {
                    return "";
                }
            } catch (error) {
                return response;
            }
        case "openassessment":
            try {
                if ("parts" in JSON.parse(response.response)) {
                    if (JSON.parse(response.response)["parts"].length > 0) {
                        if (JSON.parse(response.response)["parts"][0]["text"] !== null) {
                            return JSON.parse(response.response)["parts"][0]["text"].replace("\n", "<br>")
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            } catch (error) {
                return response;
            }
        case "clase3":
            try {
                if ("student_answers" in JSON.parse(response)) {
                    return Object.values(JSON.parse(Object.values(JSON.parse(response)["student_answers"])[0]))[0]
                } else {
                    return "";
                }
            } catch (error) {
                return response;
            }
        case "freetextresponse":
            try {
                if ("student_answer" in JSON.parse(response)) {
                    return JSON.parse(response)["student_answer"]
                } else {
                    return "";
                }
            } catch (error) {
                return response;
            }
        case "eollistgrade":
            try {
                if ("comment" in JSON.parse(response)) {
                    let score = JSON.parse(response)["student_score"]
                    if (score === 2) {
                        return "Asiste";
                    } else if (score === 1) {
                        return "Inasistencia justificada";
                    } else if (score === 0) {
                        return "Inasistencia injustificada";
                    } else {
                        return "Otro (" + score + ")";
                    }
                } else {
                    return "";
                }
            } catch (error) {
                return response;
            }
        case "eolzoom":
            return "";
        case "iterativexblock":
            try {
                const parsed = JSON.parse(response);
                if ("student_answers" in parsed) {
                    return Object.entries(parsed.student_answers)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join('\n');
                }
                return "";
            } catch (error) {
                return response;
            }
        case "table_advanced_problem":
            try {
                // Check if we have the responses in the xblock object
                if (xblock.responses) {
                    let thisUserResponses = xblock.responses.filter(r => r.username === username);
                    if (thisUserResponses && thisUserResponses.length > 0) {
                        if (thisUserResponses.some(r => r.Respuesta === undefined)) {
                            return "";
                        }
                        thisUserResponses.sort((a, b) => {
                            const numA = parseInt(a.Pregunta.replace("Pregunta ", ""));
                            const numB = parseInt(b.Pregunta.replace("Pregunta ", ""));
                            return numA - numB;
                        });
                        return thisUserResponses.reduce((acc, r) => acc + r.Respuesta + "<br>", "").slice(0, -4)
                    } else {
                        return ""
                    }
                }
                return "";
            } catch (error) {
                return response;
            }
        default:
            return response;
    }
}


export const getXBlockResponseAttempts = (xblockType, response) => {
    switch (xblockType) {
        case "dialogsquestionsxblock":
            try {
                return JSON.parse(response)["attempts"];
            } catch (error) {
                return response;
            }
        case "vof":
            try {
                return JSON.parse(response)["attempts"];
            } catch (error) {
                return response;
            }
        case "multiplechoice":
            try {
                if ("attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "radiomultiplechoice":
            try {
                if ("attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "checkboxes":
            try {
                if ("attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "dropdown":
            try {
                if ("attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "openassessment":
            try {
                if ("parts" in JSON.parse(response.response)) {
                    if (JSON.parse(response.response)["parts"].length > 0) {
                        if (JSON.parse(response.response)["parts"][0]["text"] !== null) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "clase3":
            try {
                if ("attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "freetextresponse":
            try {
                if ("count_attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["count_attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "eollistgrade":
            try {
                if ("comment" in JSON.parse(response) && "student_score" in JSON.parse(response)) {
                    return 1;
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        case "eolzoom":
            return null;
        case "iterativexblock":
            try {
                const parsed = JSON.parse(response);
                return "student_answers" in parsed ? 1 : 0;
            } catch (error) {
                return 0;
            }
        case "table_advanced_problem":
            try {
                if ("attempts" in JSON.parse(response)) {
                    return JSON.parse(response)["attempts"];
                } else {
                    return 0;
                }
            } catch (error) {
                return response;
            }
        default:
            return response;
    }
}

export const getXBlockResponseIsCorrect = (xblock, response, username = null) => {
    let user_response = formatXBlockResponse(xblock, response)
    switch (xblock.true_block_type) {
        case "dialogsquestionsxblock":
            try {
                let correct = Object.values(xblock["correct_answer"]).map(answer => answer.replace("[_[s]_]", "").replace("[_[i]_]", "")).join(", ")
                return correct === user_response;
            } catch (error) {
                return null;
            }
        case "vof":
            try {
                let correct = Object.values(xblock["questions"]).map(question => question.valor).map(v => v === true ? "V" : "F").join(", ")
                return correct === user_response;
            } catch (error) {
                return null;
            }
        case "multiplechoice":
            try {
                if ("student_answers" in JSON.parse(response)) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(xblock.data, "text/html");
                    const checkboxGroup = doc.querySelector("choicegroup");
                    const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];
                    const index = parseInt(Object.values(JSON.parse(response)["student_answers"])[0].slice(-1));
                    const selectedChoice = choices[index];
                    return selectedChoice.getAttribute("correct") === "true";
                } else {
                    return null;
                }
            } catch (error) {
                console.error("Error processing multiplechoice response:", error);
                return null;
            }
        case "radiomultiplechoice":
            try {
                if ("student_answers" in JSON.parse(response)) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(xblock.data, "text/html");
                    const radioGroup = doc.querySelector("radiogroup");
                    const choices = radioGroup ? Array.from(radioGroup.querySelectorAll("choice")) : [];
                    // Get selected indexes from response
                    const selectedIndexes = Object.values(JSON.parse(response)["student_answers"])[0]
                        .map(answer => parseInt(answer.slice(-1)));

                    // Check if all selected choices are correct and no correct choices are unselected
                    const correctChoices = choices.map((choice, index) => ({
                        index,
                        isCorrect: choice.getAttribute("correct") === "true"
                    }));

                    const allSelectedAreCorrect = selectedIndexes.every(index =>
                        correctChoices[index].isCorrect
                    );

                    const allCorrectAreSelected = correctChoices
                        .filter(choice => choice.isCorrect)
                        .every(choice =>
                            selectedIndexes.includes(choice.index)
                        );

                    return allSelectedAreCorrect && allCorrectAreSelected;
                } else {
                    return null;
                }
            } catch (error) {
                console.error("Error processing radiomultiplechoice response:", error);
                return null;
            }
        case "checkboxes":
            try {
                if ("student_answers" in JSON.parse(response)) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(xblock.data, "text/html");
                    const checkboxGroup = doc.querySelector("checkboxgroup");
                    const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];

                    // Get selected indexes from response
                    const selectedIndexes = Object.values(JSON.parse(response)["student_answers"])[0]
                        .map(answer => parseInt(answer.slice(-1)));

                    // Check if all selected choices are correct and no correct choices are unselected
                    const correctChoices = choices.map((choice, index) => ({
                        index,
                        isCorrect: choice.getAttribute("correct") === "true"
                    }));

                    const allSelectedAreCorrect = selectedIndexes.every(index =>
                        correctChoices[index].isCorrect
                    );

                    const allCorrectAreSelected = correctChoices
                        .filter(choice => choice.isCorrect)
                        .every(choice =>
                            selectedIndexes.includes(choice.index)
                        );

                    return allSelectedAreCorrect && allCorrectAreSelected;
                } else {
                    return null;
                }
            } catch (error) {
                console.error("Error processing checkboxes response:", error);
                return null;
            }
        case "dropdown":
            try {
                if ("student_answers" in JSON.parse(response)) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(xblock.data, "text/html");
                    const optionInputs = doc.querySelectorAll("optioninput");

                    // Get correct answers from the XML
                    const correctAnswers = Array.from(optionInputs).map(input => {
                        const options = Array.from(input.querySelectorAll("option"));
                        const correctOption = options.find(opt => opt.getAttribute("correct") === "True");
                        return correctOption ? correctOption.textContent : "";
                    });

                    const correct = correctAnswers.join(", ");

                    return correct === user_response;
                } else {
                    return null;
                }
            } catch (error) {
                console.error("Error processing dropdown response:", error);
                return null;
            }
        case "clase3":
            try {
                if ("correct_map" in JSON.parse(response)) {
                    if ("correctness" in Object.values(JSON.parse(response)["correct_map"])[0]) {
                        return Object.values(JSON.parse(response)["correct_map"])[0]["correctness"] === "correct"
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        case "openassessment":
            return null;
        case "freetextresponse":
            return null;
        case "eollistgrade":
            return null;
        case "eolzoom":
            return null;
        case "iterativexblock":
            return "-";
        case "table_advanced_problem":
            try {
                if (xblock.responses) {
                    let thisUserResponses = xblock.responses.filter(r => r.username === username);
                    if (thisUserResponses.some(r => r.Respuesta === undefined)) {
                        return null;
                    }
                    if (thisUserResponses && thisUserResponses.length > 0) {
                        thisUserResponses.sort((a, b) => a["Pregunta"] - b["Pregunta"]);
                        for (let i = 0; i < thisUserResponses.length; i++) {
                            if (thisUserResponses[i].Respuesta === thisUserResponses[i]['Respuesta Correcta']) {
                                continue;
                            } else {
                                return false;
                            }
                        }
                        return true;
                    } else {
                        return null;
                    }
                }
                return null;
            } catch (error) {
                return null;
            }
        default:
            return null;
    }
}

export const getBlockSubdata = (xblock) => {
    if (xblock.true_block_type === "text") {
        const cleanText = xblock.text
            .replace(/<script[\s\S]*?<\/script>/gi, '')
            .replace(/<style[\s\S]*?<\/style>/gi, '')
            .replace(/<canvas[\s\S]*?<\/canvas>/gi, '')
            .replace(/<link[^>]*>/gi, '')
            .replace(/<iframe[^>]*>/gi, '')
            .replace(/<hr[^>]*>/gi, '')
            .replace(/<br[^>]*>/gi, '')
        return cleanText;
    } else if (xblock.true_block_type === "html") {
        const cleanText = xblock.text
            .replace(/<script[\s\S]*?<\/script>/gi, '')
            .replace(/<style[\s\S]*?<\/style>/gi, '')
            .replace(/<canvas[\s\S]*?<\/canvas>/gi, '')
            .replace(/<link[^>]*>/gi, '')
            .replace(/<iframe[^>]*>/gi, '')
            .replace(/<hr[^>]*>/gi, '')
            .replace(/<br[^>]*>/gi, '')
        return cleanText;
    } else if (xblock.true_block_type === "eolcontainer") {
        const cleanText = xblock.text
            .replace(/<script[\s\S]*?<\/script>/gi, '')
            .replace(/<style[\s\S]*?<\/style>/gi, '')
            .replace(/<canvas[\s\S]*?<\/canvas>/gi, '')
            .replace(/<link[^>]*>/gi, '')
            .replace(/<iframe[^>]*>/gi, '')
            .replace(/<hr[^>]*>/gi, '')
            .replace(/<br[^>]*>/gi, '')
        return cleanText;
    } else if (xblock.true_block_type === "eolquestion") {
        const cleanText = xblock.text
            .replace(/<script[\s\S]*?<\/script>/gi, '')
            .replace(/<style[\s\S]*?<\/style>/gi, '')
            .replace(/<canvas[\s\S]*?<\/canvas>/gi, '')
            .replace(/<link[^>]*>/gi, '')
            .replace(/<iframe[^>]*>/gi, '')
            .replace(/<hr[^>]*>/gi, '')
            .replace(/<br[^>]*>/gi, '')
        return xblock.index + ".- " + cleanText;
    } else if (xblock.true_block_type === "dropdown") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xblock.data, 'text/html');
        const optionResponse = doc.querySelector('optionresponse');
        if (optionResponse) {
            const div = document.createElement('div');
            div.innerHTML = optionResponse.innerHTML;
            optionResponse.replaceWith(div);
        }
        const optionInputs = doc.querySelectorAll('optioninput');
        optionInputs.forEach(optionInput => {
            const select = document.createElement('select');
            select.style.display = 'inline';
            const options = optionInput.querySelectorAll('option');
            options.forEach(option => {
                const selectOption = document.createElement('option');
                selectOption.value = option.getAttribute('correct') === 'True' ? 'true' : 'false';
                selectOption.textContent = option.textContent;
                select.appendChild(selectOption);
            });
            optionInput.replaceWith(select);
        });
        return doc.body.innerHTML;
    } else if (xblock.true_block_type === "dialogsquestionsxblock" || xblock.true_block_type === "eoldialogs") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xblock.text, 'text/html');

        // Replace .dropdowndialogo with <select>
        const dropdowns = doc.querySelectorAll('.dropdowndialogo');
        dropdowns.forEach(span => {
            const textContent = span.textContent;

            // Parse the options
            const options = textContent.split(',').map(option => {
                const isSelected = option.startsWith('(') && option.endsWith(')');
                const cleanOption = option.replace(/[()]/g, '').trim(); // Remove parentheses
                return { value: cleanOption, isSelected };
            });

            // Create a <select> element
            const select = document.createElement('select');
            select.style.display = 'inline';

            // Add <option> elements to the <select>
            options.forEach(({ value }) => {
                const option = document.createElement('option');
                option.textContent = value;
                option.value = value;
                select.appendChild(option);
            });

            // Explicitly set the selected option after all <option> elements are added
            const selectedOptionIndex = options.findIndex(option => option.isSelected);
            if (selectedOptionIndex !== -1) {
                select.selectedIndex = selectedOptionIndex;
            }

            // Replace the span with the select
            span.replaceWith(select);
        });

        // Replace .inputdialogo with <input>
        const inputs = doc.querySelectorAll('.inputdialogo');
        inputs.forEach(span => {
            const input = document.createElement('input');
            input.type = 'text';
            input.style.display = 'inline';
            input.value = span.textContent.trim(); // Use the span's content as the input's value
            input.disabled = true; // Disable the input element
            span.replaceWith(input);
        });

        return doc.body.innerHTML;


    } else if (xblock.true_block_type === "checkboxes") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xblock.data, "text/html");

        // Parse the checkboxgroup
        const checkboxGroup = doc.querySelector("checkboxgroup");
        const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];

        // Extract choice data
        const choiceData = choices.map((choice) => ({
            text: choice.innerHTML.trim(), // Includes any HTML like <img>
            isCorrect: choice.getAttribute("correct") === "true",
        }));
        return choiceData;
    } else if (xblock.true_block_type === "multiplechoice") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xblock.data, "text/html");

        // Parse the checkboxgroup
        const checkboxGroup = doc.querySelector("choicegroup");
        const choices = checkboxGroup ? Array.from(checkboxGroup.querySelectorAll("choice")) : [];

        // Extract choice data
        const choiceData = choices.map((choice) => ({
            text: choice.innerHTML.trim(), // Includes any HTML like <img>
            isCorrect: choice.getAttribute("correct") === "true",
        }));
        return choiceData;
    } else if (xblock.true_block_type === "radiomultiplechoice") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xblock.data, "text/html");
        const radioGroup = doc.querySelector("radiogroup");
        const choices = radioGroup ? Array.from(radioGroup.querySelectorAll("choice")) : [];
        const choiceData = choices.map((choice) => ({
            text: choice.innerHTML.trim(), // Includes any HTML like <img>
            isCorrect: choice.getAttribute("correct") === "true",
        }));
        return choiceData;
    } else if (xblock.true_block_type === "openassessment" || xblock.true_block_type === "freetextresponse") {
        return "<textarea type='text' disabled='true' style='width: 100%; height: 100px;'></textarea>";
    } else if (xblock.true_block_type === "eollistgrade") {
        return "";
    } else if (xblock.true_block_type === "eolzoom") {
        return xblock.zoom_url;
    } else if (xblock.true_block_type === "iterativexblock") {
        try {
            const grid = JSON.parse(xblock.data.grid_structure);
            return {
                content: grid.content,
                trueGrid: grid.grid,
                style: grid.style,
            };
        } catch (error) {
            return null;
        }
    } else if (xblock.true_block_type === "table_advanced_problem") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xblock.data, "text/html");
        
        // Keep the table structure but replace checkboxgroups with disabled checkboxes
        const checkboxgroups = doc.querySelectorAll('checkboxgroup');
        checkboxgroups.forEach(group => {
            const choices = Array.from(group.querySelectorAll('choice'));
            const div = document.createElement('div');
            div.style.display = group.style.display || 'block';
            
            choices.forEach(choice => {
                const label = document.createElement('label');
                const checkbox = document.createElement('input');
                checkbox.type = 'checkbox';
                checkbox.disabled = true;
                checkbox.checked = choice.getAttribute('correct') === 'true';
                label.appendChild(checkbox);
                label.appendChild(document.createTextNode(' ' + choice.textContent));
                div.appendChild(label);
                if (group.style.display === 'flex') {
                    label.style.marginRight = '10px';
                }
            });
            
            group.replaceWith(div);
        });
        
        return doc.body.innerHTML;
    } else {
        const htmlFileUrl = xblock.data.match(/html_file="([^"]+)"/)?.[1];
        return htmlFileUrl;
    }
}

export const renderXblock = (xblock, showDetails, handleXblockDetailsModalOpen, titlesAndDividers) => {
    return (
        <Box key={xblock.block_key} mt={"20px"} >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"} mb={"20px"}>
                <Box width={"calc(100% - 60px)"}>
                    {titlesAndDividers &&
                        <Typography mb={"10px"} variant={"p"}>{mapXblockTypes(xblock.true_block_type, xblock.type)}</Typography>
                    }
                    {(xblock.true_block_type === "text" || xblock.true_block_type === "html" || xblock.true_block_type === "eolquestion" || xblock.true_block_type === "eolcontainer") ?
                        <Typography variant={"p-small"}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    getBlockSubdata(xblock).replace(
                                        /<img/g,
                                        '<img style="max-width:100%;height:auto"'
                                    )
                                )
                            }}
                        />
                        : (xblock.true_block_type === "eoldialogs" || xblock.true_block_type === "dialogsquestionsxblock") ?
                            <Box display={"flex"} gap={"20px"}>
                                <img src={xblock.image_url} alt={xblock.character_name} style={{ "maxWidth": "100px", "height": "auto" }} />
                                <Box>
                                    {/* FIX!!! WRONG ANSWER SHOWN */}
                                    <Typography variant={"p-small"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getBlockSubdata(xblock)) }} />
                                </Box>
                            </Box>
                            : (xblock.true_block_type === "vof") ?
                                <Box>
                                    {Object.entries(xblock.questions).map(([key, value]) => (
                                        <Typography key={key} className="vof-question" variant={"p-small"} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(key + ".- " + (value.valor ? "[V]" : "[F]") + " " + value.enunciado) }} />
                                    ))}
                                </Box>
                                : (xblock.true_block_type === "dropdown") ?
                                    <Typography variant={"p-small"}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                getBlockSubdata(xblock)
                                            ).replace(
                                                /<img/g,
                                                '<img style="max-width:100%;height:auto"'
                                            )
                                        }}
                                    />
                                    : (xblock.true_block_type === "openassessment" || xblock.true_block_type === "freetextresponse") ?
                                        <Box>
                                            <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getBlockSubdata(xblock)) }} />
                                        </Box>
                                        : (xblock.true_block_type === "checkboxes" || xblock.true_block_type === "multiplechoice" || xblock.true_block_type === "radiomultiplechoice") ?
                                            <Box>
                                                {getBlockSubdata(xblock).map((choice, index) => (
                                                    <Box key={index} display="flex" alignItems="center" mb={1}>
                                                        <input type={xblock.true_block_type === "checkboxes" ? "checkbox" : "radio"} disabled={true} id={`choice-${index}`} checked={choice.isCorrect} />
                                                        <Typography
                                                            variant='p-small'
                                                            htmlFor={`choice-${index}`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(choice.text).replace(
                                                                    /<img/g,
                                                                    '<img style="max-width:100%;height:auto"'
                                                                )
                                                            }}
                                                            style={{ marginLeft: "8px" }}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                            : (xblock.true_block_type === "eollistgrade") ?
                                                <Typography variant={"p-small"}>{getBlockSubdata(xblock)}</Typography>
                                                : (xblock.true_block_type === "clase3") ?
                                                    <iframe
                                                        title={xblock.block_key}
                                                        src={getBlockSubdata(xblock)}
                                                        width="100%"
                                                        height="500px"
                                                    />
                                                    : (xblock.true_block_type === "eolzoom") ?
                                                        <Link sx={{ color: "#d23831" }} href={getBlockSubdata(xblock)} target="_blank">
                                                            <Typography color={"#d23831"} variant={"p-small"}>{getBlockSubdata(xblock)}</Typography>
                                                        </Link>
                                                        : (xblock.true_block_type !== "iterativexblock") ?
                                                            <Typography variant={"p-small"}>{getBlockSubdata(xblock)}</Typography>
                                                            :
                                                            null
                    }
                </Box>
                {showDetails &&
                    <Box width={"60px"}>
                        <IconButton onClick={() => handleXblockDetailsModalOpen(xblock)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Box>
                }
            </Box>

            {xblock.true_block_type === "iterativexblock" ?
                <Box>
                    {(() => {
                        const data = getBlockSubdata(xblock);
                        if (!data) return <Typography variant='p-small' textAlign={'center'} color={'red'}>Error al cargar Iterativo.</Typography>;

                        const gridTemplateAreas = data.trueGrid.map(line => {
                            // Filter out empty cells and join the remaining ones with spaces
                            const cleanLine = line.filter(cell => cell).join(" ");
                            // If the line is empty after filtering, return "." to maintain grid structure
                            return `"${cleanLine || '.'}"`;
                        }).join("\n");

                        const gridStyles = {
                            display: 'grid',
                            gridTemplateAreas: gridTemplateAreas,
                            gap: '1px',
                            border: data.gridlines ? '1px solid #ccc' : 'none'
                        };

                        return (
                            <Box className={`iterative-xblock iterative-xblock-style-${data.style}`}>
                                <Box sx={gridStyles}>
                                    {Object.entries(data.content).map(([cellKey, cellValue]) => {
                                        const cellStyles = {
                                            gridArea: cellKey.slice(5),
                                            padding: cellValue.type === 'text' ? '1em' : 0,
                                            display: 'flex',
                                            borderLeft: cellValue.format?.border_left ? `${cellValue.format.border_bold ? '3px' : '1px'} solid #000` : 'none',
                                            borderRight: cellValue.format?.border_right ? `${cellValue.format.border_bold ? '3px' : '1px'} solid #000` : 'none',
                                            borderTop: cellValue.format?.border_top ? `${cellValue.format.border_bold ? '3px' : '1px'} solid #000` : 'none',
                                            borderBottom: cellValue.format?.border_bottom ? `${cellValue.format.border_bold ? '3px' : '1px'} solid #000` : 'none',
                                            justifyContent: cellValue.format?.horizontal_align === 'center' ? 'center' :
                                                cellValue.format?.horizontal_align === 'right' ? 'flex-end' : 'flex-start',
                                            alignItems: cellValue.format?.vertical_align === 'middle' ? 'center' :
                                                cellValue.format?.vertical_align === 'bottom' ? 'flex-end' : 'flex-start',
                                            backgroundColor: cellValue.format?.background_color || '#ffffff',
                                            color: cellValue.format?.text_color || '#000000'
                                        };

                                        const textStyles = {
                                            fontWeight: cellValue.format?.bold ? 'bold' : 'normal',
                                            fontStyle: cellValue.format?.italic ? 'italic' : 'normal',
                                            textDecoration: cellValue.format?.underline ? 'underline' : 'none',
                                            textAlign: cellValue.format?.horizontal_align || 'left',
                                            margin: 0
                                        };

                                        return (
                                            <Box key={cellKey} sx={cellStyles}>
                                                {cellValue.type === 'text' && (
                                                    <Typography sx={textStyles}>{cellValue.content}</Typography>
                                                )}
                                                {cellValue.type === 'question' && (
                                                    <textarea
                                                        disabled
                                                        style={{
                                                            width: '100%',
                                                            ...textStyles
                                                        }}
                                                        value={cellValue.content}
                                                    />
                                                )}
                                                {cellValue.type === 'answer' && (
                                                    <textarea
                                                        disabled
                                                        style={{
                                                            width: '100%',
                                                            ...textStyles
                                                        }}
                                                        value={"Respuesta anterior:\n" + cellValue.content}
                                                    />
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );
                    })()}
                </Box>
                :
                null
            }
            {titlesAndDividers &&
                <Divider />
            }
        </Box>
    )
}

const cleanText = (text) => {
    if (!text) return '';
    return text
        .trim()
        .replace(/[\t\n\r]+/g, ' ')   // Replace tabs, newlines, and carriage returns with a space
        .replace(/\s+/g, ' ')         // Replace multiple spaces with a single space
        .replace(/Ã\u0093/g, 'Ó')     // Fix accented uppercase O
        .replace(/Ã\u0091/g, 'Ñ')     // Fix uppercase Ñ
        .replace(/Ã\u00c1/g, 'Á')     // Fix accented uppercase A
        .replace(/Ã\u00c9/g, 'É')     // Fix accented uppercase E
        .replace(/Ã\u00cd/g, 'Í')     // Fix accented uppercase I
        .replace(/Ã\u00da/g, 'Ú')     // Fix accented uppercase U
        .replace(/Ã³/g, 'ó')          // Fix accented lowercase o
        .replace(/Ã¡/g, 'á')          // Fix accented lowercase a
        .replace(/Ã©/g, 'é')          // Fix accented lowercase e
        .replace(/Ã­/g, 'í')          // Fix accented lowercase i
        .replace(/Ãº/g, 'ú')          // Fix accented lowercase u
        .replace(/â/g, '"')
        .replace(/Ã±/g, 'ñ')          // Fix lowercase ñ
        .replace(/Â/g, '')            // Remove unwanted Â characters
        .replace(/Â°/g, '°')          // Fix degree symbol
        .replace(/°/g, '°');          // Ensure degree symbol is properly encoded
};

export const parseSurveyContents = async (keycloak, xblocks) => {
    let newContents = [];
    for (let xblock of xblocks) {
        const isEncuestaClase3 = xblock.display_name.includes("Custom JavaScript Display and Grading");
        if (isEncuestaClase3) {
            const htmlFile = xblock.data.match(/html_file="([^"]+)"/)?.[1];
            let htmlContent = await postGetSurveyContents(keycloak.token, htmlFile);

            if (htmlContent.content) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent.content, 'text/html');

                const title = cleanText(doc.querySelector('.tituloPagina')?.textContent);

                const sections = [];

                let currentSection = null;

                // Get all relevant elements including divPregs
                const allElements = Array.from(doc.querySelectorAll('.setPreguntas, .tablagrande, .divPregs'));
                allElements.forEach(element => {
                    if (element.classList.contains('setPreguntas') || element.classList.contains('divPregs')) {
                        // Handle setPreguntas and divPregs similarly
                        const preguntasDiv = element.querySelector('.preguntas');
                        if (preguntasDiv) {
                            const numero = preguntasDiv.querySelector('.numero')?.textContent?.trim();
                            const enunciado = cleanText(preguntasDiv.querySelector('.enunciado')?.textContent);

                            // If this setPregunta contains a complete question (textarea, text input, number input, or checkboxes)
                            const textarea = element.querySelector('textarea');
                            const textInput = element.querySelector('input[type="text"]');
                            const numberInput = element.querySelector('input[type="number"]');
                            const choiceList = element.querySelector('.textoEjercicio');

                            if (textarea || textInput || numberInput || choiceList) {
                                // Handle complete single questions
                                if (textarea || textInput || numberInput) {
                                    sections.push({
                                        number: numero,
                                        enunciado: enunciado,
                                        subsections: [{
                                            number: 1,
                                            type: numberInput ? 'number' : 'openquestion',
                                            enunciado: '',
                                            code: element.getAttribute('codigo')
                                        }]
                                    });
                                } else if (choiceList) {
                                    const alternatives = [];
                                    choiceList.querySelectorAll('li').forEach(li => {
                                        alternatives.push({
                                            letra: cleanText(li.querySelector('.letra')?.textContent),
                                            text: cleanText(li.querySelector('label')?.textContent)
                                        });
                                    });

                                    // Determine if it's multiple choice or checkboxes
                                    const isCheckbox = choiceList.querySelector('ul.checklist');

                                    sections.push({
                                        number: numero,
                                        enunciado: enunciado,
                                        subsections: [{
                                            number: 1,
                                            type: isCheckbox ? 'checkboxes' : 'multiplechoice',
                                            enunciado: '',
                                            alternatives: alternatives,
                                            code: element.getAttribute('codigo')
                                        }]
                                    });
                                }
                                currentSection = null;
                            } else {
                                // This is potentially a parent enunciado for sliders/likert
                                if (enunciado) {
                                    currentSection = {
                                        number: numero,
                                        enunciado: enunciado,
                                        subsections: []
                                    };
                                    sections.push(currentSection);
                                }
                            }
                        } else {
                            // This setPregunta might be a slider without its own preguntas div
                            const slider = element.querySelector('div');
                            if (slider && currentSection && slider.id.includes('-slider')) {
                                const sliderEnunciado = cleanText(element.textContent);
                                currentSection.subsections.push({
                                    number: currentSection.subsections.length + 1,
                                    type: 'slider',
                                    enunciado: sliderEnunciado.split(": $(")[0].split("slider_code")[0].split(" $(")[0].trim(),
                                    code: element.getAttribute('codigo')
                                });
                            }
                        }
                    } else {
                        if (currentSection) {
                            element.querySelectorAll('.dtrowf').forEach(row => {
                                const likertEnunciado = cleanText(row.querySelector('.dtcell1')?.textContent);
                                const code = row.querySelector('.dtcell2 div')?.getAttribute('codigo');
                                let alternatives = [];
                                element.querySelectorAll('.dtcell2 span').forEach(span => {
                                    alternatives.push({
                                        letra: "",
                                        text: cleanText(span.textContent)
                                    });
                                });
                                currentSection.subsections.push({
                                    number: currentSection.subsections.length + 1,
                                    type: 'likert',
                                    enunciado: likertEnunciado,
                                    alternatives: alternatives,
                                    code: code
                                });
                            });
                        }
                    }
                });

                newContents.push({
                    id: xblock.id,
                    responses: [],
                    htmlFile: htmlFile,
                    title: title,
                    sections: sections
                });
            }
        }
    }
    return newContents;
}

export const calculateEvaluationGrade = (responses, scale) => {

    let totalPoints = 0;
    let maxPossiblePoints = 0;

    // If there are no responses at all, return null
    if (!responses || responses.length === 0) return null;

    // If all responses are null or empty, return null
    if (responses.every(response => response.response === null || response.response === undefined || response.response === "")) return null;

    responses.forEach(response => {
        const maxScore = response.is_vof ? 1.0 : 12.0;

        // Calculate points for this response
        let points = 0;
        if ('is_correct' in response && response.is_correct !== null && response.is_correct !== undefined) {
            points = response.is_correct ? maxScore : 0;
        } else if ('score' in response && response.score !== null && response.score !== undefined) {
            points = Math.min(response.score, maxScore);
        }
        // Note: if neither is_correct nor score exists, points remain 0

        totalPoints += points;
        maxPossiblePoints += maxScore;
    });

    if (maxPossiblePoints === 0) return 1.0;

    // Calculate percentage achieved
    const decimalScale = scale / 100;

    // Calculate grade (1.0 - 7.0 scale with 4.0 at scale%)
    if (totalPoints < decimalScale*maxPossiblePoints) {
        return (4.0 - 1.0) * (totalPoints / (decimalScale*maxPossiblePoints)) + 1.0;
    } else {
        // For percentage < scale: linear from 1.0 to 4.0
        return (7.0 - 4.0) * ((totalPoints - decimalScale*maxPossiblePoints) / (maxPossiblePoints*(1 - decimalScale))) + 4.0;
    }
};

export const calculateEvaluationStats = (contents, enrolledUsers, scale = 60) => {
    if (!contents || contents.length === 0 || !enrolledUsers || enrolledUsers.length === 0) {
        return {
            submittedCount: 0,
            averageGrade: 0,
            medianGrade: 0,
            stdDeviation: 0,
            maxGrade: 0,
            passedCount: 0,
            failedCount: 0
        };
    }

    // Get unique users who submitted at least one non empty response
    const userResponses = new Set();
    contents.forEach(content => {
        content.responses.forEach(response => {
            const user = enrolledUsers.find(u => u.user_id === response.user_id);
            if (user && response.response !== null && response.response !== undefined && response.response !== "") {
                userResponses.add(response.user_id);
            }
        });
    });

    // Calculate grades for each user
    const grades = Array.from(userResponses).map(userId => {
        // Get all responses for this user, ensuring we have an entry for each content
        const userResponses = contents.map(content => {
            // Find user's response for this content, or return empty response
            return content.responses.find(r => r.user_id === userId) || {
                score: 0,
                max_score: 12
            };
        });

        const userGrade = calculateEvaluationGrade(userResponses, scale);
        return userGrade;
    }).filter(grade => !isNaN(grade));

    // Sort grades for median calculation
    const sortedGrades = [...grades].sort((a, b) => a - b);

    return {
        submittedCount: userResponses.size,
        averageGrade: grades.length ? (grades.reduce((a, b) => a + b, 0) / grades.length).toFixed(1) : 0,
        medianGrade: grades.length ? (
            grades.length % 2 === 0
                ? ((sortedGrades[grades.length / 2 - 1] + sortedGrades[grades.length / 2]) / 2).toFixed(1)
                : sortedGrades[Math.floor(grades.length / 2)].toFixed(1)
        ) : 0,
        stdDeviation: grades.length ? calculateStdDeviation(grades).toFixed(1) : 0,
        maxGrade: grades.length ? Math.max(...grades).toFixed(1) : 0,
        passedCount: grades.filter(grade => grade >= 4.0).length,
        failedCount: grades.filter(grade => grade < 4.0).length
    };
};

const calculateStdDeviation = (grades) => {
    const mean = grades.reduce((a, b) => a + b, 0) / grades.length;
    const variance = grades.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / grades.length;
    return Math.sqrt(variance);
};


export const calculateFinalGrade = (grades, model) => {
    if (!grades || grades.length === 0) return 1.0;


    var grade = 0.0;
    if (model.controles_rate > 0) {
        grade += model.controles_rate * Math.average(Object.values(grades.controles));
    }
    if (model.preguntas_calificadas_rate > 0) {
        if (grades.tests["Preguntas Calificadas"]) {
            grade += model.preguntas_calificadas_rate * grades.tests["Preguntas Calificadas"];
        }
    }

    return grade;
}

export const calculateManualGradeStats = (contents, gradesType) => {
    // Return default values if no contents
    if (!contents) return {
        averageGrade: "—",
        medianGrade: "—",
        stdDeviation: "—",
        maxGrade: "—",
        submittedCount: 0,
        passedCount: 0,
        failedCount: 0
    };

    let grades = [];

    if (gradesType === "otros_unico") {
        // For otros_unico, just get the grades directly
        grades = contents["0"]?.map(entry => Number(entry.grade)) || [];
    } else {
        // For otros_multiple, calculate average grade per user
        const userGrades = {};
        const maxIndex = Math.max(...Object.keys(contents).map(Number));

        // Initialize all users with 1.0 for missing grades
        Object.values(contents).forEach(gradeList => {
            gradeList.forEach(entry => {
                if (!userGrades[entry.user_id]) {
                    userGrades[entry.user_id] = new Array(maxIndex).fill(1.0);
                }
            });
        });

        // Fill in actual grades
        Object.entries(contents).forEach(([index, gradeList]) => {
            gradeList.forEach(entry => {
                userGrades[entry.user_id][Number(index) - 1] = Number(entry.grade);
            });
        });

        // Calculate averages
        grades = Object.values(userGrades).map(userGradeList =>
            userGradeList.reduce((sum, grade) => sum + grade, 0) / userGradeList.length
        );
    }

    // Return default values if no grades
    if (grades.length === 0) {
        return {
            averageGrade: "—",
            medianGrade: "—",
            stdDeviation: "—",
            maxGrade: "—",
            submittedCount: 0,
            passedCount: 0,
            failedCount: 0
        };
    }

    // Calculate statistics
    const average = grades.reduce((sum, grade) => sum + grade, 0) / grades.length;
    const sortedGrades = [...grades].sort((a, b) => a - b);

    // Calculate median
    let median;
    if (sortedGrades.length % 2 === 0) {
        const mid = sortedGrades.length / 2;
        median = (sortedGrades[mid - 1] + sortedGrades[mid]) / 2;
    } else {
        median = sortedGrades[Math.floor(sortedGrades.length / 2)];
    }

    // Calculate other stats
    const variance = grades.reduce((sum, grade) => sum + Math.pow(grade - average, 2), 0) / grades.length;
    const stdDev = Math.sqrt(variance);
    const maxGrade = Math.max(...grades);
    const passedCount = grades.filter(grade => grade >= 4.0).length;

    return {
        averageGrade: average.toFixed(1),
        medianGrade: median.toFixed(1),
        stdDeviation: stdDev.toFixed(1),
        maxGrade: maxGrade.toFixed(1),
        submittedCount: grades.length,
        passedCount,
        failedCount: grades.length - passedCount
    };
};

export const parseEvaluationContents = (xblocks, isPreguntaCalificada, subsectionName) => {
    let newContents = [];
    let currentGroup = [];

    // For pregunta calificada, remove first HTML block
    if (isPreguntaCalificada) {
        let filteredXblocks = xblocks;
        if (xblocks.length > 0 && xblocks[0].block_type === 'html') {
            filteredXblocks = xblocks.slice(1);
        }

        // Get the last paragraph from the last question block
        const questionBlocks = filteredXblocks.slice(0, -1);
        const lastQuestionBlock = questionBlocks[questionBlocks.length - 1];
        let lastParagraph = "";

        if (lastQuestionBlock) {
            // Create a temporary div to parse HTML
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = lastQuestionBlock.text;
            const paragraphs = Array.from(tempDiv.getElementsByTagName('p'));

            // Find the last non-empty paragraph
            for (let i = paragraphs.length - 1; i >= 0; i--) {
                const paragraphText = paragraphs[i].textContent.trim();
                if (paragraphText) {
                    // Remove bold formatting if present
                    let innerHTML = paragraphs[i].innerHTML;
                    innerHTML = innerHTML.replace(/<\/?strong>/g, '').replace(/<\/?b>/g, '');
                    lastParagraph = innerHTML;
                    break;
                }
            }
        }

        let mergedItem = {
            id: filteredXblocks[filteredXblocks.length - 1].id,
            question: filteredXblocks.slice(0, -1).map(block => ({
                ...block,
                subsection_name: subsectionName,
                last_paragraph: lastParagraph
            })),
            content: filteredXblocks[filteredXblocks.length - 1],
            responses: []
        };
        newContents.push(mergedItem);
        return newContents;
    }

    // For controles and tests, only remove first HTML block from first question
    let isFirstQuestion = true;
    let currentXblocks = xblocks;

    // Process blocks sequentially
    for (let i = 0; i < currentXblocks.length; i++) {
        const block = currentXblocks[i];

        // If block is html, text, eolquestion or eolcontainer, add to current group
        if (['html', 'text', 'eolquestion', 'eoldialogs', 'eolcontainer'].includes(block.block_type)) {
            // Skip first HTML block only for first question
            if (isFirstQuestion && currentGroup.length === 0 && block.block_type === 'html') {
                continue;
            }
            currentGroup.push(block);
        } else {
            // If we have a group with an eolquestion and we found a different block type
            if (currentGroup.some(b => b.block_type === 'eolquestion')) {
                // Create merged item with all accumulated blocks and the content block
                let mergedItem = {
                    id: block.id,
                    question: currentGroup,
                    content: block,
                    responses: []
                };
                newContents.push(mergedItem);
                isFirstQuestion = false;
            }
            // Reset the group for next iteration
            currentGroup = [];
        }
    }

    return newContents;
}