import React, { useState } from 'react';
import { Grid, MenuItem, TextField, Dialog, DialogContent, DialogTitle, IconButton, Button, List, ListItem, IconButton as MuiIconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { postCourseImplementation } from '../../../../../../requests/catalog/postCourseImplementation';
import DeleteIcon from '@mui/icons-material/Delete';


const CatalogCourseImplementationCreateModal = ({
    keycloak,
    open, 
    handleClose,
    catalog,
    setCatalog,
    courseIndex,
    otherImplementations,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [implementationYear, setImplementationYear] = useState("");
    const [implementationTerm, setImplementationTerm] = useState("none");
    const [implementationType, setImplementationType] = useState("none");
    const [implementationPlatform, setImplementationPlatform] = useState("none");
    const [errors, setErrors] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const [gradingItems, setGradingItems] = useState([]);
    const [newGradingType, setNewGradingType] = useState('none');
    const [newGradingValue, setNewGradingValue] = useState('');
    const [newGradingName, setNewGradingName] = useState('');
    const [scale, setScale] = useState('');

    const gradingTypes = [
        { value: 'autoinstruccional', label: 'Curso autoinstruccional' },
        { value: 'controles', label: 'Controles' },
        { value: 'preguntas', label: 'Preguntas calificadas' },
        { value: 'plataforma', label: 'Avance en plataforma' },
        { value: 'otros_unico', label: 'Otros (nota única)' },
        { value: 'otros_multiple', label: 'Otros (notas múltiples)' }
    ];

    const validateForm = () => {
        let errors = {};
        if (implementationYear === "") {
            errors.implementationYear = "Por favor ingrese un año.";
        } else if (isNaN(implementationYear)) {
            errors.implementationYear = "Por favor ingrese un año válido.";
        } else if (implementationYear.length !== 4) {
            errors.implementationYear = "Por favor ingrese un año válido.";
        }
        if (implementationTerm === "none") {
            errors.implementationTerm = "Por favor seleccione un semestre.";
        }
        if (implementationType === "none") {
            errors.implementationType = "Por favor seleccione una modalidad.";
        }
        if (implementationPlatform === "none") {
            errors.implementationPlatform = "Por favor seleccione una plataforma.";
        }
        if (otherImplementations.some(impl => impl.year === implementationYear && impl.term === implementationTerm && impl.type === implementationType)) {
            errors.implementationYear = "Ya existe una implementación con estos datos.";
            errors.implementationTerm = "Ya existe una implementación con estos datos.";
            errors.implementationType = "Ya existe una implementación con estos datos.";
        }
        if (gradingItems.length === 0) {
            errors.grading = "Debe agregar al menos un ítem de evaluación.";
        } else if (gradingItems.length > 7) {
            errors.grading = "No puede tener más de 7 categorías.";
        } else {
            const hasAutoinstruccional = gradingItems.some(item => item.type === 'autoinstruccional');
            if (hasAutoinstruccional && gradingItems.length > 1) {
                errors.grading = "Curso autoinstruccional no puede combinarse con otras categorías.";
            } else if (!hasAutoinstruccional) {
                const totalValue = gradingItems.reduce((sum, item) => sum + Number(item.value), 0);
                if (totalValue !== 100) {
                    errors.grading = "La suma de ponderaciones debe ser 100%.";
                }
            }
        }
        const hasGradedItems = gradingItems.some(item => 
            item.type === 'controles' || item.type === 'preguntas'
        );
        
        if (hasGradedItems) {
            if (!scale) {
                errors.scale = "Por favor ingrese una escala de notas.";
            } else if (isNaN(scale) || scale < 0 || scale > 100 || !Number.isInteger(Number(scale))) {
                errors.scale = "La escala debe ser un número entero entre 0 y 100.";
            }
        }
        
        return errors;
    }
            
    const handleAddGradingItem = () => {
        if (newGradingType === 'none') return;
        if (newGradingType !== 'autoinstruccional' && !newGradingValue) return;
        
        // Validate maximum categories
        if (gradingItems.length >= 7) {
            setErrors(prev => ({ ...prev, grading: "No puede agregar más de 7 categorías." }));
            return;
        }

        // Validate otros name
        if (['otros_unico', 'otros_multiple'].includes(newGradingType)) {
            if (!newGradingName || newGradingName.length < 3 || newGradingName.length > 50) {
                setErrors(prev => ({ ...prev, grading: "El nombre para 'Otros' debe tener entre 3 y 50 caracteres." }));
                return;
            }
        }

        const newItem = {
            type: newGradingType,
            value: newGradingType === 'autoinstruccional' ? 100 : Number(newGradingValue),
            name: ['otros_unico', 'otros_multiple'].includes(newGradingType) ? newGradingName : null
        };

        setGradingItems([...gradingItems, newItem]);
        setNewGradingType('none');
        setNewGradingValue('');
        setNewGradingName('');
        setErrors(prev => ({ ...prev, grading: "" }));
    };

    const handleRemoveGradingItem = (index) => {
        setGradingItems(gradingItems.filter((_, i) => i !== index));
    };

    const shouldShowGradingForm = () => {
        const hasAutoinstruccional = gradingItems.some(item => item.type === 'autoinstruccional');
        return !hasAutoinstruccional;
    };

    const getAvailableGradingTypes = () => {
        if (gradingItems.length === 0) {
            return gradingTypes;
        }

        // If we have any non-autoinstruccional items, filter out autoinstruccional
        if (gradingItems.some(item => item.type !== 'autoinstruccional')) {
            const usedTypes = gradingItems.map(item => item.type);
            
            return gradingTypes.filter(type => {
                // Filter out autoinstruccional
                if (type.value === 'autoinstruccional') return false;
                
                // Filter out already used single-use categories
                if (['controles', 'preguntas', 'plataforma'].includes(type.value)) {
                    return !usedTypes.includes(type.value);
                }
                
                // Always show otros options
                return true;
            });
        }

        return gradingTypes;
    };

    const shouldShowScaleField = () => {
        return gradingItems.some(item => 
            item.type === 'controles' || item.type === 'preguntas'
        );
    };

    const handleCreateImplementation = async () => {
        setErrors({});
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let createData = {
            "course_id": catalog.courses[courseIndex]._id,
            "year": implementationYear,
            "term": implementationTerm,
            "type": implementationType,
            "platform": implementationPlatform,
            "grading": {
                "autoinstruccional": gradingItems.some(item => item.type === 'autoinstruccional'),
                "scale": gradingItems.some(item => 
                    item.type === 'controles' || item.type === 'preguntas'
                ) ? Number(scale) : 0,
                "avance_rate": gradingItems.find(item => item.type === 'plataforma')?.value || 0,
                "controles_rate": gradingItems.find(item => item.type === 'controles')?.value || 0,
                "preguntas_calificadas_rate": gradingItems.find(item => item.type === 'preguntas')?.value || 0,
                "other_rates": gradingItems.filter(item => 
                    item.type !== 'autoinstruccional' && 
                    item.type !== 'controles' && 
                    item.type !== 'preguntas' && 
                    item.type !== 'plataforma'
                ).map(item => ({
                    name: item.name,
                    value: item.value,
                    type: item.type
                }))
            }
        }
        let response = await postCourseImplementation(keycloak.token, createData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear implementación", "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setImplementationYear("");
            setImplementationTerm("none");
            setImplementationType("none");
            setImplementationPlatform("none");
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Implementación creada!`, "text": `La nueva implementación del curso ${catalog.courses[courseIndex].shortname} se ha creado correctamente.` } });
        }
        setFormLoading(false);
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Nueva implementación de {catalog.courses[courseIndex].shortname}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='p' fontWeight={"bold"} mb={"0px"}>Datos de la implementación</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Año"
                            variant="outlined"
                            value={implementationYear}
                            onChange={(e) => {
                                setImplementationYear(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationYear: "" }))
                            }}
                            error={!!errors.implementationYear}
                            helperText={errors.implementationYear}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Semestre"
                            variant="outlined"
                            select
                            value={implementationTerm}
                            onChange={(e) => {
                                setImplementationTerm(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationTerm: "" }))
                            }}
                            error={!!errors.implementationTerm}
                            helperText={errors.implementationTerm}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"0"}>Anual</MenuItem>
                            <MenuItem value={"1"}>Primer semestre</MenuItem>
                            <MenuItem value={"2"}>Segundo semestre</MenuItem>
                            <MenuItem value={"3"}>Verano</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Modalidad"
                            variant="outlined"
                            select
                            value={implementationType}
                            onChange={(e) => {
                                setImplementationType(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationType: "" }))
                            }}
                            error={!!errors.implementationType}
                            helperText={errors.implementationType}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"b-learning"}>b-learning</MenuItem>
                            <MenuItem value={"e-learning"}>e-learning</MenuItem>
                            <MenuItem value={"otros"}>Otros</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Plataforma"
                            variant="outlined"
                            select
                            value={implementationPlatform}
                            onChange={(e) => {
                                setImplementationPlatform(e.target.value)
                                setErrors(prevErrors => ({ ...prevErrors, implementationPlatform: "" }))
                            }}
                            error={!!errors.implementationPlatform}
                            helperText={errors.implementationPlatform}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"moodle"}>Moodle</MenuItem>
                            <MenuItem value={"u-cursos"}>U-Cursos</MenuItem>
                            <MenuItem value={"cmmeduformacion"}>Open edX (CMMEdu Formación)</MenuItem>
                            <MenuItem value={"redfid"}>Open edX (RedFID)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='p' fontWeight={"bold"} mt={"20px"}>Modelo de evaluación</Typography>
                    </Grid>
                    {shouldShowGradingForm() && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Tipo de evaluación"
                                        value={newGradingType}
                                        onChange={(e) => setNewGradingType(e.target.value)}
                                        disabled={formLoading}
                                    >
                                        <MenuItem disabled value="none">Seleccione una opción...</MenuItem>
                                        {getAvailableGradingTypes().map(type => (
                                            <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {['otros_unico', 'otros_multiple'].includes(newGradingType) && (
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            value={newGradingName}
                                            onChange={(e) => setNewGradingName(e.target.value)}
                                            disabled={formLoading}
                                        />
                                    </Grid>
                                )}
                                {newGradingType !== 'autoinstruccional' && (
                                    <Grid item xs={12} md={['otros_unico', 'otros_multiple'].includes(newGradingType) ? 2 : 4}>
                                        <TextField
                                            fullWidth
                                            label="Ponderación"
                                            type="number"
                                            value={newGradingValue}
                                            onChange={(e) => setNewGradingValue(e.target.value)}
                                            disabled={formLoading}
                                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        variant="red"
                                        onClick={handleAddGradingItem}
                                        disabled={formLoading || 
                                                newGradingType === 'none' || 
                                                (newGradingType !== 'autoinstruccional' && !newGradingValue)}
                                        fullWidth
                                    >
                                        Agregar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <List>
                            {gradingItems.map((item, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <MuiIconButton edge="end" onClick={() => handleRemoveGradingItem(index)}>
                                            <DeleteIcon />
                                        </MuiIconButton>
                                    }
                                >
                                    {item.type === 'autoinstruccional' ? 
                                        gradingTypes.find(t => t.value === item.type)?.label :
                                        `${gradingTypes.find(t => t.value === item.type)?.label}${item.name ? ` - ${item.name}` : ''}: ${item.value}%`
                                    }
                                </ListItem>
                            ))}
                        </List>
                        {errors.grading && (
                            <Typography variant='p-error' mt={0} ml={1}>{errors.grading}</Typography>
                        )}
                    </Grid>
                    {shouldShowScaleField() && (
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                label="Exigencia de evaluación"
                                type="number"
                                value={scale}
                                onChange={(e) => {
                                    setScale(e.target.value);
                                    setErrors(prevErrors => ({ ...prevErrors, scale: "" }));
                                }}
                                error={!!errors.scale}
                                helperText={errors.scale}
                                disabled={formLoading}
                                InputProps={{ 
                                    inputProps: { 
                                        min: 0, 
                                        max: 100, 
                                        step: 1 
                                    } 
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<AddCircleOutlineIcon />} variant='blue' onClick={handleCreateImplementation} disabled={formLoading}>
                            Crear implementación
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CatalogCourseImplementationCreateModal;