import axios from 'axios';

export const postGetManualMultiGrades = async (token, courseKey, courseType) => {

    try {

        const response = await axios.post(process.env.REACT_APP_API_URL + "/get_manual_multi_grades", {course_key: courseKey, course_type: courseType}, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });

        return { data: response.data, error: "" };

    } catch (error) {
        return { data: null, error: "Error desconocido: " + error.message };
    }
}