import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import EvaluacionQuestionDetailsModal from '../modals/EvaluacionQuestionDetailsModal';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { renderXblock } from '../../../utils/formatters';
import { MathJax } from 'better-react-mathjax';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const PreTestPostTestTable = ({
    questions,
    enrolledUsers,
    isPreguntasCalificadas,
    title,
    identifier,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess,
    isSummary
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: isSummary ? 6 : 10, page: 0 });
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const [detailsModal, setDetailsModal] = useState(false);

    const handleDownloadCalificacionesCSV = () => {
        let header = [
            "Número",
            "Enunciado",
            "Respuestas totales",
            "Tasa de correctas pre-test",
            "Tasa de correctas post-test",
            "Diferencia"
        ];
        let lines = [];
        let output = [];

        for (let question of questions) {
            var thisLine = [];
            // Get question number
            thisLine.push(question.question?.find(block => block.block_type === "eolquestion")?.index || "");

            // Get question text
            const text = question.question?.find(block => block.block_type === "eolquestion")?.text || "";
            const decodedText = document.createElement('textarea');
            decodedText.innerHTML = text;
            thisLine.push(decodedText.value);

            // Get total responses (using preTest as reference since they should match)
            thisLine.push(question.matchingContent.preTest.responses.length);

            // Calculate pre-test correct rate
            const preTestRate = calculateCorrectRate(question.matchingContent.preTest);
            thisLine.push(preTestRate === "—" ? "—" : preTestRate);

            // Calculate post-test correct rate
            const postTestRate = calculateCorrectRate(question.matchingContent.postTest);
            thisLine.push(postTestRate === "—" ? "—" : postTestRate);

            // Calculate difference (if both rates are numbers)
            if (preTestRate !== "—" && postTestRate !== "—") {
                const preRate = parseFloat(preTestRate);
                const postRate = parseFloat(postTestRate);
                const difference = (postRate - preRate).toFixed(1);
                thisLine.push(`${difference > 0 ? '+' : ''}${difference}%`);
            } else {
                thisLine.push("—");
            }

            lines.push(thisLine);
        }

        output.push(header);
        for (let line of lines) {
            output.push(line);
        }

        const fileName = "evaluaciones_pretest_posttest_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");

        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const renderMultipleXBlocks = (xblocks) => {
        let renderedXblocks = [];
        for (let xblock of xblocks.full_pre_question) {
            renderedXblocks.push(<MathJax>{renderXblock(xblock, false, null, false)}</MathJax>);
        }
        renderedXblocks.push(<MathJax>{renderXblock(xblocks.full_question, false, null, false)}</MathJax>);
        return renderedXblocks;
    }

    const columns = [
        {
            field: 'number',
            disableColumnMenu: true,
            headerName: 'Número',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        }
    ];
    if (!isSummary) {
        columns.push(
            {
                field: 'question',
                disableColumnMenu: true,
                headerName: 'Enunciado',
                sortable: false,
                width: 480,
                headerAlign: 'center',
                align: 'left',
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" height="100%">
                        <MathJax>
                            <div
                                style={{ maxWidth: '100%' }}
                                dangerouslySetInnerHTML={{
                                    __html: params.value.replace(
                                        /<img/g,
                                        '<img style="max-width:100%;height:auto"'
                                    )
                                }}
                            />
                        </MathJax>
                    </Box>
                ),
            }
        );
    }
    columns.push(
        {
            field: 'correct_rate_1',
            disableColumnMenu: true,
            headerName: 'Pre-test',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        }
    );
    columns.push(
        {
            field: 'correct_rate_2',
            disableColumnMenu: true,
            headerName: 'Post-test',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        }
    );
    columns.push(
        {
            field: 'difference',
            disableColumnMenu: true,
            headerName: 'Diferencia',
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.value === "—") return "—";
                const difference = parseFloat(params.value);
                return (
                    <Typography variant='p'
                        sx={{
                            color: difference >= 0 ? 'green' : '#D23831'
                        }}
                    >
                        {difference >= 0 ? `+${difference}%` : `${difference}%`}
                    </Typography>
                );
            }
        }
    );
    columns.push(
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Detalles',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={() => { setSelectedQuestion(params.row); setDetailsModal(true) }}
                >
                    <MoreVertIcon />
                </IconButton>
            ),
        }
    );

    function calculateCorrectRate(question) {
        if (!question.responses || question.responses.length === 0) {
            return "—";
        }
        if (question.content.true_block_type === "openassessment" || question.content.true_block_type === "freetextresponse") {
            return "—";
        }
        let correctCount = 0;
        let totalResponses = question.responses.filter(response => response.response !== "").length;
        for (let response of question.responses) {
            if (response.is_correct) {
                correctCount++;
            }
        }
        const percentage = (correctCount / totalResponses * 100).toFixed(1);
        return `${percentage}%`;
    }

    function makeRows() {
        let rows = [];
        for (let question of questions) {
            const preTestRate = calculateCorrectRate(question.matchingContent.preTest);
            const postTestRate = calculateCorrectRate(question.matchingContent.postTest);

            // Calculate difference
            let difference = "—";
            if (preTestRate !== "—" && postTestRate !== "—") {
                const preRate = parseFloat(preTestRate);
                const postRate = parseFloat(postTestRate);
                difference = (postRate - preRate).toFixed(1);
            }

            rows.push({
                id: question.id,
                number: question.question?.find(block => block.block_type === "eolquestion")?.index ? question.question.find(block => block.block_type === "eolquestion").index : "",
                question: question.question?.find(block => block.block_type === "eolquestion")?.text ? question.question.find(block => block.block_type === "eolquestion").text : "",
                correct_rate_1: preTestRate,
                correct_rate_2: postTestRate,
                difference: difference,
                answers: (() => {
                    const preResponses = question.matchingContent.preTest.responses;
                    const postResponses = question.matchingContent.postTest.responses;

                    // Create maps for faster lookup
                    const preMap = new Map(preResponses.map(r => [`${r.edx_block_id}-${r.user_id}`, r]));
                    const postMap = new Map(postResponses.map(r => [`${r.edx_block_id}-${r.user_id}`, r]));

                    // Get all unique block-user pairs
                    const allPairs = new Set([
                        ...preResponses.map(r => `${r.edx_block_id}-${r.user_id}`),
                        ...postResponses.map(r => `${r.edx_block_id}-${r.user_id}`)
                    ]);

                    // Create normalized arrays
                    const normalizedPre = [];
                    const normalizedPost = [];

                    allPairs.forEach(pair => {
                        const preResponse = preMap.get(pair) || {
                            id: `dummy-pre-${pair}`,
                            created: null,
                            response: "",
                            is_correct: null,
                            edx_block_id: pair.split('-')[0],
                            user_id: pair.split('-')[1]
                        };

                        const postResponse = postMap.get(pair) || {
                            id: `dummy-post-${pair}`,
                            created: null,
                            response: "",
                            is_correct: null,
                            edx_block_id: pair.split('-')[0],
                            user_id: pair.split('-')[1]
                        };

                        normalizedPre.push(preResponse);
                        normalizedPost.push(postResponse);
                    });

                    return {
                        preTest: normalizedPre,
                        postTest: normalizedPost
                    };
                })(),
                full_pre_question: question.question,
                full_question: question.content
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Pre-test — Post-test</Typography>
                <Box>
                    <IconButton onClick={handleDownloadCalificacionesCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(questions).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p-error' textAlign={"center"}>No se encontraron preguntas para comparar.</Typography>
            </Box>
            :
            <div style={{ maxWidth: isSummary ? '580px' : '1060px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[isSummary ? 6 : 10]}
                    onPaginationModelChange={setPaginationModel}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                {selectedQuestion &&
                    <>
                        <EvaluacionQuestionDetailsModal
                            open={detailsModal}
                            handleClose={() => setDetailsModal(false)}
                            xblock={selectedQuestion.full_question}
                            renderedXblock={renderMultipleXBlocks(selectedQuestion)}
                            responses={selectedQuestion.answers}
                            enrolledUsers={enrolledUsers}
                            title={title}
                            subtitle={selectedQuestion.number}
                            isPreTestPostTest={true}
                            identifier={identifier}
                            setAlertSuccess={setAlertSuccess}
                            setAlertError={setAlertError}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                    </>
                }
            </div>
    );
};

export default PreTestPostTestTable;