import React, { useState, useEffect } from 'react';
import { Typography, Card, Grid, Box, CircularProgress, TextField, MenuItem, Link, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import "dayjs/locale/es";
import SearchIcon from '@mui/icons-material/Search';
import { getAnalytics } from '../../requests/getAnalytics';
import {
    usersPerBrowserChart,
    usersPerCityChart,
    usersPerCountryChart,
    usersPerDayChart,
    usersPerDeviceTypeChart,
    usersPerHourChart,
    usersPerOriginChart,
    usersPerOSChart,
    redfidUsersByPlatformChart,
    redfidVisitsPerSiteChart,
    cmmeduVisitsPerSiteChart,
    cmmeduVisitsPerSeminarioChart
} from '../../utils/charts';
import { REDFID_COLORS, CMMEDU_COLORS } from '../../utils/formatters';


const WEBSITES = {
    "cmmedu": {
        "id": "2",
        "name": "CMMEdu",
        "url": "https://cmmedu.uchile.cl"
    },
    "redfid": {
        "id": "3",
        "name": "RedFID",
        "url": "https://www.redfid.cl"
    },
    "matcon": {
        "id": "4",
        "name": "MatCon",
        "url": "https://matcon.cmmedu.uchile.cl"
    },
    "suma-y-sigue": {
        "id": "6",
        "name": "Suma y Sigue",
        "url": "https://sumaysigue.uchile.cl"
    },
    "matematica-en-aula": {
        "id": "7",
        "name": "Matemática en Aula",
        "url": "https://matematicaenaula.cmmedu.uchile.cl"
    },
    "refip": {
        "id": "8",
        "name": "ReFIP",
        "url": "https://refip.cmmedu.uchile.cl"
    },
    "rutas-digitales": {
        "id": "9",
        "name": "Rutas Digitales",
        "url": "https://recursosplandiferenciado.cmmedu.uchile.cl"
    }
}

const TraficoWeb = ({ keycloak }) => {

    const [website, setWebsite] = useState("cmmedu");
    const [chosenWebsite, setChosenWebsite] = useState(null);
    const [startDay, setStartDay] = useState(dayjs().subtract(30, "day"));
    const [endDay, setEndDay] = useState(dayjs());
    const [data, setData] = useState(null);
    const [loadingAnalytics, setLoadingAnalytics] = useState(false);

    const fetchAnalytics = async () => {
        try {
            setLoadingAnalytics(true);
            const response = await getAnalytics(keycloak.token, WEBSITES[website].id, startDay.format("YYYY-MM-DD"), endDay.format("YYYY-MM-DD"));
            setData(response.analytics);
            setChosenWebsite(website);
            setLoadingAnalytics(false);
        } catch (error) {
            setLoadingAnalytics(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (data && !loadingAnalytics) {
            var palette;
            if (website === "redfid") {
                palette = REDFID_COLORS;
            } else {
                palette = CMMEDU_COLORS;
            }
            usersPerDayChart(data, palette);
            usersPerCountryChart(data, palette);
            usersPerCityChart(data, palette);
            usersPerBrowserChart(data, palette);
            usersPerOSChart(data, palette);
            usersPerOriginChart(data, palette);
            usersPerHourChart(data, palette);
            usersPerDeviceTypeChart(data, palette);
            if (website === "redfid") {
                redfidUsersByPlatformChart(data, palette);
                redfidVisitsPerSiteChart(data, "home", palette);
                redfidVisitsPerSiteChart(data, "cursos", palette);
                redfidVisitsPerSiteChart(data, "comunidades", palette);
                redfidVisitsPerSiteChart(data, "recursos", palette);
            } else if (website === "cmmedu") {
                cmmeduVisitsPerSiteChart(data, palette);
                cmmeduVisitsPerSeminarioChart(data, palette);
            }
        }
    }, [data, loadingAnalytics]);

    return (
        <>
            <Typography fontSize={{ xs: "1.2em", md: "1.6em" }} mb={"10px"} variant="title">Tráfico web</Typography>
            <Typography fontSize={{ xs: "0.8em", md: "1.0em" }} variant="title">Visitas a las plataformas del CMMEdu</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} display={"flex"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} width={"100%"}>
                            <TextField
                                label="Sitio web"
                                value={website}
                                fullWidth
                                select
                                onChange={(e) => setWebsite(e.target.value)}
                            >
                                {Object.keys(WEBSITES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {WEBSITES[key].name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} width={"100%"} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    label="Fecha de inicio"
                                    value={startDay}
                                    fullWidth
                                    sx={{ marginRight: "5px", width: "100%" }}
                                    onChange={(date) => {
                                        setStartDay(date);
                                    }}
                                    minDate={dayjs('2024-08-01')}
                                    maxDate={endDay || dayjs()}
                                    slotProps={{
                                        textField: {
                                            disabled: loadingAnalytics
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} width={"100%"} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    label="Fecha de término"
                                    value={endDay}
                                    fullWidth
                                    sx={{ marginRight: "5px", width: "100%" }}
                                    onChange={(date) => {
                                        setEndDay(date);
                                    }}
                                    minDate={startDay || dayjs('2024-08-01')}
                                    maxDate={dayjs()}
                                    slotProps={{
                                        textField: {
                                            disabled: loadingAnalytics
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Button
                                variant="red"
                                onClick={() => {
                                    fetchAnalytics();
                                }}
                                disabled={loadingAnalytics}
                                startIcon={<SearchIcon />}
                            >Consultar</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {(!loadingAnalytics && data) &&
                    <Grid item xs={12} width={"100%"} display={"flex"} justifyContent={"center"}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                <Card sx={{ backgroundColor: "white", padding: "10px", "height": "100%", mx: "5px", pr: "20px", width: "100%" }}>
                                    <Typography width={"fit-content"} variant="p-small" textAlign={"left"}><b>Sitio:</b> {WEBSITES[website].name}</Typography>
                                    <Typography width={"fit-content"} variant="p-small" textAlign={"left"}><b>URL:</b> <Link target="_blank" href={WEBSITES[website].url} color="#D23831">{WEBSITES[website].url}</Link></Typography>
                                    <Typography width={"fit-content"} variant="p-small" textAlign={"left"}><b>Fecha de inicio:</b> {startDay.format("DD/MM/YYYY")}</Typography>
                                    <Typography width={"fit-content"} variant="p-small" textAlign={"left"}><b>Fecha de término:</b> {endDay.format("DD/MM/YYYY")}</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                <Card sx={{ backgroundColor: "white", padding: "10px", "height": "100%", mx: "5px", px: "20px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <Typography width={"100%"} variant="p-small" fontSize={"2.5em"} textAlign={"center"}>{Object.values(data.analytics.users_per_day).reduce((acc, item) => acc + item, 0)}</Typography>
                                    <Typography width={"fit-content"} variant="p-small" textAlign={"center"}>Visitantes distintos</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                <Card sx={{ backgroundColor: "white", padding: "10px", "height": "100%", mx: "5px", px: "20px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <Typography width={"100%"} variant="p-small" fontSize={"2.5em"} textAlign={"center"}>{Object.values(data.analytics.users_per_hostname).reduce((acc, item) => acc + item, 0)}</Typography>
                                    <Typography width={"fit-content"} variant="p-small" textAlign={"center"}>Visitas a páginas</Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {loadingAnalytics ? <Box textAlign={"center"} mt={"50px"}><CircularProgress /></Box> :
                <Grid container spacing={2}>
                    {(data && !loadingAnalytics) ?
                        <Grid item xs={12}>
                            <Grid container mt={"5px"} spacing={2}>
                                <Grid item xs={12} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-day"></div>
                                    </Card>
                                </Grid>
                                {chosenWebsite === "redfid" ?
                                    <>
                                        <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                            <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                <div id="visits-per-platform-redfid"></div>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                            <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                <div id="redfid-home-sites-chart"></div>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} display="flex">
                                            <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                <div id="redfid-cursos-sites-chart"></div>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                            <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                <div id="redfid-comunidades-sites-chart"></div>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                            <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                <div id="redfid-recursos-sites-chart"></div>
                                            </Card>
                                        </Grid>
                                    </>
                                    : chosenWebsite === "cmmedu" ?
                                        <>
                                            <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                                <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                    <div id="cmmedu-sites-chart"></div>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                                <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                                    <div id="cmmedu-seminarios-chart"></div>
                                                </Card>
                                            </Grid>
                                        </>
                                        : <></>
                                }
                                <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-country"></div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-city"></div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-device-type"></div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-browser"></div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-os"></div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-origin"></div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} display="flex" alignItems={"center"} justifyContent={"center"}>
                                    <Card sx={{ backgroundColor: "white", padding: "10px", "width": "100%", "height": "100%" }}>
                                        <div id="users-per-hour"></div>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        : (!data && !loadingAnalytics) ?
                            <></>
                            :
                            <Grid item xs={12}>
                                <Typography variant={"p-error"} textAlign={"center"} mt={"40px"}>Ha ocurrido un error.</Typography>
                            </Grid>
                    }
                </Grid >
            }
        </>
    );
}

export default TraficoWeb;