export const mapTopics = (topic) => {
  switch (topic) {
    case "numeros":
      return "Números";
    case "algebra":
      return "Álgebra";
    case "geometria":
      return "Geometría";
    case "datos":
      return "Datos y azar";
    case "otros":
      return "Otros cursos";
    case "diplomado":
      return "Diplomado";
    default:
      return topic;
  }
};

export const mapPlatforms = (platform) => {
  switch (platform) {
    case "moodle":
      return "https://cmmedu.uchile.cl";
    case "u-cursos":
      return "https://cmmedu.uchile.cl";
    case "cmmeduformacion":
      return "https://cmmeduformacion.uchile.cl";
    case "redfid":
      return "https://cursos.redfid.cl";
    default:
      return "https://cmmedu.uchile.cl";
  }
}

export const mapPlatformNames = (platform) => {
  switch (platform) {
    case "moodle":
      return "Moodle";
    case "u-cursos":
      return "U-Cursos";
    case "cmmeduformacion":
      return "CMMEdu Formación";
    case "redfid":
      return "RedFID";
    default:
      return "Otra";
  }
}

export const mapPlatformsStudio = (platform) => {
  switch (platform) {
    case "cmmeduformacion":
      return "https://studio.cmmeduformacion.uchile.cl";
    case "redfid":
      return "https://studio.cursos.redfid.cl";
    default:
      return "https://cmmedu.uchile.cl";
  }
}

export const mapCycles = (cycle) => {
  switch (cycle) {
    case "1":
      return "Primer ciclo";
    case "2":
      return "Segundo ciclo";
    case "12":
      return "Primer y segundo ciclo";
    case "3":
      return "Media";
    default:
      return cycle;
  }
}

export const mapContactMethods = (contact_method) => {
  switch (contact_method) {
    case "email":
      return "Correo";
    case "phone":
      return "Teléfono";
    case "whatsapp":
      return "WhatsApp";
    default:
      return contact_method;
  }
}

export const mapGenders = (gender) => {
  switch (gender) {
    case "male":
      return "Masculino";
    case "female":
      return "Femenino";
    case "other":
      return "Otro";
    default:
      return gender;
  }
}

export const mapTitles = (title) => {
  switch (title) {
    case "basica":
      return "Profesor(a) de Educación General Básica";
    case "matematicas":
      return "Profesor(a) de Matemáticas";
    case "no-title":
      return "Sin título";
    default:
      return title;
  }
}

export const mapEvents = (event) => {
  switch (event) {
    case "course_data_update":
      return { label: "Inició extracción de datos de un curso en edX", color: "#646464" };
    case "course_data_update_manual":
      return { label: "Solicitó actualizar datos de un curso", color: "#646464" };
    case "received_course_data":
      return { label: "Se recibieron datos de un curso desde edX", color: "#646464" };
    case "received_course_data_failed":
      return { label: "Falló en edX la extracción de datos de un curso", color: "red" };
    case "make_old_seguimiento":
      return { label: "Solicitó planillas antiguas de Seguimiento", color: "#646464" };
    case "processed_course_data":
      return { label: "Se cargaron datos nuevos en plataforma", color: "green" };
    case "processed_course_data_failed":
      return { label: "Falló la carga de datos nuevos en plataforma", color: "red" };
    case "made_old_seguimiento":
      return { label: "Se crearon planillas antiguas de Seguimiento", color: "green" };
    case "made_old_seguimiento_failed":
      return { label: "Falló la creación de planillas antiguas de Seguimiento", color: "red" };
    case "enable_seguimiento":
      return { label: "Habilitó seguimiento de una sección de un curso", color: "#646464" };
    case "disable_seguimiento":
      return { label: "Inhabilitó seguimiento de una sección de un curso", color: "#646464" };
    case "create_course":
      return { label: "Creó curso en catálogo", color: "#646464" };
    case "create_course_implementation":
      return { label: "Creó implementación de curso en catálogo", color: "#646464" };
    case "create_course_section":
      return { label: "Creó sección de curso en catálogo", color: "#646464" };
    case "update_course":
      return { label: "Actualizó curso en catálogo", color: "#646464" };
    case "update_course_implementation":
      return { label: "Actualizó implementación de curso en catálogo", color: "#646464" };
    case "update_course_section":
      return { label: "Actualizó sección de curso en catálogo", color: "#646464" };
    case "delete_course":
      return { label: "Eliminó curso en catálogo", color: "#646464" };
    case "delete_course_implementation":
      return { label: "Eliminó implementación de curso en catálogo", color: "#646464" };
    case "delete_course_section":
      return { label: "Eliminó sección de curso en catálogo", color: "#646464" };
    case "create_diplomado":
      return { label: "Creó diplomado en catálogo", color: "#646464" };
    case "update_diplomado":
      return { label: "Actualizó diplomado en catálogo", color: "#646464" };
    case "delete_diplomado":
      return { label: "Eliminó diplomado en catálogo", color: "#646464" };
    case "create_diplomado_module":
      return { label: "Creó módulo de diplomado en catálogo", color: "#646464" };
    case "update_diplomado_module":
      return { label: "Actualizó módulo de diplomado en catálogo", color: "#646464" };
    case "delete_diplomado_module":
      return { label: "Eliminó módulo de diplomado en catálogo", color: "#646464" };
    case "create_diplomado_implementation":
      return { label: "Creó implementación de diplomado en catálogo", color: "#646464" };
    case "update_diplomado_implementation":
      return { label: "Actualizó implementación de diplomado en catálogo", color: "#646464" };
    case "delete_diplomado_implementation":
      return { label: "Eliminó implementación de diplomado en catálogo", color: "#646464" };
    case "create_diplomado_section":
      return { label: "Creó sección de diplomado en catálogo", color: "#646464" };
    case "update_diplomado_section":
      return { label: "Actualizó sección de diplomado en catálogo", color: "#646464" };
    case "delete_diplomado_section":
      return { label: "Eliminó sección de diplomado en catálogo", color: "#646464" };
    case "delete_from_queue":
      return { label: "Canceló solicitud de actualización de datos de curso", color: "#646464" };
    case "update_user":
      return { label: "Editó perfil de docente", color: "#646464" };
    case "delete_user":
      return { label: "Eliminó datos de docente", color: "#646464" };
    case "update_user_situation":
      return { label: "Actualizó situación de docente", color: "#646464" };
    case "set_manual_grades":
      return { label: "Configuró evaluaciones manuales", color: "#646464" };
    case "set_manual_multi_grades":
      return { label: "Configuró evaluaciones manuales", color: "#646464" };
    default:
      return { label: event, color: "#646464" };
  }
};

export const mapDashboards = (dashboard) => {
  switch (dashboard) {
    case "inicio":
      return "Inicio";
    case "catalogo":
      return "Catálogo de cursos";
    case "trafico":
      return "Tráfico web";
    case "profesores":
      return "Docentes participantes";
    case "static":
      return "Servidores Static";
    case "history":
      return "Historial";
    default:
      return dashboard;
  }
};

export const mapXblockTypes = (xblock_type, type) => {
  switch (xblock_type) {
    case "html":
      return "HTML";
    case "text":
      return "Texto";
    case "problem":
      return "Problema";
    case "eolquestion":
      return "Enunciado";
    case "eoldialogs":
      return "Diálogo";
    case "dialogsquestionsxblock":
      return "Diálogo con preguntas";
    case "eolcontainer":
      return "Cápsula (" + type + ")";
    case "eolconditional":
      return "Condicional";
    case "freetextresponse":
      return "Pregunta abierta";
    case "iterativexblock":
      return "Iterativo";
    case "iaaxblock":
      return "Documento incremental";
    case "vof":
      return "Verdadero o falso";
    case "dropdown":
      return "Dropdown";
    case "checkboxes":
      return "Selección múltiple";
    case "multiplechoice":
      return "Selección única";
    case "table_advanced_problem":
      return "Tabla avanzada de selección";
    case "radiomultiplechoice":
      return "Selección única (radio)";
    case "clase3":
      return "Clase 3 — Otros bloques";
    case "eollistgrade":
      return "Notas manuales";
    case "openassessment":
      return "ORA";
    case "eolzoom":
      return "Zoom";
    default:
      return xblock_type;
  }
}

export const mapDeviceTypes = (device_type) => {
  switch (device_type) {
    case "Desktop":
      return "Escritorio";
    case "Smartphone":
      return "Mobile";
    case "Phablet":
      return "Tablet";
    default:
      return "Otros";
  }
}

export const mapAsistencia = (asistencia) => {
  switch (asistencia) {
    case "0":
      return "Inasistencia";
    case "1":
      return "Inasistencia justificada";
    case "2":
      return "Asistencia";
    default:
      return asistencia;
  }
}

export const mapSatisfaction = (satisfaction) => {
  switch (satisfaction) {
    case "ES_SAT.CURSO_":
      return "Curso en general";
    case "ES_SAT.PLAT_":
      return "Plataforma CMMEdu Formación";
    case "ES_SAT.TV_":
      return "Talleres virtuales (asincrónicos)";
    case "ES_SAT.IS_":
      return "Talleres sincrónicos";
    case "ES_SAT.TP_":
      return "Talleres presenciales";
    case "ES_SAT.METOD_":
      return "Metodología";
    case "ES_SAT.REL_":
      return "Labor del relator virtual";
    case "ES_SAT.TUTOR_":
      return "Labor del tutor virtual"
    case "ES_SAT.MATCOM_":
      return "Material complementario"
    case "ES_SAT.INFOANTES_":
      return "Información previa"
    case "ES_SAT.PLANIFYCOORD_":
      return "Planificación y coordinación"
    case "ES_SAT.APREND_":
      return "Aprendizajes adquiridos"
    case "ES_SAT.RECOMIENDA_":
      return "Probabilidad de recomendar"
    default:
      return satisfaction;
  }
}
