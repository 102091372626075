import React, { useState, useEffect } from "react";
import { Typography, Grid, Divider, Card, CardContent, CircularProgress, Box, FormControl, Select, MenuItem } from "@mui/material";
import { getAllSituations } from "../../../requests/getAllSituations";
import { mapCycles, mapTopics, mapPlatformNames, mapSatisfaction } from "../../../utils/mappers";
import { answersEncuestaSlider } from "../../../utils/charts";


const CourseImplementationsAll = ({
    keycloak,
    course,
    loadingAllCourseData,
    allCourseData,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [allSituaciones, setAllSituaciones] = useState([]);
    const [loadingAllSituaciones, setLoadingAllSituaciones] = useState(true);
    const [selectedSatCode, setSelectedSatCode] = useState("ALL");

    const fetchAllSituaciones = async () => {
        setLoadingAllSituaciones(true);
        const response = await getAllSituations(keycloak.token);
        if (response.allSituations) {
            setAllSituaciones(response.allSituations);
        } else {
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener situaciones", text: response.data.error },
            });
            setAlertError(true);
        }
        setLoadingAllSituaciones(false);
    }

    const getSatisfactionData = () => {
        if (!allCourseData?.implementations) return { codes: [], responses: {} };
        
        const codes = new Set();
        const responses = {};

        allCourseData.implementations.forEach(implementation => {
            implementation.sections.forEach(section => {
                section.satisfaction_data.questions.forEach(question => {
                    codes.add(question.code);
                    if (!responses[question.code]) {
                        responses[question.code] = [];
                    }
                    responses[question.code].push(...question.responses.map(r => r.value));
                });
            });
        });

        return {
            codes: Array.from(codes).sort(),
            responses
        };
    };

    useEffect(() => {
        if (allCourseData) {
            fetchAllSituaciones();
            
            setTimeout(() => {
                const { codes, responses } = getSatisfactionData();
                if (codes.length > 0) {
                    let plotResponses = [];
                    Object.values(responses).forEach(codeResponses => {
                        plotResponses.push(...codeResponses);
                    });
                    answersEncuestaSlider(plotResponses, "satisfaction", true);
                }
            }, 500);
        }
    }, [allCourseData]);

    useEffect(() => {
        if (!allCourseData) return;

        const { codes, responses } = getSatisfactionData();
        if (codes.length === 0) return;

        let plotResponses = [];
        if (selectedSatCode === "ALL") {
            Object.values(responses).forEach(codeResponses => {
                plotResponses.push(...codeResponses);
            });
        } else {
            plotResponses = responses[selectedSatCode] || [];
        }

        answersEncuestaSlider(plotResponses, "satisfaction", true);
    }, [selectedSatCode, allCourseData]);

    return (
        (!loadingAllCourseData && allCourseData) ?
        (
        (!loadingAllSituaciones && allSituaciones) ?
            <>
                <Grid container spacing={2} mt={"5px"}>
                    <Grid item xs={12}>
                        <Typography fontSize={{xs: "0.8em", sm: "0.9em", md: "1.0em", lg: "1.2em"}} variant="title">Resumen general del curso</Typography>
                        <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography fontSize={"1.2em"} variant="title">Información general</Typography>
                                <Divider sx={{ my: 1 }} />
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Programa:</Typography>
                                    <Typography variant={"p-small"}>Suma y Sigue</Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Ciclo:</Typography>
                                    <Typography variant={"p-small"}>{mapCycles(course.cycle)}</Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Eje temático:</Typography>
                                    <Typography variant={"p-small"}>{mapTopics(course.topic)}</Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Plataforma:</Typography>
                                    <Typography variant={"p-small"}>
                                        {course.implementations[course.implementations.length - 1]?.platform ? mapPlatformNames(course.implementations[course.implementations.length - 1].platform) : "Otra"}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Total participantes:</Typography>
                                    <Typography variant={"p-small"}>
                                        {course.implementations.map(implementation => implementation.sections).flat().map(section => section.enrollments).reduce((a, b) => a + b, 0)}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Primera implementación:</Typography>
                                    <Typography variant={"p-small"}>
                                        {course.implementations[0] ? course.implementations[0].year + "-" + course.implementations[0].term : "—"}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography variant={"p-small"}>Implementación más reciente:</Typography>
                                    <Typography variant={"p-small"}>
                                        {course.implementations[course.implementations.length - 1] ? course.implementations[course.implementations.length - 1].year + "-" + course.implementations[course.implementations.length - 1].term : "—"}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography fontSize={"1.2em"} variant="title">Implementaciones</Typography>
                                <Divider sx={{ my: 1 }} />
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography fontWeight={"bold"} variant={"p"}>E-learning ({course.implementations.filter(implementation => implementation.type === "e-learning").length})</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="p-small">Participantes</Typography>
                                    <Typography variant="p-small">{course.implementations.filter(implementation => implementation.type === "e-learning").map(implementation => implementation.sections).flat().map(section => section.enrollments).reduce((a, b) => a + b, 0)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="p-small">Secciones</Typography>
                                    <Typography variant="p-small">{course.implementations.filter(implementation => implementation.type === "e-learning").map(implementation => implementation.sections).flat().length}</Typography>
                                </Box>
                                <Divider sx={{ my: 1 }} />
                                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                                    <Typography fontWeight={"bold"} variant={"p"}>B-learning ({course.implementations.filter(implementation => implementation.type === "b-learning").length})</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="p-small">Participantes</Typography>
                                    <Typography variant="p-small">{course.implementations.filter(implementation => implementation.type === "b-learning").map(implementation => implementation.sections).flat().map(section => section.enrollments).reduce((a, b) => a + b, 0)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="p-small">Secciones</Typography>
                                    <Typography variant="p-small">{course.implementations.filter(implementation => implementation.type === "b-learning").map(implementation => implementation.sections).flat().length}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="p-small">Territorios</Typography>
                                    <Typography variant="p-small">{new Set(course.implementations.filter(implementation => implementation.type === "b-learning").map(implementation => implementation.sections).flat().map(section => section.location)).size}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography fontSize={"1.2em"} variant="title">Participantes</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={7}>
                        <Card>
                            <CardContent>
                                <Typography fontSize={"1.2em"} variant="title">Notas finales</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5}>
                        <Card>
                            <CardContent>
                                <Typography fontSize={"1.2em"} variant="title">Satisfacción</Typography>
                                <Divider sx={{ my: 1 }} />
                                {allCourseData && getSatisfactionData().codes.length > 0 ? (
                                    <>
                                        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                            <Select
                                                value={selectedSatCode}
                                                onChange={(e) => setSelectedSatCode(e.target.value)}
                                            >
                                                <MenuItem value="ALL">Todas las preguntas</MenuItem>
                                                {getSatisfactionData().codes.sort((a, b) => mapSatisfaction(a).localeCompare(mapSatisfaction(b))).map(code => (
                                                    <MenuItem key={code} value={code}>{mapSatisfaction(code)}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Box id="encuestas-chart-container-satisfaction" />
                                    </>
                                ) : (
                                    <Typography variant="p-small" textAlign="center">
                                        No hay datos de satisfacción disponibles
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
            : <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}> 
                <CircularProgress />
            </Box>
        ) : loadingAllCourseData ?
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}> 
            <CircularProgress />
        </Box> : 
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}> 
            <Typography variant="p-small">No se pudo obtener la información del curso</Typography>
        </Box>
    );
}

export default CourseImplementationsAll;