import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import { getXBlockResponseIsCorrect, calculateEvaluationGrade } from '../../../utils/formatters';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const AvanceTable = ({
    sectionData,
    identifier,
    evaluations,
    scale,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess,
    allResponses
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    // Add helper function to filter and format taller names
    const getTallerNumber = (name) => {
        const match = name.match(/Taller\s+(\d+)/i);
        return match ? parseInt(match[1]) : null;
    };

    const formatTallerName = (name) => {
        const match = name.match(/taller\s*(\d+)/i);
        return match ? `T${match[1]}` : name;
    };

    const calculateUserSectionPercentages = (userId, section) => {
        let correctCount = 0;
        let answeredCount = 0;
        let totalCount = 0;

        // Get all xblocks in this section that can be evaluated
        const xblocks = section.subsections.flatMap(subsection =>
            subsection.units.flatMap(unit =>
                // Skip units that start with "control" (case insensitive)
                (!unit.name.toLowerCase().startsWith('control') && !unit.name.toLowerCase().startsWith('recapitulemos') && !unit.name.toLowerCase().startsWith('pregunta calificada')) ?
                    unit.xblocks.filter(xblock => 
                        ["drag-and-drop-v2", "textinput", "table_advanced_problem", "radiomultiplechoice", "multiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3", "freetextresponse"].includes(xblock.true_block_type)
                    )
                : [] // Return empty array for control units
            )
        );

        totalCount = xblocks.length;
        if (totalCount === 0) return { answered: "0", correct: "0" };

        xblocks.forEach(xblock => {
            const responseKey = `${xblock.id}_${userId}`;
            const response = allResponses[responseKey];
            
            if (response) {
                answeredCount++;
                const isCorrect = getXBlockResponseIsCorrect(xblock, response.response, sectionData.enrolled_users.filter(user => user.show).find(user => user.id === response.user_id)?.username);
                if (isCorrect === true) {
                    correctCount++;
                }
            }
        });

        return {
            total: totalCount.toString(),
            answered: answeredCount.toString(),
            correct: correctCount.toString()
        };
    };

    const calculateControlPercentages = (userId, unit, controlCount) => {
        // Find the control in evaluations that matches this unit
        const control = evaluations?.controles?.[controlCount - 1];

        if (!control) {
            return { answered: "0%", correct: "0%" };
        }

        // Get user responses for this control
        const userResponses = control.contents?.map(content => {
            return content.responses?.find(r => r.user_id === userId) || {
                score: 0,
                max_score: content.max_score || 12
            };
        }) || [];

        // Calculate how many questions were answered
        const totalQuestions = control.contents?.length || 0;
        const answeredQuestions = userResponses.filter(response => 
            response.score > 0 || response.is_correct !== undefined
        ).length;

        // Calculate grade using the formatter function
        const grade = calculateEvaluationGrade(userResponses, scale);

        return {
            answered: totalQuestions === 0 ? "0%" : Math.round((answeredQuestions / totalQuestions) * 100) + "%",
            correct: grade ? grade.toFixed(1) : "—"
        };
    };

    const getEncuestaAcronym = (name) => {
        return name.split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase();
    };

    const calculateEncuestaCompletion = (userId, subsection) => {
        // Get all xblocks from all units in this subsection that are encuesta questions
        const encuestaXblocks = subsection.units.flatMap(unit => 
            unit.xblocks.filter(xblock => 
                xblock.display_name.includes("Custom JavaScript Display and Grading")
            )
        );

        if (encuestaXblocks.length === 0) return { total: 0, completed: 0 };

        let totalAnswered = 0;
        encuestaXblocks.forEach(xblock => {
            const responseKey = `${xblock.id}_${userId}`;
            const response = allResponses[responseKey];
            
            if (response && response.response) {
                try {
                    const parsedResponse = JSON.parse(response.response);
                    if (parsedResponse.student_answers) {
                        totalAnswered++;
                    }
                } catch (e) {
                    console.error("Error parsing response:", e);
                }
            }
        });

        return { total: encuestaXblocks.length, completed: totalAnswered };
    };

    const handleDownloadAvanceCSV = () => {
        let header = ["Usuario"];
        let controlCount = 1;
        
        // Keep same order as columns
        sectionData.sections.forEach(section => {
            if (getTallerNumber(section.name) !== null) {
                header.push(formatTallerName(section.name));
            }
            
            // Add Controls
            section.subsections.forEach(subsection => {
                subsection.units
                    .filter(unit => unit.name.toLowerCase().startsWith("control"))
                    .forEach(unit => {
                        header.push(`C${controlCount}`);
                        controlCount++;
                    });
            });
            
            // Add Encuestas
            section.subsections
                .filter(subsection => subsection.name.toLowerCase().startsWith("encuesta"))
                .forEach(subsection => {
                    header.push(getEncuestaAcronym(subsection.name));
                });
        });

        let lines = [];
        let output = [];
        for (let user of sectionData.enrolled_users.filter(user => user.show)) {
            var thisLine = [user.username];
            
            sectionData.sections.forEach(section => {
                // Add Taller data
                if (getTallerNumber(section.name) !== null) {
                    const percentages = calculateUserSectionPercentages(user.user_id, section);
                    thisLine.push(parseInt(percentages.answered) === 0 ? "—" : `${percentages.answered}/${percentages.correct}/${percentages.total}`);
                }
                
                // Add Control data
                section.subsections.forEach(subsection => {
                    subsection.units
                        .filter(unit => unit.name.toLowerCase().startsWith("control"))
                        .forEach(unit => {
                            const percentages = calculateControlPercentages(user.user_id, unit);
                            thisLine.push(parseInt(percentages.answered) === 0 ? "—" : `${percentages.answered}/${percentages.correct}`);
                        });
                });
                
                // Add Encuesta data
                section.subsections
                    .filter(subsection => subsection.name.toLowerCase().startsWith("encuesta"))
                    .forEach(subsection => {
                        const completion = calculateEncuestaCompletion(user.user_id, subsection);
                        thisLine.push(completion.total === 0 ? "—" : `${completion.completed}/${completion.total}`);
                    });
            });

            lines.push(thisLine);
        }
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        const fileName = "avance_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const getOrderedColumns = () => {
        const baseColumns = [{
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            width: 180,
            headerAlign: 'center',
            align: 'center',
            description: 'Usuario',
        }];

        let controlCount = 1;

        sectionData.sections.forEach(section => {
            // Process Talleres
            if (getTallerNumber(section.name) !== null) {
                // Calculate total questions for this taller
                const totalQuestions = section.subsections.flatMap(subsection =>
                    subsection.units.flatMap(unit =>
                        (!unit.name.toLowerCase().startsWith('control') && 
                         !unit.name.toLowerCase().startsWith('recapitulemos') && 
                         !unit.name.toLowerCase().startsWith('encuesta') && 
                         !unit.name.toLowerCase().startsWith('firma reglamento') && 
                         !unit.name.toLowerCase().startsWith('consentimiento') && 
                         !unit.name.toLowerCase().startsWith('pregunta calificada')) ?
                            unit.xblocks.filter(xblock => 
                                ["drag-and-drop-v2", "textinput", "table_advanced_problem", "radiomultiplechoice", "multiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3", "freetextresponse"].includes(xblock.true_block_type)
                            )
                        : []
                    )
                ).length;

                baseColumns.push({
                    field: section.name,
                    disableColumnMenu: true,
                    headerName: formatTallerName(section.name),
                    description: `${section.name} (${totalQuestions} preguntas)`,
                    width: 60,
                    headerAlign: 'center',
                    sortable: false,
                    align: 'center',
                    renderCell: (params) => {
                        const value = params.value;
                        const [answered, correct, total] = value.split('/');
                        const answeredNum = parseInt(answered);
                        const correctNum = parseInt(correct);
                        const totalNum = parseInt(total);
                        
                        if (answeredNum === 0) {
                            return (
                                <Box>
                                    <Typography variant="p">—</Typography>
                                </Box>
                            );
                        }
                        
                        const answeredPercentage = Math.round((answeredNum / totalNum) * 100);
                        const correctPercentage = Math.round((correctNum / answeredNum) * 100);
                        
                        return (
                            <Box>
                                <Typography variant="p">
                                    {answeredPercentage}%
                                </Typography>
                                <Typography fontWeight={"bold"} variant="p" sx={{ color: correctPercentage >= 60 ? '#4caf50' : '#f44336' }}>
                                    {correctPercentage}%
                                </Typography>
                            </Box>
                        );
                    }
                });
            }

            // Process Controls
            section.subsections.forEach(subsection => {
                subsection.units
                    .filter(unit => unit.name.toLowerCase().startsWith("control"))
                    .forEach(unit => {
                        baseColumns.push({
                            field: `control_${controlCount}`,
                            disableColumnMenu: true,
                            headerName: `C${controlCount}`,
                            description: unit.name,
                            width: 60,
                            headerAlign: 'center',
                            sortable: false,
                            align: 'center',
                            renderCell: (params) => {
                                const value = params.value;
                                const [responded, grade] = value.split('/');
                                const respondedNum = parseInt(responded);
                                
                                if (respondedNum === 0) {
                                    return (
                                        <Box>
                                            <Typography variant="p">—</Typography>
                                        </Box>
                                    );
                                }
                                
                                return (
                                    <Box>
                                        <Typography variant="p">
                                            {responded}
                                        </Typography>
                                        <Typography fontWeight={"bold"} variant="p" sx={{ color: parseFloat(grade) >= 4.0 ? '#4caf50' : '#f44336' }}>
                                            {grade}
                                        </Typography>
                                    </Box>
                                );
                            }
                        });
                        controlCount++;
                    });
            });

            // Process Encuestas
            section.subsections
                .filter(subsection => subsection.name.toLowerCase().startsWith("encuesta"))
                .forEach(subsection => {
                    baseColumns.push({
                        field: `encuesta_${getEncuestaAcronym(subsection.name)}`,
                        disableColumnMenu: true,
                        headerName: getEncuestaAcronym(subsection.name),
                        description: subsection.name,
                        width: 60,
                        headerAlign: 'center',
                        sortable: false,
                        align: 'center',
                        renderCell: (params) => {
                            const value = params.value;
                            if (value.total === 0) {
                                return (
                                    <Box>
                                        <Typography variant="p">—</Typography>
                                    </Box>
                                );
                            }
                            return (
                                <Box>
                                    <Typography variant="p">
                                        {value.completed}/{value.total}
                                    </Typography>
                                </Box>
                            );
                        }
                    });
                });
        });

        return baseColumns;
    };

    const columns = getOrderedColumns();

    function makeRows() {
        let rows = [];
        for (let user of sectionData.enrolled_users.filter(user => user.show)) {
            let row = {
                id: user.user_id,
                username: user.username,
            };

            // Add Taller percentages
            sectionData.sections
                .filter(section => getTallerNumber(section.name) !== null)
                .forEach(section => {
                    const percentages = calculateUserSectionPercentages(user.user_id, section);
                    row[section.name] = `${percentages.answered}/${percentages.correct}/${percentages.total}`;
                });

            // Add Control percentages
            let controlCount = 1;
            sectionData.sections.forEach(section => {
                section.subsections.forEach(subsection => {
                    subsection.units
                        .filter(unit => unit.name.toLowerCase().startsWith("control"))
                        .forEach(unit => {
                            const percentages = calculateControlPercentages(user.user_id, unit, controlCount);
                            row[`control_${controlCount}`] = `${percentages.answered}/${percentages.correct}`;
                            controlCount++;
                        });
                });
            });

            // Add Encuesta completion status
            sectionData.sections.forEach(section => {
                section.subsections
                    .filter(subsection => subsection.name.toLowerCase().startsWith("encuesta"))
                    .forEach(subsection => {
                        const acronym = getEncuestaAcronym(subsection.name);
                        const completion = calculateEncuestaCompletion(user.user_id, subsection);
                        row[`encuesta_${acronym}`] = completion;
                    });
            });

            rows.push(row);
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Avance en el curso</Typography>
                <Box>
                    <IconButton onClick={handleDownloadAvanceCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        !sectionData ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p-error' textAlign={"center"}>No hay datos disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1240px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    onPaginationModelChange={setPaginationModel}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'username',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default AvanceTable;