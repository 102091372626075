import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, Chip, CardContent, IconButton, Box } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CatalogCourseModal from "../modals/catalog/course/CatalogCourseModal";
import CatalogCourseEditModal from "../modals/catalog/course/crud/CatalogCourseEditModal";
import CatalogCourseDeleteModal from "../modals/catalog/course/crud/CatalogCourseDeleteModal";
import { mapTopics, mapCycles } from '../../../utils/mappers';
import { formatTimestamp } from '../../../utils/formatters';


const CatalogCourse = ({
    keycloak,
    courseIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [courseData, setCourseData] = useState(null);
    const [otherCourses, setOtherCourses] = useState(null);

    const [showCourseModal, setShowCourseModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleOpenCourseModal = () => {
        setShowCourseModal(true);
    }

    const handleCloseCourseModal = () => {
        setShowCourseModal(false);
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }
    
    useEffect(() => {
        setCourseData(catalog.courses[courseIndex]);
        setOtherCourses(catalog.courses.filter(c => c !== catalog.courses[courseIndex]));
    }, [catalog]);

    return (
        courseData && 
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#fff' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"10px"}>
                    <Typography mb={"0px"} fontSize={"1.2em"} variant="title">{courseData.shortname}</Typography>
                    <Box display={"flex"}>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenCourseModal()}>
                            <ListIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenEditModal()}>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20 }} onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Typography mb={0} textAlign={"justify"} fontSize={"1.0em"} variant="title">{courseData.name}</Typography>
                <Box m={"10px 0"}>
                    <Chip style={{"marginRight": "5px"}} label={mapTopics(courseData.topic)} variant={"eje-tematico"} />
                    <Chip label={mapCycles(courseData.cycle)} variant={courseData.cycle} />
                </Box>
                <Typography fontSize={"0.8em"} variant="p">{courseData.description}</Typography>
            </CardContent>
            <Grid container spacing={2} sx={{ padding: '20px', alignSelf: 'flex-end' }}>
                <Grid item xs={12} md={6}>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Implementaciones</u></b>: {courseData.implementations.length}
                    </Typography>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Secciones</u></b>: {courseData.implementations.reduce((acc, curr) => acc + curr.sections.length, 0)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Participantes</u></b>: {courseData.total_enrollments}
                    </Typography>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Aprobados</u></b>: {courseData.implementations.reduce((acc, curr) => acc + curr.sections.length, 0)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Última actualización</u></b>: {courseData.last_update ? formatTimestamp(courseData.last_update) : "—"}
                    </Typography>
                </Grid>
            </Grid>
            <>
                <CatalogCourseModal
                    keycloak={keycloak}
                    open={showCourseModal}
                    handleClose={handleCloseCourseModal}
                    index={courseIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogCourseEditModal
                    keycloak={keycloak}
                    open={showEditModal}
                    handleClose={handleCloseEditModal}
                    index={courseIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    otherCourses={otherCourses}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogCourseDeleteModal
                    keycloak={keycloak}
                    open={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    index={courseIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </>
        </Card>
    )
}

export default CatalogCourse;
