import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button,
    TextField, MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { postSetManualMultiGradeUsers } from '../../../requests/postSetManualMultiGradeUsers';

const NotasManualesMultipleEditModal = ({
    keycloak,
    courseKey,
    courseType,
    open,
    handleClose,
    enrolledUsers,
    title,
    evaluations,
    selectedEvaluation,
    setManualMultiGrades,
    setAlertError,
    setAlertSuccess,
    alerts,
    setAlerts
}) => {
    const [grades, setGrades] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (selectedEvaluation) {
            const initialGrades = enrolledUsers.map(user => {
                const existingGrade = selectedEvaluation.grades?.find(grade => grade.user_id === user.user_id);
                return {
                    user_id: user.user_id,
                    grade: existingGrade?.grade || "",
                    observations: existingGrade?.observations || ""
                };
            });
            initialGrades.sort((a, b) => enrolledUsers.find(u => u.user_id === a.user_id).username.localeCompare(enrolledUsers.find(u => u.user_id === b.user_id).username));
            setGrades(initialGrades);
        }
    }, [selectedEvaluation, enrolledUsers]);

    const validateForm = () => {
        const newErrors = {};

        grades.forEach((grade, index) => {
            if (grade.grade) {
                const gradeValue = parseFloat(grade.grade);
                if (isNaN(gradeValue) || gradeValue < 1.0 || gradeValue > 7.0) {
                    newErrors[`grade_${index}`] = "La nota debe estar entre 1.0 y 7.0";
                } else {
                    const decimalStr = grade.grade.toString().split('.')[1] || '';
                    if (decimalStr.length > 1) {
                        newErrors[`grade_${index}`] = "La nota debe tener máximo un decimal";
                    }
                }
            }

            if (grade.observations && grade.observations.length > 500) {
                newErrors[`observations_${index}`] = "Las observaciones no pueden exceder 500 caracteres";
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleUpdateNotas = async () => {
        if (!validateForm()) return;
        
        setFormLoading(true);
        const response = await postSetManualMultiGradeUsers(
            keycloak.token,
            title,
            selectedEvaluation.evaluation_index,
            grades,
            courseKey,
            courseType
        );

        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { 
                "title": "Error al actualizar notas", 
                "text": response.error 
            }});
        } else {
            setManualMultiGrades(response.data);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { 
                "title": "¡Notas actualizadas!", 
                "text": "Las notas han sido actualizadas correctamente." 
            }});
        }
        handleClose();
        setFormLoading(false);
    };

    const updateGrade = (index, field, value) => {
        const newGrades = [...grades];
        newGrades[index] = { ...newGrades[index], [field]: value };
        setGrades(newGrades);

        // Remove focus if enter is pressed
        if (field === 'grade' && value.endsWith('\n')) {
            document.activeElement.blur();
        }
    };

    const handleGradeBlur = (index, value) => {
        if (!value) return;

        let formattedValue = value;

        // Convert to number and validate range
        const numValue = parseFloat(value);
        if (isNaN(numValue) || numValue > 7.0 || numValue < 1.0) {
            formattedValue = "";
        } else {
            // Cut to one decimal and ensure .0 format
            formattedValue = numValue.toFixed(1);
        }

        const newGrades = [...grades];
        newGrades[index] = { ...newGrades[index], grade: formattedValue };
        setGrades(newGrades);
    };

    return (
        selectedEvaluation &&
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                {selectedEvaluation.evaluation_name}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                {grades.map((grade, index) => (
                    <Grid container spacing={2} key={grade.user_id} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={3} sx={{ "display": "flex", "alignItems": "center" }}>
                            <Typography>
                                {enrolledUsers.find(u => u.user_id === grade.user_id)?.username}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <TextField
                                fullWidth
                                label="Nota"
                                size='small'
                                value={grade.grade}
                                onChange={(e) => updateGrade(index, 'grade', e.target.value)}
                                onBlur={(e) => handleGradeBlur(index, e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.target.blur();
                                    }
                                }}
                                error={!!errors[`grade_${index}`]}
                                helperText={errors[`grade_${index}`]}
                            />
                        </Grid>
                        <Grid item xs={8} md={7}>
                            <TextField
                                fullWidth
                                label="Observaciones"
                                size='small'
                                value={grade.observations}
                                onChange={(e) => updateGrade(index, 'observations', e.target.value)}
                                error={!!errors[`observations_${index}`]}
                                helperText={errors[`observations_${index}`]}
                            />
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Button
                        variant="red"
                        onClick={handleUpdateNotas}
                        disabled={formLoading}
                    >
                        Actualizar
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default NotasManualesMultipleEditModal;
