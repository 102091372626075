import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import { formatTimestamp } from '../../../utils/formatters';
import { MathJax } from 'better-react-mathjax';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const PreTestPostTestResponsesTable = ({
    enrolledUsers,
    xblock,
    responses,
    title,
    identifier,
    number,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const handleDownloadXBlockResponsesCSV = () => {
        let header = [
            "Usuario",
            "Respuesta pre-test",
            "Correcto pre-test",
            "Respuesta post-test",
            "Correcto post-test"
        ];
        let lines = [];
        let output = [];
        
        for (let [key, value] of Object.entries(responses.preTest)) {
            var thisLine = [];
            // Get username or default to "—"
            thisLine.push(enrolledUsers.find(user => user.user_id === value.user_id)?.username || "—");
            
            // Get response text and decode HTML entities
            const responseText1 = value.response || "—";
            const decodedText1 = document.createElement('textarea');
            decodedText1.innerHTML = responseText1;
            thisLine.push(decodedText1.value);
            
            // Format correct/incorrect status
            thisLine.push(value.is_correct !== null ? (value.is_correct ? "Sí" : "No") : "—");

            // Get response text and decode HTML entities
            const responseText2 = responses.postTest[key].response || "—";
            const decodedText2 = document.createElement('textarea');
            decodedText2.innerHTML = responseText2;
            thisLine.push(decodedText2.value);

            // Format correct/incorrect status
            thisLine.push(responses.postTest[key].is_correct !== null ? (responses.postTest[key].is_correct ? "Sí" : "No") : "—");
            
            lines.push(thisLine);
        }
        
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        
        const fileName = "respuestas_" + title.replace(/ /g, "_") + "_Pregunta_" + number + "_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'response_1',
            disableColumnMenu: true,
            headerName: 'Respuesta pre-test',
            width: 350,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    <MathJax>
                        <div
                            style={{ maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{
                                __html: params.value.replace(
                                    /<img/g,
                                    '<img style="max-width:100%;height:auto"'
                                )
                            }}
                        />
                    </MathJax>
                </Box>
            ),
        },
        {
            field: 'is_correct_1',
            disableColumnMenu: true,
            headerName: 'Correcto',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            valueGetter: (params, row) => row == null ? "—" : row.is_correct_1 == null ? "—" : row.is_correct_1,
            renderCell: (params) => (
                params.row.is_correct_1 !== "—" ?
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.row.is_correct_1 ?
                            <CheckCircleIcon sx={{ color: "green" }}>
                            </CheckCircleIcon> :
                            <CancelIcon sx={{ color: "red" }}>
                            </CancelIcon>
                        }
                    </Box> : "—"
            ),
        },
        {
            field: 'response_2',
            disableColumnMenu: true,
            headerName: 'Respuesta post-test',
            width: 350,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    <MathJax>
                        <div
                            style={{ maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{
                                __html: params.value.replace(
                                    /<img/g,
                                    '<img style="max-width:100%;height:auto"'
                                )
                            }}
                        />
                    </MathJax>
                </Box>
            ),
        },
        {
            field: 'is_correct_2',
            disableColumnMenu: true,
            headerName: 'Correcto',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            valueGetter: (params, row) => row == null ? "—" : row.is_correct_2 == null ? "—" : row.is_correct_2,
            renderCell: (params) => (
                params.row.is_correct_2 !== "—" ?
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.row.is_correct_2 ? <CheckCircleIcon sx={{ color: "green" }}></CheckCircleIcon> : <CancelIcon sx={{ color: "red" }}></CancelIcon>}
                    </Box> : "—"
            ),
        }
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(responses.preTest)) {
            rows.push({
                id: value.id,
                username: enrolledUsers.find(user => user.user_id === value.user_id)?.username || "—",
                created: value.created ? formatTimestamp(value.created) : "—",
                response_1: value.response ? value.response : "—",
                is_correct_1: value.is_correct !== null ? value.is_correct : "—",
                response_2: responses.postTest[key].response ? responses.postTest[key].response : "—",
                is_correct_2: responses.postTest[key].is_correct !== null ? responses.postTest[key].is_correct : "—"
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Lista de respuestas del XBlock</Typography>
                <Box>
                    <IconButton onClick={handleDownloadXBlockResponsesCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        responses.preTest.length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p-small' textAlign={"center"}>No hay datos de respuestas disponibles, o este XBlock no tiene respuestas.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1120px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    getRowHeight={() => 'auto'}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>
    );
};

export default PreTestPostTestResponsesTable;