import React, { useState } from 'react';
import { Box, CircularProgress, Grid, Typography, List, ListItem, ListItemButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import XblockDetailsModal from '../../modals/XblockDetailsModal';
import { mapXblockTypes } from '../../../../utils/mappers';
import { renderXblock } from '../../../../utils/formatters';
import { MathJax } from 'better-react-mathjax';



const CourseSectionExplorer = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    selectedModule,
    selectedImplementation,
    selectedSection,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [talleresTab, setTalleresTab] = useState(null);
    const [actividadesTab, setActividadesTab] = useState(null);
    const [unidadesTab, setUnidadesTab] = useState(null);
    const [xblockDetailsModalOpen, setXblockDetailsModalOpen] = useState(false);
    const [selectedXblock, setSelectedXblock] = useState(null);


    const showXblock = (xblock) => {
        if (xblock.true_block_type === "text" || xblock.true_block_type === "html") {
            const content = xblock.text || "";
            const trimmed = content.trim();
            if (!trimmed || /^\s*$/.test(trimmed)) {
                return false;
            }
            const strippedOfTags = trimmed
                .replace(/<script[\s\S]*?<\/script>/gi, '')
                .replace(/<style[\s\S]*?<\/style>/gi, '')
                .replace(/<canvas[\s\S]*?<\/canvas>/gi, '')
                .replace(/<link[^>]*>/gi, '')
                .replace(/<iframe[^>]*>/gi, '')
                .replace(/<hr[^>]*>/gi, '')
                .replace(/<[^>]+>/g, '')
                .trim();
            return strippedOfTags.length > 0;
        } else if (xblock.true_block_type === "eolconditional") {
            return null;
        } else if (xblock.true_block_type === "eolquestion") {
            return true;
        } else if (xblock.true_block_type === "eoldialogs") {
            return true;
        } else if (xblock.true_block_type === "dialogsquestionsxblock") {
            return true;
        } else if (xblock.true_block_type === "eolquestion") {
            return true;
        } else if (xblock.true_block_type === "eolcontainer") {
            return true;
        } else if (xblock.true_block_type === "freetextresponse") {
            return true;
        } else if (xblock.true_block_type === "vof") {
            return true;
        } else if (xblock.true_block_type === "dropdown") {
            return true;
        } else if (xblock.true_block_type === "table_advanced_problem") {
            return true;
        } else if (xblock.true_block_type === "checkboxes") {
            return true;
        } else if (xblock.true_block_type === "multiplechoice") {
            return true;
        } else if (xblock.true_block_type === "radiomultiplechoice") {
            return true;
        } else if (xblock.true_block_type === "clase3") {
            return true;
        } else if (xblock.true_block_type === "openassessment") {
            return true;
        } else if (xblock.true_block_type === "eollistgrade") {
            return true;
        } else if (xblock.true_block_type === "eolzoom") {
            return true;
        } else if (xblock.true_block_type === "iterativexblock") {
            return true;
        } else {
            return null;
        }
    };


    const handleTalleresTabClick = (name) => {
        setUnidadesTab(null);
        setActividadesTab(null);
        setTalleresTab(name);
    }

    const handleActividadesTabClick = (name) => {
        setUnidadesTab(null);
        setActividadesTab(name);
    }

    const handleUnidadesTabClick = (name) => {
        setUnidadesTab(name);
    }

    const handleXblockDetailsModalOpen = (xblock) => {
        setSelectedXblock(xblock);
        setXblockDetailsModalOpen(true);
    }

    const handleXblockDetailsModalClose = () => {
        setSelectedXblock(null);
        setXblockDetailsModalOpen(false);
    }

    return (
        sectionData ?
            <Box width={"100%"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="p-small">
                            El explorador de cursos te permite visualizar el contenido del curso, y acceder a las actividades y unidades de cada taller.
                            Además, puedes ver las respuestas de los profesores a las actividades. Debido a la complejidad de los contenidos, algunos elementos
                            pueden no ser mostrados correctamente.
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Typography variant="p" sx={{
                            color: "#646464",
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "0.9em",
                            marginTop: "15px",
                            fontFamily: "Avenir Heavy"
                        }}>Talleres</Typography>
                        <List>
                            {Object.values(sectionData.sections).map(taller => (
                                <ListItem key={taller.name} disablePadding sx={{ "backgroundColor": talleresTab === taller.name ? "#60696C" : "#efefef" }}>
                                    <ListItemButton onClick={() => handleTalleresTabClick(taller.name)}>
                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={talleresTab === taller.name ? "#efefef" : "#646464"}>{taller.name}</Typography>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        {talleresTab &&
                            <>
                                <Typography variant="p" sx={{
                                    color: "#646464",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "15px",
                                    fontFamily: "Avenir Heavy"
                                }}>Actividades</Typography>
                                <List>
                                    {Object.values(sectionData.sections.find(taller => taller.name === talleresTab).subsections).map(actividad => (
                                        <ListItem key={actividad.name} disablePadding sx={{ "backgroundColor": actividadesTab === actividad.name ? "#60696C" : "#efefef" }}>
                                            <ListItemButton onClick={() => handleActividadesTabClick(actividad.name)}>
                                                <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={actividadesTab === actividad.name ? "#efefef" : "#646464"}>{actividad.name}</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        }
                    </Grid>
                    <Grid item xs={4} md={2}>
                        {actividadesTab &&
                            <>
                                <Typography variant="p" sx={{
                                    color: "#646464",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "15px",
                                    fontFamily: "Avenir Heavy"
                                }}>Unidades</Typography>
                                <List>
                                    {Object.values(sectionData.sections.find(taller => taller.name === talleresTab).subsections.find(actividad => actividad.name === actividadesTab).units).map(unidad => (
                                        <ListItem key={unidad.name} disablePadding sx={{ "backgroundColor": unidadesTab === unidad.name ? "#60696C" : "#efefef" }}>
                                            <ListItemButton onClick={() => handleUnidadesTabClick(unidad.name)}>
                                                <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={unidadesTab === unidad.name ? "#efefef" : "#646464"}>{unidad.name}</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {unidadesTab &&
                            <>
                                <Typography variant="p" sx={{
                                    color: "#646464",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "0.9em",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    fontFamily: "Avenir Heavy"
                                }}>XBlocks</Typography>
                                <Box sx={{ maxHeight: "700px", overflow: "auto" }}>
                                    {Object.values(sectionData.sections.find(taller => taller.name === talleresTab)
                                        .subsections.find(actividad => actividad.name === actividadesTab)
                                        .units.find(unidad => unidad.name === unidadesTab)
                                        .xblocks).map(xblock => (
                                            showXblock(xblock) &&
                                            <MathJax key={xblock.block_key}>{renderXblock(xblock, true, handleXblockDetailsModalOpen, true)}</MathJax>
                                        ))}


                                </Box>
                            </>
                        }
                    </Grid>
                </Grid>
                {selectedXblock &&
                    <XblockDetailsModal
                        keycloak={keycloak}
                        open={xblockDetailsModalOpen}
                        handleClose={handleXblockDetailsModalClose}
                        platform={isDiplomado ? course.modules[selectedModule].implementations[selectedImplementation].platform : course.implementations[selectedImplementation].platform}
                        courseKey={
                            isDiplomado ?
                                course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
                                :
                                course.implementations[selectedImplementation].sections[selectedSection].course_key
                        }
                        xblock={selectedXblock}
                        renderedXblock={<MathJax>{renderXblock(selectedXblock, false, null, false)}</MathJax>}
                        enrolledUsers={sectionData.enrolled_users}
                        identifier={
                            isDiplomado ?
                                course.shortname + "_" +
                                course.modules[selectedModule].module + "_" +
                                course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                :
                                course.shortname + "_" +
                                course.implementations[selectedImplementation].year + "_" +
                                course.implementations[selectedImplementation].term + "_" +
                                course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                course.implementations[selectedImplementation].sections[selectedSection].section
                        }
                        setAlertSuccess={setAlertSuccess}
                        setAlertError={setAlertError}
                        alerts={alerts}
                        setAlerts={setAlerts}
                    />
                }
            </Box>
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>
    );

}

export default CourseSectionExplorer;