import React from 'react';
import { Box, IconButton, Link, Drawer, List, ListItem, ListItemButton, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import cmmeduLogo from '../assets/img/cmmedu.png';
import whiteLogo from '../assets/img/logo.svg';
import { mapDashboards } from '../utils/mappers';

const Header = ({ isMobile, onMenuToggle, isMenuOpen, availableDashboards, handleSectionClick }) => {

    const groupedDashboards = availableDashboards.reduce((acc, dashboard) => {
        if (!dashboard.header) {
            acc.fixed = acc.fixed || [];
            acc.fixed.push(dashboard);
        } else {
            acc[dashboard.header] = acc[dashboard.header] || [];
            acc[dashboard.header].push(dashboard);
        }
        return acc;
    }, {});

    return (
        <>
            <Box
                width="100%"
                backgroundColor="#d23831"
                boxShadow="0px 6px 4px -4px #cccccc"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={1.5}
                px={isMobile ? 2 : 5}
            >
                {isMobile &&
                    <Box display="flex" alignItems="center" mr="40px">
                        <IconButton
                            onClick={onMenuToggle}
                            sx={{ display: { xs: 'block', md: 'none' }, color: '#FFFFFF' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                }
                <Box
                    component="img"
                    src={whiteLogo}
                    alt="Logo"
                    sx={{
                        width: isMobile ? 120 : 150,
                        filter: 'brightness(0) invert(1)'
                    }}
                />
                <Link href="https://cmmedu.uchile.cl" target='_blank' display={"flex"} align='center'>
                    <Box
                        component="img"
                        src={cmmeduLogo}
                        alt="CMMEdu Logo"
                        sx={{
                            width: isMobile ? 80 : 120
                        }}
                    />
                </Link>
            </Box>
            <Box width="100%" height={6} backgroundColor="#60696C" />
            <Drawer anchor="left" open={isMenuOpen} onClose={onMenuToggle}>
                <Box sx={{ width: 240 }} mt={"80px"}>
                    <List>
                        {groupedDashboards.fixed && groupedDashboards.fixed.map((dashboard) => (
                            <ListItem key={dashboard.key} disablePadding>
                                <ListItemButton onClick={() => handleSectionClick({key: dashboard.key, type: "fixed"})}>
                                    <Typography sx={{ textAlign: "center", width: "100%" }}>{mapDashboards(dashboard.key)}</Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                        {Object.entries(groupedDashboards).map(([header, dashboards]) => {
                            if (header === 'fixed') return null;
                            return (
                                <React.Fragment key={header}>
                                    <Divider />
                                    <Typography
                                        sx={{
                                            color: "#d23831",
                                            fontWeight: "bold",
                                            fontSize: "1.1em",
                                            margin: "10px 0",
                                            textAlign: "center",
                                            fontFamily: "Avenir Heavy"
                                        }}
                                    >
                                        {header}
                                    </Typography>
                                    {dashboards.map((dashboard) => (
                                        <ListItem key={dashboard.key} disablePadding>
                                            <ListItemButton onClick={() => handleSectionClick({key: dashboard.key, type: dashboard.type})}>
                                                <Typography>{mapDashboards(dashboard.key)}</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default Header;