import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, MenuItem, Card, CardContent, CardMedia, Button, Breadcrumbs, Link, CircularProgress } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { getStatic } from "../../requests/getStatic";


const Static = ({
    keycloak,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [selectedStatic, setSelectedStatic] = useState("suma-y-sigue");
    const [loadingStatic, setLoadingStatic] = useState(false);
    const [currentPath, setCurrentPath] = useState("");
    const [data, setData] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState([]);

    const STATICS = {
        "suma-y-sigue": {
            "id": "2",
            "name": "Suma y Sigue",
            "url": "https://static.sumaysigue.uchile.cl"
        },
        "redfid": {
            "id": "3",
            "name": "RedFID",
            "url": "https://static.redfid.cl"
        },
        "matcon": {
            "id": "4",
            "name": "MatCon",
            "url": "https://static.matcon.cmmedu.uchile.cl"
        },
        "sumo-primero": {
            "id": "6",
            "name": "Sumo Primero",
            "url": "https://static.sumoprimero.cmmedu.uchile.cl"
        }
    }

    const fetchStatic = async (server, path) => {
        setLoadingStatic(true);
        try {
            const response = await getStatic(keycloak.token, server, path);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingStatic(false);
        }
    }


    const handleDirectoryClick = (subdir) => {
        const newPath = currentPath ? `${currentPath}/${subdir}` : subdir;
        setCurrentPath(newPath);
        setBreadcrumb([...breadcrumb, subdir]);
        fetchStatic(selectedStatic, newPath);
    };

    const handleBreadcrumbClick = (index) => {
        const newPath = breadcrumb.slice(0, index + 1).join("/");
        setCurrentPath(newPath);
        setBreadcrumb(breadcrumb.slice(0, index + 1));
        fetchStatic(selectedStatic, newPath);
    };

    useEffect(() => {
        if (selectedStatic) {
            fetchStatic(selectedStatic, "");
            setBreadcrumb([]);
            setCurrentPath("");
        }
    }, [selectedStatic]);

    return (
        <>
            <Typography fontSize={{ xs: "1.2em", md: "1.6em" }} mb={"10px"} variant="title">Servidores Static</Typography>
            <Typography fontSize={{ xs: "0.8em", md: "1.0em" }} variant="title">Gestión de archivos estáticos con acceso público</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Servidor"
                        value={selectedStatic}
                        fullWidth
                        select
                        onChange={(e) => setSelectedStatic(e.target.value)}
                    >
                        {Object.keys(STATICS).map((key) => (
                            <MenuItem key={key} value={key}>
                                {STATICS[key].name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Link onClick={() => handleBreadcrumbClick(-1)} style={{ cursor: "pointer" }}>
                            /
                        </Link>
                        {breadcrumb.map((crumb, index) => (
                            <Link key={index} onClick={() => handleBreadcrumbClick(index)} style={{ cursor: "pointer" }}>
                                {crumb}
                            </Link>
                        ))}
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                {loadingStatic ? (
                    <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {data.filter(item => item.type === "directory").length > 0 &&
                            <Grid item xs={12}>
                                <Typography fontSize={{ xs: "1.0em", md: "1.2em" }} variant="title">Subdirectorios</Typography>
                            </Grid>
                        }
                        {data.filter(item => item.type === "directory").map((item, index) => (
                            <Grid item key={index} xs={6} sm={4} md={3} display={"flex"}>
                                <Button variant="static" fullWidth onClick={() => handleDirectoryClick(item.name)}>
                                    {item.name}
                                </Button>
                            </Grid>
                        ))}
                        {data.filter(item => item.type === "file").length > 0 &&
                            <Grid item xs={12}>
                                <Typography fontSize={{ xs: "1.0em", md: "1.2em" }} variant="title">Archivos</Typography>
                            </Grid>
                        }

                        {data.filter(item => item.type === "file").map((item, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4} display={"flex"}>
                                <Card sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    {(item.name.endsWith(".png") || item.name.endsWith(".jpg") || item.name.endsWith(".jpeg") || item.name.endsWith(".gif")) ? (
                                        <CardMedia
                                            component="img"
                                            image={item.url}
                                            alt={item.name}
                                            sx={{
                                                objectFit: "contain",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        />
                                    ) : item.name.endsWith(".mp4") ? (
                                        <CardMedia
                                            component="video"
                                            controls
                                            src={item.url}
                                            alt={item.name}
                                            sx={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                        />
                                    ) : item.name.endsWith(".pdf") ? (
                                        <embed
                                            src={item.url}
                                            type="application/pdf"
                                            width="100%"
                                            height="100%"
                                        />
                                    ) : item.name.endsWith(".svg") ? (
                                        <CardMedia
                                            component="img"
                                            image={item.url}
                                            alt={item.name}
                                            sx={{
                                                objectFit: "contain",
                                                width: "100%",
                                                height: "auto",
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "16px",
                                                height: "150px",
                                            }}
                                        >
                                            <InsertDriveFileIcon fontSize="large" />
                                            <Typography variant="p" fontWeight={"bold"}>
                                                .{item.name.split('.').pop()}
                                            </Typography>
                                        </div>
                                    )}

                                    {/* File Info */}
                                    <CardContent>
                                        <Typography
                                            variant="p-small"
                                            title={`${STATICS[selectedStatic].url}${currentPath !== "" ? "/" + currentPath : ""}/${item.name}`} // Tooltip with full URL
                                        >
                                            <Link target="_blank" href={STATICS[selectedStatic].url + (currentPath !== "" ? "/" + currentPath : "") + "/" + item.name}>{STATICS[selectedStatic].url}{currentPath !== "" ? "/" + currentPath : ""}/{item.name}</Link>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}


                    </>
                )}
            </Grid>
        </>
    );
};

export default Static;