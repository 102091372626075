import React, { useState, useEffect } from 'react';
import { MenuItem, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, TextField, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import "dayjs/locale/es";
import { putUser } from '../../../requests/putUser';


const ProfesorEditModal = ({
    keycloak,
    open,
    handleClose,
    selectedUser,
    setUsers,
    regions,
    establecimientos,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {

    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({ "schools": [] });

    const [fullName, setFullName] = useState("");
    const [platformEmail, setPlatformEmail] = useState("");
    const [platformUsername, setPlatformUsername] = useState("");
    const [run, setRun] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName1, setLastName1] = useState("");
    const [lastName2, setLastName2] = useState("");
    const [preferredContactMethod, setPreferredContactMethod] = useState("none");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("none");
    const [title, setTitle] = useState("none");
    const [email, setEmail] = useState("");
    const [isStaff, setIsStaff] = useState(false);
    const [region, setRegion] = useState("none");
    const [comuna, setComuna] = useState("none");
    const [schools, setSchools] = useState([]);

    const validateForm = () => {
        let errors = { "schools": [] };
        if (firstName !== "") {
            if (firstName.length < 2) {
                errors.first_name = "El nombre debe tener al menos 2 caracteres.";
            } else if (firstName.length > 50) {
                errors.first_name = "El nombre debe tener menos de 50 caracteres.";
            }
        }
        if (lastName1 !== "") {
            if (lastName1.length < 2) {
                errors.last_name_1 = "El apellido paterno debe tener al menos 2 caracteres.";
            } else if (lastName1.length > 50) {
                errors.last_name_1 = "El apellido paterno debe tener menos de 50 caracteres.";
            }
        }
        if (lastName2 !== "") {
            if (lastName2.length < 2) {
                errors.last_name_2 = "El apellido materno debe tener al menos 2 caracteres.";
            } else if (lastName2.length > 50) {
                errors.last_name_2 = "El apellido materno debe tener menos de 50 caracteres.";
            }
        }
        if (email !== "") {
            if (!String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                errors.email = "El correo electrónico no es válido.";
            }
        }
        if (phone !== "") {
            if (!String(phone).match(/^[0-9]+$/)) {
                errors.phone = "El teléfono debe contener solo números.";
            } else if (phone.length < 9) {
                errors.phone = "El teléfono debe tener al menos 9 caracteres.";
            } else if (phone.length > 15) {
                errors.phone = "El teléfono debe tener menos de 15 caracteres.";
            }
        }
        let currentNames = [];
        for (let i = 0; i < schools.length; i++) {
            if (schools[i].region === "none") {
                errors.schools[i] = { ...errors.schools[i], "region": "Seleccione una región." };
            }
            if (schools[i].comuna === "none") {
                errors.schools[i] = { ...errors.schools[i], "comuna": "Seleccione una comuna." };
            }
            if (schools[i].name === "none") {
                errors.schools[i] = { ...errors.schools[i], "name": "Seleccione un establecimiento." };
            } else if (currentNames.includes(schools[i].name)) {
                errors.schools[i] = { ...errors.schools[i], "name": "Ya ha seleccionado este establecimiento." };
            } else {
                currentNames.push(schools[i].name);
            }

            if (schools[i].startTime == null) {
                errors.schools[i] = { ...errors.schools[i], "startTime": "Seleccione una fecha de inicio." };
            } else {
                if (!dayjs(schools[i].startTime).isValid()) {
                    errors.schools[i] = { ...errors.schools[i], "startTime": "La fecha de inicio no es válida." };
                }
            }

            if (schools[i].endTime != null) {
                if (!dayjs(schools[i].endTime).isValid()) {
                    errors.schools[i] = { ...errors.schools[i], "endTime": "La fecha de término no es válida." };
                } else {
                    if (dayjs(schools[i].endTime).isBefore(schools[i].startTime)) {
                        errors.schools[i] = { ...errors.schools[i], "endTime": "La fecha de término debe ser posterior a la fecha de inicio." };
                    }
                }
            }
        }
        return errors;
    }

    const handleEditUser = async () => {
        setErrors({ "schools": [] });
        let validateErrors = validateForm();
        if (Object.keys(validateErrors).length !== 1 || Object.keys(validateErrors["schools"]).length !== 0) {
            setErrors(validateErrors);
            return;
        }
        setFormLoading(true);
        let editData = {
            "id": selectedUser.id,
            "full_name": fullName,
            "is_staff": isStaff
        }
        editData.first_name = firstName;
        editData.last_name_1 = lastName1;
        editData.last_name_2 = lastName2;
        editData.personal_email = email !== "" ? email : null;
        editData.phone = phone;
        if (gender !== "none") {
            editData.gender = gender;
        }
        if (title !== "none") {
            editData.title = title;
        }
        if (preferredContactMethod !== "none") {
            editData.contact_method = preferredContactMethod;
        }
        if (region !== "none") {
            editData.region = regions.regions[region].name;
        }
        if (comuna !== "none") {
            editData.comuna = regions.regions[region].comunas[comuna];
        }
        if (schools.length > 0) {
            editData.schools = schools.map(school => {
                return {
                    "region": regions.regions[school.region].name,
                    "comuna": regions.regions[school.region].comunas[school.comuna],
                    "name": school.name,
                    "rbd": school.rbd,
                    "date_start": school.startTime,
                    "date_end": school.endTime
                }
            });
        } else {
            editData.schools = [];
        }
        let response = await putUser(keycloak.token, editData);
        if (response.error !== "") {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al editar profesor(a)", "text": response.error } });
        } else {
            setUsers(response.users);
            handleClose();
            setFullName("");
            setPlatformEmail("");
            setPlatformUsername("");
            setRun("");
            setFirstName("");
            setLastName1("");
            setLastName2("");
            setPreferredContactMethod("none");
            setPhone("");
            setEmail("");
            setIsStaff(false);
            setRegion("none");
            setComuna("none");
            setSchools([]);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Profesor(a) editado/a!`, "text": `Los datos del/la profesor(a) han sido editados correctamente.` } });
        }
        setFormLoading(false);
    }

    const handleAddSchool = () => {
        if (!formLoading) {
            setSchools([...schools, { region: "none", comuna: "none", name: "none", rbd: "", startTime: null, endTime: null }]);
        }
    }

    const handleRemoveSchool = (index) => {
        if (!formLoading) {
            let newSchools = schools.filter((school, i) => i !== index);
            setSchools(newSchools);
            setErrors({ ...errors, "schools": errors.schools.filter((error, i) => i !== index) });
        }
    }

    useEffect(() => {
        if (selectedUser === undefined) {
            handleClose();
            return;
        }
        setFullName(selectedUser.full_name ? selectedUser.full_name : "");
        setPlatformEmail(selectedUser.platform_email ? selectedUser.platform_email : "");
        setPlatformUsername(selectedUser.username ? selectedUser.username : "");
        setRun(selectedUser.run ? selectedUser.run : "");
        setFirstName(selectedUser.first_name !== "—" ? selectedUser.first_name : "");
        setLastName1(selectedUser.last_name_1 !== "—" ? selectedUser.last_name_1 : "");
        setLastName2(selectedUser.last_name_2 !== "—" ? selectedUser.last_name_2 : "");
        setPreferredContactMethod(selectedUser.contact_method !== "—" ? selectedUser.contact_method : "none");
        setPhone(selectedUser.phone !== "—" ? selectedUser.phone : "");
        setGender(selectedUser.gender !== "—" ? selectedUser.gender : "none");
        setTitle(selectedUser.title !== "—" ? selectedUser.title : "none");
        setEmail(selectedUser.personal_email !== "—" ? selectedUser.personal_email : "");
        setIsStaff(selectedUser.is_staff);
        setRegion(selectedUser.region !== "—" ? regions.regions.findIndex(region => region.name === selectedUser.region) : "none");
        setComuna((selectedUser.comuna !== "—") ? regions.regions[regions.regions.findIndex(region => region.name === selectedUser.region)].comunas.findIndex(comuna => comuna === selectedUser.comuna) : "none");
        if (selectedUser.schools) {
            const schoolsToSet = selectedUser.schools.map(school => {
                return {
                    region: regions.regions.findIndex(region => region.name === school.region),
                    comuna: regions.regions[regions.regions.findIndex(region => region.name === school.region)].comunas.findIndex(comuna => comuna.toUpperCase() === school.comuna),
                    name: school.name,
                    rbd: school.rbd,
                    startTime: school.date_start ? dayjs(school.date_start) : null,
                    endTime: school.date_end ? dayjs(school.date_end) : null
                }
            });
            setSchools(schoolsToSet);
        } else {
            setSchools([]);
        }
        setErrors({ "schools": [] });
    }, [selectedUser]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Editar datos de profesor
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="p" sx={{ "fontWeight": "bold" }} mb={"10px"}>Datos personales</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="RUT"
                            variant="outlined"
                            value={run}
                            disabled={true}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            label="Nombre en plataforma"
                            variant="outlined"
                            value={fullName}
                            disabled={true}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre de usuario"
                            variant="outlined"
                            value={platformUsername}
                            disabled={true}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Correo electrónico en plataforma"
                            variant="outlined"
                            value={platformEmail}
                            disabled={true}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Apellido paterno"
                            variant="outlined"
                            value={lastName1}
                            onChange={(e) => {
                                setLastName1(e.target.value);
                                setErrors({ ...errors, "last_name_1": "" });
                            }}
                            error={!!errors.last_name_1}
                            helperText={errors.last_name_1}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Apellido materno"
                            variant="outlined"
                            value={lastName2}
                            onChange={(e) => {
                                setLastName2(e.target.value);
                                setErrors({ ...errors, "last_name_2": "" });
                            }}
                            error={!!errors.last_name_2}
                            helperText={errors.last_name_2}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre(s)"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                setErrors({ ...errors, "first_name": "" });
                            }}
                            error={!!errors.first_name}
                            helperText={errors.first_name}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Correo electrónico personal"
                            variant="outlined"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setErrors({ ...errors, "email": "" });
                            }}
                            error={!!errors.email}
                            helperText={errors.email}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Teléfono"
                            variant="outlined"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                                setErrors({ ...errors, "phone": "" });
                            }}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            disabled={formLoading}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Método de contacto preferido"
                            variant="outlined"
                            select
                            value={preferredContactMethod}
                            onChange={(e) => {
                                setPreferredContactMethod(e.target.value);
                                setErrors({ ...errors, "preferred_contact_method": "" });
                            }}
                            error={!!errors.preferred_contact_method}
                            helperText={errors.preferred_contact_method}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"whatsapp"}>WhatsApp</MenuItem>
                            <MenuItem value={"phone"}>Teléfono</MenuItem>
                            <MenuItem value={"email"}>Email</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="¿Es staff?"
                            variant="outlined"
                            select
                            value={isStaff}
                            onChange={(e) => {
                                setIsStaff(e.target.value);
                                setErrors({ ...errors, "is_staff": "" });
                            }}
                            error={!!errors.is_staff}
                            helperText={errors.is_staff}
                            disabled={formLoading}
                        >
                            <MenuItem value={true}>Sí</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Género"
                            variant="outlined"
                            select
                            value={gender}
                            onChange={(e) => {
                                setGender(e.target.value);
                                setErrors({ ...errors, "gender": "" });
                            }}
                            error={!!errors.gender}
                            helperText={errors.gender}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"male"}>Masculino</MenuItem>
                            <MenuItem value={"female"}>Femenino</MenuItem>
                            <MenuItem value={"other"}>Otro</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            label="Título profesional"
                            variant="outlined"
                            select
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setErrors({ ...errors, "title": "" });
                            }}
                            error={!!errors.title}
                            helperText={errors.title}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            <MenuItem value={"no-title"}>Sin título</MenuItem>
                            <MenuItem value={"basica"}>Profesor(a) de Educación General Básica</MenuItem>
                            <MenuItem value={"matematicas"}>Profesor(a) de Matemáticas</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Región de residencia"
                            variant="outlined"
                            select
                            value={region}
                            onChange={(e) => {
                                setComuna("none");
                                setRegion(e.target.value);
                                setErrors({ ...errors, "region": "" });
                            }}
                            error={!!errors.region}
                            helperText={errors.region}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            {regions.regions.map((region, index) => (
                                <MenuItem key={index} value={index}>{region.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Comuna de residencia"
                            variant="outlined"
                            select
                            value={comuna}
                            onChange={(e) => {
                                setComuna(e.target.value);
                                setErrors({ ...errors, "comuna": "" });
                            }}
                            error={!!errors.comuna}
                            helperText={errors.comuna}
                            disabled={formLoading || region === "none"}
                        >
                            <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                            {(region != null && region !== "none") && regions.regions[region].comunas.map((comuna, index) => (
                                <MenuItem key={index} value={index}>{comuna}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="p" sx={{ "fontWeight": "bold" }} mb={"20px"}>Establecimientos</Typography>
                    </Grid>
                </Grid>
                {schools.map((school, index) => (
                    <Grid key={index} container spacing={2} mb={"20px"}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Región"
                                variant="outlined"
                                select
                                value={school.region}
                                onChange={(e) => {
                                    let newSchools = schools;
                                    newSchools[index].name = "none";
                                    newSchools[index].comuna = "none";
                                    newSchools[index].region = e.target.value;
                                    setSchools(newSchools);
                                    setErrors({
                                        ...errors,
                                        schools: [
                                            ...errors.schools.slice(0, index),
                                            { ...errors.schools[index], region: "", comuna: "", name: "" },
                                            ...errors.schools.slice(index + 1)
                                        ]
                                    });
                                }}
                                error={!!errors.schools[index]?.region}
                                helperText={errors.schools[index]?.region}
                                disabled={formLoading}
                            >
                                <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                                {regions.regions.map((region, index) => (
                                    <MenuItem key={index} value={index}>{region.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Comuna"
                                variant="outlined"
                                select
                                value={school.comuna}
                                onChange={(e) => {
                                    let newSchools = schools;
                                    newSchools[index].name = "none";
                                    newSchools[index].comuna = e.target.value;
                                    setSchools(newSchools);
                                    setErrors({
                                        ...errors,
                                        schools: [
                                            ...errors.schools.slice(0, index),
                                            { ...errors.schools[index], comuna: "", name: "" },
                                            ...errors.schools.slice(index + 1)
                                        ]
                                    });
                                }}
                                error={!!errors.schools[index]?.comuna}
                                helperText={errors.schools[index]?.comuna}
                                disabled={formLoading}
                            >
                                <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                                {(school.region != null && school.region !== "none") && regions.regions[school.region].comunas.map((comuna, index) => (
                                    <MenuItem key={index} value={index}>{comuna}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                fullWidth
                                label="Establecimiento"
                                variant="outlined"
                                select
                                value={school.name}
                                onChange={(e) => {
                                    let newSchools = schools;
                                    newSchools[index].name = e.target.value;
                                    newSchools[index].rbd = establecimientos.find(establecimiento => establecimiento.NOM_RBD === e.target.value).RBD;
                                    setSchools(newSchools);
                                    setErrors({
                                        ...errors,
                                        schools: [
                                            ...errors.schools.slice(0, index),
                                            { ...errors.schools[index], name: "" },
                                            ...errors.schools.slice(index + 1)
                                        ]
                                    });
                                }}
                                error={!!errors.schools[index]?.name}
                                helperText={errors.schools[index]?.name}
                                disabled={formLoading}
                            >
                                <MenuItem disabled value={"none"}>Seleccione una opción...</MenuItem>
                                {(school.comuna != null && school.comuna !== "none") && establecimientos.filter(e => e.NOM_COM_RBD === regions.regions[school.region].comunas[school.comuna].toUpperCase()).map((establecimiento, index) => (
                                    <MenuItem key={index} value={establecimiento.NOM_RBD}>{establecimiento.NOM_RBD}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="RBD"
                                variant="outlined"
                                value={school.rbd}
                                disabled={formLoading}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    label="Fecha de inicio"
                                    value={school.startTime}
                                    fullWidth
                                    sx={{ marginRight: "5px", width: "100%" }}
                                    onChange={(date) => {
                                        let newSchools = schools;
                                        newSchools[index].startTime = date;
                                        setSchools(newSchools);
                                        setErrors({
                                            ...errors,
                                            schools: [
                                                ...errors.schools.slice(0, index),
                                                { ...errors.schools[index], startTime: "" },
                                                ...errors.schools.slice(index + 1)
                                            ]
                                        });
                                    }}
                                    slotProps={{
                                        textField: {
                                            disabled: formLoading
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            {errors.schools[index]?.startTime && <Typography m={"3px 0px 0px 10px"} variant="p-small" color="error">{errors.schools[index].startTime}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    label="Fecha de término"
                                    value={school.endTime}
                                    fullWidth
                                    sx={{ marginLeft: "5px", width: "100%" }}
                                    onChange={(date) => {
                                        let newSchools = schools;
                                        newSchools[index].endTime = date;
                                        setSchools(newSchools);
                                        setErrors({
                                            ...errors,
                                            schools: [
                                                ...errors.schools.slice(0, index),
                                                { ...errors.schools[index], endTime: "" },
                                                ...errors.schools.slice(index + 1)
                                            ]
                                        });
                                    }}
                                    slotProps={{
                                        textField: {
                                            disabled: formLoading
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            {errors.schools[index]?.endTime && <Typography m={"3px 0px 0px 10px"} variant="p-small" color="error">{errors.schools[index].endTime}</Typography>}
                        </Grid>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"} mt={"20px"}>
                            <Typography sx={{ "cursor": formLoading ? "auto" : "pointer" }} onClick={() => handleRemoveSchool(index)} width={"fit-content"} variant="a" textAlign={"center"} mb={"10px"}>Quitar establecimiento</Typography>
                        </Grid>
                    </Grid>
                ))}
                <Grid container spacing={2}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Typography sx={{ "cursor": formLoading ? "auto" : "pointer" }} onClick={handleAddSchool} width={"fit-content"} variant="a" textAlign={"center"} mb={"30px"}>+ Agregar establecimiento</Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button startIcon={<EditIcon></EditIcon>} variant="red" onClick={handleEditUser} disabled={formLoading}>Editar</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ProfesorEditModal;