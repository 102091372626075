import React, { useEffect } from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EvaluacionQuestionResponsesTable from '../tables/EvaluacionQuestionResponsesTable';
import PreTestPostTestResponsesTable from '../tables/PreTestPostTestResponsesTable';
import { answersHistogramChartMultipleChoice, answersHistogramChartVOF, answersPieChart } from '../../../utils/charts';


const EvaluacionQuestionDetailsModal = ({
    open,
    handleClose,
    xblock,
    responses,
    renderedXblock,
    enrolledUsers,
    title,
    subtitle,
    isPreTestPostTest,
    identifier,
    isOra,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    useEffect(() => {
        if (open && responses) {
            setTimeout(() => {
                if (xblock.true_block_type === "multiplechoice" || xblock.true_block_type === "radiomultiplechoice") {
                    if (isPreTestPostTest) {
                        answersHistogramChartMultipleChoice(
                            {
                                preTest: responses.preTest.map(response => {
                                    const tempDiv = document.createElement('div');
                                    tempDiv.innerHTML = response.response;
                                    return tempDiv.textContent.slice(0, 1);
                                }),
                                postTest: responses.postTest.map(response => {
                                    const tempDiv = document.createElement('div');
                                    tempDiv.innerHTML = response.response;
                                    return tempDiv.textContent.slice(0, 1);
                                })
                            },
                            renderedXblock,
                            true
                        );
                    } else {
                        answersHistogramChartMultipleChoice(
                            responses.map(response => {
                                const tempDiv = document.createElement('div');
                                tempDiv.innerHTML = response.response;
                                return tempDiv.textContent.slice(0, 1);
                            }),
                            renderedXblock,
                            false
                        );
                    }
                } else if (xblock.true_block_type === "vof") {
                    if (isPreTestPostTest) {
                        // Don't render VOF chart for pre/post test
                    } else {
                        answersHistogramChartVOF(
                            responses.map(response => response.response),
                            renderedXblock,
                            false
                        );
                    }
                }
                if (xblock.true_block_type !== "openassessment" && xblock.true_block_type !== "iterativexblock") {
                    if (isPreTestPostTest) {
                        answersPieChart(
                            {
                                preTest: responses.preTest.map(response => response.is_correct),
                                postTest: responses.postTest.map(response => response.is_correct)
                            },
                            true
                        );
                    } else {
                        answersPieChart(
                            responses.map(response => response.is_correct),
                            false
                        );
                    }
                }
            }, 100);
        }
    }, [responses, open, xblock.true_block_type, renderedXblock]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"lg"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                {title} — Pregunta {subtitle}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                {responses ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {renderedXblock}
                        </Grid>
                        <Grid item xs={12}>
                            {!isPreTestPostTest ?
                                <EvaluacionQuestionResponsesTable
                                    xblock={xblock}
                                    responses={responses}
                                    enrolledUsers={enrolledUsers}
                                    title={title}
                                    identifier={identifier}
                                    number={subtitle}
                                    isOra={isOra}
                                    setAlertSuccess={setAlertSuccess}
                                    setAlertError={setAlertError}
                                    alerts={alerts}
                                    setAlerts={setAlerts}
                                />
                                :
                                <PreTestPostTestResponsesTable
                                    xblock={xblock}
                                    responses={responses}
                                    enrolledUsers={enrolledUsers}
                                    title={title}
                                    identifier={identifier}
                                    number={subtitle}
                                    isOra={isOra}
                                    setAlertSuccess={setAlertSuccess}
                                    setAlertError={setAlertError}
                                    alerts={alerts}
                                    setAlerts={setAlerts}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={6} id="answers-histogram-container">
                        </Grid>
                        <Grid item xs={12} md={6} id="answers-pie-container">
                        </Grid>
                    </Grid> :
                    <Box>
                        <Typography textAlign="center" variant="p-error">Error al obtener datos del XBlock</Typography>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
}

export default EvaluacionQuestionDetailsModal;