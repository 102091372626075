import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ResumenUserActionsModal from '../modals/ResumenUserActionsModal';
import { calculateEvaluationGrade } from '../../../utils/formatters';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const ResumenTable = ({
    keycloak,
    isDiplomado,
    courseKey,
    sectionData,
    identifier,
    allResponses,
    evaluations,
    manualGrades,
    situaciones,
    setSituaciones,
    asistencia,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess,
    grading
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const [selectedUser, setSelectedUser] = useState(null);
    const [actionsModal, setActionsModal] = useState(false);

    const handleDownloadResumenCSV = () => {
        let header = [
            "Usuario",
            "Situación actual",
            "Avance",
            "Encuestas respondidas",
            "Asistencia",
            "Nota controles",
            "Nota final",
            "Situación final CMM",
            "Situación final CPEIP",
            "Observaciones"
        ];
        let lines = [];
        let output = [];
        for (let user of sectionData.enrolled_users) {
            var thisLine = [];
            thisLine.push(user.username);
            thisLine.push(situaciones.find(situacion => situacion.user_id === user.user_id)?.current_situation || "—");
            thisLine.push(calculateOverallProgress(user.user_id));
            thisLine.push(calculateEncuestasRespondidas(user.user_id));
            thisLine.push("—");
            thisLine.push("—");
            thisLine.push("—");
            thisLine.push(situaciones.find(situacion => situacion.user_id === user.user_id)?.final_situation || "—");
            thisLine.push(situaciones.find(situacion => situacion.user_id === user.user_id)?.final_situation_cpeip || "—");
            thisLine.push(situaciones.find(situacion => situacion.user_id === user.user_id)?.observaciones || "—");
            lines.push(thisLine);
        }
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        const fileName = "resumen_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'username',
            disableColumnMenu: true,
            headerName: 'Usuario',
            sortable: false,
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'situacion_actual',
            disableColumnMenu: true,
            headerName: 'Situación actual',
            sortable: false,
            width: 130,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'avance_plataforma',
            disableColumnMenu: true,
            headerName: 'Avance',
            sortable: false,
            width: 70,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'encuestas_respondidas',
            disableColumnMenu: true,
            headerName: 'Encuestas',
            sortable: false,
            width: 90,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'asistencia',
            disableColumnMenu: true,
            headerName: 'Asistencia',
            sortable: false,
            width: 90,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nota_final',
            disableColumnMenu: true,
            headerName: 'Nota final',
            sortable: false,
            width: 90,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'situacion_final',
            disableColumnMenu: true,
            headerName: 'Situación final',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box>
                    <Typography variant='p-small'>
                        CMM: {params.row.situacion_final.cmm}
                    </Typography>
                    <Typography variant='p-small'>
                        CPEIP: {params.row.situacion_final.cpeip}
                    </Typography>
                </Box>

            ),
        },
        {
            field: 'observaciones',
            disableColumnMenu: true,
            headerName: 'Observaciones',
            sortable: false,
            width: 300,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'actions',
            disableColumnMenu: true,
            headerName: 'Gestionar',
            sortable: false,
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={() => { setSelectedUser(params.row); setActionsModal(true) }}
                >
                    <MoreVertIcon />
                </IconButton>
            ),
        },
    ];

    const calculateOverallProgress = (userId) => {
        let totalAnswered = 0;
        let totalQuestions = 0;

        // Only count Taller sections (similar to AvanceTable)
        sectionData.sections
            .filter(section => section.name.toLowerCase().includes('taller'))
            .forEach(section => {
                const xblocks = section.subsections.flatMap(subsection =>
                    subsection.units.flatMap(unit =>
                        (!unit.name.toLowerCase().startsWith('control') && 
                         !unit.name.toLowerCase().startsWith('recapitulemos') && 
                         !unit.name.toLowerCase().startsWith('encuesta') && 
                         !unit.name.toLowerCase().startsWith('firma reglamento') && 
                         !unit.name.toLowerCase().startsWith('consentimiento') && 
                         !unit.name.toLowerCase().startsWith('pregunta calificada')) ?
                            unit.xblocks.filter(xblock => 
                                ["drag-and-drop-v2", "textinput", "table_advanced_problem", "radiomultiplechoice", "multiplechoice", "checkboxes", "dropdown", "dialogsquestionsxblock", "vof", "clase3", "freetextresponse"].includes(xblock.true_block_type)
                            )
                        : []
                    )
                );

                totalQuestions += xblocks.length;

                xblocks.forEach(xblock => {
                    const responseKey = `${xblock.id}_${userId}`;
                    const response = allResponses[responseKey];
                    
                    if (response) {
                        totalAnswered++;
                    }
                });
            });

        return totalQuestions === 0 ? "0%" : Math.round((totalAnswered / totalQuestions) * 100) + "%";
    };

    const calculateEncuestasRespondidas = (userId) => {
        let respondedEncuestas = 0;
        let totalEncuestas = 0;

        // Using the same logic as AvanceTable
        sectionData.sections.forEach(section => {
            section.subsections
                .filter(subsection => subsection.name.toLowerCase().startsWith("encuesta"))
                .forEach(subsection => {
                    totalEncuestas++;
                    
                    const encuestaXblocks = subsection.units.flatMap(unit => 
                        unit.xblocks.filter(xblock => 
                            xblock.display_name.includes("Custom JavaScript Display and Grading")
                        )
                    );

                    if (encuestaXblocks.length === 0) return;

                    let answeredQuestions = 0;
                    encuestaXblocks.forEach(xblock => {
                        const responseKey = `${xblock.id}_${userId}`;
                        const response = allResponses[responseKey];

                        if (response && response.response) {
                            try {
                                const parsedResponse = JSON.parse(response.response);
                                if (parsedResponse.student_answers) {
                                    answeredQuestions++;
                                }
                            } catch (e) {
                                console.error("Error parsing response:", e);
                            }
                        }
                    });

                    // Consider encuesta responded if at least 50% of questions are answered
                    if (answeredQuestions / encuestaXblocks.length >= 0.5) {
                        respondedEncuestas++;
                    }
                });
        });

        return `${respondedEncuestas}/${totalEncuestas}`;
    };

    const calculateAsistencia = (userId) => {
        if (!asistencia || asistencia.length === 0) return "—";
        
        let eventsAttended = 0;
        asistencia.forEach(event => {
            const userAttendance = event.asistencia.find(a => a.user_id === userId);
            if (userAttendance && (userAttendance.assistance === "1" || userAttendance.assistance === "2")) {
                eventsAttended++;
            }
        });
        
        return `${eventsAttended}/${asistencia.length}`;
    };

    const getSummaryData = () => {
        const allUsers = new Set();
        [...evaluations.controles, ...evaluations.tests].forEach(evaluation => {
            evaluation.contents?.forEach(content => {
                content.responses?.forEach(response => {
                    allUsers.add(response.user_id);
                });
            });
        });

        const summaryData = Array.from(allUsers).map(userId => {
            const userSummary = {
                username: userId,
                grades: {
                    controles: {},
                    tests: {},
                    other: {}
                }
            };

            // Calculate grades for controls
            evaluations.controles.forEach((control, index) => {
                const scale = grading.scale;

                const userResponses = control.contents?.map(content => {
                    return content.responses?.find(r => r.user_id === userId) || {
                        score: 0,
                        max_score: content.max_score || 12
                    };
                }) || [];

                userSummary.grades.controles[`Control ${index + 1}`] = calculateEvaluationGrade(userResponses, scale);
            });

            // Calculate grades for tests
            evaluations.tests.forEach((test, index) => {
                if (test.name === "Pre-Test - Post-Test") {
                    return;
                }
                const scale = grading.scale;

                const userResponses = test.contents?.map(content => {
                    return content.responses?.find(r => r.user_id === userId) || {
                        score: 0,
                        max_score: content.max_score || 12
                    };
                }) || [];

                userSummary.grades.tests[test.name] = calculateEvaluationGrade(userResponses, scale);
            });

            // Calculate grades for other rates
            Object.keys(manualGrades).forEach(grade => {
                let evaluation = manualGrades[grade]
                if (Object.keys(evaluation)[0] === "0" && evaluation[0].find(ev => ev.user_id === userId)) {
                    userSummary.grades.other[grade] = evaluation[0].find(user => user.user_id === userId).grade;
                }
            });

            return userSummary;
        });

        return summaryData;
    }

    function makeRows() {
        let rows = [];
        const summaryData = getSummaryData();

        for (let user of sectionData.enrolled_users) {
            let this_situacion = situaciones.find(situacion => situacion.user_id === user.user_id);
            let userSummary = summaryData.find(data => data.username === user.user_id);

            // Calculate nota controles (average of all controls)
            let notaControles = '—';
            if (userSummary && Object.keys(userSummary.grades.controles).length > 0) {
                const controlGrades = Object.values(userSummary.grades.controles);
                notaControles = (controlGrades.reduce((sum, grade) => sum + grade, 0) / controlGrades.length).toFixed(1);
            }

            // Calculate nota final
            let notaFinal = '—';
            if (userSummary) {
                let finalGrade = 0;
                let totalWeight = 0;

                // Add controles grade if exists
                if (grading?.controles_rate > 0 && notaControles !== '—') {
                    finalGrade += parseFloat(notaControles) * (grading.controles_rate / 100);
                    totalWeight += grading.controles_rate;
                }

                // Add preguntas calificadas grade if exists
                if (grading?.preguntas_calificadas_rate > 0 && userSummary.grades.tests["Preguntas Calificadas"]) {
                    finalGrade += userSummary.grades.tests["Preguntas Calificadas"] * (grading.preguntas_calificadas_rate / 100);
                    totalWeight += grading.preguntas_calificadas_rate;
                }

                // Add avance grade if exists
                if (grading?.avance_rate > 0) {
                    const avancePercentage = parseFloat(calculateOverallProgress(user.user_id).replace('%', ''));
                    if (avancePercentage > 0) {
                        const avanceGrade = avancePercentage >= grading.scale ? 
                            7 : 
                            Math.max(1, Math.min(6.9, (avancePercentage * 7) / grading.scale));
                        finalGrade += avanceGrade * (grading.avance_rate / 100);
                        totalWeight += grading.avance_rate;
                    }
                }

                // Add other rates if they exist
                if (userSummary.grades.other && grading?.other_rates?.length > 0) {
                    grading.other_rates.forEach(rate => {
                        const otherGrade = userSummary.grades.other[rate.name];
                        if (otherGrade) {
                            finalGrade += otherGrade * (rate.value / 100);
                            totalWeight += rate.value;
                        }
                    });
                }

                // Calculate final grade if we have any weights
                if (totalWeight > 0) {
                    notaFinal = (finalGrade * 100 / totalWeight).toFixed(1);
                }
            }

            rows.push({
                id: user.user_id,
                username: user.username,
                asistencia: calculateAsistencia(user.user_id),
                avance_plataforma: calculateOverallProgress(user.user_id),
                encuestas_respondidas: calculateEncuestasRespondidas(user.user_id),
                situacion_actual: this_situacion?.current_situation || "—",
                situacion_final: this_situacion ? 
                    {"cmm": this_situacion?.final_situation || "—",
                    "cpeip": this_situacion?.final_situation_cpeip || "—"}
                    :
                    {"cmm": "—", "cpeip": "—"},
                nota_controles: notaControles,
                nota_final: notaFinal,
                observaciones: this_situacion?.observaciones || "—"
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "16px 10px 15px 16px" }}>
                <Typography variant='title' fontSize={"1.2rem"} width="fit-content">Resumen de avance</Typography>
                <Box>
                    <IconButton onClick={handleDownloadResumenCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        sectionData.enrolled_users.length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p' textAlign={"center"}>No hay información disponible.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1180px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'username',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                {selectedUser &&
                    <>
                        <ResumenUserActionsModal
                            keycloak={keycloak}
                            open={actionsModal}
                            courseKey={courseKey}
                            courseType={isDiplomado ? "diplomado" : "course"}
                            handleClose={() => setActionsModal(false)}
                            selectedUser={selectedUser}
                            situacion={situaciones.find(situacion => situacion.user_id === selectedUser.id)}
                            setSituaciones={setSituaciones}
                            setAlertSuccess={setAlertSuccess}
                            setAlertError={setAlertError}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                    </>
                }
            </div>
    );
};

export default ResumenTable;