import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountIcon from '@mui/icons-material/AccountCircle';
import NotasManualesMultipleViewModal from '../modals/NotasManualesMultipleViewModal';
import NotasManualesMultipleEditModal from '../modals/NotasManualesMultipleEditModal';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const NotasManualesTableMultiple = ({
    keycloak,
    courseKey,
    courseType,
    enrolledUsers,
    evaluations,
    handleEdit,
    identifier,
    title,
    setManualMultiGrades,
    alerts,
    setAlerts,
    setAlertSuccess,
    setAlertError
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [selectedEvaluation, setSelectedEvaluation] = useState(null);
    const [viewModal, setViewModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);


    const handleDownloadEncuestaQuestionResponsesCSV = () => {
        let header = [
            "Evaluación",
            "Promedio",
            "Desviación",
            "Mediana",
            "Mínima",
            "Máxima"
        ];
        let lines = [];
        let output = [];
        for (let [key, value] of Object.entries(evaluations)) {
            var thisLine = [];
            let average_grade = value.grades.some(grade => grade.grade !== "") ? (value.grades.reduce((sum, grade) => sum + parseFloat(grade.grade), 0) / value.grades.length).toFixed(1) : "—";
            let std_dev = value.grades.some(grade => grade.grade !== "") ? Math.sqrt(value.grades.reduce((sum, grade) => sum + Math.pow(parseFloat(grade.grade) - parseFloat(average_grade), 2), 0) / value.grades.length).toFixed(1) : "—";
            let median_grade = value.grades.some(grade => grade.grade !== "") ? value.grades.sort((a, b) => parseFloat(a.grade) - parseFloat(b.grade))[Math.floor(value.grades.length / 2)].grade.toFixed(1) : "—";
            let min_grade = value.grades.some(grade => grade.grade !== "") ? value.grades.sort((a, b) => parseFloat(a.grade) - parseFloat(b.grade))[0].grade.toFixed(1) : "—";
            let max_grade = value.grades.some(grade => grade.grade !== "") ? value.grades.sort((a, b) => parseFloat(b.grade) - parseFloat(a.grade))[0].grade.toFixed(1) : "—";
            thisLine.push(value.name);
            thisLine.push(average_grade);
            thisLine.push(std_dev);
            thisLine.push(median_grade);
            thisLine.push(min_grade);
            thisLine.push(max_grade);
            lines.push(thisLine);
        }
        
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        
        const fileName = "notas_" + title.replace(/ /g, "_") + "_" + identifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const columns = [
        {
            field: 'evaluation_name',
            disableColumnMenu: true,
            headerName: 'Evaluación',
            width: 300,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'average_grade',
            disableColumnMenu: true,
            headerName: 'Promedio',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'std_dev',
            disableColumnMenu: true,
            headerName: 'Desviación',
            width: 130,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'median_grade',
            disableColumnMenu: true,
            headerName: 'Mediana',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'min_grade',
            disableColumnMenu: true,
            headerName: 'Mínima',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'max_grade',
            disableColumnMenu: true,
            headerName: 'Máxima',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'actions',
            disableColumnMenu: true,
            headerName: 'Acciones',
            width: 120,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu ev={params.row} />
            ),
        }
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(evaluations)) {
            let average_grade = value.grades.some(grade => grade.grade !== "") ? (value.grades.reduce((sum, grade) => sum + parseFloat(grade.grade), 0) / value.grades.length).toFixed(1) : "—";
            let std_dev = value.grades.some(grade => grade.grade !== "") ? Math.sqrt(value.grades.reduce((sum, grade) => sum + Math.pow(parseFloat(grade.grade) - parseFloat(average_grade), 2), 0) / value.grades.length).toFixed(1) : "—";
            let median_grade = value.grades.some(grade => grade.grade !== "") ? value.grades.sort((a, b) => parseFloat(a.grade) - parseFloat(b.grade))[Math.floor(value.grades.length / 2)].grade.toFixed(1) : "—";
            let min_grade = value.grades.some(grade => grade.grade !== "") ? value.grades.sort((a, b) => parseFloat(a.grade) - parseFloat(b.grade))[0].grade.toFixed(1) : "—";
            let max_grade = value.grades.some(grade => grade.grade !== "") ? value.grades.sort((a, b) => parseFloat(b.grade) - parseFloat(a.grade))[0].grade.toFixed(1) : "—";
            rows.push({
                id: key,
                evaluation_name: value.name,
                evaluation_index: value.evaluation_index,
                average_grade: average_grade,
                std_dev: std_dev,
                median_grade: median_grade,
                min_grade: min_grade,
                max_grade: max_grade,
                grades: value.grades
            });
        }
        return rows;
    }

    const ActionsMenu = ({ ev }) => {

        const [anchorEl, setAnchorEl] = useState(null);

        const handleClickActions = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseActions = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClickActions}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseActions}
                >
                    <MenuItem
                        onClick={() => {
                            setSelectedEvaluation(ev);
                            setViewModal(true);
                        }}
                    >
                        <AccountIcon sx={{ "marginRight": "10px" }} /> Ver notas
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setSelectedEvaluation(ev);
                            setUpdateModal(true);
                        }}
                    >
                        <EditIcon sx={{ "marginRight": "10px" }} /> Actualizar notas
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Notas — {title}</Typography>
                <Box>
                    <IconButton onClick={handleEdit}><EditIcon></EditIcon></IconButton>
                    <IconButton onClick={handleDownloadEncuestaQuestionResponsesCSV}><DownloadIcon></DownloadIcon></IconButton> 
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        (evaluations === undefined || evaluations.length === 0) ?
            <Box width={"100%"} textAlign={"center"} mt={"20px"}>
                <Typography variant='p-small' textAlign={"center"}>No hay datos disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1070px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    getRowHeight={() => 'auto'}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'evaluation_name',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                <NotasManualesMultipleViewModal
                    keycloak={keycloak}
                    courseKey={courseKey}
                    courseType={courseType}
                    enrolledUsers={enrolledUsers}
                    open={viewModal}
                    handleClose={() => setViewModal(false)}
                    title={title}
                    identifier={identifier}
                    evaluations={evaluations}
                    selectedEvaluation={selectedEvaluation}
                    setAlertError={setAlertError}
                    setAlertSuccess={setAlertSuccess}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <NotasManualesMultipleEditModal
                    keycloak={keycloak}
                    courseKey={courseKey}
                    courseType={courseType}
                    enrolledUsers={enrolledUsers}
                    open={updateModal}
                    handleClose={() => setUpdateModal(false)}
                    title={title}
                    evaluations={evaluations}
                    selectedEvaluation={selectedEvaluation}
                    setManualMultiGrades={setManualMultiGrades}
                    setAlertError={setAlertError}
                    setAlertSuccess={setAlertSuccess}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </div>
    );
};

export default NotasManualesTableMultiple;