import React, { useState, useEffect } from 'react';
import { 
    Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button,
    TextField, Box, Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { postSetManualMultiGrades } from '../../../requests/postSetManualMultiGrades';

const MAX_EVALUATIONS = 20;

const NotasManualesMultipleModal = ({
    keycloak,
    courseKey,
    courseType,
    open,
    handleClose,
    title,
    manualMultiGrades,
    setManualMultiGrades,
    gradingObject,
    setAlertError,
    setAlertSuccess,
    alerts,
    setAlerts
}) => {
    
    const [evaluations, setEvaluations] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        
        // Check for duplicate names
        const names = evaluations.map(e => e.name.trim().toLowerCase());
        const hasDuplicates = names.length !== new Set(names).size;
        if (hasDuplicates) {
            newErrors.duplicate = "No puede haber evaluaciones con el mismo nombre";
        }

        // Validate each evaluation entry
        evaluations.forEach((evaluation, index) => {
            if (!evaluation.name || evaluation.name.trim() === '') {
                newErrors[`name_${index}`] = "El nombre es requerido";
            }
            if (evaluation.name && evaluation.name.length > 100) {
                newErrors[`name_${index}`] = "El nombre no puede exceder 100 caracteres";
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleUpdateEvaluations = async () => {
        if (!validateForm()) return;
        setFormLoading(true);

        // Map evaluations to include index
        const evaluationsWithIndex = evaluations.map((evaluation, index) => ({
            name: evaluation.name.trim(),  // Make sure to trim the name
            index: index + 1 // Using 1-based indexing
        }));

        const response = await postSetManualMultiGrades(
            keycloak.token, 
            title,  // This is the evaluation_name (e.g., "Tareas")
            evaluationsWithIndex,
            courseKey, 
            courseType
        );

        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { 
                "title": "Error al actualizar evaluaciones", 
                "text": response.error 
            }});
        } else {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { 
                "title": "¡Evaluaciones actualizadas!", 
                "text": "Las evaluaciones han sido actualizadas correctamente." 
            }});
            setManualMultiGrades(response.data);
        }
        handleClose();
        setFormLoading(false);
    };

    const addEvaluation = () => {
        if (evaluations.length >= MAX_EVALUATIONS) return;
        setEvaluations([...evaluations, { name: "" }]);
    };

    const removeEvaluation = (index) => {
        const newEvaluations = [...evaluations];
        newEvaluations.splice(index, 1);
        setEvaluations(newEvaluations);
    };

    const updateEvaluation = (index, name) => {
        const newEvaluations = [...evaluations];
        newEvaluations[index] = { name };
        setEvaluations(newEvaluations);
    };

    useEffect(() => {
        // Initialize evaluations from existing data
        if (manualMultiGrades && manualMultiGrades[title]) {
            // manualMultiGrades[title] is now an array of evaluations
            const existingEvals = manualMultiGrades[title]
                .sort((a, b) => a.evaluation_index - b.evaluation_index)
                .map(evaluation => ({
                    name: evaluation.name
                }));
            setEvaluations(existingEvals);
        } else {
            setEvaluations([]);
        }
    }, [manualMultiGrades, title]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Configurar Evaluaciones — {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography mb={"20px"} variant='p-small'>Nota: en caso de eliminar una evaluación, se eliminarán las notas asociadas a esta.</Typography>
                    </Grid>
                    {evaluations.map((evaluation, index) => (
                        <Grid item xs={12} key={index} container spacing={2} alignItems="center">
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label={`Nombre de la evaluación ${index + 1}`}
                                    value={evaluation.name}
                                    onChange={(e) => updateEvaluation(index, e.target.value)}
                                    error={!!errors[`name_${index}`]}
                                    helperText={errors[`name_${index}`]}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => removeEvaluation(index)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    {errors.duplicate && (
                        <Grid item xs={12}>
                            <Typography textAlign={"center"} variant='p-small' color="error">{errors.duplicate}</Typography>
                        </Grid>
                    )}
                    
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="center" gap={2}>
                        <IconButton 
                            onClick={addEvaluation}
                            disabled={evaluations.length >= MAX_EVALUATIONS}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                        <Button 
                            variant="red" 
                            onClick={handleUpdateEvaluations}
                            disabled={formLoading}
                        >
                            Actualizar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default NotasManualesMultipleModal;