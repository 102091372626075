import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import AvanceTable from '../../tables/AvanceTable';
import { getAvance } from '../../../../requests/getAvance';
import { getAllResponses } from '../../../../requests/getAllResponses';
import { plotAvance } from '../../../../utils/charts';


const CourseSectionAvance = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    allResponses,
    evaluations,
    selectedModule,
    selectedImplementation,
    selectedSection,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [avance, setAvance] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchAvance = async () => {
        setLoading(true)
        var courseKey;
        if (isDiplomado) {
            courseKey = course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].course_key
        } else {
            courseKey = course.implementations[selectedImplementation].sections[selectedSection].course_key
        }
        const response = await getAvance(keycloak.token, courseKey);
        if (!response.avance) {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener avance", text: response.error },
            });
        } else {
            setAvance(response.avance)
        }
        setLoading(false)
    }

    const makePlotAvance = () => {
        if (!avance || avance.length === 0) return;
        const tallerNames = [...new Set(avance.flatMap(user => 
            user.sections.map(section => section.section_name)
        ))];
        const series = tallerNames.map(tallerName => ({
            name: tallerName,
            data: avance.map(user => ({
                x: user.full_name,
                y: (() => {
                    const section = user.sections.find(s => s.section_name === tallerName);
                    return section ? [
                        new Date(section.first_response).getTime(),
                        new Date(section.last_response).getTime()
                    ] : null;
                })()
            })).filter(item => item.y !== null)
        }));
        plotAvance(series);
    }

    useEffect(() => {
        fetchAvance();
    }, []);

    useEffect(() => {
        if (avance) {
            //makePlotAvance();
        }
    }, [avance]);

    return (
        sectionData ?
            <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                {(!loading && avance) ?
                    <Box width={"100%"}>
                        <AvanceTable
                            sectionData={sectionData}
                            identifier={
                                isDiplomado ?
                                    course.shortname + "_" +
                                    course.modules[selectedModule].module + "_" +
                                    course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                    course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                    course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                    :
                                    course.shortname + "_" +
                                    course.implementations[selectedImplementation].year + "_" +
                                    course.implementations[selectedImplementation].term + "_" +
                                    course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                    course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                    course.implementations[selectedImplementation].sections[selectedSection].section
                            }
                            scale={
                                isDiplomado ?
                                    course.modules[selectedModule].implementations[selectedImplementation].grading?.scale || 60 :
                                    course.implementations[selectedImplementation].grading?.scale || 60
                            }
                            evaluations={evaluations}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setAlertError={setAlertError}
                            setAlertSuccess={setAlertSuccess}
                            allResponses={allResponses}
                        />
                        <div id="avance-plot-container"></div>
                    </Box>
                    : loading ?
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <CircularProgress />
                        </Box>
                        :
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
                        </Box>
                }
            </Box>
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>
    );

}

export default CourseSectionAvance;