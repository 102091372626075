import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Grid, Typography, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { putCourseSection } from "../../../../../../requests/catalog/putCourseSection";


const CatalogCourseSectionSeguimientoModal = ({
    keycloak,
    open,
    handleClose,
    courseIndex,
    implementationIndex,
    sectionIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [formLoading, setFormLoading] = useState(false);

    const handleSeguimientoSection = async () => {
        setFormLoading(true);
        let editData = {
            "id": catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex]._id,
            "course_implementation_id": catalog.courses[courseIndex].implementations[implementationIndex]._id,
            "course_key": catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].course_key,
            "location": catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].location,
            "section": catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].section,
            "seguimiento_enabled": !catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled
        }
        let response = await putCourseSection(keycloak.token, editData);
        if (response.catalog == null) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": `Error al ${catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled ? "inhabilitar" : "habilitar"} seguimiento`, "text": response.error } });
        } else {
            setCatalog(response.catalog);
            handleClose();
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": 
                `¡Seguimiento ${catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled ? "inhabilitado" : "habilitado"}!`, "text": `Se ha ${catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled ? "inhabilitado" : "habilitado"} el seguimiento para la sección ${catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].location}${catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].section} de la implementación ${catalog.courses[courseIndex].implementations[implementationIndex].year}-${catalog.courses[courseIndex].implementations[implementationIndex].term} (${catalog.courses[courseIndex].implementations[implementationIndex].type}) del curso ${catalog.courses[courseIndex].shortname}.` } 
            });
        }
        setFormLoading(false);
    }

    return (
        catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex] &&
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "Avenir Heavy", fontSize: "1.2em" }}>
                Seguimiento de sección
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={"0.8em"} mb={"10px"} variant="p">
                            ¿Está seguro que desea {catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled ? "inhabilitar" : "habilitar"} el seguimiento para la sección {catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].location}{catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].section} de la 
                            implementación {catalog.courses[courseIndex].implementations[implementationIndex].year + "-" + catalog.courses[courseIndex].implementations[implementationIndex].term} ({catalog.courses[courseIndex].implementations[implementationIndex].type}) 
                            del curso {catalog.courses[courseIndex].shortname}?
                            {!catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled && " Los datos se actualizarán semanalmente de manera automática."}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button startIcon={<VisibilityIcon />} variant='blue' onClick={handleSeguimientoSection} disabled={formLoading}>
                            {catalog.courses[courseIndex].implementations[implementationIndex].sections[sectionIndex].seguimiento_enabled ? "Inhabilitar" : "Habilitar"} seguimiento
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )

}

export default CatalogCourseSectionSeguimientoModal;