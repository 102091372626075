import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Grid, List, ListItem, ListItemButton, Divider } from '@mui/material';
import EncuestaAnswersModal from '../../modals/EncuestaAnswersModal';
import { answersEncuestaSlider, answersEncuestaMultipleChoice, answersEncuestaCheckboxes, answersEncuestaNumber } from '../../../../utils/charts';


const CourseSectionEncuestas = ({
    keycloak,
    course,
    isDiplomado,
    sectionData,
    surveys,
    selectedModule,
    selectedImplementation,
    selectedSection,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    
    const [surveyTab, setSurveyTab] = useState("Resumen");
    const [openModal, setOpenModal] = useState(false);
    const [currentSubsection, setCurrentSubsection] = useState(null);

    const handleOpenModal = (subsection) => {
        setCurrentSubsection(subsection);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setCurrentSubsection(null);
        setOpenModal(false);
    }

    const findResponses = (code) => {
        let encuesta = surveys.encuestas.find(encuesta => encuesta.name === surveyTab)?.contents;
        if (encuesta) {
            for (let content of encuesta) {
                for (let section of content.sections) {
                    for (let subsection of section.subsections) {
                        if (subsection.code === code) {
                            return content.responses.map(response => {
                                let jsonResponse = JSON.parse(response.response);
                                if (jsonResponse && jsonResponse.student_answers) {
                                    let responseText = Object.values(jsonResponse.student_answers)[0];
                                    if (responseText) {
                                        let answerText = JSON.parse(responseText);
                                        if (answerText) {
                                            let answer = answerText["answer"].replace("\"", "").replace("'", "").replace("EC_AÑOS.EXP", "EC_ANIOS_EXP");
                                            let answers = answer.split("_*_");
                                            let matchingAnswer = answers.find(a => a.startsWith(code));
                                            if (matchingAnswer) {
                                                if (subsection.type === "number") {
                                                    return {
                                                        value: Math.min(Math.max(0, parseInt(matchingAnswer.split(";_")[1])), 100),
                                                        userId: response.user_id,
                                                        timestamp: response.created
                                                    };
                                                } else {
                                                    return {
                                                        value: matchingAnswer.split(";_")[1],
                                                        userId: response.user_id,
                                                        timestamp: response.created
                                                    };
                                                }
                                            }
                                        }
                                    }
                                }
                                return null;
                            }).filter(Boolean);
                        }
                    }
                }
            }
        }
        return [];
    }

    const plotAnswers = (subsection) => {
        let code = subsection.code;
        let type = subsection.type;
        let responses = findResponses(code);

        // Extract just the values for plotting
        let responseValues = responses.map(r => r.value);

        const containerId = `encuestas-chart-container-${code}`;
        const container = document.getElementById(containerId);
        if (!container) {
            return;
        }
        container.innerHTML = '';

        if (responseValues.length === 0) {
            return;
        }

        // Check if open question responses are all numbers
        if (type === 'openquestion') {
            const allNumbers = responseValues.every(value => {
                const trimmed = value.trim();
                return !isNaN(trimmed) && trimmed !== '';
            });

            if (allNumbers) {
                // Convert strings to numbers for plotting
                const numericValues = responseValues.map(v => Number(v.trim()));
                answersEncuestaNumber(numericValues, code);
                return;
            }
        }

        // Rest of the existing conditions
        if (type === 'slider') {
            answersEncuestaSlider(responseValues, code, false);
        } else if (type === 'multiplechoice' || type === 'likert') {
            answersEncuestaMultipleChoice(responseValues, code, subsection.alternatives);
        } else if (type === 'checkboxes') {
            answersEncuestaCheckboxes(responseValues, code, subsection.alternatives);
        } else if (type === 'number') {
            answersEncuestaNumber(responseValues, code);
        }
    }

    const handleSurveyTabClick = (tab, tabReport) => {
        //destroyCharts();
        setSurveyTab(tab);
    }

    useEffect(() => {
        if (surveyTab !== "Resumen" && surveys.encuestas) {
            const content = surveys.encuestas.find(encuesta => encuesta.name === surveyTab)?.contents ||
                surveys.instrumentos.find(instrumento => instrumento.name === surveyTab)?.contents;

            if (content) {
                // Wait for next render cycle to ensure containers exist
                setTimeout(() => {
                    content.forEach(section => {
                        section.sections.forEach(section => {
                            section.subsections.forEach(subsection => {
                                plotAnswers(subsection);
                            });
                        });
                    });
                }, 0);
            }
        }
    }, [surveyTab, surveys]);

    return (
        sectionData ?
            <Box width={"100%"} display={"flex"} justifyContent={"start"}>
                {surveys ?
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <List>
                                <ListItem key={"Resumen"} disablePadding sx={{ "backgroundColor": surveyTab === "Resumen" ? "#60696C" : "#efefef" }}>
                                    <ListItemButton onClick={() => handleSurveyTabClick("Resumen", null)}>
                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={surveyTab === "Resumen" ? "#efefef" : "#646464"}>Resumen</Typography>
                                    </ListItemButton>
                                </ListItem>
                                {surveys.instrumentos.length > 0 &&
                                    <>
                                        <Typography variant="p" sx={{
                                            color: "#646464",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "0.9em",
                                            marginTop: "15px",
                                            fontFamily: "Avenir Heavy"
                                        }}>Instrumentos</Typography>
                                        <List>
                                            {surveys.instrumentos.map(instrumento => (
                                                <ListItem key={instrumento.name} disablePadding sx={{ "backgroundColor": surveyTab === instrumento.name ? "#60696C" : "#efefef" }}>
                                                    <ListItemButton onClick={() => handleSurveyTabClick(instrumento.name, "reporte_instrumentos")}>
                                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={surveyTab === instrumento.name ? "#efefef" : "#646464"}>{instrumento.name}</Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                }
                                <Divider />
                                {surveys.encuestas.length > 0 &&
                                    <>
                                        <Typography variant="p" sx={{
                                            color: "#646464",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "0.9em",
                                            marginTop: "15px",
                                            fontFamily: "Avenir Heavy"
                                        }}>Encuestas</Typography>
                                        <List>
                                            {surveys.encuestas.map(encuesta => (
                                                <ListItem key={encuesta.name} disablePadding sx={{ "backgroundColor": surveyTab === encuesta.name ? "#60696C" : "#efefef" }}>
                                                    <ListItemButton onClick={() => handleSurveyTabClick(encuesta.name, "reporte_encuestas")}>
                                                        <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={surveyTab === encuesta.name ? "#efefef" : "#646464"}>{encuesta.name}</Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                }
                            </List>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            {surveyTab == null ?
                                <Box width={"100%"} textAlign={"center"}>
                                    <Typography variant='p' textAlign={"center"}>Por favor seleccione una pestaña.</Typography>
                                </Box>
                                : surveyTab === "Resumen" ?
                                    <Box>
                                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                            <Typography variant='p-small' textAlign={"center"}>Resumen de encuestas</Typography>
                                        </Box>
                                    </Box>
                                    :
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {(surveys.instrumentos.find(instrumento => instrumento.name === surveyTab)?.contents ||
                                                    surveys.encuestas.find(encuesta => encuesta.name === surveyTab)?.contents)?.map((content, idx) => (
                                                        <Box key={idx} sx={{ mb: 4 }}>
                                                            <Typography variant="title" sx={{ mb: 2 }}>{content.title}</Typography>
                                                            {content.sections.map((section, sectionIdx) => (
                                                                <Box key={sectionIdx} sx={{ mb: 2 }}>
                                                                    <Typography variant="p" sx={{ mb: 1, fontWeight: 'bold', maxWidth: '800px' }}>
                                                                        {section.number}. {section.enunciado}
                                                                    </Typography>
                                                                    {section.subsections.length > 1 && section.subsections.map((subsection, subIdx) => (
                                                                        <Box key={subIdx}>
                                                                            <Typography
                                                                                variant="p-small"
                                                                                sx={{
                                                                                    ml: 2,
                                                                                    display: 'block',
                                                                                    mb: 1,
                                                                                    maxWidth: '800px'
                                                                                }}
                                                                            >
                                                                                {section.number}.{subsection.number}. {subsection.enunciado}
                                                                            </Typography>
                                                                            <Typography sx={{ display: 'block', ml: 3, cursor: 'pointer' }} variant="p-small" fontSize="0.7em" color="#d23831" onClick={() => handleOpenModal(subsection)}>
                                                                                Ver respuestas
                                                                            </Typography>
                                                                            <Box
                                                                                id={`encuestas-chart-container-${subsection.code}`}
                                                                                className="encuestas-chart-container"
                                                                                sx={{ mb: 2, maxWidth: '800px' }}
                                                                            />
                                                                        </Box>
                                                                    ))}
                                                                    {section.subsections.length === 1 && (
                                                                        <Box>
                                                                            <Typography sx={{ display: 'block', ml: 1, cursor: 'pointer' }} variant="p-small" fontSize="0.7em" color="#d23831" onClick={() => handleOpenModal(section.subsections[0])}>
                                                                                Ver respuestas
                                                                            </Typography>
                                                                            <Box
                                                                                id={`encuestas-chart-container-${section.subsections[0].code}`}
                                                                                className="encuestas-chart-container"
                                                                                sx={{ mb: 2, maxWidth: '800px' }}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                            </Grid>
                                        </Grid>
                                        {currentSubsection &&
                                            <EncuestaAnswersModal
                                                open={openModal}
                                                handleClose={handleCloseModal}
                                                currentSubsection={currentSubsection}
                                                enrolledUsers={sectionData.enrolled_users}
                                                responses={findResponses(currentSubsection.code)}
                                                identifier={
                                                    isDiplomado ?
                                                        course.shortname + "_" +
                                                        course.modules[selectedModule].module + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].year + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].term + "_" +
                                                        course.modules[selectedModule].implementations[selectedImplementation].sections[selectedSection].section
                                                        :
                                                        course.shortname + "_" +
                                                        course.implementations[selectedImplementation].year + "_" +
                                                        course.implementations[selectedImplementation].term + "_" +
                                                        course.implementations[selectedImplementation].type.slice(0, 1).toUpperCase() + "_" +
                                                        course.implementations[selectedImplementation].sections[selectedSection].location + "_" +
                                                        course.implementations[selectedImplementation].sections[selectedSection].section
                                                }
                                                alerts={alerts}
                                                setAlerts={setAlerts}
                                                setAlertSuccess={setAlertSuccess}
                                                setAlertError={setAlertError}
                                            />
                                        }
                                    </>
                            }
                        </Grid>
                    </Grid>
                        :
                        <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                            <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
                        </Box>
                }
            </Box>
            :
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant={"p-error"} textAlign={"center"}>No hay información disponible.</Typography>
            </Box>
    );
}

export default CourseSectionEncuestas;