import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider, Link, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { postUpdateAsistenciaEvents } from '../../../requests/postUpdateAsistenciaEvents';


const EventsModal = ({
    keycloak,
    open,
    handleClose,
    courseKey,
    courseType,
    asistencia,
    setAsistencia,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {
    
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    const [eventsError, setEventsError] = useState("");

    const validateEvents = () => {
        let errors = {};

        return errors;
    }

    const handleAddEvent = () => {
        if (events.length >= 10) return;
        setEvents([...events, {
            id: null,
            event_name: '',
            event_date: '',
            asistencia: 0
        }]);
    };

    const handleRemoveEvent = (index) => {
        const newEvents = events.filter((_, i) => i !== index);
        setEvents(newEvents);
    };

    const handleEventChange = (index, field, value) => {
        const newEvents = [...events];
        newEvents[index][field] = value;
        setEvents(newEvents);
    };

    const handleSubmit = async () => {
        const errors = validateEvents();
        if (Object.keys(errors).length > 0) {
            setEventsError(errors);
            setLoading(false);
            return;
        }
        setLoading(true);
        let editData = {
            "course_key": courseKey,
            "course_type": courseType,
            "events": events
        }
        const response = await postUpdateAsistenciaEvents(keycloak.token, editData);
        if (response.error) {
            setAlertError(response.error);
            setLoading(false);
            return;
        }
        setAsistencia(response.data);
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": `¡Eventos actualizados!`, "text": `Los eventos han sido actualizados correctamente.` } });
        handleClose();
        setLoading(false);
    };

    useEffect(() => {
        if (open && asistencia && asistencia.length > 0) {
            const initialEvents = asistencia.map(item => ({
                id: item.id,
                event_name: item.event_name,
                event_date: item.event_date,
                asistencia: item.asistencia
            }));
            setEvents(initialEvents);
        } else if (open) {
            setEvents([]);
        }
    }, [open, asistencia]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Gestionar eventos
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2} pt={0}>
                    <Grid item xs={12}>
                        <Typography variant={"p-small"}>
                            Al crear un evento, este quedará habilitado para poder pasar asistencia a los docentes.
                            En caso de eliminar un evento, se eliminarán los datos de asistencia asociados a este.
                        </Typography>
                    </Grid>
                    {events.map((event, index) => (
                        <Grid item xs={12} key={index} container spacing={2} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Nombre del evento"
                                    fullWidth
                                    value={event.event_name}
                                    onChange={(e) => handleEventChange(index, 'event_name', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Fecha"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={event.event_date}
                                    onChange={(e) => handleEventChange(index, 'event_date', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {event.id && (
                                    <Typography variant='p-small'>
                                        Asistentes: {event.asistencia?.filter(item => item.asistencia === 1 || item.asistencia === 2).length || 0}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={() => handleRemoveEvent(index)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                        <IconButton 
                            onClick={handleAddEvent}
                            disabled={events.length >= 10}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                        <Button 
                            variant="red" 
                            onClick={handleSubmit}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EventsModal;