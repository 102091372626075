import React from 'react';
import { Box, Typography, Drawer, List, ListItem, ListItemButton, Divider } from '@mui/material';
import { mapDashboards } from '../../utils/mappers';


const drawerWidth = 240;


const Sidebar = ({
    isMobile,
    selectedDashboard,
    setSelectedDashboard,
    availableDashboards
}) => {

    const handleSectionClick = (section, type) => {
        setSelectedDashboard({ key: section, type: type });
    };

    return (
        availableDashboards?.length > 0 ?
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Box sx={{ overflow: 'auto', marginTop: "100px" }}>
                    <List>
                        {Object.entries(
                            availableDashboards.reduce((acc, dashboard) => {
                                if (dashboard.type === "fixed") {
                                    acc.fixed = acc.fixed || [];
                                    acc.fixed.push(dashboard);
                                } else {
                                    acc[dashboard.header] = acc[dashboard.header] || [];
                                    acc[dashboard.header].push(dashboard);
                                }
                                return acc;
                            }, {})
                        ).map(([header, dashboards]) => (
                            header === "fixed" ? (
                                dashboards.map((dashboard) => (
                                    <ListItem key={dashboard.key} disablePadding sx={{ backgroundColor: selectedDashboard?.key === dashboard.key && selectedDashboard?.type === "fixed" ? "#60696C" : "white" }}>
                                        <ListItemButton onClick={() => handleSectionClick(dashboard.key, "fixed")}>
                                            <Typography variant="p" sx={{ textAlign: "center", width: "100%" }} fontSize={"0.85em"} m={"5px 0"} color={selectedDashboard?.key === dashboard.key && selectedDashboard?.type === "fixed" ? "white" : "#646464"}>
                                                {mapDashboards(dashboard.key)}
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            ) : (
                                <React.Fragment key={header}>
                                    <Divider />
                                    <Typography variant="p" sx={{
                                        color: "#d23831",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: "1.1em",
                                        marginTop: "15px",
                                        fontFamily: "Avenir Heavy"
                                    }}>
                                        {header}
                                    </Typography>
                                    <List>
                                        {dashboards.map((dashboard) => (
                                            <ListItem key={dashboard.key} disablePadding sx={{ backgroundColor: selectedDashboard?.key === dashboard.key && selectedDashboard?.type === (header === "Diplomados" ? "diplomado" : "course") ? "#60696C" : "white" }}>
                                                <ListItemButton onClick={() => handleSectionClick(`${dashboard.key}`, header === "Diplomados" ? "diplomado" : "course")}>
                                                    <Typography variant="p" sx={{ textAlign: "left", width: "100%" }} fontSize={"0.85em"} m={"5px 0"} color={selectedDashboard?.key === dashboard.key && selectedDashboard?.type === (header === "Diplomados" ? "diplomado" : "course") ? "white" : "#646464"}>
                                                        {dashboard.key}
                                                    </Typography>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </React.Fragment>
                            )
                        ))}
                    </List>
                </Box>
            </Drawer>
            :
            <Box width={"100%"} textAlign={"center"} mt={"50px"}>
                <Typography variant="p" textAlign={"center"} color="error">
                    No tienes permiso para acceder a ningún dashboard.
                </Typography>
            </Box>
    );
}

export default Sidebar;