import axios from 'axios';

export const getXblockResponses = async (token, xblockId) => {

    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/xblock_responses/" + xblockId, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
        });
        return { data: response.data, error: "" };
    } catch (error) {
        return { data: null, error: "Error desconocido: " + error.message };
    }
}