import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import EvaluacionQuestionDetailsModal from '../modals/EvaluacionQuestionDetailsModal';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { renderXblock } from '../../../utils/formatters';
import { MathJax } from 'better-react-mathjax';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        lineHeight: '1.5',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
    },
}));

const CalificacionesTable = ({
    questions,
    enrolledUsers,
    isPreguntasCalificadas,
    title,
    identifier,
    alerts,
    setAlerts,
    setAlertError,
    setAlertSuccess
}) => {
    

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const [detailsModal, setDetailsModal] = useState(false);

    const handleDownloadCalificacionesCSV = () => {
        let header = [
            "Número",
            "Enunciado",
            "Respuestas",
            "Tasa de correctas",
        ];
        let lines = [];
        let output = [];
        for (let question of questions) {
            var thisLine = [];
            if (isPreguntasCalificadas) {
                const match = question.question[0]?.subsection_name?.toLowerCase().match(/pregunta calificada (.*)/);
                thisLine.push(match ? match[1].toUpperCase() : "");
                const text = question.question[0]?.last_paragraph || "—";
                const decodedText = document.createElement('textarea');
                decodedText.innerHTML = text;
                thisLine.push(decodedText.value);
            } else {
                thisLine.push(question.question?.find(block => block.block_type === "eolquestion")?.index ? question.question.find(block => block.block_type === "eolquestion").index : "");
                const text = question.question?.find(block => block.block_type === "eolquestion")?.text || "";
                const decodedText = document.createElement('textarea');
                decodedText.innerHTML = text;
                thisLine.push(decodedText.value);
            }
            
            thisLine.push(question.responses.length);
            thisLine.push(calculateCorrectRate(question));
            lines.push(thisLine);
        }
        output.push(header);
        for (let line of lines) {
            output.push(line);
        }
        const courseIdentifier = title.replace(/ /g, "_") + "_" + identifier;
        const fileName = "calificaciones_" + courseIdentifier + "_" + new Date().getTime();
        const csv = '\ufeff' + Papa.unparse(output, {
            encoding: 'utf-8'
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv"); 
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const renderMultipleXBlocks = (xblocks) => {
        let renderedXblocks = [];
        for (let xblock of xblocks.full_pre_question) {
            renderedXblocks.push(<MathJax key={xblock.block_key}>{renderXblock(xblock, false, null, false)}</MathJax>);
        }
        renderedXblocks.push(<MathJax key={xblocks.full_question.block_key}>{renderXblock(xblocks.full_question, false, null, false)}</MathJax>);
        return renderedXblocks;
    }

    const columns = [
        {
            field: 'number',
            disableColumnMenu: true,
            headerName: 'Número',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'question',
            disableColumnMenu: true,
            headerName: 'Enunciado',
            sortable: false,
            width: 600,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                    <MathJax>
                        <div
                            style={{ maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{
                                __html: params.value.replace(
                                    /<img/g,
                                    '<img style="max-width:100%;height:auto"'
                                )
                            }}
                        />
                    </MathJax>
                </Box>
            ),
        },
        {
            field: 'answers',
            disableColumnMenu: true,
            headerName: 'Respuestas',
            width: 120,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.answers.length,
        },
        {
            field: 'correct_rate',
            disableColumnMenu: true,
            headerName: 'Correctas',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Detalles',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={() => { setSelectedQuestion(params.row); setDetailsModal(true) }}
                >
                    <MoreVertIcon />
                </IconButton>
            ),
        },
    ];

    function calculateCorrectRate(question) {
        if (!question.responses || question.responses.length === 0) {
            return "—";
        }
        if (question.content.true_block_type === "openassessment" || question.content.true_block_type === "freetextresponse") {
            return "—";
        }
        let correctCount = 0;
        let totalResponses = question.responses.filter(response => response.response !== "").length;
        for (let response of question.responses) {
            if (response.is_correct !== undefined && response.is_correct) {
                correctCount++;
            } else if (response.is_correct === undefined && response.score === response.max_score) {
                correctCount++;
            }
        }
        const percentage = (correctCount / totalResponses * 100).toFixed(1);
        if (isNaN(percentage)) {
            return "—";
        }
        return `${percentage}%`;
    }

    function makeRows() {
        let rows = [];
        for (let question of questions) {
            // Filter out staff responses
            const nonStaffResponses = question.responses.filter(response => {
                const user = enrolledUsers.find(user => user.user_id === response.user_id);
                return user && !user.is_staff;
            });

            // Filter out empty responses
            const nonEmptyResponses = nonStaffResponses.filter(response => response.response !== null && response.response !== undefined && response.response !== "");

            if (isPreguntasCalificadas) {
                const match = question.question[0]?.subsection_name?.toLowerCase().match(/pregunta calificada (.*)/);
                const number = match ? match[1] : "";
                
                const lastParagraph = question.question[0]?.last_paragraph || "—";
                
                rows.push({
                    id: question.id,
                    number: number.toUpperCase(),
                    question: lastParagraph,
                    correct_rate: calculateCorrectRate({...question, responses: nonEmptyResponses}), // Pass filtered responses
                    answers: nonEmptyResponses, // Use filtered responses
                    full_pre_question: question.question,
                    full_question: question.content,
                    block_type: question.content.true_block_type
                });
            } else {
                rows.push({
                    id: question.id,
                    number: question.question?.find(block => block.block_type === "eolquestion")?.index ? question.question.find(block => block.block_type === "eolquestion").index : "",
                    question: question.question?.find(block => block.block_type === "eolquestion")?.text ? question.question.find(block => block.block_type === "eolquestion").text : "",
                    correct_rate: calculateCorrectRate({...question, responses: nonEmptyResponses}), // Pass filtered responses
                    answers: nonEmptyResponses, // Use filtered responses
                    full_pre_question: question.question,
                    full_question: question.content,
                    block_type: question.content.true_block_type
                });
            }
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Desglose de la evaluación</Typography>
                <Box>
                    <IconButton onClick={handleDownloadCalificacionesCSV}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(questions).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p-error' textAlign={"center"}>No hay datos de preguntas disponibles.</Typography>
            </Box>
            :
            <div style={{ maxWidth: '1280px' }}>
                <StyledDataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[10]}
                    onPaginationModelChange={setPaginationModel}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                {selectedQuestion &&
                    <>
                        <EvaluacionQuestionDetailsModal
                            open={detailsModal}
                            handleClose={() => setDetailsModal(false)}
                            xblock={selectedQuestion.full_question}
                            renderedXblock={renderMultipleXBlocks(selectedQuestion)}
                            responses={selectedQuestion.answers}
                            enrolledUsers={enrolledUsers}
                            title={title}
                            subtitle={selectedQuestion.number}
                            isPreTestPostTest={false}
                            identifier={identifier}
                            isOra={selectedQuestion.block_type === "openassessment"}
                            setAlertSuccess={setAlertSuccess}
                            setAlertError={setAlertError}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                    </>
                }
            </div>
    );
};

export default CalificacionesTable;