import React, { useEffect, useState, useCallback, memo } from 'react';
import { Button, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider, Link, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { postUpdateAsistenciaEvents } from '../../../requests/postUpdateAsistenciaEvents';
import { mapAsistencia } from '../../../utils/mappers';

// Create a memoized row component
const AttendanceRow = memo(({ 
    user, 
    formData, 
    onAssistanceChange, 
    onObservationsChange 
}) => {
    const getAssistanceColor = (value) => {
        switch(value) {
            case "0": return "#d23831";
            case "1": return "#8b8000";
            case "2": return "green";
            default: return "#646464";
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Typography>{user.username}</Typography>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    select
                    fullWidth
                    value={formData.assistance || "-"}
                    onChange={(e) => onAssistanceChange(user.user_id, e.target.value)}
                    size="small"
                    sx={{
                        '& .MuiInputBase-input': {
                            color: getAssistanceColor(formData.assistance)
                        }
                    }}
                >
                    <MenuItem value="-">Sin registro</MenuItem>
                    <MenuItem sx={{ color: "#d23831" }} value="0">{mapAsistencia("0")}</MenuItem>
                    <MenuItem sx={{ color: "#8b8000" }} value="1">{mapAsistencia("1")}</MenuItem>
                    <MenuItem sx={{ color: "green" }} value="2">{mapAsistencia("2")}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    placeholder="Observaciones"
                    value={formData.observaciones || ""}
                    onChange={(e) => onObservationsChange(user.user_id, e.target.value)}
                    size="small"
                />
            </Grid>
        </Grid>
    );
});

const AsistenciaModal = ({
    keycloak,
    open,
    handleClose,
    enrolledUsers,
    courseKey,
    courseType,
    events,
    eventId,
    setAsistencia,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});

    // Initialize form data when modal opens
    useEffect(() => {
        if (open && eventId) {
            const currentEvent = events.find(e => e.id === eventId);
            const initialFormData = {};
            
            // Initialize data for all enrolled users
            enrolledUsers.forEach(user => {
                // Find existing attendance data for this user
                const existingData = currentEvent.asistencia?.find(a => a.user_id === user.user_id);
                
                initialFormData[user.user_id] = {
                    assistance: existingData?.assistance || "-",
                    observaciones: existingData?.observaciones || ""
                };
            });
            
            setFormData(initialFormData);
        }
    }, [open, eventId, enrolledUsers]);

    const handleAssistanceChange = useCallback((userId, value) => {
        setFormData(prev => ({
            ...prev,
            [userId]: {
                ...prev[userId],
                assistance: value
            }
        }));
    }, []);

    const handleObservationsChange = useCallback((userId, value) => {
        setFormData(prev => ({
            ...prev,
            [userId]: {
                ...prev[userId],
                observaciones: value
            }
        }));
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        const currentEvent = events.find(e => e.id === eventId);
        
        // Create attendance data array from form data
        const attendanceData = enrolledUsers.map(user => ({
            id: user.user_id,
            user_id: user.user_id,
            assistance: formData[user.user_id].assistance !== "-" ? formData[user.user_id].assistance : null,
            observaciones: formData[user.user_id].observaciones
        }));

        // Create updated event with new assistance data
        const updatedEvent = {
            ...currentEvent,
            asistencia: attendanceData
        };

        // Update events array with new data
        const updatedEvents = events.map(event => 
            event.id === eventId ? updatedEvent : event
        );

        const editData = {
            "course_key": courseKey,
            "course_type": courseType,
            "events": updatedEvents
        };

        const response = await postUpdateAsistenciaEvents(keycloak.token, editData);
        if (response.error) {
            setAlertError(response.error);
            setLoading(false);
            return;
        }
        setAsistencia(response.data);
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Asistencia actualizada!", "text": "La asistencia ha sido actualizada correctamente." } });
        handleClose();
        setLoading(false);
    };

    const currentEvent = events.find(e => e.id === eventId);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Pasar asistencia — {currentEvent?.event_name}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2} pt={2}>
                    {enrolledUsers.map(user => (
                        <Grid item xs={12} key={user.user_id} sx={{ display: "flex", gap: 2 }}>
                            <AttendanceRow
                                user={user}
                                formData={formData[user.user_id] || {}}
                                onAssistanceChange={handleAssistanceChange}
                                onObservationsChange={handleObservationsChange}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
                        <Button 
                            variant="red" 
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default memo(AsistenciaModal);