import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getXblockResponses } from '../../../requests/getXblockResponses';
import { mapPlatforms, mapPlatformsStudio, mapXblockTypes } from '../../../utils/mappers';
import XblockResponsesTable from '../tables/XblockResponsesTable';
import { answersHistogramChartMultipleChoice, answersHistogramChartVOF, answersPieChart } from '../../../utils/charts';
import { getXBlockResponseIsCorrect, formatXBlockResponse } from '../../../utils/formatters';

const XblockDetailsModal = ({
    keycloak,
    open,
    handleClose,
    platform,
    courseKey,
    xblock,
    renderedXblock,
    enrolledUsers,
    identifier,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [xblockData, setXblockData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && xblockData && xblockData.length > 0) {
            setTimeout(() => {
                if (xblock.true_block_type === "multiplechoice" || xblock.true_block_type === "radiomultiplechoice") {
                    answersHistogramChartMultipleChoice(
                        xblockData.map(response => {
                            if (!response.response) return "";
                            const tempDiv = document.createElement('div');
                            tempDiv.innerHTML = formatXBlockResponse(xblock, response.response);
                            return tempDiv.textContent.slice(0, 1);
                        }),
                        renderedXblock
                    );
                } else if (xblock.true_block_type === "vof") {
                    answersHistogramChartVOF(
                        xblockData.map(response => formatXBlockResponse(xblock, response.response) || ""),
                        renderedXblock
                    );
                }
                if (xblock.true_block_type !== "openassessment" && xblock.true_block_type !== "freetextresponse" && xblock.true_block_type !== "table_advanced_problem") {
                    answersPieChart(
                        xblockData.map(response => {
                            if (!response) return null;
                            let r = getXBlockResponseIsCorrect(xblock, response.response);
                            return r;
                        })
                    );
                }
            }, 100);
        }
    }, [xblockData, open, xblock.true_block_type, renderedXblock]);

    const fetchXblockData = async () => {
        setLoading(true);
        const response = await getXblockResponses(keycloak.token, xblock.id);
        if (response.error) {
            setAlertError(true);
            setAlerts({
                ...alerts,
                error: { title: "Error al obtener datos del XBlock", text: response.error },
            });
        } else {
            setXblockData(response.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (xblock) {
            fetchXblockData();
        }
    }, [xblock]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"lg"}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Detalles del XBlock
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                {(loading && !xblockData) ? 
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress />
                </Box> : xblockData ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="p"><b>ID del block:</b> {xblock.block_key}</Typography>
                        <Typography variant="p"><b>Tipo:</b> {mapXblockTypes(xblock.true_block_type)}</Typography>
                        <Typography variant="p"><b>Total respuestas:</b> {xblockData.length}</Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"} gap={2}>
                        <Button variant="red" onClick={() => {
                            window.open(mapPlatforms(platform) + "/courses/" + courseKey + "/courseware/" + xblock.section_structural_id + "/" + xblock.subsection_structural_id + "/" + xblock.unit_index, "_blank");
                        }}>Ver en LMS</Button>
                        <Button variant="red" onClick={() => {
                            window.open(mapPlatformsStudio(platform) + "/container/" + courseKey.replace("course-v1:", "block-v1:") + "+type@vertical+block@" + xblock.unit_structural_id, "_blank");
                        }}>Ver en Studio</Button>
                    </Grid>
                    <Grid item xs={12}>
                        {renderedXblock}
                    </Grid>
                    <Grid item xs={12}>
                        <XblockResponsesTable
                            xblock={xblock}
                            responses={xblockData}
                            enrolledUsers={enrolledUsers}
                            identifier={identifier}
                            setAlertSuccess={setAlertSuccess}
                            setAlertError={setAlertError}
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                    </Grid>
                    {xblockData.length > 0 && (
                        <>
                            <Grid item xs={12} md={6} id="answers-histogram-container">
                            </Grid>
                            <Grid item xs={12} md={6} id="answers-pie-container">
                            </Grid>
                        </>
                    )}
                </Grid> :
                <Box>
                    <Typography textAlign="center" variant="p-error">Error al obtener datos del XBlock</Typography>
                </Box>
                }
            </DialogContent>
        </Dialog>
    );
}

export default XblockDetailsModal;