import React from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotasManualesTableMultipleGrades from '../tables/NotasManualesTableMultipleGrades';

const NotasManualesMultipleViewModal = ({
    open,
    handleClose,
    enrolledUsers,
    title,
    identifier,
    selectedEvaluation,
    setAlertError,
    setAlertSuccess,
    alerts,
    setAlerts
}) => {
    
    return (
        selectedEvaluation &&
            <Dialog
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxHeight: "80%",
                        padding: "10px 20px"
                    }
                }}
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}
            >
                <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                    {selectedEvaluation.evaluation_name}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <NotasManualesTableMultipleGrades
                                enrolledUsers={enrolledUsers}
                                selectedEvaluation={selectedEvaluation}
                                identifier={identifier}
                                title={title}
                                alerts={alerts}
                                setAlertError={setAlertError}
                                setAlertSuccess={setAlertSuccess}
                                setAlerts={setAlerts}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
    );
}

export default NotasManualesMultipleViewModal;