import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Home from '../Content/Home/Home';
import { Grid, Typography, Button, Box } from '@mui/material';
import whiteLogo from '../assets/img/logo.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const HomeWrapper = ({ keycloak }) => {

  const [permissions, setPermissions] = useState(null);
  const [keycloakReady, setKeycloakReady] = useState(false);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    document.title = "CMMEdu Analytics | Plataforma de datos y seguimiento";
    if (keycloak.didInitialize){
      trackPageView();
      if (keycloak.authenticated) {
        keycloak.loadUserProfile().then(function (profile) {
          setKeycloakReady(true);
          setPermissions({
            canSeeHome: true,
            dashboards: (profile.attributes && profile.attributes.sys_dashboards) ? profile.attributes.sys_dashboards[0].split(",") : [],
          });
        });
      } else {
        setKeycloakReady(true);
      }
    }
  }, [keycloak.authenticated]);

  return (
    (permissions && permissions.canSeeHome) ? <Home keycloak={keycloak} permissions={permissions} ></Home> : keycloakReady ?
      <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/*<Header />*/}
        <Grid
          p={"0 20px"}
          component="div"
          sx={{
            flexGrow: 1,
            m: '30px auto',
            maxWidth: '1400px',
            width: '100%',
          }}
        >
          <Box textAlign="center">
            <Box
                component="img"
                src={whiteLogo}
                alt="CMMEdu Analytics"
                style={{
                    width: '200px',
                    margin: '0 auto 50px auto',
                    filter: 'invert(15%) sepia(75%) saturate(4709%) hue-rotate(353deg) brightness(91%) contrast(90%)' // This creates the red color (#d23831)
                }}
            />
            <Typography variant='title' fontSize={{xs: "1.2em"}} mb={"20px"} textAlign={"center"}>Por favor identifíquese para poder acceder a esta plataforma.</Typography>
            <Button startIcon={<AccountCircleIcon></AccountCircleIcon>} onClick={() => keycloak.login()} variant='red'>Iniciar sesión</Button>
          </Box>
        </Grid>
      </Grid>
      : <></>
  );

}

export default HomeWrapper;