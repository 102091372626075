import React, { useState, useEffect } from 'react';
import { 
    Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button,
    TextField, MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { postNotasManuales } from '../../../requests/postNotasManuales';

const NotasManualesUniqueModal = ({
    keycloak,
    courseKey,
    courseType,
    open,
    handleClose,
    enrolledUsers,
    selectedNotasManuales,
    title,
    setNotasManuales,
    setAlertError,
    setAlertSuccess,
    alerts,
    setAlerts
}) => {

    const [grades, setGrades] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        
        // Check for duplicate users
        const userIds = grades.map(g => g.user_id);
        const hasDuplicates = userIds.length !== new Set(userIds).size;
        if (hasDuplicates) {
            newErrors.duplicate = "No puede haber más de una nota por usuario";
        }

        // Validate each grade entry
        grades.forEach((grade, index) => {
            if (!grade.user_id) {
                newErrors[`user_${index}`] = "Debe seleccionar un usuario";
            }
            
            const gradeValue = parseFloat(grade.grade);
            if (isNaN(gradeValue) || gradeValue < 1.0 || gradeValue > 7.0) {
                newErrors[`grade_${index}`] = "La nota debe estar entre 1.0 y 7.0";
            } else {
                // Check decimal places
                const decimalStr = grade.grade.toString().split('.')[1] || '';
                if (decimalStr.length > 1) {
                    newErrors[`grade_${index}`] = "La nota debe tener máximo un decimal";
                }
            }

            if (grade.observations && grade.observations.length > 500) {
                newErrors[`observations_${index}`] = "Las observaciones no pueden exceder 500 caracteres";
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleUpdateNotas = async () => {
        if (!validateForm()) return;
        setFormLoading(true);
        let response = await postNotasManuales(keycloak.token, title, {"0": grades}, courseKey, courseType);
        if (response.error !== "") {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al actualizar notas", "text": response.error } });
        } else {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Notas actualizadas!", "text": "Las notas han sido actualizadas correctamente." } });
            setNotasManuales(prevState => ({
                ...prevState,
                [title]: {
                    "0": grades
                }
            }));
        }
        handleClose();
        setFormLoading(false);
    };

    const addGradeRow = () => {
        setGrades([...grades, { user_id: "none", grade: "", observations: "" }]);
    };

    const removeGradeRow = (index) => {
        const newGrades = [...grades];
        newGrades.splice(index, 1);
        setGrades(newGrades);
    };

    const updateGradeRow = (index, field, value) => {
        const newGrades = [...grades];
        newGrades[index] = { ...newGrades[index], [field]: value };
        setGrades(newGrades);
    };

    const getAvailableUsers = (currentIndex) => {
        const usedUserIds = grades
            .filter((_, index) => index !== currentIndex)
            .map(g => g.user_id);
        return enrolledUsers.filter(user => !usedUserIds.includes(user.user_id));
    };

    useEffect(() => {
        setGrades(selectedNotasManuales?.map(
            grade => ({
                user_id: grade.user_id,
                grade: grade.grade,
                observations: grade.observations
            })
        ) || []);
    }, [selectedNotasManuales]);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 0 }}>
                Notas — {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    {errors.duplicate && (
                        <Grid item xs={12}>
                            <Typography color="error">{errors.duplicate}</Typography>
                        </Grid>
                    )}
                    {grades.map((grade, index) => (
                        <Grid item xs={12} key={index} container spacing={2} alignItems="center">
                            <Grid item xs={8} md={3}>
                                <TextField 
                                    fullWidth
                                    select
                                    error={!!errors[`user_${index}`]}
                                    value={grade.user_id}
                                    onChange={(e) => updateGradeRow(index, 'user_id', e.target.value)}
                                    label="Usuario"
                                >
                                    <MenuItem disabled value="none">Seleccione un docente...</MenuItem>
                                    {getAvailableUsers(index).map((user) => (
                                        <MenuItem key={user.user_id} value={user.user_id}>
                                            {user.username}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    fullWidth
                                    label="Nota"
                                    value={grade.grade}
                                    onChange={(e) => updateGradeRow(index, 'grade', e.target.value)}
                                    error={!!errors[`grade_${index}`]}
                                    helperText={errors[`grade_${index}`]}
                                />
                            </Grid>
                            <Grid item xs={10} md={6}>
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    value={grade.observations}
                                    onChange={(e) => updateGradeRow(index, 'observations', e.target.value)}
                                    error={!!errors[`observations_${index}`]}
                                    helperText={errors[`observations_${index}`]}
                                />
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <IconButton onClick={() => removeGradeRow(index)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    
                    <Grid item xs={12} display="flex" justifyContent="center" gap={2}>
                        <IconButton 
                            onClick={addGradeRow}
                            disabled={grades.length >= enrolledUsers.length}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                        <Button 
                            variant="red" 
                            onClick={handleUpdateNotas} 
                            disabled={formLoading}
                        >
                            Actualizar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default NotasManualesUniqueModal;