import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, Chip, CardContent, IconButton, Box } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CatalogDiplomadoModal from "../modals/catalog/diplomado/CatalogDiplomadoModal";
import CatalogDiplomadoEditModal from "../modals/catalog/diplomado/crud/CatalogDiplomadoEditModal";
import CatalogDiplomadoDeleteModal from "../modals/catalog/diplomado/crud/CatalogDiplomadoDeleteModal";
import { mapTopics, mapCycles } from '../../../utils/mappers';
import { formatTimestamp } from '../../../utils/formatters';


const CatalogDiplomado = ({
    keycloak,
    diplomadoIndex,
    catalog,
    setCatalog,
    setAlertSuccess,
    setAlertError,
    alerts,
    setAlerts
}) => {

    const [diplomadoData, setDiplomadoData] = useState(null);
    const [otherDiplomados, setOtherDiplomados] = useState(null);

    const [showDiplomadoModal, setShowDiplomadoModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleOpenDiplomadoModal = () => {
        setShowDiplomadoModal(true);
    }

    const handleCloseDiplomadoModal = () => {
        setShowDiplomadoModal(false);
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    }
    
    useEffect(() => {
        setDiplomadoData(catalog.diplomados[diplomadoIndex]);
        setOtherDiplomados(catalog.diplomados.filter(c => c !== catalog.diplomados[diplomadoIndex]));
    }, [catalog]);

    return (
        diplomadoData && 
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#fff' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"10px"}>
                    <Typography mb={"0px"} fontSize={"1.2em"} variant="title">{diplomadoData.shortname}</Typography>
                    <Box display={"flex"}>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenDiplomadoModal()}>
                            <ListIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20, marginRight: "5px" }} onClick={() => handleOpenEditModal()}>
                            <EditIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton sx={{ width: 20, height: 20 }} onClick={() => handleOpenDeleteModal()}>
                            <DeleteForeverIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Typography mb={0} textAlign={"justify"} fontSize={"1.0em"} variant="title">{diplomadoData.name}</Typography>
                <Box m={"10px 0"}>
                    <Chip style={{"marginRight": "5px"}} label={mapTopics("diplomado")} variant={"eje-tematico"} />
                    <Chip label={mapCycles(diplomadoData.cycle)} variant={diplomadoData.cycle} />
                </Box>
                <Typography fontSize={"0.8em"} variant="p">{diplomadoData.description}</Typography>
            </CardContent>
            <Grid container spacing={2} sx={{ padding: '20px', alignSelf: 'flex-end' }}>
                <Grid item xs={12} md={6}>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Módulos</u></b>: {diplomadoData.modules.length}
                    </Typography>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Implementaciones</u></b>: {diplomadoData.modules.reduce((acc, curr) => acc + curr.implementations.length, 0)}
                    </Typography>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Secciones</u></b>: {diplomadoData.modules.reduce((acc, curr) => acc + curr.implementations.reduce((acc, curr) => acc + curr.sections.length, 0), 0)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Participantes</u></b>: {diplomadoData.total_enrollments}
                    </Typography>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Aprobados</u></b>: {0}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography textAlign={"left"} fontSize={"0.8em"} variant="p">
                        <b><u>Última actualización</u></b>: {diplomadoData.last_update ? formatTimestamp(diplomadoData.last_update) : "—"}
                    </Typography>
                </Grid>
            </Grid>
            <>
                <CatalogDiplomadoModal
                    keycloak={keycloak}
                    open={showDiplomadoModal}
                    handleClose={handleCloseDiplomadoModal}
                    index={diplomadoIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogDiplomadoEditModal
                    keycloak={keycloak}
                    open={showEditModal}
                    handleClose={handleCloseEditModal}
                    index={diplomadoIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    otherDiplomados={otherDiplomados}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
                <CatalogDiplomadoDeleteModal
                    keycloak={keycloak}
                    open={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    index={diplomadoIndex}
                    catalog={catalog}
                    setCatalog={setCatalog}
                    setAlertSuccess={setAlertSuccess}
                    setAlertError={setAlertError}
                    alerts={alerts}
                    setAlerts={setAlerts}
                />
            </>
        </Card>
    )
}

export default CatalogDiplomado;
